import { Column } from 'primereact/column';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import YesNoDialog from '../components/YesNoDialog';
import PublicUnitService from '../services/PublicUnitService';
import CustomLabel from '../components/CustomLabel';
import CustomDataTable from '../components/CustomDataTable';
import DivContainer from '../components/DivContainer';
import { FaPen, FaEye } from 'react-icons/fa';
import InputNumberComponent from '../components/inputs/InputNumberComponent';

class PublicUnitContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new PublicUnitService());
		this.isHalfWidth = true;
		this.state = {
			loading: true,
			elementId: props.id,
			element: {
				name: '',
				publicUnitLp: 1,
			},
			showConfirmBlockDialog: false,
			showConfirmUnblockDialog: false,
			statusOptions: [],
			roleTypeOptions: [],
			showLogoutDialog: false,

		};
		this.deleteAccount = this.deleteAccount.bind(this);
		this.unblockAccount = this.unblockAccount.bind(this);
		this.showBlockConfirmation = this.showBlockConfirmation.bind(this);
		this.hideBlockConfirmation = this.hideBlockConfirmation.bind(this);
		this.showUnblockConfirmation = this.showUnblockConfirmation.bind(this);
		this.hideUnblockConfirmation = this.hideUnblockConfirmation.bind(this);
	}

	getContainerListName() {
		return 'public-unit-list-container';
	}

	getAddSucces() {
		return 'Jednostka została utworzona';
	}

	getUpdateSucces() {
		return 'Jednostka została zaktualizowana';
	}

	updateElement(data) {
		this.setState(
			{
				element: {
					id: data.id,
					name: data.name,
					users: data.users,
					publicUnitLp: data.publicUnitLp
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}


	showBlockConfirmation() {
		this.setState({ showConfirmBlockDialog: true });
	}

	hideBlockConfirmation() {
		this.setState({ showConfirmBlockDialog: false });
	}

	showUnblockConfirmation() {
		this.setState({ showConfirmUnblockDialog: true });
	}

	hideUnblockConfirmation() {
		this.setState({ showConfirmUnblockDialog: false });
	}

	deleteAccount() {
		this.blockUi();
		this.service
			.deleteAccount(this.state.element.id)
			.then((result) => {
				this.persistMessage('success', '', 'Jednostka została usunięta');
				window.location.href = this.props.backUrl;
			})
			.catch((err) => {
				this.showErrorMessage('Nie udało się usunąć konta jednostki');
				this.hideBlockConfirmation();
				this.unblockUi();
			});
	}

	unblockAccount() {
		this.blockUi();
		this.service
			.unblockAccount(this.state.element.id)
			.then((result) => {
				this.showSuccessMessage('Odblokowano konto użytkownika');
				this.updateElement(result);
				this.hideUnblockConfirmation();
				this.unblockUi();
			})
			.catch((err) => {
				this.showErrorMessage('Nie udało się odblokować konta użytkownika');
				this.hideUnblockConfirmation();
				this.unblockUi();
			});
	}

	//override
	prepareEditButton(rendered, label) {
		const { editUrl, viewMode } = this.props;
		const { element } = this.state;
		return {
			label: label !== undefined ? label : 'Edytuj',
			type: 'BUTTON',
			variant: '',
			className: 'float-right',
			href: `${editUrl}/${element.id}`,
			rendered: viewMode === 'VIEW' && (this.isUserInRole('ROLE_PUBLIC_UNIT_ACC_MANAGE') || this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') || this.isUserInRole('ROLE_PUBLIC_UNIT_ACC_LIMITED_MANAGE')),
			iconName: 'mdi-pencil',
			iconSide: 'left',
			iconSize: 'm',
		};
	}
	handleFormSubmit(event) {
		if (event !== undefined) {
			event.preventDefault();
		}
		if (this.validator.allValid()) {
			this.handleValidForm();
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.scrollToError = true;
			this.forceUpdate();
		}
	}
	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode } = this.props;
		const { elementId } = this.state;
		console.log(this.isUserInRole('ROLE_ADMIN_PUBLIC_UNIT_MANAGE'), 'ss');

		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${elementId}`;
		}
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
				label: 'Zapisz',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: this.isUserInAnyRole('ROLE_PUBLIC_UNIT_ACC_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE','ROLE_PUBLIC_UNIT_ACC_LIMITED_MANAGE') && (viewMode === 'NEW' || viewMode === 'EDIT'),
			},
			{
				label: 'Usuń',
				className: 'float-right mr-2',
				onClick: this.showBlockConfirmation,
				rendered: this.isUserInAnyRole('ROLE_PUBLIC_UNIT_ACC_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') && viewMode === 'VIEW'
			},
		];
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowa jednostka/dział' : viewMode === 'EDIT' ? 'Edycja jednostki/działu' : 'Szczegóły jednostki/działu',
				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(viewMode === 'VIEW' && this.isUserInRole('ROLE_ADMIN_PUBLIC_UNIT_ACC_MANAGE' && this.isUserInRole('ROLE_PUBLIC_UNIT_ACC_LIMITED_MANAGE'))),
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/public-unit-list' className='p-link'>
					{'Działy/jednostki'}
				</BreadcrumbsItem>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/public-unit/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/public-unit/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/public-unit/create'>{'Nowa'}</BreadcrumbsItem> : null}
				<YesNoDialog visible={this.state.showConfirmBlockDialog} header='Usuwanie jednostki' name='visible'
					onChange={(type, x, target) => { if (target.value) { this.deleteAccount(); } else { this.hideBlockConfirmation(); } }}
					onHide={() => { this.hideBlockConfirmation(); }}>
					Czy na pewno chcesz usunąć jednostkę/dział?
				</YesNoDialog>
				{this.renderView()}
			</div>
		);
	}
	renderDetails() {
		return (
			<React.Fragment>
				<div className='row'>
					<InputTextComponent
						id='name'
						name='name'
						label={'Nazwa'}
						colClass='col-md-12'
						value={this.state.element.name}
						validator={this.validator}
						validators='required|max:255'
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
					/>

					<InputNumberComponent
						id='publicUnitLp'
						name='publicUnitLp'
						label={'Lp.'}
						colClass='col-md-6'
						min="1"
						value={this.state.element.publicUnitLp}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'naturalNumber|required'}
					/>
				</div>
				{this.props.viewMode !== 'NEW' ? this.renderUsersInfo() : null}

			</React.Fragment>
		);
	}
	renderUsersInfo() {
		return (
			<div className='row'>
				<React.Fragment><CustomLabel
					colClass="col-12"
					label="Osoby przypisane"
					rendered={this.state.element.users && this.state.element.users.length}
				/>
					<DivContainer
						rendered={this.state.element.users && this.state.element.users.length}
					>
						<CustomDataTable
							value={this.state.element.users ? this.state.element.users : null}
							emptyMessage="Brak rekordów do wyświetlenia"
							responsive
						>
							<Column header="Osoba" body={user => { return <span>{user.firstName} {user.lastName}{!!user.removed ? ' (Usunięty)' : !!user.blocked ? ' (Zablokowany)' : ''}</span> }} />
							<Column header="Uprawnienia" body={this.renderPermissions.bind(this)} />
						</CustomDataTable>
					</DivContainer></React.Fragment>
			</div>
		);

	}
	renderPermissions(user) {
		return user.permissions ? user.permissions.map((i, index) => {
			//todo STYLE!
			return i.editable && i.editable === 'Odczyt i zapis' ? <span ><FaPen /><span style={{ marginLeft: "3px", marginRight: "3px" }}>{i.description}<br/></span></span> : <span><FaEye /><span style={{ marginLeft: "3px", marginRight: "3px" }}>{i.description}<br/></span></span>
		}) : null
	}
}

PublicUnitContainer.defaultProps = {
	backUrl: '/#/public-unit-list',
	cancelUrl: '/#/public-unit/details',
	editUrl: '/#/public-unit/edit',
};

PublicUnitContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
	editUrl: PropTypes.string,
};

export default PublicUnitContainer;
