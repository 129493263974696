import {Messages} from 'primereact/messages';
import React from 'react';
import {Link} from 'react-router-dom';
import BaseContainer from '../../baseContainers/BaseContainer';
import ActionButton from '../../components/ActionButton';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import SimpleReactValidator from '../../components/validator';
import BlockUi from '../../components/waitPanel/BlockUi';
import TestSurveyService from '../../services/TestSurveyService';

class SurveyResultGenerator extends BaseContainer {
    constructor() {
        super();
        this.state = {
            count: '',
            countMobileUser: 5,
            surveyId: '',
        };
        this.validator = new SimpleReactValidator();
    }

    componentWillMount() {
        this.setState({
            valid: true,
            count: '',
            countMobileUser: 5,
            surveyId: ''
        });
    }


    handleFormSubmit(e, option) {
        e.preventDefault();
        switch (option) {
            case 'survey':
                if (this.validator.fieldValid('count') && this.validator.fieldValid('surveyId')) {
                    this.blockUi();
                    new TestSurveyService()
                        .answer(this.state.count, this.state.surveyId)
                        .then(() => {
                            this.showSuccessMessage('Ankieta została zaktaulizowana');
                            this.validator.hideMessages();
                            this.forceUpdate();
                            this.unblockUi();
                        })
                        .catch(err => {
                            this.showErrorMessage(err.message);
                            this.unblockUi();
                        });
                } else {
                    this.errorValidate();
                }
                break;
            case 'mobileUser':
                if (this.validator.fieldValid('countMobileUser')) {
                    this.blockUi();
                    new TestSurveyService()
                        .generateSomeMobileUsers(this.state.countMobileUser)
                        .then(() => {
                            this.showSuccessMessage('Mobilni użytkownicy zostali dodani');
                            this.validator.hideMessages();
                            this.forceUpdate();
                            this.unblockUi();
                        })
                        .catch(err => {
                            this.showErrorMessage(err.message);
                            this.unblockUi();
                        });
                } else {
                    this.errorValidate();
                }
                break;
        }
    }

    errorValidate = () => {
        this.validator.showMessages();
        // rerender to show messages for the first time
        this.setState({
            valid: false,
        });
        this.forceUpdate();
    }

    render() {
        let valid = this.state.valid;
        return (
            <React.Fragment>

                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>

                    <div>
                        <Messages id="custom-messages" ref={(el) => (this.messages = el)}/>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-10 col-lg-6 mx-auto'>
                                    <div className='card my-5'>
                                        <div className='card-body'>
                                            <div className='float-right'>
                                                <Link to='/'>
                                                    <span className='p-button-text p-c'>{'Wróć'}</span>
                                                </Link>
                                            </div>
                                            <h5 className='card-title text-align-left'>{'Generowanie wyników ankiety'}</h5>
                                            <form className='form-signin' onSubmit={this.handleFormSubmit}>
                                                <InputTextComponent
                                                    id='count'
                                                    name='count'
                                                    label='Liczba odpowiedzi'
                                                    classLabel="black-label"
                                                    value={this.state.count}
                                                    stateField=''
                                                    onChange={this.handleChange}
                                                    className={valid ? 'form-control' : 'form-control invalid'}
                                                    type='text'
                                                    validators='required|number'
                                                    validator={this.validator}
                                                    viewMode='NEW'
                                                    colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12'
                                                    placeholder='Liczba odpowiedzi'
                                                />
                                                <InputTextComponent
                                                    id='surveyId'
                                                    name='surveyId'
                                                    label='Id ankiety'
                                                    classLabel="black-label"
                                                    value={this.state.surveyId}
                                                    stateField=''
                                                    onChange={this.handleChange}
                                                    className={valid ? 'form-control' : 'form-control invalid'}
                                                    type='text'
                                                    validators='required|number'
                                                    validator={this.validator}
                                                    viewMode='NEW'
                                                    colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12'
                                                    placeholder='Id ankiety'
                                                />
                                                <ActionButton label='Wygeneruj' variant='login-button'
                                                              handleClick={(e) => this.handleFormSubmit(e, 'survey')}/>


                                                <h5 className='card-title text-align-left'>{'Generowanie użytkowników mobilnych'}</h5>

                                                <InputTextComponent
                                                    id="countMobileUser"
                                                    name="countMobileUser"
                                                    label='Liczba nowych ziomeczków mobilnych'
                                                    classLabel="black-label"
                                                    value={this.state.countMobileUser}
                                                    stateField=''
                                                    onChange={this.handleChange}
                                                    className={valid ? 'form-control' : 'form-control invalid'}
                                                    type='text'
                                                    validators='not_required|number'
                                                    validator={this.validator}
                                                    viewMode='NEW'
                                                    colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12'
                                                    placeholder='Liczba nowych użytkowników mobilnych'
                                                />
                                                <ActionButton label='Wygeneruj' variant='login-button'
                                                              handleClick={(e) => this.handleFormSubmit(e, 'mobileUser')}/>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BlockUi>
            </React.Fragment>
        );
    }
}

export default SurveyResultGenerator;
