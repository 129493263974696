import BaseServiceCrud from './BaseServiceCrud';

export default class PhoneNumberContentService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'phone-number-content';
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,
            phoneNumbers: criteria.phoneNumbers,
            id: criteria.id,
            first_result: criteria.firstResult,
            max_result: criteria.maxResult,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    getContent(type) {
        return this.fetch(`${this.domain}/${this.path}/content/` + type, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
