import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import InternalUserService from '../services/InternalUserService';
import UserRoleService from '../services/UserRoleService';

class UserListContainer extends BaseListContainer {
	constructor(props) {
		super(props, new InternalUserService());
		this.roleService = new UserRoleService();
		this.state = {
			list: [],
			loading: true,
			size: 0,
			first: 0,
			criteria: this.getCleanSearchCriteria(),
			statusOptions: [],
			roleOptions: [],
		};
	}

	updateSearchCriteria(criteria) {
		return {
			email: criteria.email,
			firstName: criteria.firstName,
			lastName: criteria.lastName,
			role: criteria.role,
			firstResult: criteria.firstResult,
			maxResult: criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}

	getCleanSearchCriteria() {
		return {
			email: '',
			firstName: '',
			lastName: '',
			role: undefined,
			id: null,
			firstResult: 0,
			maxResult: 10,
			sortField: 'id',
			sortAsc: true,
		};
	}

	initializeFromBackend() {
		this.enumService
			.getEnumList('UserActivityStatus')
			.then((statusOptions) =>
				this.setState({
					statusOptions,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy statusów');
			});
		this.roleService
			.getRoles()
			.then((roles) => this.setState({ roleOptions: roles }))
			.catch((err) => {
				this.messages.show({
					severity: 'error',
					summary: 'Błąd',
					detail: err.message,
				});
			});
	}

	getCriteriaName() {
		return 'user-list-sc';
	}

	getContainerListName() {
		return 'user-list-container';
	}

	renderCriteria() {
		return (
			<div className='row'>
				<CriteriaTextComponent
					id='email-sc'
					name='email'
					label={'Email'}
					colClass='col-lg-4 col-sm-6'
					value={this.state.criteria.email}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaTextComponent
					id='firstName-sc'
					name='firstName'
					label={'Imię'}
					colClass='col-lg-4 col-sm-6'
					value={this.state.criteria.firstName}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaTextComponent
					id='lastName-sc'
					name='lastName'
					label={'Nazwisko'}
					colClass='col-lg-4 col-sm-6'
					value={this.state.criteria.lastName}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaDropdownComponent
					id='role-sc'
					name='role'
					showClear
					label={'Rola'}
					colClass='col-lg-4 col-sm-6'
					value={this.state.criteria.role}
					options={this.state.roleOptions}
					placeholder='Wszystkie'
					onChange={this.handleChangeSc}
					validator={this.validator}
					optionLabel='name'
					dataKey='id'
					disabled={this.state.roleOptions.length <= 1}
				/>
			</div>
		);
	}

	prepareColumns() {
		return [
			{ field: 'email', header: 'Email', sortable: true },
			{ field: 'firstName', header: 'Imię', sortable: true },
			{ field: 'lastName', header: 'Nazwisko', sortable: true },
			{ field: 'role.name', header: 'Rola', sortable: true, },
			{
				key: 'actions',
				header: 'Szczegóły',
				body: this.actionTemplate,
				className: 'table-column-action',
			},
		];
	}

	prepareHeaderItems() {
		return this.isUserInAnyRole('ROLE_INTERNAL_USER_MANAGE', 'ROLE_PUBLIC_UNIT_USER_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')
			? [
				{
					label: 'Nowe konto użytkownika',
					href: this.props.newUrl,
					type: 'BUTTON',
					className: 'float-right',
					variant: 'yellow',
					iconName: 'mdi-plus',
					iconSide: 'left',
					iconColor: 'white',
				},
			]
			: [];
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/user-list'>{'Użytkownicy'}</BreadcrumbsItem>
				{this.renderView()}
			</div>
		);
	}
}

UserListContainer.defaultProps = {
	detailUrl: '/#/user/details',
	newUrl: '/#/user/create',
};

UserListContainer.propTypes = {
	detailUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default UserListContainer;
