/* eslint-disable react/jsx-handler-names */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ActionLink from '../ActionLink';

//deprecated
const COLOR = {
    WHITE: '#F5F3FD',
    GREEN: '#B3E2B2',
    RED: '#F7C6B7',
    YELLOW: '#FFEEB5',
    BLUE: '#BFDAFF',
    BLACK: '#000000'
}



class TileComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const { children, colClass, styling, variant, fullBg, colorTitle, colorBackground, colorContent,
            colorLink, title, content, linkTitle, rendered, renderedLink, iconLinkName } = this.props;

        let cTitle = this.specifyColor(colorTitle);
        let cBackground = this.specifyColor(colorBackground);
        let cContent = this.specifyColor(colorContent);
        let cLink = this.specifyColor(colorLink);
        let cHexLink = this.specifyColor(colorLink);

        switch (variant) {
            case "blue":
                cTitle = this.specifyColor('white');
                cBackground = this.specifyColor('white');
                cContent = this.specifyColor('white');
                cLink = this.specifyColor('white');
                cHexLink = this.specifyColor('white');
                break;
            case "green":
                cTitle = this.specifyColor('white');
                cBackground = this.specifyColor('white');
                cContent = this.specifyColor('white');
                cLink = this.specifyColor('white');
                cHexLink = this.specifyColor('white');
                break;
            case "red":
                cTitle = this.specifyColor('white');
                cBackground = this.specifyColor('white');
                cContent = this.specifyColor('white');
                cLink = this.specifyColor('white');
                cHexLink = this.specifyColor('white');
                break;
            case "yellow":
                cTitle = this.specifyColor('white');
                cBackground = this.specifyColor('white');
                cContent = this.specifyColor('white');
                cLink = this.specifyColor('white');
                cHexLink = this.specifyColor('white');
                break;
        }

        if (rendered) {
            return (

                <div className={colClass} style={styling}>
                    <div className='tile'>
                        <div className={`card py-2`} style={{ backgroundColor: 'white', borderRadius: '20px', borderTop: '7.5px #51379B solid'}}>
                            <div class="card-header-tile">
                                <div className="col-12 center-in-div">
                                    <div className="row">
                                        <div className={`text-xs font-weight-bold text-uppercase text-center`} style={{ padding: "5px" }}>
                                            <span>{title}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body center-in-div">
                                <div className="col-12 center-in-div">
                                    <div className="row align-items-center">
                                        <div className={`content h3 mb-0 text-center font-weight-bold`}>{content}</div>
                                    </div>
                                    {children}
                                </div>
                            </div>
                            {renderedLink ? (
                                <div className="card-footer d-flex">
                                    <div className="row">
                                        <div className="col-8">
                                            <ActionLink
                                                iconColor={cLink}
                                                linkColor={cHexLink}
                                                label={linkTitle}
                                                handleClick={e => this.props.onLinkClick(e)} />
                                        </div>
                                        <div className="col-4 center-in-div">
                                            <ActionLink
                                                iconSize="l"
                                                iconColor='white'
                                                linkColor={cHexLink}
                                                iconName="mdi-chevron-right"
                                                handleClick={e => this.props.onLinkClick(e)} />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

            );
        } else {
            return null;
        }
    }


    specifyColor(color) {
        if (color !== null) {
            switch (color) {
                case "white":
                    return COLOR.WHITE;
                case "blue":
                    return COLOR.BLUE;
                case "green":
                    return COLOR.GREEN;
                case "red":
                    return COLOR.RED;
                case "yellow":
                    return COLOR.YELLOW;
            }
        } else {
            return COLOR.BLACK;
        }
    }
}

TileComponent.defaultProps = {
    colClass: 'col-xl-3 col-md-6 mb-4',
    styling: undefined,
    rendered: true,
    variant: 'default',
    fullBg: false,
    colorTitle: 'blue',
    colorBackground: 'white',
    colorContent: 'black',
    colorLink: 'blue',
    renderedLink: true,
    linkTitle: "Przejdź do szczegółów",
    iconLinkName: " mdi-arrow-right-bold-circle-outline"
};

TileComponent.propTypes = {
    //wariant kolorka
    variant: PropTypes.string,
    //kolorowe tło!
    fullBg: PropTypes.bool,
    //poniżej można sobie kolorkami sterować dowolnie
    colorTitle: PropTypes.string,
    colorBackground: PropTypes.string,
    colorContent: PropTypes.string,
    colorLink: PropTypes.string,
    //czy renderować footer z linkiem
    renderedLink: PropTypes.bool,
    //tekst tytuł
    title: PropTypes.string,
    //tekst główny
    content: PropTypes.string,
    //tekst linku
    linkTitle: PropTypes.string,
    //event z klikiem na link
    onLinkClick: PropTypes.func,
    //bootstrapowe rozłożenie
    colClass: PropTypes.string,
    //czy renderować komponent
    rendered: PropTypes.bool,
    styling: PropTypes.string,
}

export default TileComponent;
