import React from 'react';
import BaseStaticContentContainer from "../baseContainers/BaseStaticContentContainer";
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import PropTypes from "prop-types";

class RegulationsContainer extends BaseStaticContentContainer {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            element: [],
        };
    }

    getBreadcrumbsItem() {
        return <BreadcrumbsItem to='/regulations'>{'Regulamin'}</BreadcrumbsItem>;
    }

    //override
    setElement() {
        const {contentType} = this.props;
        const {viewMode} = this.props;
        if (contentType && (viewMode === 'EDIT' || viewMode === 'VIEW')) {
            this.blockUi();
            this.service
                .getRegulations(contentType)
                .then((data) => {
                    this.setState({loading: false}, () => this.updateElement(data));
                })
                .catch((err) => {
                    this.handleGetDetailsError(err);
                });
        } else {
            this.setState({loading: false}, this.initAfterSetElement());
        }
    }

}

RegulationsContainer.defaultProps = {
    backUrl: '/#/start',
    contentType: 'ADMIN_REGULATIONS'
};

RegulationsContainer.propTypes = {
    contentType: PropTypes.string.isRequired,
    backUrl: PropTypes.string.isRequired,
};

export default RegulationsContainer;
