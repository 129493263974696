import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Files from '../base/Files';
import ActionLink from '../ActionLink';
import BaseInputComponent from './BaseInputComponent';


const mimeTypeRegexp = /^(application|audio|example|image|message|model|multipart|text|video)\/[a-z0-9\.\+\*-]+$/;
const extRegexp = /\.[a-zA-Z0-9]*$/;

class InputImageComponent extends BaseInputComponent {
	constructor(props, context) {
		super(props, context);
		this.filesRef = React.createRef();
		this.error = false;
		this.state = { errorMsg: undefined };

		this.handleDrop = this.handleDrop.bind(this);
		this.handleDragEnter = this.handleDragEnter.bind(this);
		this.handleDragLeave = this.handleDragLeave.bind(this);
		this.openFileChooser = this.openFileChooser.bind(this);
	}

	handleDragEnter() {
		const { dropActiveClassName } = this.props;
		const el = this.dropzone;
		el.className += ` ${dropActiveClassName}`;
	}

	handleDragLeave() {
		const { dropActiveClassName } = this.props;
		const el = this.dropzone;
		this.dropzone.className = el.className.replace(` ${dropActiveClassName}`, '');
	}

	handleDragOver(event) {
		event.preventDefault();
		event.stopPropagation();
	}

	renderView() {
		const {
			colClass,
			formGroup,
			iconColor,
			iconName,
			iconSide,
			iconSize,
			id,
			insideTable,
			label,
			maxHeight,
			maxWidth,
			objectId,
			showLabel,
			titleElement,
			titleElementClass,
			titleElementShowId,
			value,
			validateViewMode,
			validator,
			validators,
		} = this.props;
		return (
			<div className={insideTable ? '' : titleElement ? titleElementClass : colClass}>
				<div className={insideTable ? '' : 'row'}>
					<div className={insideTable ? '' : `col-md-12 ${formGroup ? 'form-group' : ''}`}>
						{label !== undefined && showLabel && !titleElement ? (
							<label
								id={`${id}-label-id`}
								className="p-label"
								htmlFor={id}
								style={{ width: '100%' }}
							>
								{label}
							</label>
						) : null}
						<span
							aria-label={label}
							aria-labelledby={`${id}-label-id`}
							className={`p-inputtext-view icon_text ${titleElement ? ' title-element' : ''} ${
								iconName !== undefined ? iconColor : ''
							}`}
						>
							{iconSide === 'left' && iconName !== undefined ? (
								<i className={`icon mdi ${iconName} ${iconSize}`} />
							) : null}
							
							{value ? (
							<img src={`data:image;base64, ${value}`} alt={label} style={{maxWidth, maxHeight}}/>
							) : null}
							{iconSide === 'right' && iconName !== undefined ? (
								<i className={`icon mdi ${iconName} ${iconSize}`} />
							) : null}
						</span>
						{titleElement && titleElementShowId ? (
							<span className="p-inputtext-view">{` ID: ${objectId}`}</span>
						) : null}
						{validateViewMode ? this.renderValidatorsMessages(validator, id, label, value, validators) : null}
					</div>
				</div>
			</div>
		);
	}

	renderEdit() {
		return this.renderNew();
	}

	// getBase64 = (file) => {
	// 	return new Promise(resolve => {
	// 	  let fileInfo;
	// 	  let baseURL = "";
	// 	  let reader = new FileReader();
	// 	  reader.readAsDataURL(file);	
	// 	  reader.onload = () => {
	// 		baseURL = reader.result;
	// 		resolve(baseURL);
	// 	  };
	// 	  console.log(fileInfo);
	// 	});
	//   };
	
	  handleFileInputChange = e => {
		console.log(e.target.files[0]);
		let { file } = this.state;
	
		file = e.target.files[0];
	
		// this.getBase64(file)
		//   .then(result => {
		// 	file["base64"] = result;
		// 	console.log("File Is", file);
		// 	this.setState({
		// 	  base64URL: result,
		// 	  file
		// 	});
		//   })
		//   .catch(err => {
		// 	console.log(err);
		//   });
	
		this.setState({
		  file: e.target.files[0]
		});
	  };


	// fileExtension(file) {
	// 	const extensionSplit = file.name.split('.');
	// 	if (extensionSplit.length > 1) {
	// 		return extensionSplit[extensionSplit.length - 1];
	// 	} else {
	// 		return 'none';
	// 	}
	// }

	fileSizeReadable(size) {
		if (size >= 1000000000) {
			return `${Math.ceil(size / 1000000000)}GB`;
		} else if (size >= 1000000) {
			return `${Math.ceil(size / 1000000)}MB`;
		} else if (size >= 1000) {
			return `${Math.ceil(size / 1000)}kB`;
		} else {
			return `${Math.ceil(size)}B`;
		}
	}

	
	openFileChooser(e) {		
		console.log('openFileChooser', e);
		if (this.notOpenFileChooser) {
			this.notOpenFileChooser = false;
			return;
		}
		this.inputElement.value = null;
		this.inputElement.click();
	}

	fileTypeAcceptable(file) {
		if (file.type) {
			const type = file.type.split('/')[0];
			if (type === 'image') {
				return true;
			}
		}
		if (this.props.onError) {
			this.props.onError({
				code: 1,
				message: `${file.name}: plik nie jest plikiem graficznym.`,
			}, file);
		}
		return false;		
	}

	fileSizeAcceptable(file) {
		const { maxFileSize, minFileSize } = this.props;
		if (file.size > maxFileSize) {
			let maxFileSizeMessage = '';
			if (maxFileSize >= 1000000000) {
				maxFileSizeMessage = `${(maxFileSize / 1000000000).toFixed(2)} GB`;
			} else if (maxFileSize >= 1000000) {
				maxFileSizeMessage = `${(maxFileSize / 1000000).toFixed(2)} MB`;
			} else if (maxFileSize >= 1000) {
				maxFileSizeMessage = `${(maxFileSize / 1000).toFixed(2)} KB`;
			} else {
				maxFileSizeMessage = `${maxFileSize} B`;
			}
			if (this.props.onError) {
				this.props.onError(
				{
					code: 2,
					message: `${
						file.name
					}: Niedopuszczalny rozmiar pliku, maksymalny rozmiar pliku to ${maxFileSizeMessage}.`,
				}, file
				);
			}	
			return false;
		} else if (file.size < minFileSize) {
			if (this.props.onError) {
				this.props.onError(
					{
						code: 3,
						message: `${file.name}: plik jest za mały.`,
					},
					file
				);
			}
			return false;
		} else {
			return true;
		}
	}

	handleDrop(event) {
		const { onAfterStateChange, stateField, name } = this.props;
		event.preventDefault();
		this.handleDragLeave(event);

		// Collect added files, perform checking, cast pseudo-array to Array,
		// then return to method
		let file;
		if (event.dataTransfer && event.dataTransfer.files.length > 0) {
			file = event.dataTransfer.files[0];
		} else if (event.target.files.length > 0) {
			file = event.target.files[0];
		}
		if (!file) {
			return;
		}

		
		//file.id = `files-${this.id++}`;
		//file.extension = this.fileExtension(file);
		file.sizeReadable = this.fileSizeReadable(file.size);

		if (!this.fileTypeAcceptable(file)) {
			return;
		}
		if (!this.fileSizeAcceptable(file)) {
			return;
		}

		
		file.preview = {
			type: 'image',
			url: window.URL.createObjectURL(file),
		};

		if (this.props.onChange) {
			if (!event.target.name) {
				event.target.name = name;
			}
			this.props.onChange('IMAGE', undefined, event, onAfterStateChange, stateField);
		}
		

	}

	handleRemoveImage(e) {
		e.preventDefault();
		this.notOpenFileChooser = true;
		if (this.props.onRemoveImage){
			this.props.onRemoveImage();
		}
	}

	renderNew() {
		const {
			colClass,
			className,
			id,
			insideTable,
			label,
			name,
			showLabel,
			validator,
			validators,
			value,
		} = this.props;
		const inputAttributes = {
			type: 'file',
			id,
			accept: 'image/*',
			multiple: false,
			name,
			style: { display: 'none' },
			ref: element => {
				this.inputElement = element;
			},
			onChange: this.handleDrop,
			tabIndex: 0,
		};

		return (
			<div className={insideTable ? '' : colClass}>
				<div className={insideTable ? '' : 'row'}>
					<div className={insideTable ? '' : 'col-md-12 form-group'}>
						{label !== undefined && showLabel ? (
							<label
								id={`${id}-label-id`}
								className="p-label"
								htmlFor={id}
								style={{ width: '100%' }}
							>
								{label}
							</label>
						) : null}
						<div>
							<input
								{...inputAttributes}
								ariaLabel={label}
								ariaLabelledBy={label === undefined && showLabel ? `${id}-label-id` : undefined}
								ariaDescribedBy={`${id}-error`}
							/>
							<div
								title={`${label} kliknij "Enter" aby wybrać plik graficzny`}
								onKeyDown={e => {
									if (e.key === 'Enter') {
										e.preventDefault();
										this.openFileChooser(e);
									}
								}}
								tabIndex="0"
								className={`${className} image-droparea`}
								onClick={(e) => this.openFileChooser(e)}
								onDrop={this.handleDrop}
								onDragOver={this.handleDragOver}
								onDragEnter={this.handleDragEnter}
								onDragLeave={this.handleDragLeave}
								ref={dropzone => {
									this.dropzone = dropzone;
								}}
							>
							{value ? (
								<React.Fragment>
									<img src={`data:image;base64,${value}`} name={name} alt={label} style={{maxWidth: this.props.maxWidth, maxHeight: this.props.maxHeight}}/>
									<ActionLink
										key={`${id}-remove-button`}
										className="file-upload-actionlink"
										label=""
										handleClick={e => this.handleRemoveImage(e)}
										iconName="mdi-close-circle-outline"
										iconColor="grey"
										iconSize="m"
										iconSide="left"
									/>
								</React.Fragment>
							) : (
							<div name={name} >Upuść plik tutaj lub kliknij aby przesłać</div>
							)}
							</div>
						</div>
							{this.renderValidatorsMessages(validator, id, label, value, validators)}
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { rendered, viewMode } = this.props;
		if (rendered) {
			switch (viewMode) {
				case 'NEW':
					return this.renderNew();
				case 'EDIT':
					return this.renderEdit();
				case 'VIEW':
				default:
					return this.renderView();
			}
		} else {
			return null;
		}
	}
}

InputImageComponent.defaultProps = {
	colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
	disabled: false,
	formGroup: true,
	iconSide: 'left',
	insideTable: false,
	maxFileSize: 100000,
	maxHeight: '100px',
	maxWidth: '100px',
	minFileSize: 100,
	objectId: '',
	placeholder: '',
	publicMode: false,
	rendered: true,
	showLabel: true,
	stateField: 'element',
	titleElement: false,
	titleElementShowId: false,
	titleElementClass: 'col-md-12',
	validateViewMode: false,
	validators: 'required',
	viewMode: 'VIEW',
};

InputImageComponent.propTypes = {
	colClass: PropTypes.string,
	disabled: PropTypes.bool,
	formGroup: PropTypes.bool,
	iconColor: PropTypes.string,
	iconName: PropTypes.string,
	iconSide: PropTypes.string,
	iconSize: PropTypes.string,
	id: PropTypes.string.isRequired,
	insideTable: PropTypes.bool,
	label: PropTypes.string.isRequired,
	maxFileSize: PropTypes.number,
	minFileSize: PropTypes.number,
	maxHeight: PropTypes.string,
	maxWidth: PropTypes.string,
	name: PropTypes.string.isRequired,
	objectId: PropTypes.string,
	onAfterStateChange: PropTypes.func,
	onChange: PropTypes.func,
	onError: PropTypes.func,
	onRemoveImage: PropTypes.func,
	rendered: PropTypes.bool,
	showLabel: PropTypes.bool,
	stateField: PropTypes.string,
	titleElement: PropTypes.bool,
	titleElementClass: PropTypes.string,
	titleElementShowId: PropTypes.bool,
	validateViewMode: PropTypes.bool,
	validator: PropTypes.oneOfType(PropTypes.array, PropTypes.object).isRequired,
	validators: PropTypes.string,
	value: PropTypes.string,
	viewMode: PropTypes.string,
};

export default InputImageComponent;
