import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InternalUserService from '../../services/InternalUserService';
import CategoryService from '../../services/CategoryService';
import YesNoDialog from '../../components/YesNoDialog';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import InputCalendarComponent from '../../components/inputs/InputCalendarComponent';
import moment from 'moment';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import InputFileUploadComponent from '../../components/inputs/InputFileUploadComponent';
import PublicUnitService from '../../services/PublicUnitService';
import AreaService from '../../services/AreaService';
import { MapLeaflet } from '../../components/business/MapLeaflet';
import EventService from '../../services/EventService';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import InputCalendarRangeComponent from '../../components/inputs/InputCalendarRangeComponent';
import Address from '../../components/Address';
import AddressModel from '../../model/Address';
import ActionLink from '../../components/ActionLink';

class EventContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new EventService(props.id));
		this.categoryService = new CategoryService();
		this.internalUserService = new InternalUserService();

		this.state = {
			loading: true,
			elementId: this.props.id,
			showRemoveDialog: false,
			markers: [],
			mapCenter: undefined,
			zoom: 13,
			element: {
				id: undefined,
				status: undefined,
				categories: [],
				date: undefined,
				startTime: undefined,
				endTime: undefined,
				title: '',
				localization: '',
				localizationCoordinates: null,
				latitude: null,
				longitude: null,
				description: '',
				shortDescription: '',
				eventOrganizer: '',
				attachments: [],
				address: new AddressModel()
			},
		};
		this.showRemoveDialog = this.showRemoveDialog.bind(this);
		this.handleAddressChange = this.handleAddressChange.bind(this);
		this.calculateEventCoordinates = this.calculateEventCoordinates.bind(this);
	}


	getContainerListName() {
		return 'event-list-container';
	}

	getAddSucces() {
		return 'Wydarzenie zostało utworzone';
	}

	getUpdateSucces() {
		return 'Wydarzenie zostało zaktualizowane';
	}



	updateElement(data) {

		this.setState(
			{
				mapCenter: data.address && data.address.longitude && data.address.latitude ? [data.address.latitude, data.address.longitude] : undefined,
				element: {
					id: data.id,
					status: data.status,
					categories: data.categories,
					date: data.date ? new Date(data.date) : undefined,
					startTime: data.startTime ? new Date(data.startTime) : undefined,
					endTime: data.endTime ? new Date(data.endTime) : undefined,
					title: data.title,
					localization: this.props.viewMode === 'VIEW' ? data.localizationName : data.localization,
					address: data.address ? data.address : new AddressModel(),
					//todo chyba tego nie będzie na ui bo w backendzie te pola się przypnie w adres
					localizationCoordinates: null,
					description: data.description,
					shortDescription: data.shortDescription,
					eventOrganizer: data.eventOrganizer,
					source: data.source,
					url: data.url,
					attachments: data.attachments ? data.attachments : []
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}

	initBeforeSetElement() {
		this.categoryService.getCategories("EVENT")
			.then(categoryOpt => this.setState({ categoryOpt }))
			.catch(err => {
				console.log(err);
				this.showErrorMessage("Nie można pobrać kategorii");
			})
	}
	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode, id, subId } = this.props;
		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${id}`;
		}
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
				label: 'Zapisz',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: this.isUserInAnyRole('ROLE_EVENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') && (viewMode === 'NEW' || viewMode === 'EDIT') ,
			},
			{
				label: 'Usuń',
				className: 'float-right',
				onClick: this.showRemoveDialog,
				rendered: this.isUserInAnyRole('ROLE_EVENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') && (viewMode === 'VIEW' && this.state.element.status?.enumValue === 'INACTIVE'),
			},
		];
	}


	prepareEditButton(rendered, label) {
		const { editUrl, id } = this.props;

		return {
			label: label !== undefined ? label : 'Edytuj',
			type: 'BUTTON',
			variant: '',
			className: 'float-right',
			href: `${editUrl}/${id}`,
			rendered: this.props.viewMode === 'VIEW' && this.state.element?.status?.enumValue === 'INACTIVE' && this.isUserInAnyRole('ROLE_EVENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'),
			iconName: 'mdi-pencil',
			iconSide: 'left',
			iconSize: 'm',
		};
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowe wydarzenie' : viewMode === 'EDIT' ? 'Edycja wydarzenia' : 'Szczegóły wydarzenia',

				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_EVENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')),
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}

	showRemoveDialog() {
		this.setState({ showRemoveDialog: true });
	}

	
	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/event-list' className='p-link'>
					{'Wydarzenia'}
				</BreadcrumbsItem>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/event/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/event/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/event/create'>{'Nowe'}</BreadcrumbsItem> : null}
				<YesNoDialog
					onChange={(type, sth, e) => {
						if (!!e.value) {
							this.blockUi(this.onRemoveAction(type, sth, e));
						} else {
							this.setState({ showRemoveDialog: false });
						}
					}}
					visible={this.state.showRemoveDialog}
					header="Usuwanie wydarzenia"
					name="visible"
					onHide={() => {
						this.setState({ showRemoveDialog: false });
					}}>
					<span>Czy na pewno chcesz usunąć wydarzenie?</span>
				</YesNoDialog>
				{this.renderView()}
			</div>
		);
	}
	getRemoveSucces() {
		return 'Wydarzenie zostało usunięte';
	}

	renderDetails() {
		return (
			<React.Fragment>
				<div class='row'>
					<div class='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
						<InputTextareaComponent
							id={"title"}
							name={"title"}
							label={"Tytuł"}
							colClass='col-12'
							value={this.state.element.title}
							validator={this.validator}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							validateViewMode
							validators="required|max:500"
							autoResize
						//rows={1}
						/>
						<InputTextareaComponent
							id='shortDescription'
							name='shortDescription'
							label={'Skrócony opis wydarzenia'}
							colClass='col-12'
							value={this.state.element.shortDescription}
							validator={this.validator}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							validateViewMode
							autoResize
							validators="required|max:1000"
						/>
						<InputTextareaComponent
							id='description'
							name='description'
							label={'Pełny opis wydarzenia'}
							colClass='col-12'
							value={this.state.element.description}
							validator={this.validator}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							validateViewMode
							autoResize
						/>
						<InputTextComponent
							id='eventOrganizer'
							name={"eventOrganizer"}
							label={"Organizator"}
							colClass='col-12'
							value={this.state.element.eventOrganizer}
							validator={this.validator}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							validateViewMode
							validators="required|max:1000"
						/>
						<InputMultiSelectComponent id="categories" name="categories"
							colClass='col-md-6'
							label="Kategorie"
							value={this.state.element.categories}
							validator={this.validator}
							validators="array_required"
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							options={this.props.viewMode === 'VIEW' ? [] : this.state.categoryOpt}
							dataKey="id"
							optionLabel="name"
							filter
							validateViewMode
						/>
						<InputCalendarRangeComponent
							key={'date'}
							idFrom={'endTime'}
							idTo={'activeTo'}
							nameTo={'endTime'}
							nameFrom={'startTime'}
							label={'Czas trwania'}
							labelFrom="od"
							labelTo="do"
							colClass={'col-12'}
							from={this.state.element.startTime}
							to={this.state.element.endTime}
							validator={this.validator}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							//minDateFrom={new Date()}
							showTime
							showIcon
							validatorsTo={`required|date|after_or_equal_time~${moment(this.state.element.startTime ? this.state.element.startTime : new Date()).format('DD-MM-YYYY HH:mm').toString()}`}
							placeholderFrom='dd-mm-rrrr GG:MM'
							placeholderTo='dd-mm-rrrr GG:MM'
							validateViewMode
							dateFormatMoment='DD-MM-YYYY HH:mm'
							validatorsToType='after_or_equal_time'
						/>
						<InputTextComponent
							id={"localization"}
							name={"localization"}
							colClass='col-12'
							label={this.props.viewMode === 'VIEW' ? "Miejsce" : "Nazwa miejsca"}
							value={this.state.element.localization}
							validator={this.validator}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							validateViewMode
							validators="not_required|max:500"
						//rendered={this.props.viewMode === 'VIEW'}
						/>
						<Address
							idPrefix={"address"}
							element={this.state.element.address}
							stateField={`element.address`}
							handleChange={this.handleAddressChange}
							viewMode={this.props.viewMode}
							validator={this.validator}
							rendered={this.props.viewMode !== 'VIEW'}
							required={true}
							colClass="col-md-12"
						/>
						<InputDropdownComponent
							colClass='col-md-6'
							label="Status"
							id="status"
							name="status"
							value={this.state.element.status}
							validator={this.validator}
							validators="not_required"
							optionLabel="label"
							viewMode={"VIEW"}
							rendered={this.props.viewMode !== "NEW"}
						/>
						<InputDropdownComponent
							colClass='col-md-6'
							label="Pochodzenie wpisu"
							id="source"
							name="source"
							value={this.state.element.source}
							validator={this.validator}
							validators="not_required"
							optionLabel="label"
							viewMode={"VIEW"}
							rendered={this.props.viewMode !== "NEW"}
						/>
						<InputTextComponent
							id={"url"}
							name={"url"}
							colClass='col-12'
							label={"URL"}
							value={this.props.viewMode === 'VIEW' ?
								<ActionLink
									label={this.state.element.url}
									openInNewTab
									href={this.state.element.url}
									handleClick={() => window.location.href}
								/>
								: this.state.element.url
							}
							validator={this.validator}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							validateViewMode
							validators="url|max:1000"
						/>
						<InputFileUploadComponent
							messages={this.messages}
							id='attachments'
							name='attachments'
							label={'Pliki multimedialne'}
							fileList={this.state.element.attachments}
							showLabel
							value={this.state.element.attachments}
							restApiUrl={`${this.service.getPath()}/${this.state.element.id}/attachments`}
							multiple
							maxFileSize={50000000}
							onChange={this.handleChange}
							itemLabel='fileName'
							itemName='uuid'
							colClass='col-12'
							viewMode={this.props.viewMode}
							validator={this.validator}
							validators="not_required"
							style={{ width: '100%' }}
							// restrictExt={true}
							// accept='.pdf,.jpg'
							maxFileCountParam={'EVENT_ATT_COUNT'}
							handleError={this.onUploladError}
							token={this.authService.getToken()}
							validateViewMode
							uploadExtParamName="EVENT_ATT_EXT"
							uploadMaxSizeParamName="EVENT_ATT_MAX"
							restrictExt={true}
						//overrideFileNotAcceptedMessage='Niepoprawne rozszerzenie pliku. Wymagany plik z rozszerzeniem: .pdf lub .jpg'
						/>

					</div>
				</div>
				<div colClass="col-12">
					<div id="mapid">
						<MapLeaflet
							draggable={this.props.viewMode !== 'VIEW'}
							mapCenter={this.state.mapCenter}
							zoom={this.state.zoom}
							markers={this.state.element?.address?.latitude && this.state.element?.address?.longitude ? [[this.state.element.address.latitude, this.state.element.address.longitude]] : []}
							handleMarkerDrag={this.handleMarkerDrag.bind(this)}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}
	handleMarkerDrag(coordinates, markerIndex) {
		console.log('handleMarkerDrag ', coordinates, markerIndex);
		this.setState(
			(prevState) => ({
				element: {
					...prevState.element,
					address: {
						...prevState.element.address,
						latitude: coordinates.lat,
						longitude: coordinates.lng,
					},
				},
			}));
	}
	handleAddressChange(inputType, parameters, event, onAfterStateChange, stateField) {
		this.handleChange(inputType, parameters, event, onAfterStateChange, stateField);
		this.calculateEventCoordinates();
	}
	handleCoordinatesChange(position) {
		console.log('handleCoordinatesChange', position);
		this.setState(
			(prevState) => ({
				mapCenter: position.lat && position.lng ? [position.lat, position.lng] : undefined,
				element: {
					...prevState.element,
					address: {
						...prevState.element.address,
						latitude: position.lat,
						longitude: position.lng,
					},
				},
			}));
	}
	calculateEventCoordinates() {

		var duration = 1500;
		clearTimeout(this.inputTimer);
		this.inputTimer = setTimeout(() => {

			if (this.state.element?.address?.postCode && this.state.element?.address?.city) {
				console.log('calculateEventCoordinates', this.state.element?.address);
				this.service.calculateEventCoordinates(this.state.element?.address)
					.then(geo => {
						//dodanie markera i zoom na 16
						console.log('znaleziono wspolrzedne obiektu ', geo);
						let markerTable = [{
							position: {
								lat: geo.coordinates[1],
								lng: geo.coordinates[0],
							},
						},
						];
						this.setState({
							markers: markerTable,
							zoom: 16
						}, () => {
							console.log(this.state);
							this.handleCoordinatesChange(markerTable[0].position);
						});
					}
					)
					.catch(() => {
						console.log('nie znaleziono punktu');
						//usuniecie markera
						this.setState({
							markers: [],
							zoom: 6,
						}, () => {
							console.log(this.state);
							this.handleCoordinatesChange({ lat: null, lng: null });
						});
					});
			}
		}, duration);

	}
}

EventContainer.defaultProps = {
	backUrl: '/#/event-list',
	cancelUrl: '/#/event/details',
	editUrl: '/#/event/edit',
};

EventContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
};

export default EventContainer;
