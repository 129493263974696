import PropTypes from 'prop-types';
import React from 'react';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import SurveyResultService from '../../services/SurveyResultService';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaInputNumberComponent from '../../components/criteria/CriteriaInputNumberComponent'
class SurveyDescriptionResultList extends BaseListContainer {
    constructor(props) {
        super(props, new SurveyResultService());
        this.state = {
            list: [],
            loading: false,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            genderOpt: []
        };
        this.actionTemplate = this.actionTemplate.bind(this);

    }
    handleSort(event) {
        if (this._isMounted) {
            this.setState(
                (prevState) => ({
                    loading: true,
                    criteria: {
                        ...prevState.criteria,
                        sortField: event.sortField && event.sortField === 'text' ? this.props.answerType === 'POINTS_ON_SCALE' ? 'numberValue' : 'stringValue' : event.sortField,
                        sortAsc: event.sortOrder > 0,
                    },
                }),
                () => this.refreshTable()
            );
        }
    }
    initializeFromBackend() {
        this.enumService
            .getEnumList('SurveyTargetGroup')
            .then((genderOpt) =>
                this.setState({
                    genderOpt,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy płci');
            });
    }
    updateSearchCriteria(criteria) {
        return {
            questionId: criteria.questionId,
            gender: criteria.gender,
            text: criteria.text,
            number: criteria.number,
            firstResult: criteria.firstResult,
            maxResult: criteria.maxResult,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc
        };
    }

    getCleanSearchCriteria() {
        return {
            questionId: this.props.questionId,
            gender: undefined,
            text: '',
            number: undefined,
            answerType: this.props.answerType,
            firstResult: 0,
            maxResult: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    getCriteriaName() {
        return `${this.props.questionId}survey-result-list-sc`;
    }

    getContainerListName() {
        return `${this.props.questionId}survey-result-list-sc`;
    }
    renderSeparator() {
        return null;
    }
    renderCriteria() {
        return (
            <div className='row'>
                {!!(this.props?.answerType === 'POINTS_ON_SCALE') ?
                    <CriteriaInputNumberComponent
                        id={"number-sc"}
                        inputId={`number-input`}
                        name={'number'}
                        label={"Odpowiedź"}
                        value={this.state.criteria.number}
                        onChange={this.handleChangeSc}
                        viewMode={'NEW'}
                        validator={this.validator}
                        validators="not_required"
                    /> :

                    <CriteriaTextComponent
                        id='text-sc'
                        name='text'
                        label={"Odpowiedź"}
                        colClass='col-lg-4 col-sm-6'
                        value={this.state.criteria.text}
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                        rendered={!!(this.props?.answerType !== 'POINTS_ON_SCALE')}
                    />}
                <CriteriaDropdownComponent
                    id='gender-sc'
                    name='gender'
                    showClear
                    label={'Grupa docelowa'}
                    colClass='col-xl-6 col-lg-6 col-md-12 col-sm-12'
                    value={this.state.criteria.gender}
                    options={this.state.genderOpt}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }

    prepareColumns() {
        let cols = [];
        cols.push({ field: 'text', header: 'Odpowiedź', sortable: true });
        return cols;
    }
    handleRemove(e, id) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ confirmationVisibile: true, removingRowDataId: id });
    }
    handleGoToEdit() {
        //todo
    }
    render() {
        return (
            <div className='container-fluid'>
                {this.renderView()}
            </div>
        );
    }
}

SurveyDescriptionResultList.defaultProps = {
    detailUrl: '/#/result/details',
};

SurveyDescriptionResultList.propTypes = {
    detailUrl: PropTypes.string,
    questionId: PropTypes.number.isRequired,
    answerType: PropTypes.string
};

export default SurveyDescriptionResultList;
