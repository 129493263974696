import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import StaticContentService from "../services/StaticContentService";
import StaticContentEditor from "../components/StaticContentEditor";

class StaticContentContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new StaticContentService());
        this.state = {
            loading: true,
            elementId: props.id,
            element: {
                id: null,
                staticContentType: null,
                title: null,
                content: null,
                engTitle: null,
                engContent:null
            },
            staticContentTypeOptions: [],
        };
    }

    updateElement(data) {
        this.setState(
            {
                element: {
                    id: data.id,
                    staticContentType: data.staticContentType,
                    title: data.title,
                    engTitle: data.engTitle,
                    content: data.content,
                    engContent: data.engContent
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    getContainerListName() {
        return 'static-content-list-container';
    }

    getUpdateSucces() {
        return 'Treść statyczna została zaktualizowana';
    }

    initBeforeSetElement() {
        this.service
            .getStaticContentTypes()
            .then((staticContentTypeOptions) =>
                this.setState({
                    staticContentTypeOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów treści statycznych');
            });
    }

    prepareFooterItems() {
        const {backUrl, cancelUrl, viewMode} = this.props;
        const {elementId} = this.state;
        let goBackUrl;
        if (viewMode === 'NEW') {
            goBackUrl = backUrl;
        } else {
            goBackUrl = `${cancelUrl}/${elementId}`;
        }
        return [
            {label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT'},
            {
                label: 'Zapisz',
                className: 'float-right',
                onClick: this.handleFormSubmit,
                rendered: viewMode === 'NEW' || viewMode === 'EDIT',
            },
        ];
    }

    prepareHeaderItems() {
        const {viewMode} = this.props;
        return [
            {
                label: viewMode === 'NEW' ? 'Nowa treść statyczna' : viewMode === 'EDIT' ? 'Edycja treści statycznej' : 'Szczegóły treści statycznej',
                type: 'LABEL',
                className: '',
            },
            this.prepareEditButton(viewMode === 'VIEW'),
        ];
    }

    getBackLabel() {
        return 'Wróć do listy';
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/static-content-list' className='p-link'>
                    {'Treści statyczne'}
                </BreadcrumbsItem>
                {this.props.viewMode === 'VIEW' ?
                    <BreadcrumbsItem to='/static-content/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
                {this.props.viewMode === 'EDIT' ?
                    <BreadcrumbsItem to='/static-content/edit'>{'Edycja'}</BreadcrumbsItem> : null}
                {this.props.viewMode === 'NEW' ?
                    <BreadcrumbsItem to='/static-content/create'>{'Nowy'}</BreadcrumbsItem> : null}
                {this.renderView()}
            </div>
        );
    }

    renderDetails() {
        return (
            <React.Fragment>
                <div className='row'>
                    <div class='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
                        <InputDropdownComponent
                            id='type'
                            name='type'
                            label={'Typ'}
                            colClass='col-md-12'
                            value={this.state.element.staticContentType}
                            validator={this.validator}
                            options={this.state.staticContentTypeOptions}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode}
                            optionLabel='label'
                            dataKey='value'
                            valueView={this.state.element.type ? this.state.element.type.label : ''}
                        />
                        <InputTextComponent
                            id='title'
                            name='title'
                            label={'Tytuł w języku polskim'}
                            colClass='col-md-12'
                            value={this.state.element.title}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                            validators={'required|max:1024'}
                            rendered={this.state.element.staticContentType?.enumValue !== 'HELP'}
                        />
                        <InputTextComponent
                            id='engTitle'
                            name='engTitle'
                            label={'Tytuł w języku angielskim'}
                            colClass='col-md-12'
                            value={this.state.element.engTitle}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                            validators={'max:1024'}
                            rendered={this.state.element.staticContentType?.enumValue !== 'HELP'}
                        />
                    </div>
                    <StaticContentEditor
                        id={'content-editor-pl'} name={'content'}
                        onChange={this.handleChange} value={this.state.element.content}
                        viewMode={this.props.viewMode} label={'Treść w języku polskim'} showLabel/>
                    <StaticContentEditor
                        id={'content-editor-eng'} name={'engContent'}
                        onChange={this.handleChange} value={this.state.element.engContent}
                        viewMode={this.props.viewMode} label={'Treść w języku angielskim'} showLabel/>
                </div>
            </React.Fragment>
        );
    }
}

StaticContentContainer.defaultProps = {
    backUrl: '/#/static-content-list',
    cancelUrl: '/#/static-content/details',
    editUrl: '/#/static-content/edit',
};

StaticContentContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default StaticContentContainer;
