import BaseServiceCrud from './BaseServiceCrud';

export default class CategoryService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'category';
	}

	getCriteria(criteria) {
		return this.objToQueryString({			
			id: criteria.id,
			name: criteria.name,
			status: criteria.status?.enumValue,
			target: criteria.target?.enumValue,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}

	changeStatus(category) {
		const newStatus = category?.status?.enumValue === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
        return this.fetch(`${this.domain}/${this.path}/${category.id}/status/${newStatus}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });

	}
	

	getCategoryUsers(categoryId, subcategoryId) {
        return this.fetch(`${this.domain}/${this.path}/category-users?category_id=${categoryId}${subcategoryId ? `&&subcategory_id=${subcategoryId}` : '' }`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

	getCategories(type) {
		return this.fetch(`${this.domain}/${this.path}/type/${type}`, {
			method: 'GET',
		})
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((err) => {
				throw err;
			});
	}
	
}
