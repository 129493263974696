import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InternalUserService from '../../services/InternalUserService';
import CategoryService from '../../services/CategoryService';
import YesNoDialog from '../../components/YesNoDialog';
import moment from 'moment';
import SurveyService from '../../services/SurveyService';
import InputCalendarRangeComponent from '../../components/inputs/InputCalendarRangeComponent';
import SurveyModel from './SurveyModel';
import QuestionModel from './QuestionModel';
import DivContainer from '../../components/DivContainer';
import CustomLabel from '../../components/CustomLabel';
import ActionButton from '../../components/ActionButton';
import QuestionComponent from './QuestionComponent';
import { MapLeaflet } from '../../components/business/MapLeaflet';
import AreaService from '../../services/AreaService';
import { PieChartComponent } from '../../components/inputs/PieChartComponent';
import SurveyDescriptionResultList from './SurveyDescriptionResultList';

class SurveyContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new SurveyService(props.id));
		this.categoryId = props.id;
		this.subcategoryId = props.subId;
		this.categoryService = new CategoryService();
		this.internalUserService = new InternalUserService();

		this.state = {
			showChangeStatusDialog: false,
			loading: true,
			elementId: this.props.id,
			showPublishDialog: false,
			showFinishDialog: false,
			markers: [],
			markersInitialized: false,
			zoom: 6,
			initialCoordinates: {
				lat: 52.112795,
				lng: 19.211946,
			},
			element: new SurveyModel(),
			//todo z parametrów
			availableQuestionCount: 5,
			answerTypeOptions: [],
			areas: []
		};
		this.showChangeStatusDialog = this.showChangeStatusDialog.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
		this.showPublishDialog = this.showPublishDialog.bind(this);
		this.publishNotification = this.publishNotification.bind(this);
		this.handleSaveDraft = this.handleSaveDraft.bind(this);
		this.publishValidate = this.publishValidate.bind(this);
		this.handleAddQuestion = this.handleAddQuestion.bind(this);
		this.handleRemoveQuestion = this.handleRemoveQuestion.bind(this);
		this.handleClickArea = this.handleClickArea.bind(this);
		this.finishSurveyDialog = this.finishSurveyDialog.bind(this);
		this.finishSurvey = this.finishSurvey.bind(this);
		this.startDownloading = this.startDownloading.bind(this);
		this.getVotesCount = this.getVotesCount.bind(this);
		this.resetSubcategory = this.resetSubcategory.bind(this);
	}


	getContainerListName() {
		return 'survey-list-container';
	}

	getAddSucces() {
		return 'Ankieta została utworzona';
	}

	getUpdateSucces() {
		return 'Ankieta zostało zaktualizowana';
	}



	updateElement(data) {
		this.setState(
			{
				element: SurveyModel.copy(data),
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}

	initBeforeSetElement() {
		this.categoryService.getCategories("NOTIFICATION")
		.then(categoryOpt => this.setState({ categoryOpt }))
		.catch(err => {
			this.showErrorMessage("Nie można pobrać kategorii");
		})
		this.enumService
			.getEnumList('SurveyAnswerType')
			.then((answerTypeOptions) =>
				this.setState({
					answerTypeOptions,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać typów odpowiedzi');
			});
		this.enumService
			.getEnumList('SurveyStatus')
			.then((status) =>
				this.setState({
					status,
				})
			).catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy statusów');
			});
		this.enumService
			.getEnumList('SurveyTargetGroup')
			.then((targetGroupOptions) =>
				this.setState({
					targetGroupOptions,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać grup docelowych');
			});
		if (this.props.viewMode === 'NEW') {
			new AreaService()
				.getAreas()
				.then(areas => {
					this.setState((prevState) => ({
						element: {
							...prevState.element,
							areas: areas,
						},
					}));
				})
				.catch((err) => {
					this.messages.show({
						severity: 'error',
						summary: 'Błąd',
						detail: err.message,
					});
				});
		}
	}

	initAfterSetElement() {
		if (this.state.element?.category?.id) {
			this.fetchSubcategories();
		} else {
			this.unblockUi();
		}
	}

	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode, id, subId } = this.props;
		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${id}`;
		}
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
				label: 'Zapisz jako wersja robocza',
				className: 'float-right',
				onClick: this.handleSaveDraft,
				rendered: this.isUserInAnyRole('ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') && (viewMode === 'NEW' || viewMode === 'EDIT'),
			},
			{
				label: 'Opublikuj',
				className: 'float-right',
				onClick: this.publishValidate,
				rendered: this.isUserInAnyRole('ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') && (viewMode === 'NEW' || this.state.element.status?.enumValue === 'DRAFT'),
			},
			{
				label: 'Zakończ',
				className: 'float-right',
				onClick: this.finishSurveyDialog,
				rendered: this.isUserInAnyRole('ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') && viewMode === 'VIEW' && this.state.element.status?.enumValue === 'PUBLISHED',
			},
		];
	}
	finishSurveyDialog(event) {
		if (event !== undefined) {
			event.preventDefault();
		}
		this.setState({ showFinishDialog: true })
	}
	finishSurvey(type, costam, target) {
		if (target.value) {
			this.scrollToTop();

			this.blockUi();
			if (this._isMounted) {
				this.service.finishSurvey(this.state.elementId)
					.then(() => {
						const { backUrl } = this.props;
						this.blockUi();
						this.persistMessage('success', '', "Ankieta została zakończona");
						window.location.href = backUrl;
					})
					.catch((err) => {
						this.showErrorMessage(err.message);
						this.setState({ showFinishDialog: false });
						this.unblockUi();
					});
			}
		} else {
			this.setState({ showFinishDialog: false })
		}
	}
	publishValidate(event) {
		if (event !== undefined) {
			event.preventDefault();
		}
		if (this.validator.allValid()) {
			
			if (!this.state.element.questions || this.state.element.questions.length === 0) {
				this.showErrorMessage("Nie sprecyzowano żadnego pytania")
				return;
			}
			if (!this.state.element.areas || this.state.element.areas.filter(a => !!a.selected).length === 0) {
				this.showErrorMessage("Nie wybrano żadnego obszaru")
				return;
			} 
			this.setState({ showPublishDialog: true })
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.scrollToError = true;
			this.forceUpdate();
		}
	}
	handleSaveDraft(event) {
		if (event !== undefined) {
			event.preventDefault();
		}
		if (this.validatorDraft.allValid()) {
			this.blockUi(this.handleValidForm);
		} else {
			this.validator.hideMessages();
			this.validatorDraft.showMessages();
			this.scrollToError = true;
			this.forceUpdate();
		}
	}
	afterAddSuccess(response) {
		const { backUrl } = this.props;
		this.saveCookie('survey-list-activeIndex', 1);
		this.blockUi();
		this.persistMessage('success', '', this.getAddSucces(response));
		window.location.href = backUrl;
	}
	afterUpdateSuccess(response) {
		const { backUrl } = this.props;
		this.saveCookie('survey-list-activeIndex', 1);
		this.blockUi();
		this.persistMessage('success', '', this.getUpdateSucces(response));
		window.location.href = backUrl;
	}

	prepareEditButton(rendered, label) {
		const { editUrl, id } = this.props;

		return {
			label: label !== undefined ? label : 'Edytuj',
			type: 'BUTTON',
			variant: '',
			className: 'float-right',
			href: `${editUrl}/${id}`,
			rendered: this.props.viewMode === 'VIEW' && this.state.element?.status?.enumValue === 'DRAFT' && this.isUserInAnyRole('ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'),
			iconName: 'mdi-pencil',
			iconSide: 'left',
			iconSize: 'm',
		};
	}

	prepareChangeStatusButton(rendered) {
		const status = this.state.element?.status?.enumValue;
		return {
			label: (status === 'ACTIVE' || status === 'PUBLISHED') ? 'Zawieś' : 'Aktywuj',
			type: 'BUTTON',
			variant: '',
			className: 'float-right',
			onClick: this.showChangeStatusDialog,
			rendered: this.props.viewMode === 'VIEW' && (status === 'ACTIVE' || status === 'PUBLISHED' || status === 'INACTIVE') && this.isUserInAnyRole('ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'),
			iconName: status !== 'ACTIVE' || 'PUBLISHED' ? 'mdi-lock' : 'mdi-lock-open',
			iconSide: 'left',
			iconSize: 'm',
		};
	}


	showPublishDialog() {
		this.setState({ showPublishDialog: true });
	}
	
	showChangeStatusDialog() {
		this.setState({ showChangeStatusDialog: true });
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowa ankieta' : viewMode === 'EDIT' ? 'Edycja ankiety' : 'Szczegóły ankiety',
				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')),
			this.prepareChangeStatusButton(viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') && this.state.element?.status?.enumValue !== ('EXPIRED' || 'DRAFT')),
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}
	updateElementBeforeCreateOrUpdate(element) {
		if (!!element.immediatelySend) {
			element.sendDate = new Date();
		}
		return element;
	}
	publishNotification(type, costam, target) {
		if (target.value) {
			let { element } = this.state;
			this.scrollToTop();

			this.blockUi();
			if (this._isMounted) {
				element = this.updateElementBeforeCreateOrUpdate(element)

				if (element.id === undefined || element.id === null) {
					this.service
						.publish(this.state.element)
						.then((data) => {
							this.saveCookie('survey-list-activeIndex', 0);
							this.persistMessage('success', '', 'Ankieta została wysłana');
							window.location.href = this.props.backUrl;
						})
						.catch((err) => {
							this.showErrorMessage(err.message);
							this.setState({ showPublishDialog: false });
							this.unblockUi();
						});
				} else {
					this.service
						.publishDraft(this.state.element)
						.then((data) => {
							this.saveCookie('survey-list-activeIndex', 0);
							this.persistMessage('success', '', 'Ankieta została wysłana');
							window.location.href = this.props.backUrl;
						})
						.catch((err) => {
							this.showErrorMessage(err.message);
							this.setState({ showPublishDialog: false });
							this.unblockUi();
						});
				}
			}
		} else {
			this.setState({ showPublishDialog: false });
		}
	}

	changeStatus(type, costam, target) {
		const status = this.state.element?.status?.enumValue;
		if (target.value) {
			this.blockUi();
			this.service
				.changeStatus(this.state.element)
				.then((data) => {
					this.showSuccessMessage(`Ankieta została  ${status !== 'INACTIVE' ? 'zawieszona' : 'aktywowana'}`);
					this.setState({ showChangeStatusDialog: false }, () => {
						this.updateElement(data);
					});
				})
				.catch((err) => {
					this.showErrorMessage(err.message);
					this.setState({ showChangeStatusDialog: false });
					this.unblockUi();
				});
		} else {
			this.setState({ showChangeStatusDialog: false });
		}
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/survey-list' className='p-link'>
					{'Konsultacje społeczne'}
				</BreadcrumbsItem>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/survey/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/survey/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/survey/create'>{'Nowa'}</BreadcrumbsItem> : null}
				<YesNoDialog
					onChange={this.publishNotification}
					visible={this.state.showPublishDialog}
					header="Wysyłanie ankiety do publikacji"
					name="visible"
					onHide={() => {
						this.setState({ showPublishDialog: false });
					}}>
					<span>Czy na pewno chcesz wysłać ankietę?</span>
				</YesNoDialog>
				<YesNoDialog
					onChange={this.finishSurvey}
					visible={this.state.showFinishDialog}
					header="Zakończenie ankiety"
					name="visible"
					onHide={() => {
						this.setState({ showFinishDialog: false });
					}}>
					<span>Czy na pewno chcesz zakończyć ankietę?</span>
				</YesNoDialog>
				<YesNoDialog
					onChange={this.changeStatus}
					visible={this.state.showChangeStatusDialog}
					header={this.state.element?.status?.enumValue !== 'INACTIVE' ? 'Zawieszenie ankiety' : 'Aktywacja ankiety'}
					name="visible"
					onHide={() => {
						this.setState({ showChangeStatusDialog: false });
					}}>
					{this.state.element?.status?.enumValue !== 'INACTIVE' ? (
						<span>Czy na pewno chcesz zawiesić wybraną ankietę?</span>
					) : (
						<span>Czy na pewno chcesz aktywować wybraną ankietę?</span>
					)}

				</YesNoDialog>
				{this.renderView()}
			</div>
		);
	}

	fetchSubcategories() {
		this.service
			.getSubcategories(this.state.element?.category?.id)
			.then(subcategories => {
				this.setState({ subcategoriesOpt: subcategories }, () => super.initAfterSetElement())
			})
			.catch(err => {
				this.showErrorMessage(err.message);
				super.initAfterSetElement()
			});
	}

	resetSubcategory (e) {
		this.fetchSubcategories(e)
		this.state.element.subcategory = undefined;
	}

	renderDetails() {
		return (
			<React.Fragment>
				<div class='row'>
					<div class='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
						<InputTextComponent
							id={"officialName"}
							name={"officialName"}
							label={"Nazwa oficjalna w języku polskim"}
							colClass='col-12'
							value={this.state.element.officialName}
							validator={[this.validator, this.validatorDraft]}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							validateViewMode
							validators="required|max:255"
						/>
						<InputTextComponent
							id={"officialNameEng"}
							name={"officialNameEng"}
							label={"Nazwa oficjalna w języku angielskim"}
							colClass='col-12'
							value={this.state.element.officialNameEng}
							validator={[this.validator, this.validatorDraft]}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							validateViewMode
							validators="not_required|max:255"
						/>
						<InputTextComponent
							id={"workingName"}
							name={"workingName"}
							label={"Nazwa robocza"}
							colClass='col-12'
							value={this.state.element.workingName}
							validator={[this.validator, this.validatorDraft]}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							validateViewMode
							validators="required|max:255"
						/>
						<InputDropdownComponent
							id='category'
							name='category'
							label={'Kategoria'}
							colClass='col-12'
							value={this.state.element.category}
							validator={[this.validator, this.validatorDraft]}
							options={this.state.categoryOpt}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							optionLabel='name'
							dataKey='id'
							validators='required'
							valueView={this.state.element.category ? this.state.element.category.name : ''}
							onAfterStateChange={e => { this.resetSubcategory(e)}}
							validateViewMode
						/>
						<InputDropdownComponent
							id='subcategory'
							name='subcategory'
							label={'Podkategoria'}
							colClass='col-12'
							value={this.state.element.subcategory}
							validator={[this.validator, this.validatorDraft]}
							options={this.state.subcategoriesOpt}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							optionLabel='name'
							dataKey='id'
							validators='not_required'
							valueView={this.state.element.subcategory ? this.state.element.subcategory.name : ''}
							validateViewMode
							rendered={(this.state.subcategoriesOpt?.length && this.state.subcategoriesOpt?.length > 0)}
						/>

						<InputDropdownComponent
							colClass='col-6'
							label="Status"
							id="status"
							name="status"
							value={this.state.element.status}
							validator={[this.validator, this.validatorDraft]}
							validators="not_required"
							optionLabel="label"
							viewMode={"VIEW"}
							rendered={this.props.viewMode !== "NEW"}
						/>
						<InputDropdownComponent
							id='targetGroup'
							name='targetGroup'
							label={'Grupa docelowa'}
							colClass='col-12'
							value={this.state.element.targetGroup}
							validator={[this.validator, this.validatorDraft]}
							options={this.state.targetGroupOptions}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							optionLabel='label'
							dataKey='enumValue'
							validators='required'
							valueView={this.state.element.targetGroup ? this.state.element.targetGroup.label : ''}
							validateViewMode
						/>
						<InputCalendarRangeComponent
							key={'active'}
							idFrom={'activeFrom'}
							idTo={'activeTo'}
							nameTo={'activeTo'}
							nameFrom={'activeFrom'}
							label={'Czas trwania'}
							labelFrom="od"
							labelTo="do"
							colClass='col-12'
							from={this.state.element.activeFrom}
							to={this.state.element.activeTo}
							validator={[this.validator, this.validatorDraft]}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							//minDateFrom={new Date()}
							showTime
							showIcon
							validatorsTo={`required|date|day_after_time~${moment(this.state.element.activeFrom ? this.state.element.activeFrom : new Date()).format('DD-MM-YYYY HH:mm').toString()}`}
							placeholderFrom='dd-mm-rrrr GG:MM'
							placeholderTo='dd-mm-rrrr GG:MM'
							validateViewMode
							dateFormatMoment='DD-MM-YYYY HH:mm'
							validatorsToType='day_after_time'
						/>
					</div>
				</div>
				<DivContainer colClass="col-12">
					<CustomLabel
						colClass="col-md-12"
						label="Pytania"
					/>
					<div>
						<div class="row">
							<div class="col-12">
								<ActionButton
									label="Dodaj pytanie"
									className="float-right"
									key={'next-button'}
									handleClick={(e) => this.handleAddQuestion()}
									disabled={!!(this.state.element?.questions?.length >= this.state.availableQuestionCount)}
									rendered={this.props.viewMode !== 'VIEW'}
								/>
							</div>
						</div>
						<hr id="line-separator" />
					</div>
					{this.state?.element?.questions && this.state?.element?.questions?.length ? this.state?.element?.questions.map((question, index) => {
						return (
							<QuestionComponent
								idPrefix={index}
								element={question}
								viewMode={this.props.viewMode}
								handleChange={this.handleChange}
								validator={[this.validator, this.validatorDraft]}
								onErrorMessage={(message) => this.showErrorMessage(message)}
								rendered={this.state.element.questions !== undefined}
								stateField={`element.questions[${index}]`}
								answerTypeOptions={this.state.answerTypeOptions}
								handleRemoveQuestion={this.handleRemoveQuestion}
								validateViewMode
							/>
						)
					}) : null}
				</DivContainer>
				<InputTextComponent
					id={"thanksText"}
					name={"thanksText"}
					label={"Tekst podziękowania"}
					colClass='col-12'
					value={this.state.element.thanksText}
					validator={[this.validator, this.validatorDraft]}
					onChange={this.handleChange}
					viewMode={this.props.viewMode}
					validateViewMode
					validators="required|max:160"
				/>
				<InputTextComponent
					id={"thanksTextEng"}
					name={"thanksTextEng"}
					label={"Tekst podziękowania w języku angielskim"}
					colClass='col-12'
					value={this.state.element.thanksTextEng}
					validator={[this.validator, this.validatorDraft]}
					onChange={this.handleChange}
					viewMode={this.props.viewMode}
					validateViewMode
					validators="not_required|max:255"
				/>
				<div id="mapid">
					<MapLeaflet areas={this.state.element?.areas} handleClickArea={this.handleClickArea} viewMode={this.props.viewMode} />
				</div>
				{
					this.state.element?.status?.enumValue === 'PUBLISHED' || this.state.element?.status?.enumValue === 'EXPIRED' ?
						this.renderResults() : null
				}
			</React.Fragment>
		);
	}
	startDownloading() {
		this.service.startDownloading({ enumValue: 'csv' }, {}, this.state.elementId).then(res => {
			this.blockUi(undefined, 'Generowanie pliku rozpoczęte, proszę czekać');
			this.intervalId = setInterval(this.getReportResult.bind(this, res.uuid, 'csv'), 8000);

		})
			.catch((err) => {
				this.unblockUi(this.showErrorMessage(err.message));;
			});
	}
	getReportResult(request, format) {
		this.service
			.getReportResult(request)
			.then((data) => {
				this.unblockUi();
				this.setState({ showPrintDialog: false }, () => {
					this.service.export(this.authService.getToken(), `${this.state.element.officialName}.csv`, this.showErrorMessage, 'csv', data.uuid);
					clearInterval(this.intervalId);
				});

				;
			})
			.catch((err) => {
				// status 422 zarezerwowany dla sytuacji gdy jeszcze nie ma wyniku;
				// w pozostałych sytaucjach błąd ma spowodować przerwanie zapytywania
				if (err.errorCode && err.errorCode !== 'REPORT_UNFINISHED') {
					this.unblockUi();
					this.showErrorMessage(err.message);
					clearInterval(this.intervalId);
				}
			});
	}
	getVotesCount() {
		const votes = this.state?.element?.genderGroup;
		return votes.reduce(function(sum, element) {
			return  +sum + +element[1];
		}, 0)
	}
	renderResults() {
		return (
			<DivContainer colClass="col-12 survey-result-box">
				<CustomLabel
					colClass="col-md-12"
					label="Prezentacja wyników"
				/>
				<ActionButton
					label='Eksportuj'
					className='float-right mr-2'
					iconName='mdi-file-export'
					iconSide='left'
					iconSize='m'
					handleClick={(e) => this.startDownloading()}
				/>
				<div class='row' style={{ width: '100%' }}>
					<div class='col-lg-9 col-md-12' >
						<PieChartComponent
							data={this.state.element.genderGroup}
							questionName={"Podział ankietowanych ze względu na wiek"}
							chartLanguage='pl'
						/>
					</div>
					<div class='col-lg-3 col-sm-12 text-center my-auto'>
						<span> Liczba ankietowanych: {this.getVotesCount()} </span>
					</div>
				</div>
				{this.state.element.questions && this.state.element.questions.length ?
					this.state.element.questions.map((q, index) => {
						let rqm;

						if (q.answerType && (q.answerType.enumValue === 'SINGLE_CHOICE' || q.answerType.enumValue === 'MULTIPLE_CHOICE')) {
							rqm = this.state.element?.resultQuestionModels.find(r => r.questionId === q.id);
						}

						return rqm && (rqm?.type === 'SINGLE_CHOICE' || rqm?.type === 'MULTIPLE_CHOICE') ?
							<div className="card p-d-flex p-jc-center question-div">
								<CustomLabel
									colClass="col-md-12"
									label={`${index + 1}. ${rqm.name}`}
								/>
								<PieChartComponent data={rqm.data} questionName={"Dane zbiorczo"} />
								{this.state.element.targetGroup?.enumValue === 'UNDER_18' ? <PieChartComponent data={rqm.dataFemale} questionName={this.state.element?.targetGroup?.label} /> : null}
								{this.state.element.targetGroup?.enumValue === 'BETWEEN_18_AND_40' ? <PieChartComponent data={rqm.dataMale} questionName={this.state.element?.targetGroup?.label} /> : null}
								{this.state.element.targetGroup?.enumValue === 'OVER_40' ? <PieChartComponent data={rqm.dataOther} questionName={this.state.element?.targetGroup?.label} /> : null}
							</div>
							: <div className="card p-d-flex p-jc-center question-div">
								<CustomLabel
									colClass="col-md-12"
									label={`${index + 1}. ${q.questionName}`}
								/>
								<SurveyDescriptionResultList questionId={q.id} answerType={q?.answerType?.enumValue}

								/> </div>
					})
					: null}

			</DivContainer>
		);
	}
	handleClickArea(id) {
		const modifiedList = this.state.element.areas;
		const selectedArea = modifiedList.find(a => a.id === id);

		if (selectedArea) {
			const wasSelected = !!selectedArea.selected;
			selectedArea.selected = !wasSelected;
			this.setState((prevState) => ({
				element: {
					...prevState.element,
					areas: modifiedList,
				},
			}));
		}
	}
	handleAddQuestion(e) {
		if (e) {
			e.preventDefault();
		}
		const modifiedList = this.state.element.questions;
		modifiedList.push(new QuestionModel(modifiedList.length + 1));
		this.setState(
			(prevState) => ({
				element: {
					...prevState.element,
					questions: modifiedList
				},
			}),
		);
	}
	handleRemoveQuestion(e, index) {
		if (e) {
			e.preventDefault();
		}
		let modifiedList = this.state.element.questions;
		modifiedList.splice(index, 1);
		this.setState(
			(prevState) => ({
				element: {
					...prevState.element,
					questions: modifiedList
				},
			}),
		);
	}
}

SurveyContainer.defaultProps = {
	backUrl: '/#/survey-list',
	cancelUrl: '/#/survey/details',
	editUrl: '/#/survey/edit',
};

SurveyContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
};

export default SurveyContainer;
