import { InputText } from 'primereact/inputtext';
import queryString from 'query-string';
import { Messages } from 'primereact/messages';
import { Password } from 'primereact/password';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import BaseContainer from '../baseContainers/BaseContainer';
import ActionButton from '../components/ActionButton';
import ActionLink from '../components/ActionLink';
import SimpleReactValidator from '../components/validator';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import { CaptchaV2 } from './../components/utils/CaptchaV2';
import BlockUi from './../components/waitPanel/BlockUi';
import AppPrefixUtils from '../utils/AppPrefixUtils';

class LoginContainer extends BaseContainer {
	constructor() {
		super();
		this.handleChange = this.handleChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.authService = new AuthService();
		this.userService = new UserService();
		this.state = {
			username: '',
			password: '',
			redirectToReferrer: true,
			authValid: true,
			showCaptcha: false,
			captchaPreventAction: false
		};
		this.authValidValidator = new SimpleReactValidator({
			validators: {
				auth: {
					// name the rule
					message: ':attribute',
					rule: (val, params, validator) => {
						return this.state.authValid;
					}, // optional
					required: true,
				},
			},
		});
	}
	getContainerListName(){
		return 'login-page'
	}
	componentDidMount() {
		super.componentDidMount();
		const values = queryString.parse(this.props.location.search);

		if (values?.location) {
			this.targetLocation = values.location;
		}
		this.readMessage();
		//Messages.multiReadPersistMessage(['login-page'], this.messages);
	}
	readMessage() {
        const messageFromStorage = this.readCookie(this.getContainerListName());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getContainerListName());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
            }
        }
    }
	handleFormSubmit(e) {
		if (e !== undefined) {
			e.preventDefault();
		}
		this.blockUi();
		this.authService
			.login(this.state.username, this.state.password)
			.then((res) => {
				if (this.props.onAfterLogin) {
					this.props.onAfterLogin();
				}
			})
			.catch((err) => {
				console.log('login err', err);
				if (err !== null && err !== undefined) {
					if (err.status === 'UNAUTHORIZED' || err.status === 'FORBIDDEN') {
						if (err.errorCode === 'PASSWORD_EXPIRED') {
							this.userService
								.sendLinkForGenerateNewPassword(this.state.username)
								.then(() => {
									this.showSuccessMessage('Twoje hasło wygasło, na skrzynce pocztowej otrzymasz maila z linkiem do zresetowania hasła.');
									this.validator.hideMessages();
									this.forceUpdate();
									this.unblockUi();
								})
								.catch(() => {
									this.showErrorMessage('Wystąpił nieoczekiwany błąd');
									this.unblockUi();
								});
						} else {
							this.unblockUi();
							if (this.messages !== undefined && this.messages !== null) {
								this.messages.show({
									closable: false,
									life: 30000,
									severity: 'error',
									summary: 'Błąd',
									detail: err.message,
								});
							} else {
								console.log('authService::login this.messages === undefined', err);
							}
						}
					} else {
						this.unblockUi();
					}
				} else {
					this.unblockUi();
					if (this.messages !== undefined && this.messages !== null) {
						this.messages.show({
							closable: false,
							life: 30000,
							severity: 'error',
							summary: 'Błąd',
							detail: 'Nie można nawiązać połączenia z serwerem',
						});
					} else {
						console.log('authService::login this.messages === undefined', err);
					}
				}
			});
	}

	render() {
		if (this.authService.loggedIn()) {
			return this.renderAfterAuth();
		} else {
			return this.renderBeforeAuth();
		}
	}

	renderAfterAuth() {
		const { redirectToReferrer } = this.state;

		if (redirectToReferrer === true) {

			return <Redirect to={this.targetLocation != undefined ? this.targetLocation : '/start'} />;
		}
		return <Redirect to={'/start'} />;
	}

	renderBeforeAuth() {
		return (
			<React.Fragment>
				<BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
					<div id='main-login'>
						<Messages id="custom-messages" ref={(el) => (this.messages = el)} ></Messages>
						<form
							className='login-pane'
							onSubmit={(e) => {
								if (!this.state.captchaPreventAction) {
									this.handleFormSubmit(e);
								}
							}}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									if (!this.state.captchaPreventAction) {
										this.handleFormSubmit();
									}
								}
							}}
							//avoid setting red border just after the page has loaded (red border because of undefined required value in FF)
							noValidate>
							<div className='row'>
								<div className='col-md-6 form-div'>
									<div>
										<div className='welcome-text-header'>WITAMY w </div>
										<div className='welcome-text'>MojUrzad</div>
										{/* <img className='welcome-img' src='images/app_logo.svg'/> */}
									</div>
									<div id="captcha">
										{this.state.showCaptcha ?
											<React.Fragment>
												<CaptchaV2
													handleOnSuccess={() => {
														this.setState((prevState) => ({
															...prevState,
															captchaPreventAction: false
														}))
													}}
													handleOnExpire={() => {
														this.setState((prevState) => ({
															...prevState,
															captchaPreventAction: true
														}))
													}} />
											</React.Fragment> : null}
									</div>
								</div>
								<div className='col-md-6 form-div'>
									<div className='login-label'>Nazwa użytkownika</div>
									<div location='user_field'>
										<InputText
											ariaLabel={'Nazwa użytkownika'}
											key={'username'}
											id={'username'}
											name={'username'}
											placeholder={''}
											style={{ width: '100%' }}
											value={this.state.username}
											onChange={(e) => this.handleChange('TEXT', undefined, e, undefined, '')}
											required={true}
											validator={this.validator}
											validators='required|max:50'
										/>
										<div className='login-label'>Hasło</div>
										<div location='pass_field'>
											<Password
												ariaLabel={'Hasło'}
												key={'password'}
												id={'password'}
												name={'password'}
												placeholder={''}
												style={{ width: '100%' }}
												value={this.state.password}
												onChange={(e) => this.handleChange('TEXT', undefined, e, undefined, '')}
												promptLabel={'Hasło'}
												feedback={false}
												required={true}
												validator={this.authValidValidator}
												validators='not_required'
											/>
										</div>
										<div>
											<ActionLink label='Przypomnij hasło' href={'/#/reset-password'} variant='lost-pass-label' />
										</div>
										<ActionButton
											label='Zaloguj'
											variant='login-button normal float-right'
											handleClick={this.handleFormSubmit}
											disabled={this.state.captchaPreventAction} />
									</div>
								</div>
							</div>
						</form>

					</div>
				</BlockUi>
			</React.Fragment>
		);
	}
}
LoginContainer.propTypes = {
	onAfterLogin: PropTypes.func,
};
export default LoginContainer;
