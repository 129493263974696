import BaseServiceCrud from './BaseServiceCrud';

export default class SubcategoryService extends BaseServiceCrud {
	// Initializing important variables
	constructor(categoryId) {
		super();
		this.path = `subcategory/${categoryId}/sub`;
	}

	getCriteria(criteria) {
		return null;
	}

}
