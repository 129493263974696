import uuid from 'uuid';

export default class QuestionModel {
    constructor(index) {
        this.index = index;
        this.id = undefined;
        this.questionName = '';
        this.questionNameEng = '';
        this.aswerType = undefined;
        this.minAnswerCount = 1;
        this.maxAnswerCount = 1;
        this.answerOptions = [];
        this.minScale = 1;
        this.maxScale = 1;
    }
    static copy(from, to) {
        return QuestionModel.assingQuestions(from, to);
    }
    static copyAnswerOptions(from, to) {
        return QuestionModel.answerOptions(from, to);
    }
    static answerOptions(from, to) {
        to = [];
        if (!from) {
            return to;
        }
        from.forEach((f, index) => {
            const newTo = f;
            newTo.index = index;
            newTo.uuid = uuid.v4();
            to.push(newTo);
        })
        return to;
    }
    static assingQuestions(questionsFrom, questionsTo) {
        
        questionsTo = [];

        if (!questionsFrom) {
            return;
        }
        questionsFrom.forEach((questionFrom, index) => {
            const currentQuestionTo = new QuestionModel(index);
            QuestionModel.assign(questionFrom, currentQuestionTo, index);
            questionsTo.push(currentQuestionTo);
        });
        return questionsTo;
    }
    static assign(from, to, index) {
        if (from) {
            to.id = from.id;
            to.questionName = from.questionName ? from.questionName : '';
            to.questionNameEng = from.questionNameEng ? from.questionNameEng : '';
            to.answerType = from.answerType ? from.answerType : undefined;
            to.category = from.category ? from.category : undefined;
            to.answerOptions = from.answerOptions ? QuestionModel.answerOptions(from.answerOptions) : undefined;
            to.minAnswerCount=from.minAnswerCount ? from.minAnswerCount : undefined;
            to.maxAnswerCount = from.maxAnswerCount? from.maxAnswerCount : undefined;
            to.minScale = from.minScale ? from.minScale : undefined;
            to.maxScale = from.maxScale ? from.maxScale : undefined;
        } else {
            to = new QuestionModel(index);
        }
    }

}