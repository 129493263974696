import BaseServiceCrud from './BaseServiceCrud';

export default class ConversationServive extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'conversation';
	}

	getMessages(conversationId, firstResult, maxResults, oldestId) {
		const url = oldestId ? `${this.domain}/${this.path}/${conversationId}/${firstResult}/${maxResults}?oldestId=${oldestId}` : `${this.domain}/${this.path}/${conversationId}/${firstResult}/${maxResults}`;
		return this.fetch(
			url,
			{
				method: 'GET',
			}
		).catch((err) => {
			throw err;
		});
	}
	sendMessage(conversationId, request) {
		return this.fetch(`${this.domain}/${this.path}/${conversationId}`, {
			method: 'POST',
			body: JSON.stringify(request),
		});
	}

	sendFile(conversationId, request) {
		return this.fetch(`${this.domain}/${this.path}/${conversationId}/file`, {
			method: 'POST',
			body: JSON.stringify(request),
		});
	}

	updateConversation(conversationId, lastMessasgeId) {
		return this.fetch(
			`${this.domain}/${this.path}/${conversationId}?lastMessageId=${lastMessasgeId}`,
			{
				method: 'GET',
			}
		).catch((err) => {
			throw err;
		});
	}
	
	startConversation(request) {
		return this.fetch(`${this.domain}/${this.path}`, {
			method: 'POST',
			body: JSON.stringify(request),
		});
	}
	markAsAllRead(conversationId){
		return this.fetch(`${this.domain}/${this.path}/${conversationId}/markAllAsRead`, {
			method: 'PUT',
		}).catch((err) => {
			throw err;
		});
	}


	getMessageCount() {
        return this.fetch(`${this.domain}/${this.path}/unread-message-count`, {
			method: 'GET',
		}).catch((err) => {
			throw err;
		});

	}
}
