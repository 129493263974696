import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InternalUserService from '../../services/InternalUserService';
import CategoryService from '../../services/CategoryService';
import YesNoDialog from '../../components/YesNoDialog';
import NotificationService from '../../services/NotificationService';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import InputCalendarComponent from '../../components/inputs/InputCalendarComponent';
import moment from 'moment';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import InputFileUploadComponent from '../../components/inputs/InputFileUploadComponent';
import PublicUnitService from '../../services/PublicUnitService';
import AreaService from '../../services/AreaService';
import { MapLeaflet } from '../../components/business/MapLeaflet';
class NotificationContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new NotificationService(props.id));
		this.categoryId = props.id;
		this.subcategoryId = props.subId;
		this.categoryService = new CategoryService();
		this.internalUserService = new InternalUserService();
		this.subjectMaxLength = 60;
		this.contentMaxLength = 500;
		this.state = {
			loading: true,
			elementId: this.props.id,
			showPublishDialog: false,
			element: {
				category: undefined,
				subcategory: undefined,
				publicUnit: undefined,
				subject: '',
				subjectEng: '',
				content: '',
				contentEng: '',
				hyperlink: '',
				immediatelySend: true,
				sendDate: new Date(),
				activeTo: undefined,
				attachments: [],
				areas: []
			},
		};
		this.showPublishDialog = this.showPublishDialog.bind(this);
		this.publishNotification = this.publishNotification.bind(this);
		this.handleSaveDraft = this.handleSaveDraft.bind(this);
		this.publishValidate = this.publishValidate.bind(this);
		this.handleClickArea = this.handleClickArea.bind(this);
		this.handleChangePrevalidation = this.handleChangePrevalidation.bind(this);
	}


	getContainerListName() {
		return 'notification-list-container';
	}

	getAddSucces() {
		return 'Powiadomienie zostało utworzone';
	}

	getUpdateSucces() {
		return 'Powiadomienie zostało zaktualizowane';
	}



	updateElement(data) {

		this.setState(
			{
				element: {
					id: data.id,
					status: data.status,
					category: data.category,
					subcategory: data.subcategory,
					publicUnit: data.publicUnit,
					subject: data.subject,
					subjectEng: data.subjectEng,
					content: data.content,
					contentEng: data.contentEng,
					hyperlink: data.hyperlink,
					immediatelySend: data.immediatelySend,
					sendDate: data.sendDate ? new Date(data.sendDate) : undefined,
					activeTo: data.activeTo ? new Date(data.activeTo) : undefined,
					attachments: data.attachments ? data.attachments : [],
					areas: data.areas ? data.areas : []
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}

	initBeforeSetElement() {
		this.categoryService.getCategories("NOTIFICATION")
			.then(categoryOpt => this.setState({ categoryOpt }))
			.catch(err => {
				this.showErrorMessage("Nie można pobrać kategorii");
			})
		new PublicUnitService()
			.getAllActive()
			.then(pu => this.setState({ publicUnitOpt: pu }))
			.catch((err) => {
				this.messages.show({
					severity: 'error',
					summary: 'Błąd',
					detail: err.message,
				});
			});
		if (this.props.viewMode === 'NEW') {
			new AreaService()
				.getAreas()
				.then(areas => {
					this.setState((prevState) => ({
						element: {
							...prevState.element,
							areas: areas,
						},
					}));
				})
				.catch((err) => {
					this.messages.show({
						severity: 'error',
						summary: 'Błąd',
						detail: err.message,
					});
				});
		}
	}

	initAfterSetElement() {
		if (this.state.element?.category?.id) {
			this.fetchSubcategories();
		} else {
			this.unblockUi();
		}
	}
	setElement() {
		this.internalUserService.getUserReferenceList({ status: { enumValue: "ACTIVE", label: "Aktywna" }, notBlocked: true })
			.then(userOptions => {
				userOptions.forEach(u => u.label = `${u.firstName} ${u.lastName}`);
				this.setState({ userOptions }, () => super.setElement())
			})
			.catch(() => this.setState({ userOptions: [] }, () => super.setElement()))
	}
	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode, id, subId } = this.props;
		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${id}`;
		}
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
				label: 'Zapisz jako wersja robocza',
				className: 'float-right',
				onClick: this.handleSaveDraft,
				rendered: this.isUserInAnyRole('ROLE_NOTIFY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') && (viewMode === 'NEW' || viewMode === 'EDIT'),
			},
			{
				label: 'Opublikuj',
				className: 'float-right',
				onClick: this.publishValidate,
				rendered: this.isUserInAnyRole('ROLE_NOTIFY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') && (viewMode === 'NEW' || this.state.element.status?.enumValue === 'DRAFT'),
			},
		];
	}
	publishValidate(event) {
		if (event !== undefined) {
			event.preventDefault();
		}
		if (this.validator.allValid()) {
			if (!this.state.element.areas || this.state.element.areas.filter(a => !!a.selected).length === 0) {
				this.showErrorMessage("Nie wybrano żadnego obszaru")
				return;
			}
			this.setState({ showPublishDialog: true })
		} else {
			this.validatorDraft.hideMessages();
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.scrollToError = true;
			this.forceUpdate();
		}
	}
	handleSaveDraft(event) {
		if (event !== undefined) {
			event.preventDefault();
		}
		if (this.validatorDraft.allValid()) {
			this.blockUi(this.handleValidForm);
		} else {
			this.validator.hideMessages();
			this.validatorDraft.showMessages();
			this.scrollToError = true;
			this.forceUpdate();
		}
	}
	afterAddSuccess(response) {
		const { backUrl } = this.props;
		this.saveCookie('notification-list-activeIndex', 1);
		this.blockUi();
		this.persistMessage('success', '', this.getAddSucces(response));
		window.location.href = backUrl;
	}
	afterUpdateSuccess(response) {
		const { backUrl } = this.props;
		this.saveCookie('notification-list-activeIndex', 1);
		this.blockUi();
		this.persistMessage('success', '', this.getUpdateSucces(response));
		window.location.href = backUrl;
	}

	prepareEditButton(rendered, label) {
		const { editUrl, id } = this.props;

		return {
			label: label !== undefined ? label : 'Edytuj',
			type: 'BUTTON',
			variant: '',
			className: 'float-right',
			href: `${editUrl}/${id}`,
			rendered: this.props.viewMode === 'VIEW' && this.state.element?.status?.enumValue === 'DRAFT' && this.isUserInAnyRole('ROLE_NOTIFY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'),
			iconName: 'mdi-pencil',
			iconSide: 'left',
			iconSize: 'm',
		};
	}


	showPublishDialog() {
		this.setState({ showPublishDialog: true });
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowe powiadomienie' : viewMode === 'EDIT' ? 'Edycja powiadomienia' :'Szczegóły powiadomienia',
				
				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_NOTIFY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')),
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}
	updateElementBeforeCreateOrUpdate(element) {
		if (!!element.immediatelySend) {
			element.sendDate = new Date();
		}
		return element;
	}
	publishNotification(type, costam, target) {
		if (target.value) {
			let { element } = this.state;
			this.scrollToTop();

			this.blockUi();
			if (this._isMounted) {
				element = this.updateElementBeforeCreateOrUpdate(element)

				if (element.id === undefined || element.id === null) {
					this.service
						.publish(this.state.element)
						.then((data) => {
							this.saveCookie('notification-list-activeIndex', 0);
							this.persistMessage('success', '', 'Powiadomienie zostało opublikowane');
							window.location.href = this.props.backUrl;
						})
						.catch((err) => {
							this.showErrorMessage(err.message);
							this.setState({ showPublishDialog: false });
							this.unblockUi();
						});
				} else {
					this.service
						.publishDraft(this.state.element)
						.then((data) => {
							this.saveCookie('notification-list-activeIndex', 0);
							this.persistMessage('success', '', 'Powiadomienie zostało opublikowane');
							window.location.href = this.props.backUrl;
						})
						.catch((err) => {
							this.showErrorMessage(err.message);
							this.setState({ showPublishDialog: false });
							this.unblockUi();
						});
				}
			}
		} else {
			this.setState({ showPublishDialog: false });
		}
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/notification-list' className='p-link'>
					{'Powiadomienia'}
				</BreadcrumbsItem>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/notification/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/notification/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/notification/create'>{'Nowe'}</BreadcrumbsItem> : null}
				<YesNoDialog
					onChange={this.publishNotification}
					visible={this.state.showPublishDialog}
					header="Publikacja powiadomienia"
					name="visible"
					onHide={() => {
						this.setState({ showPublishDialog: false });
					}}>
					<span>Czy na pewno chcesz opublikować powiadomienie?</span>
				</YesNoDialog>
				{this.renderView()}
			</div>
		);
	}

	fetchSubcategories() {
		this.service
			.getSubcategories(this.state.element?.category?.id)
			.then(subcategories => {
				this.setState({ subcategoriesOpt: subcategories }, () => super.initAfterSetElement())
			})
			.catch(err => {
				this.showErrorMessage(err.message);
				super.initAfterSetElement()
			});
	}

	handleChangePrevalidation(inputType, parameters, event, onAfterStateChange, stateField, field, validationLength){
		const varName = event.target.name;
		const varValue = event.target.value || event.target.value === '' ? event.target.value : undefined;
		if (varValue?.length >= validationLength){
			this.validator.showMessageFor(field)
		}
		this.handleChangeSetState(varName, varValue, onAfterStateChange, stateField, parameters ? parameters[0] : undefined);
		
	}
	renderDetails() {
		return (
			<React.Fragment>
				<div class='row'>
					<div class='col-xl-6 col-lg-8 col-md-10 col-sm-12'>
						<InputTextComponent
							id={"subject"}
							name={"subject"}
							label={"Temat w języku polskim"}
							colClass='col-12'
							value={this.state.element.subject}
							validator={[this.validator, this.validatorDraft]}
							onChange={(inputType, parameters, event, onAfterStateChange, stateField) =>this.handleChangePrevalidation(inputType, parameters, event, onAfterStateChange, stateField, 'subject', this.subjectMaxLength)}
							viewMode={this.props.viewMode}
							validateViewMode
							validators={`required|max:${this.subjectMaxLength}`}
						/>
						<InputTextareaComponent
							id='content'
							name='content'
							label={'Treść w języku polskim'}
							colClass='col-12'
							value={this.state.element.content}
							validator={[this.validator, this.validatorDraft]}
							onChange={(inputType, parameters, event, onAfterStateChange, stateField) =>this.handleChangePrevalidation(inputType, parameters, event, onAfterStateChange, stateField, 'content', this.contentMaxLength)}
							viewMode={this.props.viewMode}
							validators={`required|max:${this.contentMaxLength}`}
							validateViewMode
							autoResize
						/>
						<InputTextComponent
							id={"subjectEng"}
							name={"subjectEng"}
							colClass='col-12'
							label={"Temat w języku angielskim"}
							value={this.state.element.subjectEng}
							validator={[this.validator, this.validatorDraft]}
							onChange={(inputType, parameters, event, onAfterStateChange, stateField) =>this.handleChangePrevalidation(inputType, parameters, event, onAfterStateChange, stateField, 'subjectEng', this.subjectMaxLength)}
							viewMode={this.props.viewMode}
							validateViewMode
							validators={`max:${this.subjectMaxLength}`}
						/>
						<InputTextareaComponent
							id='contentEng'
							name='contentEng'
							label={'Treść w języku angielskim'}
							colClass='col-12'
							value={this.state.element.contentEng}
							validator={[this.validator, this.validatorDraft]}
							onChange={(inputType, parameters, event, onAfterStateChange, stateField) =>this.handleChangePrevalidation(inputType, parameters, event, onAfterStateChange, stateField, 'contentEng', this.contentMaxLength)}
							viewMode={this.props.viewMode}
							validators={`max:${this.contentMaxLength}`}
							validateViewMode
							autoResize
						/>
						<InputDropdownComponent
							id='category'
							name='category'
							label={'Kategoria'}
							colClass='col-md-6 col-xs-12'
							value={this.state.element.category}
							validator={[this.validator, this.validatorDraft]}
							options={this.state.categoryOpt}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							optionLabel='name'
							dataKey='id'
							validators='required'
							valueView={this.state.element.category ? this.state.element.category.name : ''}
							onAfterStateChange={e => { this.fetchSubcategories(e) }}
							validateViewMode
						/>
						<InputDropdownComponent
							id='subcategory'
							name='subcategory'
							label={'Podkategoria'}
							colClass='col-md-6 col-xs-12'
							value={this.state.element.subcategory}
							validator={[this.validator, this.validatorDraft]}
							options={this.state.subcategoriesOpt}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							optionLabel='name'
							dataKey='id'
							validators='not_required'
							valueView={this.state.element.subcategory ? this.state.element.subcategory.name : ''}
							validateViewMode
							rendered={this.state.element.subcategory || (this.state.subcategoriesOpt?.length && this.state.subcategoriesOpt?.length > 0)}
						/>

						<InputDropdownComponent
							id='publicUnit'
							name='publicUnit'
							label={'Działy/jednostki'}
							colClass='col-md-6 col-xs-12'
							value={this.state.element.publicUnit}
							validator={[this.validator, this.validatorDraft]}
							options={this.state.publicUnitOpt}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							optionLabel='name'
							dataKey='id'
							validators='required'
							valueView={this.state.element.publicUnit ? this.state.element.publicUnit.name : ''}
							validateViewMode
						/>
						<InputTextComponent
							id='hyperlink'
							name={"hyperlink"}
							label={"Hiperłącze"}
							colClass='col-12'
							value={this.state.element.hyperlink}
							validator={[this.validator, this.validatorDraft]}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							validateViewMode
							validators="not_required|max:255"
						/>
						<InputDropdownComponent
							colClass='col-md-6 col-xs-12'
							label="Status"
							id="status"
							name="status"
							value={this.state.element.status}
							validator={[this.validator, this.validatorDraft]}
							validators="not_required"
							optionLabel="label"
							viewMode={"VIEW"}
							rendered={this.props.viewMode !== "NEW"}
						/>
						<InputCheckboxComponent
							id="immediatelySend"
							name="immediatelySend"
							label="Publikacja natychmiast"
							value={this.state.element.immediatelySend}
							validator={[this.validator, this.validatorDraft]}
							validators='not_required'
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							colClass="col-6"
							// onAfterStateChange={e => {
							// 	this.setState((prevState) => ({
							// 		element: {
							// 			...prevState.element,
							// 			sendDate: new Date(),
							// 		},
							// 	}));
							// }}
							validateViewMode
						/>
						<InputCalendarComponent
							id='sendDate'
							name='sendDate'
							label={'Data publikacji'}
							dateFormatMoment='YYYY-MM-DD HH:mm'
							colClass='col-md-6 col-xs-12'
							value={this.state.element.sendDate}
							validator={[this.validator, this.validatorDraft]}
							validators={`required|date|date_format~DD-MM-YYYY HH:mm,dd-mm-rrrr HH:mm|after_or_equal_time~${moment(new Date()).format('DD-MM-YYYY HH:mm').toString()}`}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							rendered={!this.state.element.immediatelySend}
							validateViewMode
							showTime
							minDate={new Date()}
							required
							showIcon
						/>
						<InputCalendarComponent
							id='activeTo'
							name='activeTo'
							label={'Data ważności'}
							dateFormatMoment='YYYY-MM-DD HH:mm'
							colClass='col-md-6 col-xs-12'
							value={this.state.element.activeTo}
							validator={[this.validator, this.validatorDraft]}
							validators={`required|date|after_or_equal_time~${moment(this.state.element.sendDate ? this.state.element.sendDate : new Date()).format('DD-MM-YYYY HH:mm').toString()}`}
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
							validateViewMode
							required
							minDate={this.state.element.sendDate ? this.state.element.sendDate : new Date()}
							showTime
							placeholder='dd-mm-rrrr GG:MM'
							showIcon
						/>
						<InputFileUploadComponent
							messages={this.messages}
							id='attachments'
							name='attachments'
							label={'Zdjęcia/pliki'}
							fileList={this.state.element.attachments}
							showLabel
							value={this.state.element.attachments}
							restApiUrl={`${this.service.getPath()}/${this.state.element.id}/attachments`}
							multiple
							maxFileSize={50000000}
							onChange={this.handleChange}
							itemLabel='fileName'
							itemName='uuid'
							colClass='col-12'
							viewMode={this.props.viewMode}
							validator={[this.validator, this.validatorDraft]}
							validators="not_required"
							style={{ width: '100%' }}
							restrictExt={true}
							accept='.pdf,.jpg'
							handleError={this.onUploladError}
							token={this.authService.getToken()}
							validateViewMode
							overrideFileNotAcceptedMessage='Niepoprawne rozszerzenie pliku. Wymagany plik z rozszerzeniem: .pdf lub .jpg'
						/>
					</div>
				</div>
					<div colClass="col-12">
					<div id="mapid">
						<MapLeaflet areas={this.state.element?.areas} handleClickArea={this.handleClickArea} viewMode={this.props.viewMode} />
					</div>
					</div>
			</React.Fragment>
		);
	}
	handleClickArea(id) {
		const modifiedList = this.state.element.areas;
		const selectedArea = modifiedList.find(a => a.id === id);

		if (selectedArea) {
			const wasSelected = !!selectedArea.selected;
			selectedArea.selected = !wasSelected;
			this.setState((prevState) => ({
				element: {
					...prevState.element,
					areas: modifiedList,
				},
			}));
		}
	}
}

NotificationContainer.defaultProps = {
	backUrl: '/#/notification-list',
	cancelUrl: '/#/notification/details',
	editUrl: '/#/notification/edit',
};

NotificationContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
};

export default NotificationContainer;
