import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import EventService from '../../services/EventService';
import UserRoleService from '../../services/UserRoleService';
import ActionLink from '../../components/ActionLink';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import CategoryService from '../../services/CategoryService';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';

class EventListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new EventService());
        this.roleService = new UserRoleService();
        this.categoryService = new CategoryService();
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            categoryOpt: [],
            statusSC: undefined,
            eventSourceOptions: [],
        };
    }

    updateSearchCriteria(criteria) {
        return {
            status: criteria.status,
            categories: criteria.categories,
            title: criteria.title,
            firstResult: criteria.firstResult,
            maxResult: criteria.maxResult,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteriaPrototype() {
        const criteria = this.getCleanSearchCriteria();
        criteria.limitObj = { value: criteria.maxResult };
        criteria.limit = 10;
        return criteria;
    }

    getCleanSearchCriteria() {
        return {
            title: '',
            status: undefined,
            categories: undefined,
            firstResult: 0,
            maxResult: 10,
            sortField: 'id',
            sortAsc: false,
        };
    }
    getCriteriaName() {
        return 'event-list-sc';
    }

    getContainerListName() {
        return 'event-list-container';
    }
    initializeFromBackend() {
        this.enumService
            .getEnumList('EventSource')
            .then((eventSourceOptions) =>
                this.setState({
                    eventSourceOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów wydarzeń.');
            });
        this.categoryService.getCategories("EVENT")
            .then(categoryOpt => this.setState({ categoryOpt }))
            .catch(err => {
                console.log(err);
                this.showErrorMessage("Nie można pobrać kategorii");
            });
        this.enumService
			.getEnumList('EventStatus')
			.then((statusOptions) => {
				this.setState({
					statusOptions,
				})
			})
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy statusów');
			});
    }

    prepareColumns() {
        return [
            { field: 'title', header: 'Nazwa', sortable: true },
            { field: 'shortDescription', header: 'Skrócony opis', sortable: true },
            { field: 'startTime', header: 'Data rozpoczęcia', sortable: true, body: this.dateTemplate.bind(this, 'startTime', 'YYYY-MM-DD HH:mm') },
			{ field: 'endTime', header: 'Data zakończenia', sortable: true, body: this.dateTemplate.bind(this, 'endTime', 'YYYY-MM-DD HH:mm') },
            { field: 'source', header: 'Rodzaj', body: this.enumTemplate.bind(this, 'source') },
            { field: 'categoriesDesc', header: 'Kategorie' },
            {
                key: 'actions',
                header: 'Akcje',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }
    prepareHeaderItems() {
        return this.isUserInAnyRole('ROLE_EVENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')
            ? [
                {
                    label: 'Utwórz wydarzenie',
                    href: this.props.newUrl,
                    type: 'BUTTON',
                    className: 'float-right',
                    variant: 'yellow',
                    iconName: 'mdi-plus',
                    iconSide: 'left',
                    iconColor: 'white',
                },
            ]
            : [];
    }

    actionTemplate(rowData) {
        var actions = super.actionTemplate(rowData);
        var isActive = rowData.status?.enumValue == 'ACTIVE';
        const { detailUrl } = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <React.Fragment>
                <ActionLink
                    label={isActive ? 'Dezaktywuj' : 'Aktywuj'}
                    handleClick={() => { this.handleEventActivitySwitch(rowData) }}
                    variant='blue'
                    className='p-link hover-underline'
                    key={'switch-activity-button'}
                    iconSize='xs'
                    iconName={isActive ? 'mdi-minus-circle' : 'mdi-check-circle'}
                    iconColor='blue'
                />
                {actions}
            </React.Fragment>
        );
    }

    handleEventActivitySwitch = (rowData) => {
        this.service.switchActivityStatus(rowData).then(res => {
            this.refreshTable();
            this.showSuccessMessage(`Wydarzenie zostało  ${rowData.status?.enumValue === 'ACTIVE' ? 'dezaktywowane' : 'aktywowane'}`);
        });
    }
    renderCriteria() {
        return (
            <div className='row'>
                  <CriteriaTextComponent
					id="title-sc"
					name="title"
					label="Nazwa"
                    colClass='col-12'
					value={this.state.criteria.title}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
                <CriteriaMultiSelectComponent
                    id="categories-sc"
                    name="categories"
                    showClear
                    label="Kategoria"
                    colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                    value={this.state.criteria.categories}
                    options={this.state.categoryOpt}
                    placeholder="Wszystkie"
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel="name"
                    dataKey='id'
                    filter
					filterBy="name"
                />
                	<CriteriaDropdownComponent
					id="status-sc"
					name="status"
					showClear
					label="Status"
                    colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                    value={this.state.criteria.status}
					options={this.state.statusOptions}
					placeholder="Wszystkie"
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
 
            </div>);
            
    }
    render = () => {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/event-list'>{'Wydarzenia'}</BreadcrumbsItem>
                {this.renderView()}
            </div>
        );
    }

}

EventListContainer.defaultProps = {
    detailUrl: '/#/event/details',
    newUrl: '/#/event/create',
};

EventListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default EventListContainer;
