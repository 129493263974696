import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputTextareaComponent from "../components/inputs/InputTextareaComponent";
import Constants from "../utils/constants";
import ApprovalContentService from "../services/ApprovalContentService";
import YesNoDialog from "../components/YesNoDialog";
import InputTextComponent from "../components/inputs/InputTextComponent";
import InputNumberComponent from '../components/inputs/InputNumberComponent';
import InputFileUploadComponent from '../components/inputs/InputFileUploadComponent';

class ApprovalContentContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new ApprovalContentService());
        this.isHalfWidth = true;
        this.state = {
            loading: true,
            elementId: props.id,
            element: {
                content: '',
                engContent: '',
                required: null,
                order: undefined,
                attachments: [],
            },
            booleanOptions: Constants.BOOLEAN_OPTIONS,
        };
    }

    updateElement(data) {
        this.setState(
            {
                element: {
                    id: data.id,
                    content: data.content,
                    engContent: data.engContent,
                    required: data.required ? Constants.BOOLEAN_OPTIONS[0] : Constants.BOOLEAN_OPTIONS[1],
                    order: data.order,
                    attachments: data.attachments ? data.attachments : [],
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    updateElementBeforeCreateOrUpdate(element) {
        element.required = element.required?.boolValue;
        return element;
    }

    getContainerListName() {
        return 'approval-content-list-container';
    }

    getAddSucces() {
        return 'Zgoda została utworzona';
    }

    getUpdateSucces() {
        return 'Zgoda została zaktualizowana';
    }

    getRemoveSucces() {
        return 'Zgoda została usunięta';
    }

    initBeforeSetElement() {
    }

    prepareFooterItems() {
        const { backUrl, cancelUrl, viewMode } = this.props;
        const { elementId } = this.state;
        let goBackUrl;
        if (viewMode === 'NEW') {
            goBackUrl = backUrl;
        } else {
            goBackUrl = `${cancelUrl}/${elementId}`;
        }
        return [
            { label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
            {
                label: 'Usuń',
                className: 'float-right',
                onClick: this.showRemoveConfirmation,
                rendered:
                    viewMode === 'VIEW'
            },
            {
                label: 'Zapisz',
                className: 'float-right',
                onClick: this.handleFormSubmit,
                rendered: viewMode === 'NEW' || viewMode === 'EDIT',
            },
        ];
    }

    prepareHeaderItems() {
        const { viewMode } = this.props;
        return [
            {
                label: viewMode === 'NEW' ? 'Nowa zgoda' : viewMode === 'EDIT' ? 'Edycja zgody' : 'Szczegóły zgody',
                type: 'LABEL',
                className: '',
            },
            this.prepareEditButton(viewMode === 'VIEW'),
        ];
    }

    getBackLabel() {
        return 'Wróć do listy';
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/approval-content-list' className='p-link'>
                    {'Zgody'}
                </BreadcrumbsItem>
                <YesNoDialog
                    onChange={this.onRemoveAction}
                    visible={this.state.showConfirmRemoveDialog}
                    header="Usuwanie zgód"
                    name="visible"
                    onHide={() => {
                        this.setState({ showConfirmRemoveDialog: false });
                    }}>
                    Czy na pewno chcesz usunąć wybraną zgodę?
                </YesNoDialog>
                {this.props.viewMode === 'VIEW' ?
                    <BreadcrumbsItem to='/approval-content/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
                {this.props.viewMode === 'EDIT' ?
                    <BreadcrumbsItem to='/approval-content/edit'>{'Edycja'}</BreadcrumbsItem> : null}
                {this.props.viewMode === 'NEW' ?
                    <BreadcrumbsItem to='/approval-content/create'>{'Nowy'}</BreadcrumbsItem> : null}
                {this.renderView()}
            </div>
        );
    }

    renderDetails() {
        return (
            <React.Fragment>
                <div class='row'>
                    <InputTextareaComponent
                        id='content'
                        name='content'
                        label={'Treść w języku polskim'}
                        colClass='col-md-12'
                        value={this.state.element.content}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'required|max:1500'}
                    />

                    <InputTextareaComponent
                        id='eng-content'
                        name='engContent'
                        label={'Treść w języku angielskim'}
                        colClass='col-md-12'
                        value={this.state.element.engContent}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'max:1500'}
                    />

                    <InputNumberComponent
                        id='order'
                        name='order'
                        label={'Kolejność'}
                        colClass='col-md-6'
                        min={0}
                        value={this.state.element.order}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'naturalNumber'}
                    />

                    <InputDropdownComponent
                        id='required'
                        name='required'
                        dataKey='label'
                        label={'Obowiązkowa'}
                        colClass='col-md-6'
                        value={this.state.element.required}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'required'}
                        optionLabel="label"
                        options={this.state.booleanOptions}
                    />

                    <InputFileUploadComponent
                        messages={this.messages}
                        id='attachments'
                        name='attachments'
                        label={'Pliki'}
                        fileList={this.state.element.attachments}
                        showLabel
                        value={this.state.element.attachments}
                        restApiUrl={`${this.service.getPath()}/${this.state.element.id}/attachments`}
                        multiple
                        maxFileSize={50000000}
                        onChange={this.handleChange}
                        itemLabel='fileName'
                        itemName='uuid'
                        colClass='col-xl-12'
                        viewMode={this.props.viewMode}
                        validator={this.validator}
                        validators="not_required"
                        style={{ width: '100%' }}
                        restrictExt={true}
                        accept='.pdf,.jpg'
                        handleError={this.onUploladError}
                        token={this.authService.getToken()}
                        validateViewMode
                        maxFileCount={1}
                    />
                </div>

            </React.Fragment>
        );
    }
}

ApprovalContentContainer.defaultProps = {
    backUrl: '/#/approval-content-list',
    cancelUrl: '/#/approval-content/details',
    editUrl: '/#/approval-content/edit',
};

ApprovalContentContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,

};

export default ApprovalContentContainer;
