
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

import React from 'react';
import InputPasswordComponent from '../../components/inputs/InputPasswordComponent';
import { Messages } from 'primereact/messages';

import SimpleReactValidator from '../../components/validator';
import UserService from '../../services/UserService';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import { Card } from 'primereact/card';
import CustomMessages from '../../components/CustomMessages';
import BlockUi from '../../components/waitPanel/BlockUi';
import SettingService from '../../services/SettingService';

class ChangePasswordContainer extends BaseDetailsContainer {
	constructor() {
		super();
		this.handleChange = this.handleChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.state = {
			newPassword: '',
			repeatPassword: '',
			maxLength: 15,
			minLength: 5,
			lowerCaseCount: 3,
			upperCaseCount: 4,
			numbersCount: 0,
			specialCharactersCount: 0,
		};
		this.validator = new SimpleReactValidator();
		this.handleRedirect = this.handleRedirect.bind(this);

	}
	initBeforeSetElement(){
		const service = new SettingService();
		service.getPasswordParamsPublic().then((resp) => {
			this.setState({
				maxLength: resp['PASSWORD_MAX_LENGTH'],
				minLength: resp['PASSWORD_MIN_LENGTH'],
				lowerCaseCount: resp['PASSWORD_LOWERCASE_COUNT'],
				upperCaseCount: resp['PASSWORD_UPPERCASE_COUNT'],
				numbersCount: resp['PASSWORD_NUMBERS_COUNT'],
				specialCharactersCount: resp['PASSWORD_SPECIAL_CHARS_COUNT'],
			});
		});
	}
	handleChange(e) {
		console.log(e);
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	setElement() {
		this.initAfterSetElement();
	}
	prepareFooterItems() {
				return [
					{ label: 'Anuluj', onClick: this.handleRedirect },
					{
						label: 'Zapisz',
						variant: 'accent',
						className: 'float-right',
						onClick: this.handleFormSubmit,
					},
				];
	}
	handleFormSubmit(e) {
		e.preventDefault();
		if (this.validator.allValid()) {

			const body = { newPassword: this.state.newPassword, oldPassword: this.state.oldPassword };
			this.blockUi();
			new UserService()
				.changePassword(body)
				.then((res) => {
					this.persistMessage('success','', 'Hasło zostało zmienione pomyślnie');
					this.handleRedirect();
					this.unblockUi();
				})
				.catch((err) => {
					this.showErrorMessage(err.message);
					this.unblockUi();
				});
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.setState({
				valid: false,
			});
			this.forceUpdate();
		}
	}
	getContainerListName(){
		return 'manage-account-details';
	}
	handleRedirect() {
		this.props.history.replace('/manage-account-details');
	}
	render() {
		return (
			<React.Fragment>
				<Messages ref={(el) => (this.messages = el)} />
				<BreadcrumbsItem to="/manage-account-details" className="p-link">
					{'Zarządzanie kontem'}
				</BreadcrumbsItem>
				{this.renderView()}
				
			</React.Fragment>
		);	}

		renderView() {
			return (
				<React.Fragment>
					<BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
						
						<div className='row'>
							{/* <div className='col-12'>{this.renderBackLink()}</div> */}
						</div>
						{this.state.loading ? null : (
							<React.Fragment >
								<Card 
								footer={this.renderFooter()} header={this.renderHeader()}
								>
									<form onSubmit={this.handleFormSubmit} noValidate>
										{this.renderDetails()}
									</form>
							 	</Card>
							 </React.Fragment>
						)}
					</BlockUi>
				</React.Fragment >
			);
		}
		renderDetails(){	
		return (
			 <React.Fragment>
			{/* // 	<Messages ref={(el) => (this.messages = el)} /> */}
				{/* <div id="main-login">
					<div className="container"> */}
					<div>
						<div className="row">
							{/* <div className="col-sm-9 col-md-7 col-lg-4 mx-auto">
								<div className="card card-signin my-5">
									<div className="card-body">
										<h5 className="card-title text-align-left">
											{'Zmiana hasła'}
										</h5>
										<form
											className="form-signin"
											onSubmit={this.handleFormSubmit}>
											<div className="form-group"></div>
											<div className="form-group">
													 */}
													 <InputPasswordComponent
													id="oldPassword"
													// eslint-disable-next-line max-len
													label={
														'Stare hasło'
													}
													labelForValidator='Stare hasło'
													name="oldPassword"
													value={
														this.state.oldPassword
													}
													validator={this.validator}
													onChange={(a, b, c) =>
														this.handleChange(c)
													}
													viewMode="EDIT"
													validators="required"
													colClass="col-xl-12 col-lg-12 col-md-12 col-sm-12"
													feedback={false}
												/>
												<InputPasswordComponent
													id="newPassword"
													// eslint-disable-next-line max-len
													label={this.getPasswordLabel()}
													labelForValidator="Nowe hasło"
													name="newPassword"
													value={
														this.state.newPassword
													}
													validator={this.validator}
													onChange={(a, b, c) =>
														this.handleChange(c)
													}
													viewMode="EDIT"
													validators={`required|extended_password:${this.state.minLength}:${this.state.maxLength}:${this.state.lowerCaseCount}:${this.state.upperCaseCount}:${this.state.numbersCount}:${this.state.specialCharactersCount}`}
													//validators={`required|password:9`}
													colClass="col-xl-12 col-lg-12 col-md-12 col-sm-12"
												/>
												<InputPasswordComponent
													id="repeatPassword"
													label="Powtórz nowe hasło"
													name="repeatPassword"
													value={
														this.state
															.repeatPassword
													}
													validator={this.validator}
													onChange={(a, b, c) =>
														this.handleChange(c)
													}
													viewMode="EDIT"
													validators={`required|equals:~${this.state.newPassword}~,Nowe hasło`}
													colClass="col-xl-12 col-lg-12 col-md-12 col-sm-12"
												/>
											</div>
											{/* </form>
									</div>
								</div>
							</div> */}
						</div>
					{/* </div>
					</div>  */}
					</React.Fragment>
		);
	}
	getPasswordLabel(){
		let message = 'Pole nowe hasło musi się składać z od :min do :max znaków, zawierać co najmniej :lower i :upper, :number oraz :special spośród =!@#$%^&*(){}[]|:";\'<>?,./'
		message = message.replace(':min', this.state.minLength);
					message = message.replace(':max', this.state.maxLength);

					message = message.replace(
						':lower',
						this.validator.declination(this.state.lowerCaseCount, 2)
					);
					message = message.replace(
						':upper',
						this.validator.declination(this.state.upperCaseCount, 3)
					);
					message = message.replace(
						':number',
						this.validator.declination(this.state.numbersCount, 4)
					);
					message = message.replace(
						':special',
						this.validator.declination(this.state.specialCharactersCount, 5)
					);
					return message;
	}
}
export default ChangePasswordContainer;
