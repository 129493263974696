import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import UserRoleService from '../../services/UserRoleService';
import CriteriaCalendarRangeComponent from '../../components/criteria/CriteriaCalendarRangeComponent';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import PublicUnitService from '../../services/PublicUnitService';
import CriteriaCheckboxComponent from '../../components/criteria/CriteriaCheckboxComponent';
import IncidentService from '../../services/IncidentService';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import IncidentReplyContainer from './IncidentReplyContainer';
import { MapLeaflet } from '../../components/business/MapLeaflet';
import IncidentContainer from './IncidentContainer';
import moment from 'moment';
import CategoryService from '../../services/CategoryService';
class IncidentListContainer extends BaseListContainer {
	constructor(props) {
		super(props, new IncidentService());
		this.roleService = new UserRoleService();
		this.categoryService = new CategoryService();
		this.state = {
			list: [],
			checkedRows: [],
			checkedCategories: [],
			checkedSubcategories: [],
			checkedAssignedUser: [],
			loading: true,
			size: 0,
			first: 0,
			maxResultListMode: 10,
			criteria: this.getCleanSearchCriteria(),
			statusOptions: [],
			statusSC: undefined,
			subcategoriesOpt: [],
			categoryOpt: [],
			activeIndex: 0,
			showReplyDialog: false,
			showStatusDialog: false,
			showAssignDialog: false,
			showApproveDialog: false,
			showCategoryDialog: false,
			positionListWithIdMap: new Map(),
			showDetails: false,
		};
		this.isAdmin = this.authService.getProfile()?.userRole === "Administrator"
		this.userId = this.authService.getProfile()?.userId;
		this.hideReplyDialog = this.hideReplyDialog.bind(this);
		this.hideStatusDialog = this.hideStatusDialog.bind(this);
		this.usersTemplate = this.usersTemplate.bind(this);
		this.handleClickMarker = this.handleClickMarker.bind(this);
		this.templateWithBold = this.templateWithBold.bind(this);
		this.dateTemplate = this.dateTemplate.bind(this)
	}

	updateSearchCriteria(criteria) {
		return {
			textSc: criteria.textSc,
			sentFrom: criteria.sentFrom,
			sentTo: criteria.sentTo,
			statuses: criteria.statuses,
			category: criteria.category,
			subcategory: criteria.subcategory,
			firstResult: criteria.firstResult,
			maxResult: this.state.activeIndex === 1 ? 2147483647 : criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}
	getCleanSearchCriteriaPrototype() {

		const criteria = this.getCleanSearchCriteria();
		const status = this.state.statusSC ? this.state.statusSC : criteria.status;

		criteria.limitObj = { value: criteria.maxResult };
		criteria.limit = 10;
		criteria.status = status;
		return criteria;
	}
	getCleanSearchCriteria() {
		return {
			textSc: '',
			sentFrom: undefined,
			sentTo: undefined,
			statuses: [],
			category: undefined,
			subcategory: undefined,
			firstResult: 0,
			maxResult: 10,
			sortField: 'sendDate',
			sortAsc: false,
		};
	}
	initializeFromBackend() {
		this.enumService
			.getEnumList('IncidentStatus')
			.then((statusOptions) => {
				statusOptions.push({ enumValue: 'SENT', label: 'Wysłane' });
				this.setState({
					statusOptions,
				})
			})
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy statusów');
			});
	this.categoryService.getCategories("NOTIFICATION")
			.then(categoryOpt => this.setState({ categoryOpt }))
			.catch(err => {
				this.showErrorMessage("Nie można pobrać kategorii");
			})
	}
	fetchSubcategories() {
		if (this.state.criteria?.category?.id) {
			this.service
				.getSubcategories(this.state.criteria?.category?.id)
				.then(subcategories => {
					this.setState({ subcategoriesOpt: subcategories })
				})
				.catch(err => {
					this.setState({ subcategoriesOpt: [] })
					this.showErrorMessage(err.message);
				});
		} else {
			this.setState({ subcategoriesOpt: [] })
		}
	}
	getCriteriaName() {
		return 'incident-list-sc';
	}

	getContainerListName() {
		return 'incident-list-container';
	}
	readCriteriaFromCookie() {
		this.readCheckedFromCookie();
	}
	mapCriteria(criteria) {
		criteria.sentFrom = criteria.sentFrom ? new Date(criteria.sentFrom) : undefined;
		criteria.sentTo = criteria.sentTo ? new Date(criteria.sentTo) : undefined;
		if(this.state.activeIndex === 1){
			criteria.maxResult = 2147483647;
		}
		return criteria;
	}
	renderCriteria() {
		return (
			<div className='row'>
				<CriteriaTextComponent
					id='textSc-sc'
					name='textSc'
					label={'Opis'}
					colClass='col-12'
					value={this.state.criteria.textSc}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaMultiSelectComponent
					id="statuses-sc"
					name="statuses"
					showClear
					label={'Statusy'}
					colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
					value={this.state.criteria.statuses}
					dataKey="enumValue"
					optionLabel="label"
					options={this.state.statusOptions}
					placeholder="Wszystkie"
					onChange={this.handleChangeSc}
					validator={this.validator}
					filter
					filterBy="label"
				/>
				<CriteriaCalendarRangeComponent
					key={`sendDate`}
					idFrom={`sentFrom`}
					idTo={`sentTo`}
					nameTo={'sentTo'}
					nameFrom={'sentFrom'}
					label={'Data wysłania'}
					labelFrom="od"
					labelTo="do"
					//colClass={column.colClass ? column.colClass : 'col-md-6'}
					colClass={'col-12'}
					from={this.state.criteria.sentFrom}
					to={this.state.criteria.sentTo}
					validator={this.validator}
					onChange={this.handleChangeSc}
					viewMode={this.props.viewMode}
					showIcon
					placeholderFrom={'dd-mm-rrrr GG:MM'}
					placeholderTo={'dd-mm-rrrr GG:MM'}
					validateOnChange={false}
					stateField=""
					hourFormat="24"
					showTime={true}
					showSeconds={false}
				/>

				<CriteriaDropdownComponent
					id="category-sc"
					name="category"
					showClear
					label="Kategoria"
					colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
					value={this.state.criteria.category}
					onAfterStateChange={e => {
						this.setState(
							(prevState) => ({
								criteria: {
									...prevState.criteria,
									subcategory: undefined
								},
							}),
							() => this.fetchSubcategories(e)
						);
					}}
					options={this.state.categoryOpt}
					placeholder="Wszystkie"
					onChange={this.handleChangeSc}
					validator={this.validator}
					optionLabel="name"
				/>
				{this.state.criteria?.subcategory || (this.state.subcategoriesOpt?.length && this.state.subcategoriesOpt?.length > 0) ?
					<CriteriaDropdownComponent
						id="subcategory-sc"
						name="subcategory"
						showClear
						label="Podkategoria"
						colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
						value={this.state.criteria.subcategory}
						options={this.state.subcategoriesOpt}
						placeholder="Wszystkie"
						onChange={this.handleChangeSc}
						validator={this.validator}
						optionLabel="name"
					/> : null}

			</div>
		);
	}
	checkTemplate(rowData) {
		return (
			<InputCheckboxComponent
				colClass="col-xl-4 col-lg-5 col-md-5 col-sm-12"
				id={`checked${rowData.id}`}
				name="checked"
				label="Zaznacz"
				value={this.state.checkedRows.indexOf(rowData.id) > -1}
				validator={this.validator}
				validators={'not_required'}
				onChange={(a, b, e) => { this.handleCheckChange(rowData.id, e.checked, rowData?.category?.id, rowData.subcategory?.id, rowData?.assignedUser?.id) }}
				viewMode="EDIT"
				showLabel={false}
				insideTable
			/>
		);
	}
	onAfterGetList() {
		if (this.state?.list && this.state?.list?.length) {

			const positionListWithIdMap = new Map();
			this.state.list.forEach(l => positionListWithIdMap.set({ id: l.id, selected: !!(l.id === this.state.selectedMarker) }, l.position));
			const modifedCategoryList = [];
			const modifiedSubcategoryList = [];
			const modifiedAssignedUserList = [];
			this.state.checkedRows.forEach(cr => {
				const foundElementFromList = this.state.list.find(l => l.id == cr);
				if (foundElementFromList) {
					if (foundElementFromList.category) {
						modifedCategoryList.push(foundElementFromList.category.id)
					}
					if (foundElementFromList.subcategory) {
						modifiedSubcategoryList.push(foundElementFromList.subcategory.id)
					}
					if (foundElementFromList.assignedUser) {
						modifiedAssignedUserList.push(foundElementFromList.assignedUser.id)
					}
				}
			});
			this.setState({ positionListWithIdMap, checkedCategories: modifedCategoryList, checkedSubcategories: modifiedSubcategoryList, checkedAssignedUser: modifiedAssignedUserList });
		}
	}
	handleCheckChange(id, v, categoryId, subcategoryId, assignedUserId) {
		const modifiedList = this.state.checkedRows;
		const modifedCategoryList = this.state.checkedCategories;
		const modifiedSubcategoryList = this.state.checkedSubcategories;
		const modifiedAssignedUserList = this.state.checkedAssignedUser;
		if (v) {
			modifiedList.push(id);
			modifedCategoryList.push(categoryId)
			modifiedSubcategoryList.push(subcategoryId)
			modifiedAssignedUserList.push(assignedUserId)
		} else {
			const index = modifiedList.indexOf(id);
			if (index > -1) {
				modifiedList.splice(index, 1);
			}
			const categoryIndex = modifedCategoryList.indexOf(categoryId);
			if (categoryIndex > -1) {
				modifedCategoryList.splice(categoryIndex, 1);
			}
			const subcategoryIndex = modifiedSubcategoryList.indexOf(subcategoryId);
			if (subcategoryIndex > -1) {
				modifiedSubcategoryList.splice(subcategoryIndex, 1);
			}
			const assignedUserIndex = modifiedAssignedUserList.indexOf(assignedUserId);
			modifiedAssignedUserList.splice(assignedUserIndex, 1)
		}

		this.setState({ checkedRows: modifiedList, checkedCategories: modifedCategoryList, checkedSubcategories: modifiedSubcategoryList, checkedAssignedUser: modifiedAssignedUserList });
	}
	getContainerCheckedRowsListName() {
		return 'incident-checked-list-container';
	}
	getContainerCheckedCategoriesListName() {
		return 'incident-checked-categories-list-container';
	}
	getContainerCheckedSubcategoriesListName() {
		return 'incident-checked-sub-categories-list-container';
	}
	getContainerCheckedUsersListName() {
		return 'incident-checked-assigned-user-list-container';
	}
	getActiveIndexCookie() {
		return 'incident-list-activeIndex'
	}
	readCheckedFromCookie() {
		const readRows = this.readCookie(this.getContainerCheckedRowsListName());
		const readCategories = this.readCookie(this.getContainerCheckedCategoriesListName());
		const readSubcategories = this.readCookie(this.getContainerCheckedSubcategoriesListName());
		const readIndex = this.readCookie(this.getActiveIndexCookie());
		const readUser = this.readCookie(this.getContainerCheckedUsersListName());
		const rows =
			readRows !== undefined && readRows != null ? JSON.parse(readRows) : [];
		const activeIndex = readIndex != undefined && readIndex != null ? JSON.parse(readIndex) : 0;
		const categories = readCategories !== undefined && readCategories != null ? JSON.parse(readCategories) : [];
		const subcategories = readSubcategories !== undefined && readSubcategories != null ? JSON.parse(readSubcategories) : [];
		const users = readUser !== undefined && readUser != null ? JSON.parse(readUser) : [];
		// if (this._isMounted) {
		this.setState(
			(prevState) => ({
				checkedRows: rows,
				checkedCategories: categories,
				checkedSubcategories: subcategories,
				checkedAssignedUser: users,
				activeIndex,
				criteria: {
					...prevState.criteria,
					maxResult: activeIndex === 1 ? 2147483647 : prevState.criteria.maxResult,
				},
			}),
			() => {
				 super.readCriteriaFromCookie();
			}
		);
		// }
		this.removeCookie(this.getContainerCheckedRowsListName());
		this.removeCookie(this.getContainerCheckedCategoriesListName());
		this.removeCookie(this.getContainerCheckedSubcategoriesListName());
		this.removeCookie(this.getContainerCheckedUsersListName());
	}
	componentWillUnmount() {
		const { criteria, checkedRows, checkedCategories, checkedSubcategories, checkedAssignedUser } = this.state;
		this.saveCookie(this.getContainerCheckedRowsListName(), JSON.stringify(checkedRows));
		this.saveCookie(this.getContainerCheckedCategoriesListName(), JSON.stringify(checkedCategories));
		this.saveCookie(this.getContainerCheckedSubcategoriesListName(), JSON.stringify(checkedSubcategories));
		this.saveCookie(this.getContainerCheckedUsersListName(), JSON.stringify(checkedAssignedUser))
	}
	prepareColumns() {

		return [
			{ body: this.checkTemplate.bind(this) },
			{ field: 'incidentNo', header: 'Nr', sortable: true, body: this.templateWithBold.bind(this, 'incidentNo')},
			{ field: 'description', header: 'Opis', sortable: true, body: this.templateWithBold.bind(this, 'description') },
			{ field: 'category.name', header: 'Kategoria', sortable: true, body: this.templateWithBold.bind(this, 'category.name') },
			{ field: 'subcategory.name', header: 'Podkategoria', sortable: true, body: this.templateWithBold.bind(this, 'subcategory.name') },
			// todo poco to jest
			// { field: 'publicUnit.name', header: 'Dział/jednostka', sortable: true },

			{ field: 'sendDate', header: 'Data wysłania', sortable: true, body: this.dateTemplate.bind(this, 'sendDate', 'YYYY-MM-DD HH:mm') },
			{ field: 'status', header: 'Status', body: this.enumTemplate.bind(this, 'status'), body: this.templateWithBold.bind(this, 'status.label') },
			{ field: 'assignedUser', header: 'Osoba przypisana', body: this.usersTemplate },

			{
				key: 'actions',
				header: 'Szczegóły',
				body: this.actionTemplate,
				className: 'table-column-action',
			},
		]
	}
	templateWithBold(field, rowData) {
		if (rowData.status && rowData.status?.enumValue === 'SENT' && !rowData.assignedUser) {
			return <span className="bold-row-content">{this.getValueInObjPath(field, rowData)}</span>
		} else return this.getValueInObjPath(field, rowData)
	}
	dateTemplate(field, format, rowData) {

		if (rowData[field] && moment(rowData[field]).isValid()) {
			if (rowData.status && rowData.status?.enumValue === 'SENT' && !rowData.assignedUser) {
				return <span className="bold-row-content"> {moment(rowData[field]).format(format)}</span>
			} else return moment(rowData[field]).format(format);
		} else {
			return '';
		}

	}
	getValueInObjPath(fieldName, rowData) {
		const path = fieldName.split('.');
		let i;
		let obj = rowData;
		if (!!fieldName && fieldName !== '') {
			for (i = 0; i < path.length; i++) {
				if (path[i].includes('[') && path[i].includes(']')) {
					const pathWithIndex = path[i].replace(']', '').split('[');
					obj = obj && pathWithIndex[0] && obj[pathWithIndex[0]] && pathWithIndex[1] ? obj[pathWithIndex[0]][pathWithIndex[1]] : ''
				} else {
					obj = obj && path[i] && obj[path[i]] ? obj[path[i]] : ''
				}
			}
		}
		return obj;
	}

	actionAllowed(rowData) {
		if (rowData?.assignedUser?.id) {
			return this.userId === rowData.assignedUser.id
		}
		return true;
	}

	prepareHeaderItems() {
		const enableButtons = this.state.checkedRows?.length > 0;
		return this.state.activeIndex === 0 && this.isUserInAnyRole('ROLE_INCIDENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') ? [
			//dla przypisanych lub admin
			{
				label: 'Odpowiedz',
				onClick: e => { this.handleReply(e) },
				type: 'BUTTON',
				className: 'float-right',
				variant: 'yellow',
				iconName: 'mdi-chat',
				iconSide: 'left',
				iconColor: 'white',
				disabled: !enableButtons
			},
			//dla przypisanych lub admin

			{
				label: 'Zmień status',
				onClick: e => { this.handleChangeStatus(e) },
				type: 'BUTTON',
				className: 'float-right',
				variant: 'yellow',
				iconName: 'mdi-help-circle-outline',
				iconSide: 'left',
				iconColor: 'white',
				disabled: !enableButtons
			},
			//dla przypisanych lub admin

			{
				label: 'Zmień kategorię',
				onClick: e => { if (e) { e.preventDefault(); } this.setState({ showCategoryDialog: true }) },
				type: 'BUTTON',
				className: 'float-right',
				variant: 'yellow',
				iconName: 'mdi-shape',
				iconSide: 'left',
				iconColor: 'white',
				disabled: !enableButtons,
				rendered: this.isAdmin
			},
			//dostępne dla wszystkich? 
			{
				label: 'Przekaż dalej',
				onClick: e => { this.handleAssign(e) },
				type: 'BUTTON',
				className: 'float-right',
				variant: 'yellow',
				iconName: 'mdi-account-question',
				iconSide: 'left',
				iconColor: 'white',
				disabled: !enableButtons
			},
			//dostępne dla wszystkich?
			{
				label: 'Wydrukuj',
				onClick: (e) => {
					e.preventDefault();
					this.blockUi();
					this.service.download('Zgłoszenia.pdf', {ids:this.state.checkedRows}, this.authService.getToken(), () => {
						this.messages.show({
							severity: 'error',
							summary: 'Błąd',
							detail: 'Nie udało się pobrać zgłoszeń',
						});
						this.unblockUi();
					}, () => this.unblockUi());
				}, type: 'BUTTON',
				className: 'float-right',
				variant: 'yellow',
				iconName: 'mdi-printer',
				iconSide: 'left',
				iconColor: 'white',
				disabled: !enableButtons
			},
			//dla przypisanych lub admin

			{
				label: 'Zatwierdź',
				onClick: e => { this.handleApprove(e) },
				type: 'BUTTON',
				className: 'float-right',
				variant: 'yellow',
				iconName: 'mdi-check',
				iconSide: 'left',
				iconColor: 'white',
				disabled: !enableButtons
			},
		] : [
			{
				label: 'Wydrukuj',
				onClick: (e) => {
					e.preventDefault();
					this.blockUi();
					this.service.download('Zgłoszenia.pdf', {ids:this.state.checkedRows}, this.authService.getToken(), () => {
						this.messages.show({
							severity: 'error',
							summary: 'Błąd',
							detail: 'Nie udało się pobrać zgłoszeń',
						});
						this.unblockUi();
					}, () => this.unblockUi());
				}, type: 'BUTTON',
				className: 'float-right',
				variant: 'yellow',
				iconName: 'mdi-printer',
				iconSide: 'left',
				iconColor: 'white',
				disabled: !enableButtons
			},
		];
	}
	handleApprove(e) {
		if (e) { e.preventDefault(); }
		if (this.state.checkedAssignedUser.filter(u => u != null && u != undefined && u != this.userId).length > 0) {
			this.showErrorMessage("Wśród zaznaczonych zgłoszeń znajdują się przypisane na inną osobę. Dostęp zabroniony");
			return;
		}
		this.setState({ showApproveDialog: true })
	}
	handleReply(e) {
		if (e) { e.preventDefault(); }

		//sprawdz czy wśród zaznaczonych assigned user id jest taki który nie jest nulllem i nie jest tobą - jeżeli tak zablokuj akcję
		if (!this.isAdmin && this.state.checkedAssignedUser.filter(u => u != null && u != undefined && u != this.userId).length > 0) {
			this.showErrorMessage("Wśród zaznaczonych zgłoszeń znajdują się przypisane na inną osobę. Dostęp zabroniony");
			return;
		}
		this.setState({ showReplyDialog: true })
	}
	handleAssign(e) {
		if (e) { e.preventDefault(); }
		const set = new Set(this.state.checkedCategories);
		const subcategoriesSet = new Set(this.state.checkedSubcategories);
		if (set.size !== 1) {
			this.showErrorMessage("Wybrane zgłoszenia należą do różnych kategorii, nie można przypisać użytkowników");
			return;
		} else if (subcategoriesSet.set > 1) {
			this.showErrorMessage("Wybrane zgłoszenia należą do różnych podkategorii, nie można przypisać użytkowników");
			return;
		} else if (!this.isAdmin && this.state.checkedAssignedUser.filter(u => u != null && u != undefined && u != this.userId).length > 0) {
			this.showErrorMessage("Wśród zaznaczonych zgłoszeń znajdują się przypisane na inną osobę. Dostęp zabroniony");
			return;
		} else {
			this.setState({ showAssignDialog: true, categoryId: this.state.checkedCategories[0], subcategoryId: this.state.checkedSubcategories[0] })
		}
	}
	handleChangeStatus(e) {
		if (e) { e.preventDefault(); }
		if (!this.isAdmin && this.state.checkedAssignedUser.filter(u => u != null && u != undefined && u != this.userId).length > 0) {
			this.showErrorMessage("Wśród zaznaczonych zgłoszeń znajdują się przypisane na inną osobę. Dostęp zabroniony");
			return;
		}
		this.setState({ showStatusDialog: true })
	}
	handleChangeCategory(e) {
		if (e) { e.preventDefault(); }

		this.setState({ showCategoryDialog: true })
	}
	usersTemplate(rowData) {
		//const users = [{firstName: 'Jan', lastName: 'Kowalski'}, {firstName: 'Mariusz', lastName: 'Nowakowski'}, {firstName: 'Gromisław', lastName: 'Czępiński'}]
		if (!rowData || !rowData.assignedUser) {
			return null;
		}
		const u = rowData.assignedUser;
		return (<div className='user-box'>{u.firstName} {u.lastName}{u.removed ? ' (Usunięty)' : u.blocked ? ' (Zablokowany)' : ''}</div>

		)
	}
	prepareTabPanels() {
		return [
			{ key: "list", header: "Lista zgłoszeń od użytkowników", headerClassName: "col-xl-6 col-lg-6 col-md-6 col-sm-12" },
			{ key: "map", header: "Mapa", headerClassName: "col-xl-6 col-lg-6 col-md-6 col-sm-12" },
		]
	}
	hideReplyDialog(e, callback) {
		if (e) {
			e.preventDefault();
		}
		this.setState({ showReplyDialog: false }, () => {
			if (callback) callback()
		})
	}
	hideStatusDialog(e, callback) {
		if (e) {
			e.preventDefault();
		}
		this.setState({ showStatusDialog: false }, () => {
			if (callback) callback()
		})
	}
	hideAssignDialog(e, callback) {
		if (e) {
			e.preventDefault();
		}
		this.setState({ showAssignDialog: false }, () => {
			if (callback) callback()
		})
	}
	hideApproveDialog(e, callback) {
		if (e) {
			e.preventDefault();
		}
		this.setState({ showApproveDialog: false }, () => {
			if (callback) callback()
		})
	}
	hideCategoryDialog(e, callback) {
		if (e) {
			e.preventDefault();
		}
		this.setState({ showCategoryDialog: false }, () => {
			if (callback) callback()
		})
	}

	render() {
		return (
			<div className='container-fluid'>
				{!!this.state.showDetails ? null : <BreadcrumbsItem to='/incident-list'>{'Zgłoszenia od użytkowników'}</BreadcrumbsItem>}

				{this.state.showReplyDialog ?
					<IncidentReplyContainer
						ids={this.state.checkedRows}
						onHide={e => this.hideReplyDialog(e)} visible={this.state.showReplyDialog} viewMode={'NEW'}
						afterSave={e => this.hideReplyDialog(e, () => { this.readMessage(); this.refreshTable() })}
						onError={e => this.hideReplyDialog(e, () => { this.readMessage() })}
						type='REPLY'
						header={'Odpowiedz'}
					/>
					: null}
				{this.state.showStatusDialog ?
					<IncidentReplyContainer
						ids={this.state.checkedRows}
						onHide={e => this.hideStatusDialog(e)} visible={this.state.showStatusDialog} viewMode={'NEW'}
						afterSave={e => this.hideStatusDialog(e, () => { this.readMessage(); this.refreshTable() })}
						onError={e => this.hideStatusDialog(e, () => { this.readMessage() })}
						type='STATUS'
						header={'Zmień status'}
					/>
					: null}
				{this.state.showAssignDialog ?
					<IncidentReplyContainer
						ids={this.state.checkedRows}
						onHide={e => this.hideAssignDialog(e)} visible={this.state.showAssignDialog} viewMode={'NEW'}
						afterSave={e => this.hideAssignDialog(e, () => { this.readMessage(); this.refreshTable() })}
						onError={e => this.hideAssignDialog(e, () => { this.readMessage() })}
						type='ASSIGN'
						categoryId={this.state.categoryId}
						subcategoryId={this.state.subcategoryId}
						header={'Przekaż dalej'}
					/>
					: null}
				{this.state.showApproveDialog ?
					<IncidentReplyContainer
						ids={this.state.checkedRows}
						onHide={e => this.hideApproveDialog(e)} visible={this.state.showApproveDialog} viewMode={'NEW'}
						afterSave={e => this.hideApproveDialog(e, () => { this.readMessage(); this.refreshTable() })}
						onError={e => this.hideApproveDialog(e, () => { this.readMessage() })}
						type='APPROVE'
						header={'Zatwierdź'}
						labelSave='Tak'
						labelCancel='Nie'
						className='incident-reply-yes-no'
					/>
					: null}
				{this.state.showCategoryDialog ? <IncidentReplyContainer
					ids={this.state.checkedRows}
					onHide={e => this.hideCategoryDialog(e)} visible={this.state.showCategoryDialog} viewMode={'NEW'}
					afterSave={e => this.hideCategoryDialog(e, () => { this.readMessage(); this.refreshTable() })}
					onError={e => this.hideCategoryDialog(e, () => { this.readMessage() })}
					type='CATEGORY'
					header={'Zmiana kategorii'}
				/> : null}
				{this.renderTabView()}
			</div>
		);
	}
	renderDataTable(columns) {
		if (this.state.activeIndex === 0) {
			return super.renderDataTable(columns);
		} else {
			return !!this.state.showDetails ?
				<div className="row">
					<MapLeaflet
						className="col-6"
						viewMode='NEW' handleClickMarker={(id, selectedPosition) => this.handleClickMarker(id, selectedPosition)} markerMap={this.state.positionListWithIdMap ? this.state.positionListWithIdMap : undefined} mapCenter={this.state.selectedPosition ? this.state.selectedPosition : this.state.lastSelectedPosition}
						zoom={this.state.lastZoom}
						zoomChangeHandler={this.zoomChangeHandler}
						/>
					<div className="col-6">
						<IncidentContainer viewMode={'VIEW'} id={this.state.selectedMarker} listMode={true} />
					</div> </div> :
				<MapLeaflet
					className="col-12"
					viewMode='NEW' handleClickMarker={(id, selectedPosition) => this.handleClickMarker(id, selectedPosition)} markerMap={this.state.positionListWithIdMap ? this.state.positionListWithIdMap : undefined} mapCenter={this.state.selectedPosition ? this.state.selectedPosition : this.state.lastSelectedPosition}
					zoom={this.state.lastZoom}
                    zoomChangeHandler={this.zoomChangeHandler}
					/>
		}
	}

	zoomChangeHandler = (zoom) => {
	    this.setState({lastZoom: zoom});
	}

	handleClickMarker(id, value) {
		let showDetailsFlag = true

		if (this.state.selectedMarker && this.state.selectedMarker === id) {
			showDetailsFlag = false
		}
		let idChosen = showDetailsFlag ? id : undefined
		const modifiedMap = this.state.positionListWithIdMap;

		Array.from(modifiedMap.entries()).map(([key, value]) => {
			if (key && key.id === id) {
				key.selected = !key.selected;
			}else{
				key.selected = false;
			}
			return
		});
		this.setState({ showDetails: showDetailsFlag, selectedMarker: idChosen, selectedPosition: showDetailsFlag ? value : null,
		        lastSelectedPosition: value, positionListWithIdMap: modifiedMap })
	}
	handleChangeLimitObj(v) {
		this.setState(
			(prevState) => ({
				first: 0,
				maxResultListMode: v.value,
				criteria: {
					...prevState.criteria,
					firstResult: 0,
					maxResult: v.value,
					maxResultObj: v.value,
					limit: v.value
				},
			}),
			() => this.refreshTable()
		);
	}
	handleTabChange(e) {
		this.setState(
			(prevState) => ({
				activeIndex: e.index,
				maxResultListMode: e.index === 0 ? this.state.criteria.maxResult : prevState.maxResultListMode,
				criteria: {
					...prevState.criteria,

					maxResult: e.index === 1 ? 2147483647 : this.state.maxResultListMode,
				},
			}),
			() => {
				this.refreshTable();
				this.saveCookie(this.getActiveIndexCookie(), this.state.activeIndex);
			}
		);
	}
}

IncidentListContainer.defaultProps = {
	detailUrl: '/#/incident/details',
	newUrl: '/#/incident/create',
};

IncidentListContainer.propTypes = {
	detailUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default IncidentListContainer;
