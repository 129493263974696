import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import RefuseTypeService from '../services/RefuseTypeService';
import InputTextComponent from '../components/inputs/InputTextComponent';
import InternalUserService from '../services/InternalUserService';
import YesNoDialog from "../components/YesNoDialog";
import InputNumberComponent from "../components/inputs/InputNumberComponent";

class RefuseTypeContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new RefuseTypeService());
		this.isHalfWidth = true;
		this.internalUserService = new InternalUserService();
		this.state = {
			loading: true,
			elementId: props.id,
			element: {
				lp: undefined,
				name: undefined,
				nameEng: undefined,
			},
		};
	}

	getContainerListName() {
		return 'refuse-type-list-container';
	}

	getAddSucces() {
		return 'Typ odpadów został utworzony';
	}

	getUpdateSucces() {
		return 'Typ odpadów został zaktualizowany';
	}

	getRemoveSucces() {
        return 'Typ odpadów został usunięty';
    }

	updateElement(data) {
		if (data.users) {
			data.users.forEach(u => u.label = `${u.firstName} ${u.lastName}${u.removed ? ' (Usunięty)' : u.blocked ? ' (Zablokowany)': ''}`);
		}
		this.setState(
			{
				element: {
					id: data.id,
					lp: data.lp,
					name: data.name,
					nameEng: data.nameEng,
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}

	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode } = this.props;
		const { elementId } = this.state;
		const status = this.state.element?.status?.enumValue;
		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${elementId}`;
		}
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
                label: 'Usuń',
                className: 'float-right',
                onClick: this.showRemoveConfirmation,
                rendered:
                    viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_REFUSE_TIMETABLE_MANAGE')
            },
			{
				label: 'Zapisz',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: this.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_REFUSE_TIMETABLE_MANAGE') && (viewMode === 'NEW' || viewMode === 'EDIT'),
			},
		];
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowy typ odpadów' : viewMode === 'EDIT' ? 'Edycja typu odpadów' : 'Szczegóły typu odpadów',
				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE','ROLE_REFUSE_TIMETABLE_MANAGE')),
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}

	render() {
		const status = this.state.element?.status?.enumValue;
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/refuse-type-list' className='p-link'>
					{'Typy odpadów'}
				</BreadcrumbsItem>
				<YesNoDialog
                    onChange={this.onRemoveAction}
                    visible={this.state.showConfirmRemoveDialog}
                    header="Usuwanie typu odpadów"
                    name="visible"
                    onHide={() => {
                        this.setState({ showConfirmRemoveDialog: false });
                    }}>
                    Czy na pewno chcesz usunąć wybrany typ odpadów?
                </YesNoDialog>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/refuse-type/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/refuse-type/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/refuse-type/create'>{'Nowy'}</BreadcrumbsItem> : null}
				{this.renderView()}
			</div>
		);
	}
	renderDetails() {


		return (
			<React.Fragment>
				<div class='row'>
					<InputTextComponent
						id="name"
						name="name"
						label="Nazwa typu odpadów w języku polskim"
						colClass='col-md-12'
						value={this.state.element.name}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'max:50|required'}
					/>
					<InputTextComponent
						id="nameEng"
						name="nameEng"
						label="Nazwa typu odpadów w języku angielskim"
						colClass='col-md-12'
						value={this.state.element.nameEng}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'max:50'}
					/>
					<InputNumberComponent
						id="lp"
						name="lp"
						label="Lp."
						colClass='col-md-6'
						value={this.state.element.lp}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'required|naturalNumber|greaterEquals:1'}
						min={0}
					/>
				</div>
			</React.Fragment>
		);
	}
}

RefuseTypeContainer.defaultProps = {
	backUrl: '/#/refuse-type-list',
	cancelUrl: '/#/refuse-type/details',
	editUrl: '/#/refuse-type/edit',
};

RefuseTypeContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
};

export default RefuseTypeContainer;
