import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import UserRoleService from '../../services/UserRoleService';
import CriteriaCalendarRangeComponent from '../../components/criteria/CriteriaCalendarRangeComponent';
import PublicUnitService from '../../services/PublicUnitService';
import SurveyService from '../../services/SurveyService';
import CategoryService from '../../services/CategoryService';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
class SurveyContainerList extends BaseListContainer {
	constructor(props) {
		super(props, new SurveyService());
		this.roleService = new UserRoleService();
		this.categoryService = new CategoryService()
		this.state = {
			list: [],
			loading: true,
			size: 0,
			first: 0,
			criteria: this.getCleanSearchCriteria(),
			statusOptions: [],
			subcategoriesOpt: [],
			categoryOpt: [],
			surveyStatusOpt: [],
			activeIndex : 0
		};
	}

	updateSearchCriteria(criteria) {

		return {
			officialName: criteria.officialName,
			workingName: criteria.workingName,
			activeFromSc: criteria.activeFromSc,
			activeToSc: criteria.activeToSc,
			status: criteria.status,
			category: criteria.category,
			subcategory: criteria.subcategory,
			draftOnly: criteria.draftOnly,
			firstResult: criteria.firstResult,
			maxResult: criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}

	getCleanSearchCriteriaPrototype() {
		const criteria = this.getCleanSearchCriteria();
		criteria.limitObj = { value: criteria.maxResult };
		criteria.limit = 10;
		return criteria;
	}

	getCleanSearchCriteria() {
		return {
			officialName: '',
			workingName: '',
			activeFromSc: undefined,
			activeToSc: undefined,
			category: undefined,
			subcategory: undefined,
			draftOnly: this.state?.activeIndex !== 0,
			firstResult: 0,
			maxResult: 10,
			sortField: "modifyDate",
			sortAsc: false,
		};
	}

	initializeFromBackend() {
		this.enumService
			.getEnumList('UserActivityStatus')
			.then((statusOptions) =>
				this.setState({
					statusOptions,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy statusów');
			});
		this.categoryService.getCategories("NOTIFICATION")
			.then(categoryOpt => this.setState({ categoryOpt }))
			.catch(err => {
				this.showErrorMessage("Nie można pobrać kategorii");
			})
		this.enumService
			.getEnumList('SurveyStatus')
			.then((surveyStatusOpt) => {
				surveyStatusOpt.forEach(element => {
					if (element.enumValue === "DRAFT") {
						surveyStatusOpt.splice(surveyStatusOpt.indexOf(element), 1);
					}
				});
				this.setState({
					surveyStatusOpt,
				})
			}
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy statusów');
			});
	}

	fetchSubcategories() {
		if (this.state.criteria?.category?.id) {
			this.service
				.getSubcategories(this.state.criteria?.category?.id)
				.then(subcategories => {
					this.setState({ subcategoriesOpt: subcategories })
				})
				.catch(err => {
					this.setState({ subcategoriesOpt: [] })
					this.showErrorMessage(err.message);
				});
		} else {
			this.setState({ subcategoriesOpt: [] })
		}
	}
	
	getCriteriaName() {
		return 'survey-list-sc';
	}

	getContainerListName() {
		return 'survey-list-container';
	}

	renderCriteria() {
		return (
			<div className='row'>
				<CriteriaTextComponent
					id='officialName-sc'
					name='officialName'
					label={'Nazwa oficjalna'}
					colClass='col-12'
					value={this.state.criteria.officialName}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaTextComponent
					id='workingName-sc'
					name='workingName'
					label={'Nazwa robocza'}
					colClass='col-12'
					value={this.state.criteria.workingName}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaCalendarRangeComponent
					key={`activeSc`}
					idFrom={`activeFromSc`}
					idTo={`activeToSc`}
					nameTo={'activeToSc'}
					nameFrom={'activeFromSc'}
					label={'Data ważności'}
					labelFrom="od"
					labelTo="do"
					//colClass={column.colClass ? column.colClass : 'col-md-6'}
					colClass={'col-12'}
					from={this.state.criteria.activeFromSc}
					to={this.state.criteria.activeToSc}
					validator={this.validator}
					onChange={this.handleChangeSc}
					viewMode={this.props.viewMode}
					showIcon
					validateOnChange={false}
					stateField=""
					maxDateInfinite
					placeholderFrom='dd-mm-rrrr GG:MM'
					placeholderTo='dd-mm-rrrr GG:MM'
					showTime={true}
					showSeconds={false}
				/>
				<CriteriaDropdownComponent
					id="category-sc"
					name="category"
					showClear
					label="Kategoria"
					colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
					value={this.state.criteria.category}
					onAfterStateChange={e => {
						this.setState(
							(prevState) => ({
								criteria: {
									...prevState.criteria,
									subcategory: undefined
								},
							}),
							() => this.fetchSubcategories(e)
						);
					}}
					options={this.state.categoryOpt}
					placeholder="Wszystkie"
					onChange={this.handleChangeSc}
					validator={this.validator}
					optionLabel="name"
				/>
				{this.state.criteria?.subcategory || (this.state.subcategoriesOpt?.length && this.state.subcategoriesOpt?.length > 0) ?
					<CriteriaDropdownComponent
						id="subcategory-sc"
						name="subcategory"
						showClear
						label="Podkategoria"
						colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
						value={this.state.criteria.subcategory}
						options={this.state.subcategoriesOpt}
						placeholder="Wszystkie"
						onChange={this.handleChangeSc}
						validator={this.validator}
						optionLabel="name"
					/> : null}
				{this.state.activeIndex === 0 ?
					<CriteriaMultiSelectComponent
						id="status-sc"
						name="status"
						showClear
						label="Status"
						colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
						value={this.state.criteria.status}
						options={this.state.surveyStatusOpt}
						placeholder="Wszystkie"
						onChange={this.handleChangeSc}
						validator={this.validator}
						optionLabel="label"
						dataKey={"enumValue"}
					/> : null}

			</div>
		);
	}

	prepareColumns() {
		const modifiedList = [{ field: 'officialName', header: 'Nazwa oficjalna', sortable: true }, { field: 'workingName', header: 'Nazwa robocza', sortable: true }];
		if (this.state.activeIndex === 0) {
			modifiedList.push({ field: 'activeFrom', header: 'Data ważności od', sortable: true, body: this.dateTemplate.bind(this, 'activeFrom', 'YYYY-MM-DD HH:mm') });
			modifiedList.push({ field: 'activeTo', header: 'Data ważności do', sortable: true, body: this.dateTemplate.bind(this, 'activeTo', 'YYYY-MM-DD HH:mm') });
		}
		modifiedList.push({ field: 'status', header: 'Status', body: this.enumTemplate.bind(this, "status") });
		if (this.state.activeIndex === 0) {
			modifiedList.push({ field: 'votesCount', header: 'Liczba ankietowanych' });
		}
		modifiedList.push({
			key: 'actions',
			header: 'Szczegóły',
			body: this.actionTemplate,
			className: 'table-column-action',
		});
		return modifiedList;
	}

	prepareHeaderItems() {
		return this.isUserInAnyRole('ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')
			? [
				{
					label: 'Dodaj ankietę/sondaż/głosowanie',
					href: this.props.newUrl,
					type: 'BUTTON',
					className: 'float-right',
					variant: 'yellow',
					iconName: 'mdi-plus',
					iconSide: 'left',
					iconColor: 'white',
				},
			]
			: [];
	}
	prepareTabPanels() {
		return [
			{ key: "sent", header: "Lista ankiet/sondaży/głosowań", headerClassName: "col-xl-6 col-lg-6 col-md-6 col-sm-12" },
			{ key: "drafts", header: "Robocze", headerClassName: "col-xl-6 col-lg-6 col-md-6 col-sm-12" },
		]
	}
	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/survey-list'>{'Konsultacje społeczne'}</BreadcrumbsItem>
				{this.renderTabView()}
			</div>
		);
	}
	readCriteriaFromCookie() {

		let activeIndex = this.readCookie('survey-list-activeIndex');
		if (activeIndex === undefined || activeIndex === null || activeIndex === '') {
			activeIndex = 0;
		}
		let page =
			activeIndex === 0
				? this.readCookie('survey-sent-sc-page')
				: this.readCookie('survey-drafts-sc-page');
		if (page === undefined || page === null || page === '') {
			page = 0;
		}
		const prevCriteria = this.state.criteria;
		const criteria =
			this.readCookie(this.getCriteriaName()) !== undefined && this.readCookie(this.getCriteriaName()) != null
				? this.updateSearchCriteriaPrototype(JSON.parse(this.readCookie(this.getCriteriaName())))
				: this.getCleanSearchCriteriaPrototype();
		this.removeCookie(this.getCriteriaName());
		criteria.limit = prevCriteria.limit ? prevCriteria.limit : 10;
		criteria.draftOnly = parseInt(activeIndex) !== 0;
		criteria.page = parseInt(page)
		this.mapCriteria(criteria);
		if (this._isMounted) {
			this.setState(
				prevState => ({
					activeIndex: parseInt(activeIndex),
					criteria,
					first: criteria.firstResult,
					loading: false,
				}),
				() => this.refreshTable()
			);
		}
	}
	mapCriteria(criteria) {
		criteria.activeFromSc = criteria.activeFromSc ? new Date(criteria.activeFromSc) : undefined;
		criteria.activeToSc = criteria.activeToSc ? new Date(criteria.activeToSc) : undefined
		return criteria;
	}
	handleTabChange(e) {
		// let page =
		// 	e.index === 0
		// 		? this.readCookie('survey-sent-sc-page')
		// 		: this.readCookie('survey-drafts-sc-page');
		// if (page === undefined || page === null || page === '') {
		// 	page = 0;
		// }
		let statuses = this.state.criteria.status;
		if (e.index !== this.state.activeIndex) {
			statuses = [];
		}
		const criteria =
			this.readCookie(this.getCriteriaName()) !== undefined && this.readCookie(this.getCriteriaName()) != null
				? this.updateSearchCriteriaPrototype(JSON.parse(this.readCookie(this.getCriteriaName())))
				: this.getCleanSearchCriteriaPrototype();
		this.removeCookie(this.getCriteriaName());
		this.setState(
			{
				activeIndex: e.index,
			},
			() => {

				this.saveCookie('survey-list-activeIndex', this.state.activeIndex);
				// if (this.state.activeIndex === 0) {
				// 	this.saveCookie('survey-sent-sc-page', this.state.criteria.page);
				// } else this.saveCookie('survey-drafts-sc-page', this.state.criteria.page);
				this.setState(
					prevState => ({
						criteria: {
							...prevState.criteria,
							draftOnly: this.state.activeIndex !== 0,
							status: statuses,
							page: 0,
							firstResult: 0,
							//first: parseInt(page) * prevState.criteria.limit,
						},
						first: 0,
					}),
					() => super.refreshTable()
				);
			}
		);
	}
}

SurveyContainerList.defaultProps = {
	detailUrl: '/#/survey/details',
	newUrl: '/#/survey/create',
};

SurveyContainerList.propTypes = {
	detailUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default SurveyContainerList;
