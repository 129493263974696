import BaseServiceCrud from './BaseServiceCrud';

export default class InternalUserService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'user-manage';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			first_name: criteria.firstName,
			last_name: criteria.lastName,
			email: criteria.email,
			role: criteria.role?.name,
			active: criteria.active,
			not_blocked: criteria.notBlocked,
			status: criteria.status?.enumValue,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}

	getUserReferenceList(criteria) {

		const queryString = this.getCriteria(criteria);

		return this.fetch(`${this.domain}/${this.path}/list/reference${queryString}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

	checkStatusPassword(login, password, captchaValid) {
		return this.fetch(`${this.domain}/${this.path}/password/check-status-password`, {
			method: 'POST',
			body: JSON.stringify({ login, password, captchaValid }),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

	verifyTokenAndSendNewPassword(token) {
		const encodedValue = encodeURIComponent(token);
		return this.fetch(`${this.domain}/${this.path}/password/verify-reset-link?token=${encodedValue}`, {
			method: 'POST',
		}).catch((err) => {
			throw err;
		});
	}

	updateMyAccount(element) {
		// Get a token from api server using the fetch api
		return this.fetch(`${this.domain}/${this.path}/me/${element.id}`, {
			method: 'PUT',
			body: JSON.stringify(element),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

	getMyAccount(id) {
		// Get a token from api server using the fetch api
		return this.fetch(`${this.domain}/${this.path}/me/${id}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

	blockAccount(id) {
		// Get a token from api server using the fetch api
		return this.fetch(`${this.domain}/${this.path}/block/${id}`, {
			method: 'PUT',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

	unblockAccount(id) {
		// Get a token from api server using the fetch api
		return this.fetch(`${this.domain}/${this.path}/unblock/${id}`, {
			method: 'PUT',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	remindPassword(id) {
		return this.fetch(`${this.domain}/${this.path}/remind-password/${id}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}


}
