import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import NewsConfigurationService from '../services/NewsConfigurationService';
import InputTextComponent from '../components/inputs/InputTextComponent';
import InternalUserService from '../services/InternalUserService';
import YesNoDialog from "../components/YesNoDialog";
import InputNumberComponent from "../components/inputs/InputNumberComponent";

class NewsConfigurationContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new NewsConfigurationService());
		this.isHalfWidth = true;
		this.internalUserService = new InternalUserService();
		this.state = {
			loading: true,
			elementId: props.id,
			element: {
				name: undefined,
				nameEng: undefined,
				rssAddress: undefined,
				orderNW: undefined,
			},
		};
	}

	getContainerListName() {
		return 'news-configuration-list-container';
	}

	getAddSucces() {
		return 'Konfiguracja aktualności została utworzona';
	}

	getUpdateSucces() {
		return 'Konfiguracja aktualności została zaktualizowana';
	}

	getRemoveSucces() {
        return 'Konfiguracja aktualności została usunięta';
    }

	updateElement(data) {
		if (data.users) {
			data.users.forEach(u => u.label = `${u.firstName} ${u.lastName}${u.removed ? ' (Usunięty)' : u.blocked ? ' (Zablokowany)': ''}`);
		}
		this.setState(
			{
				element: {
					id: data.id,
					name: data.name,
					nameEng: data.nameEng,
					rssAddress: data.rssAddress,
					orderNW: data.orderNW,
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}

	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode } = this.props;
		const { elementId } = this.state;
		const status = this.state.element?.status?.enumValue;
		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${elementId}`;
		}
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
                label: 'Usuń',
                className: 'float-right',
                onClick: this.showRemoveConfirmation,
                rendered:
                    viewMode === 'VIEW'
            },
			{
				label: 'Zapisz',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: this.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE') && (viewMode === 'NEW' || viewMode === 'EDIT'),
			},
		];
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowa konfiguracja aktualności' : viewMode === 'EDIT' ? 'Edycja konfiguracji aktualności' : 'Szczegóły konfiguracji aktualności',
				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE')),
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}

	render() {
		const status = this.state.element?.status?.enumValue;
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/news-configuration-list' className='p-link'>
					{'Konfiguracja aktualności'}
				</BreadcrumbsItem>
				<YesNoDialog
                    onChange={this.onRemoveAction}
                    visible={this.state.showConfirmRemoveDialog}
                    header="Usuwanie kofiguracji aktualności"
                    name="visible"
                    onHide={() => {
                        this.setState({ showConfirmRemoveDialog: false });
                    }}>
                    Czy na pewno chcesz usunąć wybrany rekord z konfiguracją aktualności?
                </YesNoDialog>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/news-configuration/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/news-configuration/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/news-configuration/create'>{'Nowy'}</BreadcrumbsItem> : null}
				{this.renderView()}
			</div>
		);
	}
	renderDetails() {


		return (
			<React.Fragment>
				<div class='row'>
					<InputTextComponent
						id="name"
						name="name"
						label="Nazwa kategorii w języku polskim"
						colClass='col-md-12'
						value={this.state.element.name}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'max:50|required'}
					/>
					<InputTextComponent
						id="nameEng"
						name="nameEng"
						label="Nazwa kategorii w języku angielskim"
						colClass='col-md-12'
						value={this.state.element.nameEng}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'max:50'}
					/>
					<InputTextComponent
						id="rssAddress"
						name="rssAddress"
						label="Adres RSS"
						colClass='col-md-12'
						value={this.state.element.rssAddress}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'required|url'}
					/>
					<InputNumberComponent
						id="orderNW"
						name="orderNW"
						label="Kolejność"
						colClass='col-md-6'
						value={this.state.element.orderNW}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'required|naturalNumber|greaterEquals:1'}
						min={0}
						max={5}
					/>
				</div>
			</React.Fragment>
		);
	}
}

NewsConfigurationContainer.defaultProps = {
	backUrl: '/#/news-configuration-list',
	cancelUrl: '/#/news-configuration/details',
	editUrl: '/#/news-configuration/edit',
};

NewsConfigurationContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
};

export default NewsConfigurationContainer;
