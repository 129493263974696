import { Column } from 'primereact/column';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import { DataTable } from 'primereact/datatable';
import CustomLabel from '../components/CustomLabel';
import InputCalendarComponent from '../components/inputs/InputCalendarComponent';
import InputTextareaComponent from '../components/inputs/InputTextareaComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import EventLogService from '../services/EventLogService';
import CustomDataTable from '../components/CustomDataTable';
import DivContainer from '../components/DivContainer';
class EventLogContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new EventLogService());
		this.state = {
			loading: true,
			elementId: props.id,
			element: {
				changeLog: [],
				date: new Date(),
				details: undefined,
				eventType: undefined,
				logType: undefined,
				login: undefined,
				referenceId: undefined,
				referenceDescription: '',
			},
		};
	}

	updateElement(data) {
		this.setState(
			{
				
				element: {
					id: data.id,
					date: data.date ? new Date(data.date) : undefined,
					eventType: data.eventType,
					logType: data.logType,
					userEmail: data.userEmail,
					referenceDescription: data.referenceTypeLabel ? `${data.referenceTypeLabel}: ${data.referenceDescription && data.referenceDescription.trim().length >0 ?`"${data.referenceDescription}" (ID: ${data.referenceId})`: `ID: ${data.referenceId}`}` : '---',
					details: data.details,
					changes: data.changes,
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}

	prepareFooterItems() {
		return [];
	}

	prepareHeaderItems() {
		return [
			{
				label: 'Szczegóły zdarzenia',
				type: 'LABEL',
				className: '',
			},
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/event-log-list' className='p-link'>
					{'Dziennik zdarzeń'}
				</BreadcrumbsItem>
				<BreadcrumbsItem to='/event-log/details'>{'Szczegóły'}</BreadcrumbsItem>
				{this.renderView()}
			</div>
		);
	}
	operationLabel(value) {
		if (value) {
			switch (value) {
				case 'ADD': return ' [Dodano]';
				case 'EDIT': return ' [Zmieniono]';
				case 'DELETE': return ' [Usunięto]';
				default: return value;
			}
		}
		else {
			return '';
		}
	}
	changeValueTemplate(field, rowData) {
		if (rowData && rowData[field]) {
			const value = rowData[field];
			if (value) {
				return (
					<div>
						{value.map(c => {
							if (c.values && Object.keys(c.values).length > 0) {
								return (
									<div>
										{Object.keys(c.values).map(f => {
											return (
												<div className="col-12">{f} : {c.values[f]} {this.operationLabel(c.operation)}</div>
											)
										})}
										<div className="col-12">&nbsp;</div>
									</div>
								)

							} else {
								const isHTML = new RegExp(/<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i);
								if (isHTML.test(c.value)) {
									return (
										<div className="col-12" dangerouslySetInnerHTML={{__html: c.value}}></div>
									)
								} else {
									return (
										<div className="col-12">{c.value ? c.value : '<brak>'} {this.operationLabel(c.operation)}</div>
									)
								}
							}
						})}
					</div>

				)
			} else {
				return '';
			}
		}
		else {
			return '';
		}

	}
	renderDetails() {
		const showChanges = this.state.element.changes !== undefined && this.state.element.changes.length > 0;

		const columns = [
			{ field: 'fieldName', header: 'Nazwa pola', sortable: false },
			{ field: 'oldValue', header: 'Stara wartość', sortable: false },
			{ field: 'newValue', header: 'Nowa wartość', sortable: false },
		];
		let changeLogColumns = [];
		columns.forEach((col) => {
			const column = <Column key={col.key ? col.key : col.field} field={col.field} header={col.header} body={col.body} className={col.className} sortable={col.sortable} style={col.width !== undefined ? { width: col.width } : null} />
			changeLogColumns.push(column)
		});
		console.log(changeLogColumns);

		return (
			<React.Fragment>
				<div className='row'>
					<InputTextComponent id='logType' name='logType' label={'Typ logu'} colClass='col-md-4' value={this.state.element.logType?.label} validator={this.validator} onChange={this.handleChange} viewMode={this.props.viewMode} />
					<InputTextComponent id='eventType' name='eventType' label={'Nazwa zdarzenia'} colClass='col-md-4' value={this.state.element.eventType?.label} validator={this.validator} onChange={this.handleChange} viewMode={this.props.viewMode} />
					<InputCalendarComponent
						id='date'
						name='date'
						label={'Data'}
						dateFormatMoment='YYYY-MM-DD HH:mm'
						colClass='col-md-4'
						value={this.state.element.date}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
					/>
					<InputTextComponent
						id='referenceDescription'
						name='referenceDescription'
						label={'Dotyczy obiektu'}
						colClass='col-md-4'
						value={this.state.element.referenceDescription}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={'VIEW'}
					/>
					<InputTextComponent id='userEmail' name='userEmail' label={'Autor zmiany'} colClass='col-md-4' value={this.state.element?.userEmail} validator={this.validator} onChange={this.handleChange} viewMode={this.props.viewMode} />
					<InputTextareaComponent id='details' name='details' label={'Szczegóły'} colClass='col-md-12' value={this.state.element.details} validator={this.validator} onChange={this.handleChange} viewMode={this.props.viewMode} />
					<CustomLabel
						colClass="col-md-12"
						label="Lista zmian"
						rendered={showChanges}
					/>
					<DivContainer
						rendered={showChanges}
					>
						{/* {this.props.viewMode !== 'NEW' && this.state.element.changeLog !== undefined && this.state.element.changeLog !== null && this.state.element.changeLog.length && this.state.element.changeLog.length > 0 ? ( 
						<DataTable key='data-table' emptyMessage='Brak rekordów do wyświetlenia' className='ade-table' sortField={'fieldName'} sortOrder={1} responsive value={this.state.element.changeLog}>
							{changeLogColumns}
						</DataTable>*/}
						<CustomDataTable
							value={this.state.element.changes ? this.state.element.changes : null}
							emptyMessage="Brak rekordów do wyświetlenia"
							responsive
						>
							<Column field="fieldName" header="Nazwa pola" />
							<Column header="Poprzednia wartość" body={this.changeValueTemplate.bind(this, 'oldValue')} />
							<Column header="Nowa wartość" body={this.changeValueTemplate.bind(this, 'newValue')} />
						</CustomDataTable>
					</DivContainer>
					{/* ) : null} */}
				</div>
			</React.Fragment>
		);
	}
}

EventLogContainer.defaultProps = {
	backUrl: '/#/event-log-list',
	cancelUrl: '/#/event-log/details',
};

EventLogContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
};

export default EventLogContainer;
