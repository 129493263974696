import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import PublicUnitService from '../services/PublicUnitService';

class PublicUnitListContainer extends BaseListContainer {
	constructor(props) {
		super(props, new PublicUnitService());
		this.state = {
			list: [],
			loading: true,
			size: 0,
			first: 0,
			criteria: this.getCleanSearchCriteria(),
			statusOptions: [],
			roleTypeOptions: [],
		};
	}

	updateSearchCriteria(criteria) {
		return {
			name: criteria.name,
			firstResult: criteria.firstResult,
			maxResult: criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}

	getCleanSearchCriteria() {
		return {
			name: '',
			firstResult: 0,
			maxResult: 10,
			sortField: 'publicUnitLp',
			sortAsc: true,
		};
	}

	initializeFromBackend() {
		this.enumService
			.getEnumList('UserActivityStatus')
			.then((statusOptions) =>
				this.setState({
					statusOptions,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy statusów');
			});
	}

	getCriteriaName() {
		return 'public-unit-list-sc';
	}

	getContainerListName() {
		return 'public-unit-list-container';
	}

	renderCriteria() {
		return (
			<div className='row'>
				<CriteriaTextComponent
					id='name-sc'
					name='name'
					label={'Nazwa'}
					colClass='col-12'
					value={this.state.criteria.name}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
			</div>
		);
	}

	prepareColumns() {
		return [
			{ field: 'publicUnitLp', header: 'Lp.', sortable: true },
			{ field: 'name', header: 'Nazwa', sortable: true },
			{
				key: 'actions',
				header: 'Szczegóły',
				body: this.actionTemplate,
				className: 'table-column-action',
			},
		];
	}

	prepareHeaderItems() {
		return this.isUserInAnyRole('ROLE_PUBLIC_UNIT_ACC_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')
			? [
				{
					label: 'Nowa jednostka/dział',
					href: this.props.newUrl,
					type: 'BUTTON',
					className: 'float-right',
					variant: 'yellow',
					iconName: 'mdi-plus',
					iconSide: 'left',
					iconColor: 'white',
				},
			]
			: [];
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/public-unit-list'>{'Działy/jednostki'}</BreadcrumbsItem>
				{this.renderView()}
			</div>
		);
	}
}

PublicUnitListContainer.defaultProps = {
	detailUrl: '/#/public-unit/details',
	newUrl: '/#/public-unit/create',
};

PublicUnitListContainer.propTypes = {
	detailUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default PublicUnitListContainer;
