import BaseService from './BaseService';

export default class AreaService extends BaseService {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'notification-area';
    }

    getAreas() {
        return this.fetch(`${this.domain}/${this.path}/areas`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}