import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import queryString from 'query-string';
import decode from 'jwt-decode';
import React from 'react';
import { Link } from 'react-router-dom';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputPasswordComponent from '../../components/inputs/InputPasswordComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import CustomMessages from '../../components/CustomMessages';
import BlockUi from '../../components/waitPanel/BlockUi';

import SimpleReactValidator from '../../components/validator';
import SettingService from '../../services/SettingService';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import AbstractConfirmPasswordContainer from './AbstractConfirmPasswordContainer';

class ConfirmPasswordContainer extends AbstractConfirmPasswordContainer {

    constructor() {
        super();
        this.service = new UserService();
    }

     // @Override
    getPasswordParams = () => {
        const settingService = new SettingService();
        return settingService.getPasswordParamsPublic()
            .then((resp) => {
                this.setState(
                    {
                        maxLength:              resp['PASSWORD_MAX_LENGTH'],
                        minLength:              resp['PASSWORD_MIN_LENGTH'],
                        lowerCaseCount:         resp['PASSWORD_LOWERCASE_COUNT'],
                        upperCaseCount:         resp['PASSWORD_UPPERCASE_COUNT'],
                        numbersCount:           resp['PASSWORD_NUMBERS_COUNT'],
                        specialCharactersCount: resp['PASSWORD_SPECIAL_CHARS_COUNT'],
                    },
                    () => this.unblockUi()
                );
            })
            .catch((err) => this.unblockUi());
    }

    // @Override
    getService = () => {
        return this.service;
    };

    // @Override
    handleCorrectPasswordToken = (wsRes) => {
        if(wsRes.login) {
            this.setState({ email: wsRes.login });
            this.unblockUi();
        } else {
            this.handleErrorPasswordToken();
        }
    };

    // @Override
    handleErrorPasswordToken = (wsErr) => {
        this.saveCookie(
            this.getResetPasswordContainerName(),
            JSON.stringify({
                severity: 'error',
                summary: '',
                detail: 'Niepoprawny token lub użytkownik nie został znaleziony',
            })
        );
        window.location.href = "#/reset-password"
    };

    // @Override
    handleCorrectPasswordChange = (wsRes) => {
        this.blockUi();
        this.persistMessage(
            'success',
            '',
            this.props.type === 'CREATE'
                ? 'Hasło zostało nadane pomyślnie'
                : 'Hasło zostało zmienione pomyślnie'
        );
        window.location.href = '/#/login';
    };

    // @Override
    renderDetail = () => {
        return this.renderForm();
    };

	getResetPasswordContainerName(){
		return 'reset-password';
	}

	getContainerListName() {
		return 'login-page';
	}

	persistMessage(severity, summary, detail) {
		this.saveCookie(
			this.getContainerListName(),
			JSON.stringify({
				severity,
				summary,
				detail,
			})
		);
	}

}
export default ConfirmPasswordContainer;
