import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import PropTypes from 'prop-types';

import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CategoryService from '../services/CategoryService';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';

import { FaAngleDoubleUp, FaAngleDoubleDown } from 'react-icons/fa';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ActionLink from '../components/ActionLink';

class CategoryListContainer extends BaseListContainer {
	constructor(props) {
		super(props, new CategoryService());
		this.state = {
			list: [],
			loading: true,
			size: 0,
			first: 0,
			criteria: this.getCleanSearchCriteria(),
			statusOptions: [],
			targetOptions: [],
			expandedRows: null,
		};
		this.colorTemplate = this.colorTemplate.bind(this);
		this.enumTemplate = this.enumTemplate.bind(this);
		this.usersTemplate = this.usersTemplate.bind(this);
		this.priorityTemplate = this.priorityTemplate.bind(this);
		this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
		this.handleOnRowToggle = this.handleOnRowToggle.bind(this);
		this.rowExpansionColumnTemplete = this.rowExpansionColumnTemplete.bind(this);
		this.actionSubTemplate = this.actionSubTemplate.bind(this);
		this.handleGoToSubDetail = this.handleGoToSubDetail.bind(this);
	}


	updateSearchCriteria(criteria) {
		return {
			id: criteria.id,
			status: criteria.status,
			target: criteria.target,
			name: criteria.name,
			firstResult: criteria.firstResult,
			maxResult: criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}

	getCriteriaName() {
		return 'category-list-sc';
	}

	getContainerListName() {
		return 'category-list-container';
	}
	getCleanSearchCriteria() {
		return {
			id: null,
			status: { enumValue: "ACTIVE", label: "Aktywna" },
			target: null,
			name: '',
			firstResult: 0,
			maxResult: 10,
			sortField: 'categoryNumber',
			sortAsc: true,
		};
	}

	initializeFromBackend() {
		this.enumService.getEnumList('CategoryStatus')
			.then((statusOptions) => {
				this.setState({ statusOptions })
			})
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy statusów kategorii');
			});
		this.enumService.getEnumList('CategoryTarget')
			.then((targetOptions) => {
				this.setState({ targetOptions })
			})
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy celów kategorii');
			});
	}

	renderCriteria() {
		return (
			<div className='row'>
				<CriteriaTextComponent
					id="name-sc"
					name="name"
					label="Nazwa kategorii"
					colClass="col-lg-4 col-sm-6"
					value={this.state.criteria.name}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaDropdownComponent
					id="status-sc"
					name="status"
					showClear
					label="Status"
					colClass="col-lg-4 col-sm-6"
					value={this.state.criteria.status}
					options={this.state.statusOptions}
					placeholder="Wszystkie"
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaDropdownComponent
					id="target-sc"
					name="target"
					showClear
					label="Cel"
					colClass="col-lg-4 col-sm-6"
					value={this.state.criteria.target}
					options={this.state.targetOptions}
					placeholder='Wszystkie'
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
			</div>
		);
	}

	prepareColumns() {
		return [
			{ field: 'categoryNumber', header: 'Lp.', sortable: true },
			{ field: 'name', header: 'Nazwa kategorii', sortable: true },
			{ field: 'subcategories.length', header: 'Liczba podkategorii', sortable: false },
			{ header: 'Odpowiedzialni użytkownicy', sortable: false, body: this.usersTemplate, width: '20%' },
			{ header: 'Kolor', sortable: false, body: this.colorTemplate },
			{ field: 'status', header: 'Status', sortable: true, body: (row) => this.enumTemplate('status', row) },
			{ field: 'priority', header: 'Priorytet', sortable: true, body: this.priorityTemplate },
			{
				key: 'actions',
				header: 'Szczegóły',
				body: this.actionTemplate,
				className: 'table-column-action',
			},
		];
	}



	colorTemplate(rowData) {
		if (rowData.color) {
			return (
				<div className="show-color" style={{ backgroundColor: `#${rowData.color}` }} ></div>
			)
		}
	}

	usersTemplate(rowData) {
		//const users = [{firstName: 'Jan', lastName: 'Kowalski'}, {firstName: 'Mariusz', lastName: 'Nowakowski'}, {firstName: 'Gromisław', lastName: 'Czępiński'}]
		if (!rowData || !rowData.users) {
			return null;
		}
		return (
			<div>

				{rowData.users.map((u) => {
					return (
						<div className='user-box'>{u.firstName} {u.lastName}{u.removed ? ' (Usunięty)' : u.blocked ? ' (Zablokowany)': ''}</div>
					);
				})}
			</div>
		)
	}

	priorityTemplate(rowData) {
		if (rowData.priority) {
			return (
				<FaAngleDoubleUp />
			);
		} else {
			return (
				<FaAngleDoubleDown />
			)
		}
	}

	handleGoToSubDetail(e, category, href) {
		e.preventDefault();
		sessionStorage.setItem('category', JSON.stringify(category));
		this.blockUi();
		window.location.href = href;
	}

	actionSubTemplate(category, rowData) {
		const { detailUrl } = this.props;
		const href = `${detailUrl}/${category.id}/sub/${rowData?.id}`;
		return (
			<ActionLink
				label={'Szczegóły'}
				handleClick={(e) => this.handleGoToSubDetail(e, category, href)}
				variant='blue'
				className='p-link hover-underline'
				key={'view-button'}
				iconSize='xs'
				iconName='mdi-arrow-right'
				iconColor='blue'
			/>
		);
	}

	handleGoToNewSub(e, category) {
		const { newUrl } = this.props;
		const href = `${newUrl}/${category.id}/sub`;
		e.preventDefault();
		sessionStorage.setItem('category', JSON.stringify(category));
		this.blockUi();
		window.location.href = href;
	}

	rowExpansionTemplate(rowData) {
		console.log('rowExpansionTemplate', rowData);
		return (
			<React.Fragment>
				<div className="col-md-12" style={{ paddingLeft: '4em' }}>
					<div className="row">
						<div className="col-md-8">Lista podkategorii</div>
						<div className="col-md-2">
							<ActionLink
								label="Dodaj podkategorię"
								className="float-right"
								handleClick={(e) => this.handleGoToNewSub(e, rowData)}
								iconColor="blue"
								iconName="mdi-plus"
								iconSide="left"
								variant="blue"
								id={`add_sub_${rowData.id}`}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-10">
							<DataTable value={rowData.subcategories} sortField='subcategoryNumber' sortOrder='1'>
								<Column field="subcategoryNumber" header="Lp." sortable='true'></Column>
								<Column field="name" header="Nazwa" sortable='true'></Column>
								<Column header="Odpowiedzialni użytkownicy" body={this.usersTemplate}></Column>
								<Column body={(subRowData) => this.actionSubTemplate(rowData, subRowData)} className="table-column-action" />
							</DataTable>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	rowExpansionColumnTemplete(rowData) {
		const showExpander = true; //rowData.subcategories && rowData.subcategories.length > 0;
		if (!showExpander) {
			return null;
		}
		const isExpanded = (this.state.expandedRows !== null) && this.state.expandedRows[rowData.id] === true;
		return (
			<button type="button" className="p-row-toggler p-link" ariaControls="content_0_expanded" ariaExpanded={isExpanded}
				onClick={() => {
					const expandedRows = {};
					if (!isExpanded) {
						expandedRows[rowData.id] = true;
					}
					this.setState({ expandedRows });
				}}>
				<span className={`p-row-toggler-icon pi pi-fw p-clickable pi-chevron-${isExpanded ? 'down' : 'right'}`}></span>
			</button>
		)
	}

	handleOnRowToggle(e) {
		console.log('handleOnRowToggle...', e);
		this.setState({ expandedRows: e.data })
	}

	handleOnRowExpand() {
		console.log('handleOnRowExpand...');
	}

	prepareHeaderItems() {
		return this.isUserInAnyRole('ROLE_CATEGORY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')
			? [
				{
					label: 'Nowa kategoria',
					href: this.props.newUrl,
					type: 'BUTTON',
					className: 'float-right',
					variant: 'yellow',
					iconName: 'mdi-plus',
					iconSide: 'left',
					iconColor: 'white',
				},
			]
			: [];
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/category-list'>{'Kategorie'}</BreadcrumbsItem>
				{this.renderView()}
			</div>
		);
	}
}

CategoryListContainer.defaultProps = {
	detailUrl: '/#/category/details',
	newUrl: '/#/category/create',
};

CategoryListContainer.propTypes = {
	detailUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default CategoryListContainer;
