import BaseServiceCrud from './BaseServiceCrud';
import Constants from '../utils/constants';
import moment from 'moment';

export default class RefuseTypeService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'refuse-timetable';
	}

	getCriteria(criteria) {
		return this.objToQueryString({	
			areas: criteria.areas?.map(i =>i.id),
			refuse_types: criteria.refuseTypes?.map(i =>i.id),
			date_from: criteria.dateFromSc ?  moment( criteria.dateFromSc).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
			date_to: criteria.dateToSc ?  moment(criteria.dateToSc).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
			status: criteria.status?.enumValue,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	getDayList(criteria) {
		const queryString = this.getCalendarCriteria(criteria);
		return this.fetch(`${this.domain}/${this.path}/timetable-list${queryString}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	getCalendarCriteria(criteria) {
		return this.objToQueryString({
			date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
			date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
			timetable_id: criteria.timetableId
		});
	}
	changeStatus(id){
		return this.fetch(`${this.domain}/${this.path}/change-status/${id}`, {
			method: 'PUT',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	
}
