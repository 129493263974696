import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import YesNoDialog from '../components/YesNoDialog';
import InternalUserService from '../services/InternalUserService';
import UserRoleService from '../services/UserRoleService';
import InputMultiSelectComponent from '../components/inputs/InputMultiSelectComponent';
import InputSelectButtonComponent from '../components/inputs/InputSelectButtonComponent';
import InputCheckboxComponent from '../components/inputs/InputCheckboxComponent';
import PublicUnitService from '../services/PublicUnitService';
class UserContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new InternalUserService());
		this.isHalfWidth = true;
		this.editableOptions = [
			"Odczyt i zapis", "Odczyt"
		]
		this.state = {
			loading: true,
			elementId: !!props.myId ? this.authService.getProfile().userId : props.id,
			element: {
				login: '',
				firstName: '',
				lastName: '',
				email: '',
				phoneNumber: '',
				role: undefined,
				status: { enumValue: 'ACTIVE', label: 'Aktywny' },
				permissions: [],
				publicUnits: [],
				blocked: false,
			},
			permissionsOpt: [],
			showConfirmBlockDialog: false,
			showConfirmUnblockDialog: false,
			showDeleteConfirmationDialog: false,
			statusOptions: [],
			roleTypeOptions: [],
			showLogoutDialog: false,

		};
		this.backLinkRendered = !this.props.myId;
		this.blockAccount = this.blockAccount.bind(this);
		this.unblockAccount = this.unblockAccount.bind(this);
		this.showBlockConfirmation = this.showBlockConfirmation.bind(this);
		this.hideBlockConfirmation = this.hideBlockConfirmation.bind(this);
		this.showUnblockConfirmation = this.showUnblockConfirmation.bind(this);
		this.hideUnblockConfirmation = this.hideUnblockConfirmation.bind(this);
		this.showDeleteConfirmation = this.showDeleteConfirmation.bind(this);
		this.hideDeleteConfirmation = this.hideDeleteConfirmation.bind(this);
		this.remindPassword = this.remindPassword.bind(this);
		this.handleRedirect = this.handleRedirect.bind(this);
	}

	getContainerListName() {
		return !!this.props.myId ? 'manage-account-details': 'user-list-container';
	}

	getAddSucces() {
		return 'Użytkownik został utworzony';
	}

	getUpdateSucces() {
		return 'Użytkownik został zaktualizowany';
	}

	updateElement(data) {
		if (!this.props.myId) {
		this.setState(
			{
				element: {
					id: data.id,
					login: data.login,
					oldLogin: data.login,
					firstName: data.firstName,
					lastName: data.lastName,
					phoneNumber: data.phoneNumber,
					email: data.email,
					oldEmail: data.email,
					role: data.role,
					permissions: data.permissions,
					status: data.status,
					publicUnits: data.publicUnits ? data.publicUnits : undefined,
					blocked: data.blocked,
					fakeStatus:
						data.blocked && data.status && data.status.label
							? {
									value: data.status.value,
									label: `${data.status.label} (Automatycznie zablokowany)`,
							  }
							: undefined,
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
		} else {
			this.setState(
				{
					publicUnitOpt: data.publicUnits,
					element: {
						id: data.id,
						login: data.login,
						oldLogin: data.login,
						firstName: data.firstName,
						lastName: data.lastName,
						phoneNumber: data.phoneNumber,
						email: data.email,
						oldEmail: data.email,
						role: data.role,
						permissions: data.permissions,
						status: data.status,
						publicUnits: data.publicUnits ? data.publicUnits : undefined,
						blocked: data.blocked,
						fakeStatus:
							data.blocked && data.status && data.status.label
								? {
										value: data.status.value,
										label: `${data.status.label} (Automatycznie zablokowany)`,
								  }
								: undefined,
					},
				},
				() => {
					this.initAfterSetElement();
				}
			);
		}
	}
	getManageDetailsContainer(){
		return 'manage-account-details';
	}
	readSelfEditionMessage() {
        const messageFromStorage = this.readCookie(this.getManageDetailsContainer());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getManageDetailsContainer());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
            }
        }
    }
	initBeforeSetElement() {
		this.enumService
			.getEnumList('UserActivityStatus')
			.then((statusOptions) =>
				this.setState({
					statusOptions,
				})
			).catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy statusów');
			});
		if (!(this.props.viewMode === 'EDIT' || this.props.viewMode === 'VIEW') || !(!this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') || this.detectSelfEdition())) {
			new UserRoleService()
				.getRoles()
				.then((roles) => this.setState({ roleOptions: roles }))
				.catch((err) => {
					this.messages.show({
						severity: 'error',
						summary: 'Błąd',
						detail: err.message,
					});
				});
		}
		if (!this.props.myId) {
			new PublicUnitService()
				.getAllActive()
				.then(pu => this.setState({ publicUnitOpt: pu }))
				.catch((err) => {
					this.messages.show({
						severity: 'error',
						summary: 'Błąd',
						detail: err.message,
					});
				});
		}
	}

	initAfterSetElement() {
		if (this.state.element?.role?.id && this.state.element.viewMode === 'NEW') {
			this.fetchPermissionsOpt()
		} else {
			super.initAfterSetElement()
		}
		this.readSelfEditionMessage();
	}
	setElement() {
		if (!this.props.myId) {
			super.setElement();
		} else {
			const { viewMode } = this.props;
			const { elementId } = this.state;
			if (elementId && (viewMode === 'EDIT' || viewMode === 'VIEW')) {
				this.blockUi();
				this.service
					.getMyAccount(elementId, viewMode)
					.then((data) => {
						this.setState({ loading: false }, () => this.updateElement(data));
					})
					.catch((err) => {
						this.handleGetDetailsError(err);
					});
			} else {
				this.setState({ loading: false }, this.initAfterSetElement());
			}
		}
	}
	fetchPermissionsOpt() {

		new UserRoleService()
			.getPermissionsForRole(this.state.element?.role?.id)
			.then(permissions => {
				this.setState((prevState) => ({
					element: {
						...prevState.element,
						permissions: permissions,
					},
				}), () => super.initAfterSetElement());

			})
			.catch(err => {
				this.showErrorMessage(err.message);
				super.initAfterSetElement()
			});

	}
	remindPassword(){
		this.blockUi()
		this.service.remindPassword(this.state.element.id)
		.then(()=> {
			this.unblockUi();
			this.showSuccessMessage("Link został wysłany")
		})
		.catch(err =>{ 
			this.unblockUi()
			this.showErrorMessage(err.message)});
	}
	showBlockConfirmation() {
		this.setState({ showConfirmBlockDialog: true });
	}

	hideBlockConfirmation() {
		this.setState({ showConfirmBlockDialog: false });
	}

	showUnblockConfirmation() {
		this.setState({ showConfirmUnblockDialog: true });
	}

	hideUnblockConfirmation() {
		this.setState({ showConfirmUnblockDialog: false });
	}
	showDeleteConfirmation(){
		this.setState({ showDeleteConfirmationDialog: true });
	}
	hideDeleteConfirmation(){
		this.setState({ showDeleteConfirmationDialog: false });
	}
	blockAccount() {
		this.blockUi();
		this.service
			.blockAccount(this.state.element.id)
			.then((result) => {
				this.showSuccessMessage('Zablokowano konto użytkownika');
				this.updateElement(result);
				this.hideBlockConfirmation();
				this.unblockUi();
			})
			.catch((err) => {
				this.showErrorMessage('Nie udało się zablokować konta użytkownika');
				this.hideBlockConfirmation();
				this.unblockUi();
			});
	}

	unblockAccount() {
		this.blockUi();
		this.service
			.unblockAccount(this.state.element.id)
			.then((result) => {
				this.showSuccessMessage('Odblokowano konto użytkownika');
				this.updateElement(result);
				this.hideUnblockConfirmation();
				this.unblockUi();
			})
			.catch((err) => {
				this.showErrorMessage('Nie udało się odblokować konta użytkownika');
				this.hideUnblockConfirmation();
				this.unblockUi();
			});
	}
	deleteAccount(){
		this.blockUi();
		this.service
			.remove(this.state.element)
			.then((result) => {
				//this.blockUi();
				this.persistMessage('success', '', 'Usunięto konto użytkownika');
				window.location.href = this.props.backUrl;
			})
			.catch((err) => {
				this.showErrorMessage('Nie udało się usunąć konta użytkownika');
				this.hideDeleteConfirmation();
				this.unblockUi();
			});
	}

	editionAvailable() {
		const renderforSuperAdmin = this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE');
		console.log(renderforSuperAdmin, 'renderforSuperAdmin');
		const renderForInternalUser = this.isUserInRole('ROLE_INTERNAL_USER_MANAGE') && this.state.element?.role?.name === 'Miasto';
		console.log(renderForInternalUser, 'renderForInternalUser');
		const renderForPublicUnitUser = this.isUserInRole('ROLE_PUBLIC_UNIT_USER_MANAGE') && this.state.element?.role?.name === 'Jednostka';
		console.log(renderForPublicUnitUser, 'renderForPublicUnitUser');
		return !!(renderforSuperAdmin || renderForInternalUser || renderForPublicUnitUser);
	}
	//override
	prepareEditButton(rendered, label) {
		const { editUrl, selfEditUrl, viewMode } = this.props;
		const { element } = this.state;
		return {
			label: label !== undefined ? label : 'Edytuj',
			type: 'BUTTON',
			variant: '',
			className: 'float-right',
			href: !!this.props.myId ? selfEditUrl : `${editUrl}/${element.id}`,
			rendered: viewMode === 'VIEW' && (this.editionAvailable() || !!this.props.myId),
			iconName: 'mdi-pencil',
			iconSide: 'left',
			iconSize: 'm',
		};
	}
	handleFormSubmit(event) {
		if (event !== undefined) {
			event.preventDefault();
		}
		if (this.validator.allValid()) {
			if (this.state.element.permissions.filter(p => p.selected).length === 0 && this.state.element?.role?.name !== 'Administrator') {
				this.showErrorMessage("Wymagane wybranie przynajmniej jednego uprawnienia!");
				return;
			}
			this.handleValidForm();
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.scrollToError = true;
			this.forceUpdate();
		}
	}
	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode, selfDetailsUrl } = this.props;
		const { elementId } = this.state;
		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${elementId}`;
		}
		if (!!this.props.myId) {
			goBackUrl = selfDetailsUrl;
		}
		const user = this.authService.getProfile();
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
				label: 'Zapisz',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: this.editionAvailable && (viewMode === 'NEW' || viewMode === 'EDIT'),
			},
			{
				label: 'Dezaktywacja konta',
				className: 'float-right mr-2',
				iconName: 'mdi-lock',
				iconSide: 'left',
				iconSize: 'm',
				onClick: this.showBlockConfirmation,
				rendered: viewMode === 'VIEW' && (this.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_INTERNAL_USER_MANAGE')
				|| !!this.props.myId) 
				&& user.sub !== this.state.element.email 
				&& this.state.element.status.enumValue === 'ACTIVE' 
				&& this.state.element.blocked === false,
			},
			{
				label: 'Aktywacja konta',
				className: 'float-right mr-2',
				onClick: this.showUnblockConfirmation,
				iconName: 'mdi-lock-open',
				iconSide: 'left',
				iconSize: 'm',
				rendered: viewMode === 'VIEW' && (this.editionAvailable() 
				|| !!this.props.myId)
				&& user.sub !== this.state.element.email 
				//&& this.state.element.status.enumValue === 'INACTIVE' 
				&& this.state.element.blocked === true,
			},
			{
				label: 'Usuń',
				className: 'float-right mr-2',
				iconName: 'mdi-delete',
				iconSide: 'left',
				iconSize: 'm',
				onClick: this.showDeleteConfirmation,
				rendered: this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') && viewMode === 'VIEW' && this.state.element?.status !== undefined
				&& user.sub !== this.state.element.email
			},
			{
				label: 'Przypomnienie hasła',
				className: 'float-right mr-2',
				iconName: 'mdi-email-variant',
				iconSide: 'left',
				iconSize: 'm',
				onClick: this.remindPassword,
				rendered: !this.props.myId && this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') && viewMode === 'VIEW' && this.state.element?.status !== undefined
			}
		];
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowy użytkownik' : viewMode === 'EDIT' ? 'Edycja użytkownika' : 'Szczegóły użytkownika',
				type: 'LABEL',
				className: '',
			},
			{
				label: 'Zmień hasło',
				onClick: this.remindPassword,
				key: 'a',
				type: 'BUTTON',
				className: 'float-right',
				variant: 'accent',
				iconName: 'mdi-email-variant',
				iconSide: 'left',
				iconColor: 'white',
				rendered: !!this.props.myId
			},
			this.prepareEditButton(viewMode === 'VIEW' && this.isUserInRole('ROLE_PUBLIC_UNIT_ACC_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')),
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}
	handleValidForm = () => {
		if (
			this.props.viewMode === 'EDIT' &&
			this.detectSelfEdition() &&
			((this.state.element.oldEmail &&
				this.state.element.oldEmail !== this.state.element.email) ||
				(this.state.element.oldLogin && this.state.element.oldLogin !== this.state.element.login))
		) {
			this.setState({ showLogoutDialog: true });
		} else {
			this.blockUi(super.handleValidForm());
		}
	};
	detectSelfEdition() {
		const user = this.authService.getProfile();
		//console.log(user.userId);
		//console.log(parseInt(user.userId) === parseInt(this.state.element.id), 'equals user id element id');
		return !!(
			user.userId &&
			this.state.element && this.state.element.id &&
			parseInt(user.userId) === parseInt(this.state.element.id)
		);
	}
	handleRedirect(){
		window.location.href = this.props.changePasswordUrl
	}
	render() {
		return (
			<div className='container-fluid'>
				{!!this.props.myId ?
				 <BreadcrumbsItem to='/manage-account-details' className='p-link'>
					{'Zarządzanie kontem'}
				</BreadcrumbsItem> : (
					<BreadcrumbsItem to='/user-list' className='p-link'>
						{'Użytkownicy'}
					</BreadcrumbsItem>)}
				{this.props.viewMode === 'VIEW' ? !this.props.myId ? <BreadcrumbsItem to='/user/details'>{'Szczegóły'}</BreadcrumbsItem> : null : null}
				{this.props.viewMode === 'EDIT' ? !this.props.myId ? <BreadcrumbsItem to='/user/edit'>{'Edycja'}</BreadcrumbsItem> :null: null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/user/create'>{'Nowy'}</BreadcrumbsItem> : null}
				<YesNoDialog visible={this.state.showConfirmBlockDialog} header='Zablokowanie użytkownika' name='visible'
					onChange={(type, x, target) => { if (target.value) { this.blockAccount(); } else { this.hideBlockConfirmation(); } }}
					onHide={() => { this.hideBlockConfirmation(); }}>
					Czy na pewno chcesz zdezaktywować konto użytkownika?
				</YesNoDialog>
				<YesNoDialog visible={this.state.showConfirmUnblockDialog} header='Odblokowanie użytkownika' name='visible'
					onChange={(type, x, target) => { if (target.value) { this.unblockAccount(); } else { this.hideUnblockConfirmation(); } }}
					onHide={() => { this.hideUnblockConfirmation(); }}>
					Czy na pewno chcesz aktywować konto użytkownika?
				</YesNoDialog>
				<YesNoDialog visible={this.state.showDeleteConfirmationDialog} header='Usuwanie użytkownika' name='visible'
					onChange={(type, x, target) => { if (target.value) { this.deleteAccount(); } else { this.hideDeleteConfirmation(); } }}
					onHide={() => { this.hideDeleteConfirmation(); }}>
					Czy na pewno chcesz usunąć konto użytkownika?
				</YesNoDialog>
				{this.renderView()}
			</div>
		);
	}
	createOrUpdate(logoutInThen) {
		if (!this.props.myId) {
			if (logoutInThen) {
				this.service
					.update(this.state.element)
					.then((res) => {
						this.blockUi();
						if (!!logoutInThen) {
							if (this.props?.logoutFunc) {
								this.props.logoutFunc();
							}
						}
					})
					.catch((err) => {
						this.showErrorMessage(err.message);
					});
			} else {
				super.createOrUpdate();
			}
		} else {
			if (logoutInThen) {
				this.service
					.updateMyAccount(this.state.element)
					.then((res) => {
						this.blockUi();
						if (!!logoutInThen) {
							if (this.props?.logoutFunc) {
								this.props.logoutFunc();
							}
						}
					})
					.catch((err) => {
						this.showErrorMessage(err.message);
					});
			} else {
				const { selfDetailsUrl } = this.props;
				let { element } = this.state;
				this.scrollToTop();
				if (this._isMounted) {
					element = this.updateElementBeforeCreateOrUpdate(element)
					this.service
						.updateMyAccount(element)
						.then((response) => {
							this.blockUi();
							this.persistMessage('success', '', this.getUpdateSucces(response));
							window.location.href = selfDetailsUrl;
						})
						.catch((err) => {
							this.showErrorMessage(err.message, 10000);
						});
				}
			}
		}
	}
	renderDetails() {
		return (
			<React.Fragment>
				<YesNoDialog
					header="Zatwierdzenie wylogowania"
					name="delete"
					visible={this.state.showLogoutDialog}
					onChange={(type, x, target) => {
						if (target.value) {
							this.blockUi();
							this.setState(
								{
									showLogoutDialog: false,
								},
								() => {
									this.createOrUpdate(true);
								}
							);
						} else {
							this.setState({
								showLogoutDialog: false,
							});
						}
					}}
					onHide={() => {
						this.setState((prevState) => ({
							...prevState,
							showLogoutDialog: false,
						}));
					}}>
					Zmiana danych wymaga ponownego zalogowania. Po zatwierdzeniu
					zmian zostaniesz automatycznie wylogowany. Czy chcesz
					kontynuować?
				</YesNoDialog>
				<div class='row'>
					{/* <InputTextComponent
						id='login'
						name='login'
						label={'Login'}
						colClass='col-md-4'
						value={this.state.element.login}
						validator={this.validator}
						validators='required|max:50'
						onChange={this.handleChange}
						viewMode={this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode}
					/> */}

					<InputTextComponent
						id='firstName'
						name='firstName'
						label={'Imię'}
						colClass='col-md-12'
						value={this.state.element.firstName}
						validator={this.validator}
						validators='required|alpha_space|max:100'
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
					/>

					<InputTextComponent
						id='lastName'
						name='lastName'
						label={'Nazwisko'}
						colClass='col-md-12'
						value={this.state.element.lastName}
						validator={this.validator}
						validators='required|alpha_space_dash|max:100'
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
					/>

					<InputTextComponent id='email'
						name='email'
						label={'Email'}
						colClass='col-md-12'
						value={this.state.element.email}
						validator={this.validator}
						validators='required|email|max:100'
						onChange={this.handleChange}
						viewMode={this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode} />

					{/* <InputTextComponent
						id='phoneNumber'
						name='phoneNumber'
						label={'Telefon'}
						colClass='col-md-4'
						value={this.state.element.phoneNumber}
						validator={this.validator}
						validators='required|phone|max:12'
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						max={12}
					/> */}

					<InputDropdownComponent
						id='role'
						name='role'
						label={'Rola'}
						colClass='col-md-8'
						value={this.state.element.role}
						validator={this.validator}
						options={this.state.roleOptions}
						onChange={this.handleChange}
						viewMode={this.props.viewMode === 'EDIT' && (!this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') || this.detectSelfEdition()) ? 'VIEW' : this.props.viewMode}
						optionLabel='name'
						dataKey='id'
						validators='required'
						valueView={this.state.element.role ? this.state.element.role.name : ''}
						onAfterStateChange={e => { if (this.state.element.role.name !== 'Administrator') this.fetchPermissionsOpt(e) }}
						validateViewMode
					/>
					<InputMultiSelectComponent
						id="publicUnits"
						label="Jednostki"
						name="publicUnits"
						colClass="col-xl-8"
						value={this.state.element.publicUnits}
						dataKey="id"
						optionLabel="name"
						filter
						filterBy="name"
						options={this.state.publicUnitOpt}
						validator={this.validator}
						validators={this.state.element?.role?.name === 'Jednostka' ? 'array_required' : 'array_required'}
						placeholder="Wybierz jednostki"
						viewMode={this.props.viewMode}
						disabled={this.props.viewMode === 'VIEW' || !!this.props.myId || !!this.detectSelfEdition()}
						onChange={this.handleChange}
						rendered={this.state.element?.role?.name === 'Jednostka'}
					/>
					{this.renderPermissionList()}
					<InputDropdownComponent
						id='status'
						name='status'
						label={'Status'}
						colClass='col-md-12'
						value={!this.state.element.fakeStatus ? this.state.element.status : this.state.element.fakeStatus}
						validator={this.validator}
						options={this.state.statusOptions}
						itemTemplate={this.permissionTemplate}
						onChange={null}
						viewMode={'VIEW'}
						optionLabel='label'
						dataKey='enumValue'
						validators='not_required'
						rendered={this.props.viewMode !== 'NEW'}
						valueView={
							this.state.element.fakeStatus
								? this.state.element.fakeStatus.label
								: this.state.element.status
									? this.state.element.status.label
									: ''
						}
					/>
				</div>
			</React.Fragment>
		);
	}
	renderPermissionList() {
		return this.state.element?.role?.name !== 'Administrator' && this.state.element.permissions ? this.state.element.permissions.map((i, index) => {
			return (
				<div class="row" style={{width: '100%'}}>
						<InputCheckboxComponent
							id={`${index}-permission`}
							name="selected"
							label={i.description}
							labelOnRight
							value={!!i.selected}
							validator={this.validator}
							validators='not_required'
							colClass='col-md-8 col-sm-12'
							onChange={this.handleChange}
							viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
							disabled={this.props.viewMode === 'VIEW' || !!this.props.myId || !!this.detectSelfEdition()}
							stateField={`element.permissions[${index}]`}
							positioning='col-md-12 form-group-checkbox-flex'
						/>
						<InputDropdownComponent
							id={`${index}-permission-editable`}
							name='editable'
							showLabel={false}
							colClass='col-md-4 col-sm-6'
							value={this.state.element.permissions[index]?.editable}
							validator={this.validator}
							options={this.editableOptions}
							onChange={this.handleChange}
							disabled={this.props.viewMode === 'VIEW' || !!this.props.myId || !!this.detectSelfEdition()}
							viewMode={this.props.viewMode !== 'VIEW' ? this.props.viewMode : 'EDIT'}
							rendered={i.selected}
							stateField={`element.permissions[${index}]`}
						/>
				</div >
			);
		}) : null
	}
}
UserContainer.defaultProps = {
	backUrl: '/#/user-list',
	cancelUrl: '/#/user/details',
	editUrl: '/#/user/edit',
	selfEditUrl: '#/manage-account-edit',
	selfDetailsUrl: '#/manage-account-details',
	changePasswordUrl: '#/change-password'
};

UserContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
	editUrl: PropTypes.string,
	selfEditUrl: PropTypes.string,
	selfDetailsUrl: PropTypes.selfDetailsUrl,
	myId: PropTypes.bool
};

export default UserContainer;
