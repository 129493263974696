import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import ActionLink from '../ActionLink';
import { FileUpload } from 'primereact/fileupload';
import BaseServiceCrud from '../../services/BaseServiceCrud';
import { saveAs } from 'file-saver';
import BaseInputComponent from './BaseInputComponent';

class SystemParameterService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'settings';
		this.getValue = this.getValue.bind(this);
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			active: criteria.active,
			id: criteria.id,
			type: criteria.type,
			first_result: criteria.firstResult,
			max_results: criteria.maxResults,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}

	getValue(key) {
		return this.fetch(`${this.domain}/${this.path}/key/` + key, {
			method: 'GET',
		}).catch((err) => {
			throw err;
		});
	}
	getValueByCode(code) {
		return this.fetch(`${this.domain}/${this.path}/param?code=` + code, {
			method: 'GET',
		}).catch((err) => {
			throw err;
		});
	}
	getPasswordParamsPublic() {
		return this.fetch(`${this.domain}/${this.path}/passwords-params`, {
			method: 'GET',
		}).catch((err) => {
			throw err;
		});
	}
	getFileParams(code) {
		return this.fetch(`${this.domain}/${this.path}/file-param/${code}`, {
			method: 'GET',
		}).catch((err) => {
			throw err;
		});
	}
}

class InputFileUploadComponent extends BaseInputComponent {
	constructor(props, context) {
		super(props, context);
		this.downloadFile = this.downloadFile.bind(this);
		this.state = { visible: false, progress: 0 };
		this.systemParameterService = new SystemParameterService(props.publicMode);
		this.handleBeforeUpload = this.handleBeforeUpload.bind(this);
		this.onBeforeSend = this.onBeforeSend.bind(this)
	}

	componentDidMount() {
		const { uploadExtParamName, uploadMaxSizeParamName, handleError, maxFileCountParam } = this.props;
		if (uploadExtParamName) {
			this.systemParameterService
				.getFileParams(uploadExtParamName)
				.then((result) => {
					this.setState({
						acceptFromParams: result.value,
					});
				})
				.catch((err) => {

					console.log(`can not read system param ${uploadExtParamName}: ${err}`);
					if (handleError) {
						handleError(err.message);
					}
				});
		}
		if (uploadMaxSizeParamName) {
			this.systemParameterService
				.getFileParams(uploadMaxSizeParamName)
				.then((result) => {
					this.setState({
						maxFileSizeFromParams: parseInt(result.value),
					});
				})
				.catch((err) => {
					console.log(`can not read system param ${uploadMaxSizeParamName}: ${err.message}`);
					if (handleError) {
						handleError(err.message);
					}
				});
		}
		if(maxFileCountParam){
			this.systemParameterService
				.getFileParams(maxFileCountParam)
				.then((result) => {
					this.setState({
						maxFilesCountFromParams: parseInt(result.value),
					});
				})
				.catch((err) => {
					console.log(`can not read system param ${maxFileCountParam}: ${err.message}`);
					if (handleError) {
						handleError(err.message);
					}
				});
		}
	}

	fetchGet(url, headers) {
		// const method = options !== undefined ? options.method : undefined;
		const options = { method: 'GET' };
		this.counter += 1;
		return new Promise((resolve, reject) => {
			fetch(url, {
				headers,
				...options,
			})
				.then((response) => {
					// response.headers.forEach(function (val, key) {
					// 	console.log(key + ' -> ' + val);
					// });
					if (response.ok) {
						return resolve(response);
					}
					return reject(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	downloadFileBase(url, fileLabel) {
		const { token, publicMode, handleError, onDownloadEvent } = this.props;
		const headers = {
			'Cache-Control': 'no-cache, no-store, must-revalidate',
			Pragma: 'no-cahce',
		};
		if (!publicMode) {
			headers['Authorization'] = token;
		}
		if (onDownloadEvent) {
			onDownloadEvent('start');
		}
		this.fetchGet(url, headers)
			.then((response) => {
				console.log('content-disposition', response.headers.get('content-disposition'));
				return response.blob();
			})
			.then((blob) => {
				saveAs(blob, fileLabel);
				if (onDownloadEvent) {
					onDownloadEvent('finished');
				}
			})
			.catch((err) => {
				console.log('downloadFile error', err);
				if (handleError) {
					handleError('Nie można pobrać pliku!');
				}
				if (onDownloadEvent) {
					onDownloadEvent('finished');
				}
			});
	}

	downloadImage(url) {
		const { token, publicMode, handleError } = this.props;
		const headers = {
			'Cache-Control': 'no-cache, no-store, must-revalidate',
			Pragma: 'no-cahce',
		};
		if (!publicMode) {
			headers['Authorization'] = token;
		}
		return new Promise((resolve, reject) => {
			this.fetchGet(url, headers)
				.then((response) => {
					console.log('content-disposition', response.headers.get('content-disposition'));
					return resolve(response.blob());
				})
				.catch((err) => {
					console.log('downloadFile error', err);
					if (handleError) {
						handleError('Nie można pobrać pliku!');
					}
				});
		});
	}

	downloadFile(item, showAsImage) {
		const { itemName, itemLabel, publicMode, restApiUrl } = this.props;
		let url = undefined;
		if (item.id && restApiUrl) {
			url = `${restApiUrl}/${publicMode ? 'public/' : ''}${item.id}`;
		} else {
			url = `${process.env.REACT_APP_BACKEND_URL}/file/${publicMode ? 'public/' : ''}${item[itemName]}`;
		}
		if (url) {
			if (showAsImage) {
				return this.downloadImage(url, item[itemLabel]);
			} else {
				this.downloadFileBase(url, item[itemLabel]);
			}
		}
	}

	handleBeforeUpload(e, maxFileCountExceeded) {

		const xhr = e.xhr;
		e.xhr.onloadstart = () => {
			if (maxFileCountExceeded === true) {
				xhr.abort();
			}
		};
		this.setState({ visible: true });
	}

	renderView() {
		const { colClass, file, fileList, id, insideTable, itemLabel, label, multiple, showLabel, validateViewMode, validator, validators, showImagePreview, renderCustomLabelFunc } = this.props;
		let fileValidators = multiple ? 'array_required' : 'required';
		if (validators) {
			fileValidators = validators;
		}
		return (
			<div className={insideTable ? '' : colClass}>
				<div className={insideTable ? '' : 'row'}>
					<div className={insideTable ? '' : 'col-md-12 form-group'}>
						{renderCustomLabelFunc ? renderCustomLabelFunc() :
							label !== undefined && showLabel ? (
								<label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{ width: '100%' }}>
									{label}
								</label>
							) : null}
						<div aria-label={label} aria-labelledby={`${id}-label-id`} className='col-12'>
							{multiple ? (
								fileList.map(function (item, i) {
									return (
										<div className='row' key={i}>
											<ActionLink
												variant='accent'
												label={item[itemLabel]}
												handleClick={(e) => {
													e.preventDefault();
													this.downloadFile(item);
												}}
												showImagePreview={showImagePreview}
												imageFunc={() => this.downloadFile(item, true)}
												key={`${id}-${i}-download-button`}
												className='p-link file'
												iconName='mdi-file'
												iconColor='accent'
												iconSize='xs'
												iconSide='left'
												downloadFile
											/>
										</div>
									);
								}, this)
							) : file ? (
								<div className='row' key={0}>
									<ActionLink
										variant='accent'
										label={file[itemLabel]}
										handleClick={(e) => {
											e.preventDefault();
											this.downloadFile(file);
										}}
										showImagePreview={showImagePreview}
										imageFunc={() => this.downloadFile(file, true)}
										className='p-link file'
										key='download-button'
										iconName='mdi-file'
										iconColor='accent'
										iconSize='xs'
										iconSide='left'
										downloadFile
									/>
								</div>
							) : null}
						</div>
						{validateViewMode ? this.renderValidatorsMessages(validator, id, label, multiple ? fileList : file, fileValidators) : null}
					</div>
				</div>
			</div>
		);
	}

	handleUploadError(e) {

		this.setState({ visible: false });
		const { handleError, overrideFileNotAcceptedMessage } = this.props;
		let errMsg = e.message;
		if (e.xhr && e.xhr.response && typeof e.xhr.response == 'string') {
			const errObj = JSON.parse(e.xhr.response);
			if (errObj && errObj.message) {
				if (overrideFileNotAcceptedMessage && errObj.errorCode === 'FILE_EXT_NOT_ACCEPTED') {
					errMsg = overrideFileNotAcceptedMessage;
				} else {
					errMsg = errObj.message;
				}
			}
		}
		if (handleError && !this.state.maxFileCountExceeded) {
			handleError(errMsg);
		}
		console.log(`can not upload file(s), err: ${errMsg}`);
	}

	renderEdit() {
		return this.renderNew();
	}

	renderNew() {
		const {
			colClass,
			disabled,
			file,
			fileList,
			handleRemove,
			handleUpload,
			id,
			insideTable,
			itemLabel,
			itemName,
			label,
			maxFileCount,
			maxFileSize,
			accept,
			messages,
			multiple,
			name,
			onAfterStateChange,
			onChange,
			publicMode,
			restApiUrl,
			showLabel,
			stateField,
			validator,
			validators,
			uploadExtParamName,
			uploadMaxSizeParamName,
			restrictExt,
			onFileCountExceeded,
			token,
			showImagePreview,
			renderCustomLabelFunc,
		} = this.props;

		const { acceptFromParams, maxFileSizeFromParams, maxFilesCountFromParams } = this.state;
		const { progress, visible } = this.state;
		let fileValidators = multiple ? 'array_required' : 'required';
		if (validators) {
			fileValidators = validators;
		}
		const acceptFinal = acceptFromParams ? acceptFromParams : accept;
		const maxFileSizeFinal = maxFileSizeFromParams ? maxFileSizeFromParams : maxFileSize;
		// let uploadUrl = restApiUrl; xxx
		let uploadUrl = `${process.env.REACT_APP_BACKEND_URL}/file/${publicMode ? 'public' : ''}`;
		let paramAdded = false;
		//alert(accept + '\n' + uploadExtParamName);
		if ((uploadExtParamName || accept) && restrictExt === true) {
			if (accept) {
				uploadUrl += `?ext=${accept}`;
			} else if (uploadExtParamName) {
				uploadUrl += `?ext=${acceptFinal}`;
			}
			paramAdded = true;
		}
		if (uploadMaxSizeParamName) {
			if (paramAdded) {
				uploadUrl += '&';
			} else {
				uploadUrl += '?';
			}
			uploadUrl += `max=${maxFileSizeFinal}`;
		}
		let maxFileCountExceeded = false;
		const maxFileCountFinal = maxFilesCountFromParams ? maxFilesCountFromParams : maxFileCount;
		return (
			<div className={insideTable ? '' : colClass}>
				<Dialog
					ariaCloseIconLabel='Zamknij okno dialogowe'
					key={`${id}-dialog`}
					visible={visible}
					style={{ width: '100vw', height: '100vh' }}
					modal
					closable={false}
					header={null}
					className='file-upload-dialog'
					onHide={() => this.setState({ visible: false })}>
					<ProgressSpinner className='file-upload-spinner' />
					<ProgressBar className='file-upload-bar' value={progress} showValue unit='%' mode='determinate' />
				</Dialog>
				<div className={insideTable ? '' : 'row'}>
					<div className={insideTable ? '' : 'col-md-12 form-group'}>
						{renderCustomLabelFunc ? renderCustomLabelFunc() : label !== undefined && showLabel ? (
							<span className='p-label' style={{ width: '100%' }}>
								{label}
							</span>
						) : null}
						<FileUpload
							ariaLabel={`${label} przeglądaj pliki`}
							ariaDescribedBy={`${id}-error`}
							key={id}
							id={id}
							mode='advanced'
							name="file"
							multiple={multiple}
							url={uploadUrl}
							maxFileSize={maxFileSizeFinal}
							accept={acceptFinal}
							onUpload={(e) => {
								if (handleUpload) {
									handleUpload(e, name);
								} else if (multiple) {
									onChange('MULTI_FILE_UPLOAD', ['ADD', name], e, onAfterStateChange, stateField);
								} else {
									onChange('SINGLE_FILE_UPLOAD', ['ADD', name], e, onAfterStateChange, stateField);
								}
								this.setState({ visible: false });
							}}
							auto
							onError={this.handleUploadError.bind(this)}
							onProgress={(e) => {
								const progress = Math.round((100 * e.originalEvent.loaded) / e.originalEvent.total);
								this.setState({ progress });
							}}
							onBeforeUpload={(e) => this.handleBeforeUpload(e, !!this.state.maxFileCountExceeded)}
							chooseLabel='Przeglądaj'
							invalidFileSizeMessageSummary='{0}: Niedopuszczalny rozmiar pliku, '
							invalidFileSizeMessageDetail='maksymalny rozmiar pliku to {0}.'
							disabled={disabled || (fileList && fileList.length >= maxFileCountFinal)}
							onValidationFail={() => { if (this.props.handleError) { this.props.handleError(`Przekroczono maksymalny rozmiar pliku: ${maxFileSizeFinal} B`) } }}
							onSelect={(e) => {

								maxFileCountExceeded = false;
								if (fileList?.length + e.files.length > maxFileCountFinal) {
									console.log('file count exceeded...');
									this.setState({ maxFileCountExceeded: true }, () => {
										if (onFileCountExceeded) {
											onFileCountExceeded(maxFileCountFinal);
										}
									})

								} else {
									this.setState({ maxFileCountExceeded: false });
								}
							}}
							messages={messages}
							token={token}
							onBeforeSend={this.onBeforeSend}
						//withCredentials
						/>
						{multiple ? (
							<div className="fileupload-files">
								{fileList?.map(function (item, i) {
									return (
										<div className='row file-uploaded' key={i}>
											<ActionLink
												variant='accent'
												label={item[itemLabel]}
												handleClick={(e) => {
													e.preventDefault();
													this.downloadFile(item);
												}}
												showImagePreview={showImagePreview}
												imageFunc={() => this.downloadFile(item, true)}
												key={`${id}-${i}-download-button`}
												className='p-link file'
												iconName='mdi-file'
												iconColor='accent'
												iconSize='xs'
												iconSide='left'
												downloadFile
											/>
											<ActionLink
												key={`${id}-${i}-remove-button`}
												className='file-upload-actionlink'
												label=''
												handleClick={(e) => {
													if (handleRemove) {
														handleRemove(e, i);
													} else {
														// eslint-disable-next-line max-len
														onChange('MULTI_FILE_UPLOAD', ['REMOVE', name, i], e, undefined, stateField);
													}
												}}
												params={i}
												iconName='mdi-close-circle-outline'
												iconColor='grey'
												iconSize='m'
												iconSide='left'
											/>
										</div>
									);
								}, this)}
							</div>
						) : file ? (
							<div className='col-12'>
								<div className='row' key={0}>
									<ActionLink
										variant='accent'
										label={file[itemLabel]}
										handleClick={(e) => {
											e.preventDefault();
											this.downloadFile(file);
										}}
										showImagePreview={showImagePreview}
										imageFunc={() => this.downloadFile(file, true)}
										className='p-link file'
										key={`${id}-download-button`}
										iconName='mdi-file'
										iconColor='accent'
										iconSize='xs'
										iconSide='left'
										downloadFile
									/>
									<ActionLink
										key={`${id}-${0}-remove-button`}
										className='file-upload-actionlink'
										label=''
										handleClick={(e) => {
											if (handleRemove) {
												handleRemove(e, 0);
											} else {
												// eslint-disable-next-line max-len
												onChange('SINGLE_FILE_UPLOAD', ['REMOVE', name], e, undefined, stateField);
											}
										}}
										params={0}
										iconName='mdi-close-circle-outline'
										iconColor='grey'
										iconSize='m'
										iconSide='left'
									/>
								</div></div>
						) : null}

						{this.renderValidatorsMessages(validator, id, label, multiple ? fileList : file, fileValidators)}
					</div>
				</div>
			</div>
		);
	}
	onBeforeSend(r) {
		r.xhr.setRequestHeader('Authorization', this.props.token);
	}
	render() {
		const { rendered, viewMode } = this.props;
		if (rendered) {
			switch (viewMode) {
				case 'NEW':
					return this.renderNew();
				case 'EDIT':
					return this.renderEdit();
				case 'VIEW':
				default:
					return this.renderView();
			}
		} else {
			return null;
		}
	}
}

InputFileUploadComponent.defaultProps = {
	colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
	disabled: false,
	insideTable: false,
	itemLabel: 'originalFileName',
	itemName: 'fileName',
	maxFileCount: 15,
	maxFileSize: 100000,
	multiple: true,
	publicMode: false,
	rendered: true,
	showLabel: true,
	stateField: 'element',
	validateViewMode: false,
	viewMode: 'VIEW',
	restrictExt: false,
	showImagePreview: false,
};

InputFileUploadComponent.propTypes = {
	accept: PropTypes.string,
	colClass: PropTypes.string,
	disabled: PropTypes.bool,
	file: PropTypes.object,
	fileList: PropTypes.array,
	handleError: PropTypes.func,
	handleRemove: PropTypes.func,
	handleUpload: PropTypes.func,
	id: PropTypes.string.isRequired,
	insideTable: PropTypes.bool,
	itemLabel: PropTypes.string,
	itemName: PropTypes.string,
	label: PropTypes.string.isRequired,
	maxFileCount: PropTypes.number,
	maxFileSize: PropTypes.number,
	messages: PropTypes.any,
	multiple: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onAfterStateChange: PropTypes.func,
	onChange: PropTypes.func,
	onFileCountExceeded: PropTypes.func,
	onDownloadEvent: PropTypes.func,
	overrideFileNotAcceptedMessage: PropTypes.string,
	publicMode: PropTypes.bool,
	rendered: PropTypes.bool,
	renderCustomLabelFunc: PropTypes.func,
	restApiUrl: PropTypes.string,
	restrictExt: PropTypes.bool,
	showLabel: PropTypes.bool,
	showImagePreview: PropTypes.bool,
	stateField: PropTypes.string,
	token: PropTypes.string,
	uploadExtParamName: PropTypes.string,
	uploadMaxSizeParamName: PropTypes.string,
	validateViewMode: PropTypes.bool,
	validator: PropTypes.oneOfType(PropTypes.array, PropTypes.object),
	validators: PropTypes.string,
	viewMode: PropTypes.string,
};

export default InputFileUploadComponent;
