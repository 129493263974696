import React from 'react';
import BlockUi from '../components/waitPanel/BlockUi';
import SettingService from '../services/SettingService';

class VersionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.service = new SettingService();
        this.state = {
            backendVersion: {},
            loading: true,
            uiVersion: {
                appVersion: process.env.REACT_APP_VERSION,
                appName: process.env.REACT_APP_NAME,
                buildNumber: process.env.REACT_APP_BUILD_NUMBER,
                buildTime: process.env.REACT_APP_BUILD_TIME,
            },
        };
    }

    componentDidMount() {
        this.service
            .getVersionInfo()
            .then((backendVersion) => {
                this.setState({backendVersion, loading: false});
            })
            .catch(() => {
                this.setState({backendVersion: {}, loading: false});
            });
    }

    render() {
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.loading} loader={this.loader}></BlockUi>
                <div style={{display: 'flex', minHeight: '85vh'}}>
                    <div>
                        <div className='col-md-12'>
                            <span style={{fontWeight: 'bold'}}>UI app name: </span>
                            <span>{this.state.uiVersion?.appName}</span>
                        </div>
                        <div className='col-md-12'>
                            <span style={{fontWeight: 'bold'}}>UI app version: </span>
                            <span>{this.state.uiVersion?.appVersion}</span>
                        </div>
                        <div className='col-md-12'>
                            <span style={{fontWeight: 'bold'}}>UI build number: </span>
                            <span>{this.state.uiVersion?.buildNumber}</span>
                        </div>
                        <div className='col-md-12'>
                            <span style={{fontWeight: 'bold'}}>UI build time: </span>
                            <span>{this.state.uiVersion?.buildTime}</span>
                        </div>
                        <div className='col-md-12'></div>
                        <div className='col-md-12'>
                            <span style={{fontWeight: 'bold'}}>Backend name: </span>
                            <span>{this.state.backendVersion?.name}</span>
                        </div>
                        <div className='col-md-12'>
                            <span style={{fontWeight: 'bold'}}>Backend variant: </span>
                            <span>{this.state.backendVersion?.buildVariant}</span>
                        </div>
                        <div className='col-md-12'>
                            <span style={{fontWeight: 'bold'}}>Backend version: </span>
                            <span>{this.state.backendVersion?.buildVersion}</span>
                        </div>
                        <div className='col-md-12'>
                            <span style={{fontWeight: 'bold'}}>Backend build number: </span>
                            <span>{this.state.backendVersion?.buildNumber}</span>
                        </div>
                        <div className='col-md-12'>
                            <span style={{fontWeight: 'bold'}}>Backend build time: </span>
                            <span>{this.state.backendVersion?.buildTime}</span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

VersionContainer.propTypes = {};
export default VersionContainer;
