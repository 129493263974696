import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import queryString from 'query-string';
import decode from 'jwt-decode';
import React from 'react';
import { Link } from 'react-router-dom';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputPasswordComponent from '../../components/inputs/InputPasswordComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import CustomMessages from '../../components/CustomMessages';
import BlockUi from '../../components/waitPanel/BlockUi';

import SimpleReactValidator from '../../components/validator';
import SettingService from '../../services/SettingService';
import MobileUserService from '../../services/MobileUserService';
import AbstractConfirmPasswordContainer from './AbstractConfirmPasswordContainer';

class MobileConfirmPasswordContainer extends AbstractConfirmPasswordContainer {

    constructor() {
        super();
        this.service = new MobileUserService();
    }

    // @Override
    getPasswordParams = () => {
        const settingService = new SettingService();
        return settingService.getMobilePasswordParamsPublic()
            .then((resp) => {
                this.setState(
                    {
                        maxLength:              resp['MOBILE_PASSWORD_MAX_LENGTH'],
                        minLength:              resp['MOBILE_PASSWORD_MIN_LENGTH'],
                        lowerCaseCount:         resp['MOBILE_PASSWORD_LOWERCASE_COUNT'],
                        upperCaseCount:         resp['MOBILE_PASSWORD_UPPERCASE_COUNT'],
                        numbersCount:           resp['MOBILE_PASSWORD_NUMBERS_COUNT'],
                        specialCharactersCount: resp['MOBILE_PASSWORD_SPECIAL_CHARS_COUNT'],
                    },
                    () => this.unblockUi()
                );
            })
            .catch((err) => this.unblockUi());
    }

    // @Override
    getService = () => {
        return this.service;
    };

    // @Override
    handleCorrectPasswordToken = (wsRes) => {
        if(wsRes.login) {
            this.setEmail(wsRes.login);
            this.unblockUi()
        } else {
            this.handleErrorPasswordToken();
        }
    };

    // @Override
    handleErrorPasswordToken = (wsErr) => {
        this.setStatusInvalid();
        this.unblockUi()
    };

    // @Override
    handleCorrectPasswordChange = (wsRes) => {
        this.setStatusSuccess();
    };

    // @Override
    renderDetail = () => {
        if(this.isStatusInvalid()) {
            return this.renderInvalid();
        } else if(this.isStatusSuccess()) {
            return this.renderSuccess();
        } else if(this.getEmail()) {
            return this.renderForm();
        }
    };

	setEmail = (value) => {
        this.setState({ email: value });
	}

    getEmail = () => {
        return this.state.email;
    }

	setStatusInvalid = () => {
        this.setState({status: 'INVALID'});
	}

	isStatusInvalid = () => {
	    return this.state.status == 'INVALID';
	}

	setStatusSuccess = () => {
        this.setState({status: 'SUCCESS'});
	}

	isStatusSuccess = () => {
	    return this.state.status == 'SUCCESS';
	}

	renderInvalid = () => {
	    return (
	        <div className="card-body">
	            <span className="invalid-message">
	                {'Link do generowania hasła wygasł lub jest niepoprawny. Aby wygenerować nowy, wróć do aplikacji mobilnej.'}
	            </span>
	        </div>
	    );
	}

	renderSuccess = () => {
	    return (
            <div className="card-body">
                <span className="success-message">
                    {'Hasło użytkownika zmienione pomyślnie.'}
                </span>
            </div>
        );
	}

}
export default MobileConfirmPasswordContainer;
