import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';

export class PieChartComponent extends Component {

    constructor(props) {
        super(props);
        this.lightOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            }
        };
        this.slicesColors = [
            { color: '#66a6ff', textStyle: { color: 'black' } }, //blue
            { color: '#6dc76b', textStyle: { color: 'black' } }, //green
            { color: '#ffd54d', textStyle: { color: 'black' } }, //yellow
            { color: '#ed805e', textStyle: { color: 'black' } }, //red
            { color: '#b6a8f0', textStyle: { color: 'black' } }, //viola
            { color: '#999999', textStyle: { color: 'black' } }, //gray
            { color: '#ff4d88', textStyle: { color: 'black' } } //pink
        ]
    }

    render() {
        return (

            <Chart
                height={'300px'}
                chartType="PieChart"
                loader={<div>Pobieranie danych</div>}
                data={
                    this.props.data
                }
                options={{
                    title: this.props.questionName,
                    width: this.props.width ? this.props.width : '100%',
                    width_units: this.props.width_units ? this.props.width_units : '%',
                    slices: this.slicesColors,
                    chartArea:{width:'80%'}

                }}
                rootProps={{ 'data-testid': '1' }}
                chartLanguage={this.props.chartLanguage}
            />

        )
    }
}
PieChartComponent.propTypes = {
    data: PropTypes.array.isRequired,
    questionName: PropTypes.string.isRequired
}