import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';
export default class MobileUserService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'mb_user';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
            station_id: criteria.stationId,
			login: criteria.login,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	removePersonFromStation(id, stationId){
		return this.fetch(`${this.domain}/${this.path}/${id}/${stationId}`, {
			method: 'DELETE',
		}).then((res) => {
			return Promise.resolve(res);
		});  
	}
	handleVerifyUser(login){
		return this.fetch(`${this.domain}/${this.path}/verify/${login}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});  
	}
	addMobileUser(element, stationId){
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/${stationId}`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

	checkResetPasswordToken(token, tokenType) {
		// Get a token from api server using the fetch api
		return this.fetch(`${this.domain}/mobile/check-token/${token}/${tokenType}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

	verifyTokenAndSendNewPassword(token,tokenType, body) {
		const encodedValue = encodeURIComponent(token);
		return this.fetch(`${this.domain}/mobile/password/verify-reset-link?token=${encodedValue}&&tokenType=${tokenType}`, {
			method: 'POST',
			body: JSON.stringify(body)
		}).catch((err) => {
			throw err;
		});
	}

}
