import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import PropTypes from 'prop-types';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import ActionLink from '../../components/ActionLink';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import RefuseStreetService from '../../services/RefuseStreetService';
class StreetList extends BaseListContainer {
	constructor(props) {
		super(props, new RefuseStreetService());
		this.handleNewAction = this.handleNewAction.bind(this);
		this.state = {
			list: [],
			loading: true,
			size: 0,
			first: 0,
			criteria: this.getCleanSearchCriteria(),
			statusOptions: [],
		};
	}
	//override
	componentDidMount() {
		super.componentDidMount();
		this.initializeFromBackend();
	}
	renderSeparator() {
		return null
	}
	//override
	updateSearchCriteria(criteria) {
		return {
			streetName: criteria.streetName,
			cityName: criteria.cityName,
			areaId: criteria.areaId,
			firstResult: criteria.firstResult,
			maxResult: criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}
	//override
	getCleanSearchCriteria() {
		return {
			streetName: '',
			cityName: '',
			areaId: this.props.areaId,
			firstResult: 0,
			maxResult: 10,
			sortField: 'cityName|streetName',
			sortAsc: true,
		};
	}
	//override
	initializeFromBackend() { }
	//ocerride
	getCriteriaName() {
		return 'refuse-street-list-sc';
	}
	//override
	getContainerListName() {
		return 'refuse-street-list-container';
	}
	//override
	handleGoToDetail(rowDataId, e) {
		console.log(this.props.handleDetail);
		console.log(rowDataId, 'rowDataId');
		console.log(this.props.handleDetail, 'handle detail')
		if (this.props.handleDetail) {
			e.preventDefault();
			//this.blockUi();
			const { criteria } = this.state;
			this.saveCookie(this.getCriteriaName(), JSON.stringify(criteria));
			// const indexSlash = href.indexOf('/');
			// console.log(indexSlash, 'indexSlash');

			// let idStr = href.substring(indexSlash + 1, href.length);
			this.props.handleDetail(rowDataId);
		} else {
			const href = `${this.props.detailUrl}/${rowDataId}`;
			super.handleGoToDetail(href, e);
		}
	}
componentDidUpdate(prevProps, prevState){
	if(prevProps.refreshFlag != this.props.refreshFlag){
		this.refreshTable()
	}
}
	renderCriteria() {
		return (
			<div>
				<div className="row">
					<CriteriaTextComponent
						id="street-name-sc"
						name="streetName"
						label={'Nazwa ulicy'}
						colClass='col-lg-4 col-sm-6'
						value={this.state.criteria.streetName}
						onChange={this.handleChangeSc}
						validator={this.validator}
					/>
					<CriteriaTextComponent
						id="city-name-sc"
						name="cityName"
						label={'Nazwa miejscowości'}
						colClass='col-lg-4 col-sm-6'
						value={this.state.criteria.cityName}
						onChange={this.handleChangeSc}
						validator={this.validator}
					/>

				</div>
			</div>
		);
	}

	prepareColumns() {
		return [
			{ field: 'streetName', header: 'Nazwa ulicy', sortable: true },
			{ field: 'cityName', header: 'Nazwa miejscowości', sortable: true },
		];
	}
	
	handleNewAction(event) {
		if (this.props.handleNew) {
			this.props.handleNew(event);
		} 
	}

	render() {
		return this.renderView()
	}
}

StreetList.defaultProps = {

};
StreetList.propTypes = {
	areaId: PropTypes.number.isRequired,
	detailUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default StreetList;
