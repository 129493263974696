import PropTypes from 'prop-types';
import React from 'react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import SunEditor from "suneditor-react";

//https://github.com/mkhstar/suneditor-react/blob/master/README.md
export const StaticContentEditor = (props) => {
    const buttonsDefinition = [
        ["undo", "redo"],
        ["font", "fontSize", "formatBlock"],
        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
        ["removeFormat"],
        ["fontColor", "hiliteColor"],
        ["outdent", "indent"],
        ["align", "horizontalRule", "list", "table"],
        ["link", "image"],
        ["fullScreen", "showBlocks", "codeView"],
        ["preview", "print"],
    ];
    const {
        id,
        label,
        showLabel,
        name,
        onAfterStateChange,
        onChange,
        stateField,
        colClass,
        height,
        disable,
        value,
        viewMode
    } = props;
    return (
        <React.Fragment>
            <div className={colClass}>
                <div className={'row'}>
                    <div className={`col-md-12 ${showLabel ? 'form-group' : ''}`}>
                        {value ?
                            <React.Fragment>
                                {viewMode === 'VIEW' ?
                                    <React.Fragment>
                                        {label !== undefined && showLabel ? (
                                            <label
                                                id={`${id}-label-id`}
                                                className="p-label"
                                                htmlFor={id}
                                                style={{width: '100%'}}
                                            >
                                                {label}
                                            </label>
                                        ) : null}
                                        <div className="post__content" dangerouslySetInnerHTML={{__html: value}}/>
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <label
                                            id={`${id}-label-id`}
                                            className="p-label"
                                            htmlFor={id}
                                            style={{width: '100%'}}
                                        >
                                            {label}
                                        </label>
                                        <SunEditor
                                            id={id}
                                            name={name}
                                            lang="pl"
                                            onChange={e => {
                                                let event = {
                                                    name: name,
                                                    value: e
                                                }
                                                return onChange ? onChange('STATIC_CONTENT_EDITOR', undefined, event, onAfterStateChange, stateField) : null
                                            }}
                                            setOptions={{
                                                height: 200,
                                                buttonList: buttonsDefinition
                                            }}
                                            setContents={value}
                                            height={height}
                                            disable={disable}/>
                                    </React.Fragment>
                                }
                            </React.Fragment> : null}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

StaticContentEditor.defaultProps = {
    colClass: 'col-xl-12 col-lg-12 col-md-12 col-sm-12',
    value: null,
    stateField: 'element',
    height: '500px',
    disable: false,
    viewMode: 'VIEW',//EDIT,NEW
    showLabel: false,
};

StaticContentEditor.propTypes = {
    colClass: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    viewMode: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onAfterStateChange: PropTypes.func,
    stateField: PropTypes.string,
    height: PropTypes.string,
    disable: PropTypes.bool,
    label: PropTypes.string.isRequired,
    showLabel: PropTypes.bool,
};

export default StaticContentEditor;
