import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import PropTypes from 'prop-types';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';

import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import IncidentService from '../../services/IncidentService';
import CategoryService from '../../services/CategoryService';

class IncidentReplyContainer extends BaseDialogContainer {
    constructor(props) {
        super(props, new IncidentService());
        console.log(props)
        this.categoryService = new CategoryService();
        this.state = {
            elementId: props.id,
            element: {
                message: '',
                ids: this.props.ids,
                status: undefined,
                cause: '',
                category: undefined,
                subcategory: undefined,
            },
            subcategoriesOpt: [],
            categoryOpt: []
        }
    }
    initBeforeSetElement() {
        if (this.props.type === 'STATUS') {
            this.enumService
                .getEnumList('IncidentStatus')
                .then((statusOptions) => {
                    statusOptions.forEach(element => {
                        if (element.enumValue === 'APPROVED') {
                            statusOptions.splice(statusOptions.indexOf(element), 1);
                        }
                    });
                    this.setState({
                        statusOptions,
                    })}
                ).catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy statusów');
                });
        }
        if (this.props.type === 'ASSIGN') {
            this.categoryService
                .getCategoryUsers(this.props.categoryId ,this.props.subcategoryId)
                .then((userOptions) => this.setState({ userOptions }))
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy użytkowników');
                });
        }
        if(this.props.type === 'CATEGORY'){
            this.categoryService.getCategories('ISSUE')
			.then(categoryOpt => this.setState({ categoryOpt }))
			.catch(err => {
				console.log(err);
				this.showErrorMessage("Nie można pobrać kategorii");
			})
        }
    }
    renderHeader() {

    }
    getContainerListName() {
        return 'incident-list-container';
    }
    getAddSucces() {
        return 'Wiadomość została wysłana';
    }

    handleValidForm(e) {
        if (this.props.type === 'REPLY') {
            this.service.sendReply({ message: this.state.element.message, ids: this.state.element.ids })
                .then(() => {
                    this.persistMessage('success', '', "Wiadomość została wysłana")
                    // this.props.onHide()
                    this.props.afterSave()
                })
                .catch(err => {
                    this.handleError(err)
                })
        } else if (this.props.type === 'STATUS') {
            this.service.changeStatus({ status: this.state.element.status, cause: this.state.element.cause, ids: this.state.element.ids })
                .then(() => {
                    this.persistMessage('success', '', "Zmieniono status")
                    // this.props.onHide()
                    this.props.afterSave()

                })
                .catch(err => {
                    this.handleError(err)
                })
        } else if (this.props.type === 'ASSIGN') {
            //    todo crazy case
            this.service.assignUser({ids: this.state.element.ids, userId: this.state.element?.assignee?.id})
            .then(() => {
                this.persistMessage('success', '', "Przypisano użytkownika")
                // this.props.onHide()
                this.props.afterSave()
            })
            .catch(err => {
                this.handleError(err)
            })

        } else if (this.props.type === 'APPROVE') {
            this.service.approve({ ids: this.state.element.ids })
                .then(() => {
                    this.persistMessage('success', '', "Zatwierdzono")
                    // this.props.onHide()
                    this.props.afterSave()
                })
                .catch(err => {
                    this.handleError(err)
                })
            } else if (this.props.type === 'CATEGORY') {
                this.service.changeCategory({ ids: this.state.element.ids, category: this.state.element.category, subcategory: this.state.element.subcategory })
                .then(() => {
                    this.persistMessage('success', '', "Kategoria została zmieniona")
                    // this.props.onHide()
                    this.props.afterSave()
                })
                .catch(err => {
                    this.handleError(err)
                })
            }
    }
    handleError(err) {
        if (this.props.onError) {
            this.persistMessage('error', '', err.message);
            this.props.onError()
        } else {
            this.showErrorMessage(err.message)
        }
    }
    fetchSubcategories() {
		if (this.state.element?.category?.id) {
			this.service
				.getSubcategories(this.state?.element?.category?.id)
				.then(subcategories => {
					this.setState({ subcategoriesOpt: subcategories })
				})
				.catch(err => {
					this.setState({ subcategoriesOpt: [] })
					this.showErrorMessage(err.message);
				});
		} else {
			this.setState({ subcategoriesOpt: [] })
		}
	}
    renderDetails() {
        return (
            <React.Fragment>
                {this.props?.type === 'REPLY' ?
                    <InputTextareaComponent
                        id='message'
                        name='message'
                        label={'Wiadomość'}
                        colClass='col-12'
                        value={this.state.element.message}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'required|max:200'}
                        validateViewMode
                        autoResize
                        rows={10}
                    /> : null}
                {this.props?.type === 'STATUS' ?
                    <div className="col-12">
                        <InputDropdownComponent
                            colClass='col-12'
                            label="Status"
                            id="status"
                            name="status"
                            value={this.state.element.status}
                            validator={this.validator}
                            validators="required"
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode === "NEW" ? this.props.viewMode : []}
                            options={this.state.statusOptions}
                            optionLabel="label"
                        />
                        <InputTextareaComponent
                            id='cause'
                            name='cause'
                            label={'Powód'}
                            colClass='col-12'
                            value={this.state.element.cause}
                            validator={this.validator}
                            onChange={this.handleChange}
                            viewMode={this.props.viewMode}
                            validators={'required|max:200'}
                            validateViewMode
                            autoResize
                            rendered={this.state.element?.status?.enumValue === 'REJECTED'}
                        />
                    </div>
                    : null}
                {this.props?.type === 'ASSIGN' ?
                    <InputDropdownComponent
                        colClass='col-12'
                        label="Osoba przypisana"
                        id="assignee"
                        name="assignee"
                        value={this.state.element.assignee}
                        validator={this.validator}
                        validators="required"
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode === "NEW" ? this.props.viewMode : []}
                        options={this.state.userOptions}
                        optionLabel="fullName"
                    /> : null}
                {this.props?.type === 'APPROVE' ?
                    <label>Czy na pewno zatwierdzić zgłoszenia?</label> : null}
                    {this.props?.type === 'CATEGORY' ?
                     <div className='row'>
					<InputDropdownComponent

						id='category'
						name='category'
						label={'Kategoria'}
						colClass='col-12'
						value={this.state.element.category}
						validator={this.validator}
						options={this.state.categoryOpt}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						optionLabel='name'
						dataKey='id'
						validators='required'
						valueView={this.state.element.category ? this.state.element.category.name : ''}
						onAfterStateChange={e => { this.fetchSubcategories(e) }}
						validateViewMode
					/>
					<InputDropdownComponent
						id='subcategory'
						name='subcategory'
						label={'Podkategoria'}
						colClass='col-12'
						value={this.state.element.subcategory}
						validator={this.validator}
						options={this.state.subcategoriesOpt}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						optionLabel='name'
						dataKey='id'
						validators='not_required'
						valueView={this.state.element.subcategory ? this.state.element.subcategory.name : ''}
						validateViewMode
						rendered={this.state.element.subcategory || (this.state.subcategoriesOpt?.length && this.state.subcategoriesOpt?.length > 0)}
					/>
				</div> : null}
            </React.Fragment>
        );
    }

}

IncidentReplyContainer.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
    visible: false,
    stationId: undefined,
    categoryId: undefined,
    subcategoryId: undefined
};

IncidentReplyContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    onError: PropTypes.func.isRequired,
    ids: PropTypes.array,
    type: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    categoryId: PropTypes.number,
    subcategoryId: PropTypes.number
};

export default IncidentReplyContainer;