import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class SurveyService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'survey-manage';
	}

	getCriteria(criteria) {
		let statuses = [];
		if (criteria.status !== null && criteria.status && criteria.status.length) {
			criteria.status.forEach(element => {
				statuses.push(element.enumValue);
			});
		}
		return this.objToQueryString({
			official_name: criteria.officialName,
			working_name: criteria.workingName,
			status: statuses ? statuses : undefined,
			active_from: criteria.activeFromSc ?  moment(criteria.activeFromSc).format(Constants.DATE_TIME_FORMAT) : undefined,
			active_to: criteria.activeToSc ?  moment(criteria.activeToSc).format(Constants.DATE_TIME_FORMAT) : undefined,
			category_id: criteria.category?.id,
			subcategory_id: criteria.subcategory?.id,
			draft_only: criteria.draftOnly,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	
	getSubcategories(categoryId) {
		return this.fetch(`${this.domain}/${this.path}/sub/${categoryId}`, {
			method: 'GET',
		})
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((err) => {
				throw err;
			});
	}
	publish(element) {
		return this.fetch(`${this.domain}/${this.path}/publish`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
	}
	publishDraft(element){
		return this.fetch(`${this.domain}/${this.path}/publishDraft/${element.id}`, {
			method: 'PUT',
			body: JSON.stringify(element),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	finishSurvey(id){
		return this.fetch(`${this.domain}/${this.path}/finish/${id}`, {
			method: 'PUT',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	changeStatus(element) {
		let newStatus = element?.status?.enumValue;
		const date = new Date;
		if (newStatus !== 'INACTIVE') {
			newStatus = 'INACTIVE';
		} else if (element?.activeFrom && element?.activeTo && newStatus === 'INACTIVE' && date >= element?.activeFrom && date <= element?.activeTo) {
			newStatus = 'PUBLISHED';
		} else {
			newStatus = 'ACTIVE';
		}
        return this.fetch(`${this.domain}/${this.path}/${element.id}/status/${newStatus}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });

	}
}
