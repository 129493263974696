import BaseServiceCrud from './BaseServiceCrud';

export default class RefuseTypeService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'refuse-type';
	}

	getCriteria(criteria) {
		return this.objToQueryString({			
			id: criteria.id,
			name: criteria.name,
			nameEng: criteria.nameEng,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	getActiveTypes(){
		return this.fetch(`${this.domain}/${this.path}/all-active`, {
            method: 'GET',
        })
            .then((res) => {
                return Promise.resolve(res);
            })
            .catch((err) => {
                throw err;
            });
	}
	
}
