import BaseService from './BaseService';

export default class TestSurveyService extends BaseService {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'test-survey-vote';
    }

    answer(count, surveyId) {
        const requestStr = this.objToQueryString({
            count: count,
            survey_id: surveyId,
        });
        return this.fetch(`${this.domain}/${this.path}/answer${requestStr}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    generateSomeMobileUsers(userCount) {
        const requestStr = this.objToQueryString({
            userCount: userCount,
        });
        return this.fetch(`${this.domain}/${this.path}/add-users${requestStr}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}