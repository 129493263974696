import BaseServiceCrud from './BaseServiceCrud';

export default class EmailService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'contact';
	}
	sendEmail(element) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
	getAdministrators() {
        return this.fetch(`${this.domain}/${this.path}/administrators`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

}
