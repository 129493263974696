import BaseServiceCrud from './BaseServiceCrud';

export default class RefuseStreetService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'refuse-street';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			city_name: criteria.cityName,
			street_name: criteria.streetName,
			area_id: criteria.areaId,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	getCitiesByName(filter) {
		const queryString = this.objToQueryString({ filter });
		return this.fetch(`${this.domain}/${this.path}/city-list${queryString}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	getStreetsByCityCodeAndName(cityCode, filter) {
		const queryString = this.objToQueryString({ filter, city_code: cityCode });
		return this.fetch(`${this.domain}/${this.path}/street-list${queryString}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

	deleteFromArea(streetToDeleteId){
		return this.fetch(`${this.domain}/${this.path}/delete-from-area-street/${streetToDeleteId}`, {
			method: 'DELETE',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}


}
