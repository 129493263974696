import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class EventService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'event';
	}

	getCriteria = (criteria) => {
		return this.objToQueryString({
			title: criteria.title,
			status: criteria.status?.enumValue,
			category_ids: criteria.categories?.map(i =>i.id),
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}

	switchActivityStatus = (eventModel) => {
		return this.fetch(`${this.domain}/${this.path}/switch-activity`, {
			method: 'PUT',
			body: JSON.stringify(eventModel),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	calculateEventCoordinates(address) {
		let query = this.objToQueryString({
			street: address.street,
			streetNumber: address.streetNumber,
			postCode: address.postCode,
			city: address.city,
			voivodeship: address.voivodeship?.label,
		});
		return this.fetch(`${this.domain}/${this.path}/coordinates${query}`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

}
