import BaseServiceCrud from './BaseServiceCrud';

export default class NewsConfigurationService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'news-configuration';
	}

	getCriteria(criteria) {
		return this.objToQueryString({			
			id: criteria.id,
			name: criteria.name,
			nameEng: criteria.nameEng,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	
}
