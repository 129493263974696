import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import RefuseTimetableService from '../../services/RefuseTimetableService';
import RefuseAreaService from '../../services/RefuseAreaService';
import RefuseTypeService from '../../services/RefuseTypeService';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import YesNoDialog from "../../components/YesNoDialog";
import InputNumberComponent from "../../components/inputs/InputNumberComponent";
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import CalendarContainer from './CalendarContainer';
import moment from 'moment';
class RefuseTimetableContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new RefuseTimetableService());
		this.refuseAreaService = new RefuseAreaService();
		this.refuseTypeService = new RefuseTypeService();
		this.isHalfWidth = true;
		this.editableListRef = React.createRef();
		this.handleSelectDate = this.handleSelectDate.bind(this);
		this.currentInitMonth = this.currentInitMonth.bind(this);
		this.onChangeStatus = this.onChangeStatus.bind(this);
		this.showChangeStatusConfirmation = this.showChangeStatusConfirmation.bind(this);
		this.hideChangeStatusConfirmation = this.hideChangeStatusConfirmation.bind(this);
		this.checkedDates = [];
		this.uncheckedDates = [];
		this.state = {
			events: [],
			loading: true,
			elementId: props.id,
			refuseAreaOpt: [],
			buildingTypeOpt: [],
			refuseTypesOpt: [],
			currentMonthEvents: [],
			element: {
				lp: undefined,
				refuseArea: undefined,
				buildType: undefined,
				refuseTypes: [],
				status: undefined,

			},

		};
	}

	getContainerListName() {
		return 'refuse-timetable-list-container';
	}

	getAddSucces() {
		return 'Harmonogram został utworzony';
	}

	getUpdateSucces() {
		return 'Harmonogram został zaktualizowany';
	}

	getRemoveSucces() {
		return 'Typ odpadów został usunięty';
	}
	updateElementBeforeCreateOrUpdate(element) {
		element.checkedDates = this.checkedDates;
		element.uncheckedDates = this.uncheckedDates;
		return element;
	}
	showChangeStatusConfirmation() {
		const status = this.state.element?.status?.enumValue;

		if (status && status === 'INACTIVE' && !this.validator.allValid()
		) {	
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.scrollToError = true;
			this.forceUpdate();
			return;
		}else{
		this.setState({ showChangeStatusDialog: true });
		}
	}

	hideChangeStatusConfirmation(callback) {
		this.setState({ showChangeStatusDialog: false }, () => {if(callback) {callback()}this.unblockUi()});
	}

	onChangeStatus(type, costam, target) {
		const status = this.state.element?.status?.enumValue;

		if (target.value) {
			this.blockUi();
			this.service
				.changeStatus(this.state.elementId)
				.then(() => {
					this.persistMessage('success', '', `Harmonogram został ${status && status === 'ACTIVE' ? 'zdezaktywowany' : 'aktywowany'}`);
					window.location.href = this.props.backUrl;
				})
				.catch((err) => {
					this.showErrorMessage(err.message);
					this.hideChangeStatusConfirmation();
				});
		} else {
			this.hideChangeStatusConfirmation();
		}
	}
	initAfterSetElement() {
		if (this.props.viewMode === 'EDIT' || this.props.viewMode === 'NEW') {
			const modifiedList = this.state.refuseAreaOpt;
			if (this.state.element.refuseArea) {
				if (!this.state.refuseAreaOpt.find(uo => uo.id === this.state.element.refuseArea.id)) {
					modifiedList.push(this.state.element.refuseArea);
				}
			}
			const modifiedRefuses = this.state.refuseTypesOpt;
			if(this.state.element.refuseTypes){
				this.state.element.refuseTypes.forEach(rt =>{
					if (!this.state.refuseTypesOpt.find(uo => uo.id === rt.id)) {
						modifiedRefuses.push(rt);
					}
				})
			}
			this.setState({ refuseAreaOpt: modifiedList, refuseTypesOpt: modifiedRefuses }, () => super.initAfterSetElement())
		}
		else { super.initAfterSetElement() }
	}
	updateElement(data) {
		this.setState(
			{
				element: {
					id: data.id,
					lp: data.lp,
					refuseArea: data.refuseArea,
					buildType: data.buildType,
					refuseTypes: data.refuseTypes,
					status: data.status,
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}
	initBeforeSetElement() {
		this.enumService
			.getEnumList('BuildType')
			.then((buildingTypeOpt) =>
				this.setState({
					buildingTypeOpt,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy typów zabudowy');
			});
		this.refuseAreaService
			.getActiveAreas()
			.then(refuseAreaOpt => this.setState({
				refuseAreaOpt,
			})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy obszarów');
			});
		this.refuseTypeService
			.getActiveTypes()
			.then(refuseTypesOpt => this.setState({
				refuseTypesOpt,
			})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy typów odpadów');
			});

	}
	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode } = this.props;
		const { elementId } = this.state;
		const status = this.state.element?.status?.enumValue;
		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${elementId}`;
		}
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
				label: `${status && status === 'ACTIVE' ? 'Dezaktywuj' : 'Aktywuj'}`,
				className: 'float-right',
				onClick: this.showChangeStatusConfirmation,
				rendered:
					viewMode === 'VIEW'
			},
			{
				label: 'Zapisz',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: this.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_REFUSE_TIMETABLE_MANAGE') && (viewMode === 'NEW' || viewMode === 'EDIT'),
			},
		];
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowy harmonogram' : viewMode === 'EDIT' ? 'Edycja harmonogramu' : 'Szczegóły harmonogramu',
				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE', 'ROLE_REFUSE_TIMETABLE_MANAGE')),
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}

	render() {
		const status = this.state.element?.status?.enumValue;
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/refuse-timetable-list' className='p-link'>
					{'Harmonogramy'}
				</BreadcrumbsItem>
				<YesNoDialog
					onChange={this.onChangeStatus}
					visible={this.state.showChangeStatusDialog}
					header={`${status && status === 'ACTIVE' ? 'Dezaktywacja' : 'Aktywacja'} harmonogramu`}
					name="visible"
					onHide={() => {
						this.setState({ showChangeStatusDialog: false });
					}}>
					{`Czy na pewno chcesz ${status && status === 'ACTIVE' ? 'zdezaktywować' : 'aktywować'} harmonogram?`}
				</YesNoDialog>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/refuse-timetable/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/refuse-timetable/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/refuse-timetable/create'>{'Nowy'}</BreadcrumbsItem> : null}
				{this.renderView()}
			</div>
		);
	}

	currentInitMonth(item, callback) {
		const notUnChecked = item.filter(i => {
			console.log(i, 'item from month')
			return this.uncheckedDates.indexOf(i.start) == -1
		})
		let events = [...notUnChecked, ...this.checkedDates];
		console.log(events, 'events after concat')
		this.setState(
			{
				currentMonthEvents: item,
				events,
			},
			() => {
				console.log('zaznaczone dni z bazki ', this.state.currentMonthEvents)
				console.log('dni do pokolorowania: ', this.state.events)
				console.log('zaznaczone dni wszystkie: ', this.checkedDates);
				console.log('odznaczone dni wszystkie', this.uncheckedDates)
				if (callback) {
					callback()
				}
			}
		);
	}
	organizeLists(date) {
		return new Promise(resolve => {
			date = moment(date)
			if (date.isValid() && moment.isMoment(date)) {
				date = date.format('YYYY-MM-DD');
				const indexInCheckedList = this.checkedDates.findIndex(item => item.start === date);
				const indexInUnCheckedList = this.uncheckedDates.indexOf(date);
				const indexInSaved = this.state.currentMonthEvents.findIndex(item => item.start === date);

				// 1 jest zapisane w bazie i jeszcze nie było zmieniane:
				if (indexInSaved !== -1 && indexInCheckedList === -1 && indexInUnCheckedList === -1) {
					console.log('case 1');
					this.uncheckedDates.push(date);
					// 2 jest zapisane w bazie i jest w zaznaczonych (teoretyczny chyba niemożliwy przypadek)

				} else if (indexInSaved !== -1 && indexInCheckedList !== -1 && indexInUnCheckedList === -1) {
					console.log('case 2');
					this.checkedDates.splice(indexInCheckedList, 1)
					this.uncheckedDates.push(date)
					// 3 jest zapisane w bazie i jest w odznaczonych (czyli trzeba zaznaczyć ponownie :) )

				} else if (indexInSaved !== -1 && indexInCheckedList === -1 && indexInUnCheckedList !== -1) {
					console.log('case 3');

					this.uncheckedDates.splice(indexInUnCheckedList, 1);
					//	this.checkedDates.push({ start: date });
					// 4 nie jest zapisane w bazie i jest w zaznaczonych (czyli trzeba odznaczyć)

				} else if (indexInSaved === -1 && indexInCheckedList !== -1 && indexInUnCheckedList === -1) {
					console.log('case 4');
					this.uncheckedDates.push(date);
					this.checkedDates.splice(indexInCheckedList, 1)
					// 5 nie jest zapisane w bazie i jest w odznaczonych (czyli trzeba zaznaczyć)
				} else if (indexInSaved === -1 && indexInCheckedList === -1 && indexInUnCheckedList !== -1) {
					console.log('case 5')
					this.uncheckedDates.splice(indexInUnCheckedList, 1);
					this.checkedDates.push({ start: date });
					// 6 nie jest zapisane w bazie ani w zaznaczonych, ani w odznaczonych (trzeba zaznaczyć)

				} else if (indexInSaved === -1 && indexInCheckedList === -1 && indexInUnCheckedList === -1) {
					console.log('case 6')
					this.checkedDates.push({ start: date });
				}
				console.log(this.state.currentMonthEvents, 'dni z bazki po zaznaczeniu (nie powinny się zmienic)')
				console.log(this.checkedDates, 'zaznacozne dni')
				console.log(this.uncheckedDates, 'odznaczone dni')
			}
			return resolve()
		})
	}
	handleSelectDate(date) {
		this.organizeLists(date).then(res => {
			let notUnChecked = this.state.currentMonthEvents.filter(i => {
				return this.uncheckedDates.indexOf(i.start) === -1
			})
			if (!notUnChecked) {
				notUnChecked = [];
			}
			let events = [...notUnChecked, ...this.checkedDates];
			this.setState({ events });
		})
			.catch(err => console.log(err))

	}
	handleError(msg, callback) {
		if (msg) {
			this.showErrorMessage(msg);
		}
		if (callback) {
			callback()
		}
	}
	renderDetails() {
		return (
			<React.Fragment>
				<div class='row'>
					<InputNumberComponent
						id="lp"
						name="lp"
						label="Lp."
						colClass='col-md-6'
						value={this.state.element.lp}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'required|naturalNumber|greaterEquals:1'}
						min={0}
						validateViewMode
					/>
					<InputDropdownComponent
						id='refuseArea'
						name='refuseArea'
						label={'Obszar'}
						colClass='col-md-12'
						value={this.state.element.refuseArea}
						validator={this.validator}
						options={this.state.refuseAreaOpt}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						optionLabel='name'
						dataKey='id'
						valueView={this.state.element.refuseArea ? this.state.element.refuseArea.name : ''}
						validateViewMode
					/>
					<InputDropdownComponent
						id='buildType'
						name='buildType'
						label={'Typ zabudowy'}
						colClass='col-md-12'
						value={this.state.element.buildType}
						validator={this.validator}
						options={this.state.buildingTypeOpt}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						optionLabel='label'
						dataKey='enumValue'
						valueView={this.state.element.buildType ? this.state.element.buildType.label : ''}
						validateViewMode
					/>
					<InputMultiSelectComponent
						id="refuseTypes"
						name="refuseTypes"
						colClass='col-md-12'
						label="Odpady"
						value={this.state.element.refuseTypes}
						validator={this.validator}
						validators="array_required"
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						options={this.props.viewMode === 'VIEW' ? [] : this.state.refuseTypesOpt}
						dataKey="id"
						optionLabel="name"
						filter
						validateViewMode
					/>
					<CalendarContainer params={{ param: 'NEW' }} viewMode={this.props.viewMode} days={this.state.events} handleSelectDate={this.handleSelectDate} handleEventClick={this.handleSelectDate}
						timetableId={this.props.id} currentInitMonth={this.currentInitMonth} handleError={this.handleError.bind(this)}
					/>
				</div>
			</React.Fragment>
		);
	}
}

RefuseTimetableContainer.defaultProps = {
	backUrl: '/#/refuse-timetable-list',
	cancelUrl: '/#/refuse-timetable/details',
	editUrl: '/#/refuse-timetable/edit',
};

RefuseTimetableContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
};

export default RefuseTimetableContainer;
