import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import InputTextEditorComponent from '../components/inputs/InputTextEditorComponent';
import InputFileUploadComponent from '../components/inputs/InputFileUploadComponent';
import ContactService from '../services/ContactService'
class ContactContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new ContactService());
		this.state = {
			loading: true,
			elementId: props.id,
			element: {
				admin: undefined,
				content: '',
				description: '',
				subject: '',
				attachments: [],
			},
			parameters: [],
		};
		this.editorRef = React.createRef();
		this.onFileCountExceeded = this.onFileCountExceeded.bind(this);
	}
	initBeforeSetElement() {
		this.service.getAdministrators()
			.then(administratorsOpt => {
				this.setState({ administratorsOpt })
			})
			.catch(err => {
				this.showErrorMessage(err.message);
			});
	}
	updateElement(data) {
		this.setState(
			{
				element: {
					id: data.id,
					content: data.content,
					description: data.description,
					subject: data.subject,
					emailType: data.emailType,
					attachments: data.attachments,
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}
	renderBackLink() {
		return null;
	}

	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode } = this.props;
		const { elementId } = this.state;
		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${elementId}`;
		}
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
				label: 'Wyślij',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: viewMode === 'NEW',
			},
		];
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: 'Wiadomość do administratora',
				type: 'LABEL',
				className: '',
			},
		];
	}
	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/contact-admin' className='p-link'>
					{'Kontakt z administratorem'}
				</BreadcrumbsItem>
				{this.renderView()}
			</div>
		);
	}
	getContainerListName() {
		return 'start-container';
	}


	renderDetails() {
		return (
			<React.Fragment>
				<div className='row'>
				<InputDropdownComponent
						id='admin'
						name='admin'
						label={'Administrator'}
						colClass='col-12'
						value={this.state.element.admin}
						validator={this.validator}
						options={this.state.administratorsOpt}
						onChange={this.handleChange}
						viewMode={'NEW'}
						optionLabel='fullName'
						dataKey='id'
						valueView={this.state.element.admin ? `${this.state.element.admin.firstName} ${this.state.element.admin.lastName}` : ''}
					/>
					<InputTextComponent
						id='subject'
						name='subject'
						label={'Temat'}
						colClass='col-12'
						value={this.state.element.subject}
						validator={this.validator}
						validators='required|max:150'
						onChange={this.handleChange}
						viewMode={'NEW'}
					/>
					<InputTextEditorComponent
						colClass='col-12'
						id='content'
						name='content'
						value={this.state.element.content}
						label='Treść wiadomości'
						placeholder='Treść wiadomości...'
						validator={this.validator}
						validators={`max:1500|required`}
						rows='10'
						onChange={this.handleChange}
						viewMode={'NEW'}
						editorRef={this.editorRef}
					/>
					<InputFileUploadComponent
						messages={this.messages}
						id='attachments'
						name='attachments'
						label={'Załączniki'}
						fileList={this.state.element.attachments}
						showLabel
						value={this.state.element.attachments}
						restApiUrl={`${this.service.getPath()}/${this.state.element.id}/attachments`}
						multiple
						maxFileCount={3}
						maxFileSize={5000000}
						onChange={this.handleChange}
						itemLabel='fileName'
						itemName='uuid'
						colClass='col-xl-12'
						viewMode={'NEW'}
						validator={this.validator}
						validators="not_required"
						style={{ width: '100%' }}
						restrictExt={true}
						handleError={this.onUploladError}
						token={this.authService.getToken()}
						validateViewMode
						onFileCountExceeded={this.onFileCountExceeded}
					/>
				</div>
			</React.Fragment>
		);
	}
	onFileCountExceeded(e) {
		this.setState({ visible: false }, () => this.showErrorMessage("Przekroczono maksymalną liczbę załączników"));
	}
	handleValidForm() {
		this.service
			.sendEmail(this.state.element)
			.then((response) => {
				this.blockUi();
				this.persistMessage('success', '', "Wiadomość została wysłana");
				window.location.href = this.props.backUrl;
			})
			.catch((err) => {
				this.showErrorMessage(err.message, 10000);
			});
	}
}

ContactContainer.defaultProps = {
	backUrl: '/#/start',
	cancelUrl: '/#/email-template/details',
	editUrl: '/#/email-template/edit',
};

ContactContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
	editUrl: PropTypes.string,
};

export default ContactContainer;
