import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import CriteriaCalendarRangeComponent from '../components/criteria/CriteriaCalendarRangeComponent';
import EventLogService from '../services/EventLogService';

class EventLogListContainer extends BaseListContainer {
	constructor(props) {
		super(props, new EventLogService());
		this.state = {
			list: [],
			loading: true,
			size: 0,
			first: 0,
			criteria: this.getCleanSearchCriteria(),
			eventTypeOptions: [],
			logTypeOptions: [],
		};
		this.startDownloading = this.startDownloading.bind(this);
	}

	updateSearchCriteria(criteria) {
		return {
			dateFrom: criteria.dateFrom,
			dateTo: criteria.dateTo,
			eventType: criteria.eventType,
			logType: criteria.logType,
			userEmail: criteria.userEmail,
			objectType: criteria.objectType,
			objectId: criteria.objectId,
			firstResult: criteria.firstResult,
			maxResult: criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}

	getCleanSearchCriteria() {
		return {
			dateFrom: undefined,
			dateTo: undefined,
			eventType: undefined,
			logType: undefined,
			dateFrom: undefined,
			dateTo: undefined,
			userEmail: '',			
			objectType: undefined,
			objectId: '',
			firstResult: 0,
			maxResult: 10,
			sortField: 'date',
			sortAsc: false,
		};
	}

	initializeFromBackend() {
		this.enumService
			.getEnumList('EventType')
			.then((eventTypeOptions) =>
				this.setState({
					eventTypeOptions,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy typów zdarzeń');
			});
		this.enumService
			.getEnumList('EventLogType')
			.then((logTypeOptions) =>
				this.setState({
					logTypeOptions,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy typów logów');
			});
			this.enumService
			.getEnumList('EventLogObjectType')
			.then((objectTypes) => 
				this.setState({objectTypes})
			)
			.catch(() => {
				this.showErrorMessage('Nie można pobrać listy typów obiektów');
			}); 
	}

	getCriteriaName() {
		return 'event-log-list-sc';
	}

	getContainerListName() {
		return 'event-log-list-container';
	}
	renderCriteria() {
		return (
			<div>
			<div className='row'>
				<CriteriaDropdownComponent
					id='eventTypeSc'
					name='eventType'
					showClear
					label={'Nazwa zdarzenia'}
					colClass='col-lg-4 col-sm-6'
					value={this.state.criteria.eventType}
					options={this.state.eventTypeOptions}
					placeholder='Wszystkie'
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaDropdownComponent
					id='logTypeSc'
					name='logType'
					showClear
					label={'Typ logu'}
					colClass='col-lg-4 col-sm-6'
					value={this.state.criteria.logType}
					options={this.state.logTypeOptions}
					placeholder='Wszystkie'
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaTextComponent id='userEmail-sc' name='userEmail' label={'Login'} colClass='col-lg-4 col-sm-6' value={this.state.criteria.userEmail} onChange={this.handleChangeSc} validator={this.validator} />
				<CriteriaCalendarRangeComponent
                    key={`time`}
                    idFrom={`dateFrom`}
                    idTo={`dateTo`}
                    nameTo={'dateTo'}
                    nameFrom={'dateFrom'}
                    label={'Data zdarzenia'}
                    labelFrom="od"
                    labelTo="do"
                    //colClass={column.colClass ? column.colClass : 'col-md-6'}
                    colClass={'col-12'}
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    validator={this.validator}
                    onChange={this.handleChangeSc}
                    viewMode={this.props.viewMode}
                    showIcon
                    onlyTime                    
                    placeholderFrom={'dd-mm-rrrr GG:MM'}
                    placeholderTo={'dd-mm-rrrr GG:MM'}
                    validateOnChange={false}
                    stateField=""
                    showTime
					hourFormat="24"
					showSeconds={false}
                />
				</div>
				<div className="row">
				<CriteriaDropdownComponent
					id='objectType-sc'
					name='objectType'
					showClear
					label={'Typ obiektu'}
					colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
					value={this.state.criteria.objectType}
					options={this.state.objectTypes}
					placeholder='Wszystkie'
					onChange={this.handleChangeSc}
					validator={this.validator}
					filter
				/>
				<CriteriaTextComponent 
					id='objectId-sc' 
					name='objectId' 
					label={'ID obiektu'} 
					colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
					value={this.state.criteria.objectId} 
					onChange={this.handleChangeSc} 
					validator={this.validator} 
					validators="numeric"
				/>				
				</div>		
			</div>
		);
	}

	prepareColumns() {
		return [
			{ field: 'id', header: 'ID', sortable: true, width: '90px' },
			{ field: 'eventType', header: 'Nazwa zdarzenia', sortable: true, body: this.enumTemplate.bind(this, 'eventType') },
			{ field: 'userEmail', header: 'Login', sortable: true },
			{ field: 'date', header: 'Data', sortable: true, body: this.dateTemplate.bind(this, 'date', 'YYYY-MM-DD HH:mm') },
			
			
			{ field: 'logType', header: 'Typ logu', sortable: true, body: this.enumTemplate.bind(this, 'logType') },
			{
				key: 'actions',
				header: 'Szczegóły',
				body: this.actionTemplate,
				className: 'table-column-action',
			},
		];
	}

	prepareHeaderItems() {
		return [
			this.prepareExportToPrintButton(true),
		]
	}
	prepareExportToPrintButton(rendered, label) {
		return {
			label: label !== undefined ? label : 'Eksportuj',
			type: 'BUTTON',
			variant: '',
			className: 'float-right mr-2',
			rendered: rendered,
			iconName: 'mdi-file-export',
			iconSide: 'left',
			iconSize: 'm',
			onClick: (e) => this.startDownloading()
		};
	}
	startDownloading() {
		this.service.startDownloading({enumValue:'csv'}, {}).then(res => {
			this.blockUi(undefined, 'Generowanie pliku rozpoczęte, proszę czekać');
			this.intervalId = setInterval(this.getReportResult.bind(this, res.uuid, 'csv'), 8000);

		})
			.catch((err) => {
				this.unblockUi(this.showErrorMessage(err.message));;
			});
	}
	getReportResult(request, format) {
		this.service
			.getReportResult(request)
			.then((data) => {
				this.unblockUi();
				this.setState({ showPrintDialog: false }, () => {
					this.service.export(this.authService.getToken(), `Zdarzenia.csv`, this.showErrorMessage, 'csv', data.uuid);
					clearInterval(this.intervalId);
				});

				;
			})
			.catch((err) => {
				// status 422 zarezerwowany dla sytuacji gdy jeszcze nie ma wyniku;
				// w pozostałych sytaucjach błąd ma spowodować przerwanie zapytywania
				if (err.errorCode && err.errorCode !== 'REPORT_UNFINISHED') {
					this.unblockUi();
					this.showErrorMessage(err.message);
					clearInterval(this.intervalId);
				}
			});
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/event-log-list'>{'Dziennik zdarzeń'}</BreadcrumbsItem>
				{this.renderView()}
			</div>
		);
	}
}

EventLogListContainer.defaultProps = {
	detailUrl: '/#/event-log/details',
};

EventLogListContainer.propTypes = {
	detailUrl: PropTypes.string,
};

export default EventLogListContainer;
