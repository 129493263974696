import Constants from "./constants";
class MessageUtils {
	static readPersistMessage(messageLabel, messagesComponent) {
		const messageFromStorage = sessionStorage.getItem(messageLabel);
		if (messageFromStorage !== undefined && messageFromStorage != null) {
			const message = JSON.parse(messageFromStorage);
			sessionStorage.removeItem(messageLabel);
			if (
				message.severity !== undefined &&
				message.severity != null &&
				message.summary !== undefined &&
				message.summary != null &&
				message.detail !== undefined &&
				message.detail != null
			) {
				messagesComponent.show({
					severity: message.severity,
					summary: message.summary,
					detail: message.detail,
					life: message.life,
				});
			} else if (message.life !== undefined && message.life != null) {
				messagesComponent.show({
					severity: message.severity,
					summary: message.summary,
					detail: message.detail,
					life: message.life,
				});
			}
		}
	}

	static multiReadPersistMessage(messagesArray, messagesComponent) {
		for (let messages in messagesArray) {
			let messageLabel = messagesArray[messages];
			this.readPersistMessage(messageLabel, messagesComponent);
		}
	}

	static persistMessage(containerListName, severity, summary, detail) {
		sessionStorage.setItem(
			containerListName,
			JSON.stringify({
				life: 30000,
				severity,
				summary,
				detail,
			})
		);
	}

	static persistMessage(containerListName, severity, summary, detail, life) {
		sessionStorage.setItem(
			containerListName,
			JSON.stringify({
				life: life == undefined ? 30000 : life,
				severity,
				summary,
				detail,
			})
		);
	}
	static showErrorMessage(errMsg, life = Constants.ERROR_MSG_LIFE, closable = true, summary = 'Błąd', messages) {
		this.showMessage('error', summary, errMsg, life, closable, errMsg, messages);
	}
	static showSuccessMessage(errMsg, life = Constants.ERROR_MSG_LIFE, closable = true, summary = 'Sukces', messages) {
		this.showMessage('success', summary, errMsg, life, closable, errMsg, messages);
	}

	static showMessage(severity, summary, detail, life = 5000, closable = true, errMsg, messages) {
		if (messages !== undefined && messages !== null) {
			messages.clear();
			messages.show({
				severity,
				summary,
				detail,
				life,
				closable,
			});
		} else {
			if (errMsg !== undefined) {
				console.log('messages === undefined', errMsg);
			} else {
				console.log('messages === undefined');
			}
		}
	}
}

export default MessageUtils;
