import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import PropTypes from 'prop-types';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaTextComponent from "../components/criteria/CriteriaTextComponent";
import ApprovalContentService from "../services/ApprovalContentService";
import CriteriaDropdownComponent from "../components/criteria/CriteriaDropdownComponent";
import Constants from "../utils/constants";

class ApprovalContentListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new ApprovalContentService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            booleanOptions: Constants.BOOLEAN_OPTIONS,
        };
    }

    updateSearchCriteria(criteria) {
        return {
            id: criteria.id,
            content: criteria.content,
            required: criteria.required,
            firstResult: criteria.firstResult,
            maxResult: criteria.maxResult,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCriteriaName() {
        return 'approval-content-list-sc';
    }

    getContainerListName() {
        return 'approval-content-list-container';
    }

    getCleanSearchCriteria() {
        return {
            content: '',
            required: null,
            id: null,
            firstResult: 0,
            maxResult: 10,
            sortField: 'order',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='content-sc'
                    name='content'
                    label={'Treść'}
                    colClass='col-md-8'
                    value={this.state.criteria.content}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
                <CriteriaDropdownComponent
                    id='required-sc'
                    name='required'
                    showClear
                    label={'Obowiązkowa'}
                    colClass='col-lg-4'
                    value={this.state.criteria.required}
                    options={this.state.booleanOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }

    prepareColumns() {
        return [
            {field: 'order', header: 'Kolejność', sortable: true, width: '9%'},
            {field: 'content', header: 'Treść', sortable: true},
            {
                field: 'required',
                header: 'Obowiązkowa',
                sortable: true,
                body: this.booleanTemplate.bind(this, 'required'),
                width: '20%'
            },
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    prepareHeaderItems() {
        return [
            {
                label: 'Dodaj zgodę',
                href: this.props.newUrl,
                type: 'BUTTON',
                className: 'float-right',
                variant: 'yellow',
                iconName: 'mdi-plus',
                iconSide: 'left',
                iconColor: 'white',
            }
        ]
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/approval-content-list'>{'Zgody'}</BreadcrumbsItem>
                {this.renderView()}
            </div>
        );
    }
}

ApprovalContentListContainer.defaultProps = {
    detailUrl: '/#/approval-content/details',
    newUrl: '/#/approval-content/create',
};

ApprovalContentListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default ApprovalContentListContainer;
