import React, { Component } from 'react';

class BaseInputComponent extends Component {

    renderValidatorsMessages(validator, id, label, value, validators) {

        if (validator !== null && validator !== undefined) {
            if (Array.isArray(validator)) {
                return (
                    <div aria-live='assertive'>
                        {validator.map(val => {
                           return val.message(id, label, value, validators);
                        })}
                    </div>);
            } else {
                return (
                    <div aria-live='assertive'>
                        {validator.message(id, label, value, validators)}
                    </div>
                );
            }
        } else {
            return null;
        }
    }
}

export default BaseInputComponent;
