/* eslint-disable react/jsx-handler-names */
import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import BaseListContainer from '../baseContainers/BaseListContainer';
import  DivContainer from '../components/DivContainer';
import PropTypes from 'prop-types';
import { OverlayPanel } from 'primereact/overlaypanel';
import ReminderService from '../services/ReminderService';
import {FaBell}  from 'react-icons/fa';
import AuthService from '../services/AuthService';

class ReminderContainer extends BaseListContainer {
	constructor(props) {
		super(props, new ReminderService());
		this.authService = new AuthService();
		this.state = {
			size: 0,
			list: [],
			criteria: this.getCleanSearchCriteria(),
			intervalId: undefined,
		};

		this.showReminders = this.showReminders.bind(this);
		this.markAsRead = this.markAsRead.bind(this);
		this.readReminders = this.readReminders.bind(this);
	}
	intervalId = undefined;
	componentDidMount() {
		super.componentDidMount();
		if (this.authService.isUserInAnyRole('ROLE_INCIDENT_VIEW', 'ROLE_INCIDENT_MANAGE','ROLE_ADMIN_SUPER_MANAGE')){
		this.readReminders();		
		this.intervalId = setInterval(this.readReminders, 60000);
		}
	}

	componentWillUnmount() {
		clearInterval(this.intervalId);
	}

	getCleanSearchCriteria() {
		return {
			maxResult: 10,
			firstResult:0,
			sortField: 'sendDate',
			sortAsc: false,
		};
	}

	render() {
		return <div>{this.renderView()}</div>;
	}

	showReminders(e) {
		this.op.toggle(e);
	}

	readReminders() {
		this.service.getList(this.getCleanSearchCriteria()).then(data => {
			if (this._isMounted) {
				this.setState({
					list: data.content,
					loading: false,
					size: data.totalElements,
				});
			}
		}).catch(() => {
			this.showErrorMessage('Wystąpił błąd systemu. Nie udało się pobrać listy.', 10000);
		})
		;
	}

	markAsRead(e, reminder) {
		e.preventDefault();
		this.service.markAsRead(reminder.id).then(() => {
			if (reminder.type && reminder.type=== 'INCIDENT' && reminder.refId ) {
				window.location.href = `#/incident/details/${reminder.refId}`;
			}
			super.componentDidMount()
		});
	}

	renderReminders() {
		let reminders;
		if (this.state.size > 0) {
			reminders = this.state.list.map(reminder => {
				return (
					<li style={{ cursor: 'pointer' }}>
						<a
							href={'javascript:;'}
							tabIndex="0"
							onClick={e => {
								this.markAsRead(e, reminder);
							}}
						>
							<b>{reminder.note}</b>
						</a>
					</li>
				);
			});
		} else {
			reminders = 'Brak przypomnień';
		}

		return <ul className="reminder-note">{reminders}</ul>;
	}

	renderView() {
		return (
			<span>
				<a
					href={'javascript:;'}
					tabIndex="0"
					className="header_menu_element"
					onClick={e => {
						e.preventDefault();
						this.showReminders(e);
					}}
				>
					{/* <i className="icon far fa-bell" />  */}
					<div className="reminder-bell">
					<FaBell/></div>Przypomnienia
					<span className="red_number">{this.state.size > 0 ? this.state.size : ""}</span>
				</a>

				<OverlayPanel ref={el => (this.op = el)} className="reminder-overlay">
					<DivContainer colClass="col-lg-12 col-md-12">
						<DivContainer colClass="row">{this.renderReminders()}</DivContainer>
					</DivContainer>
				</OverlayPanel>
			</span>
		);
	}
}

ReminderContainer.defaultProps = {
	currentUser: undefined,
};

ReminderContainer.propTypes = {
};

export default ReminderContainer;
