import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import RefuseCollectionInformationService from '../services/RefuseCollectionInformationService';
import StaticContentEditor from '../components/StaticContentEditor';
import DivContainer from '../components/DivContainer';

class RefuseCollectionInformationContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new RefuseCollectionInformationService());
		this.state = {
			loading: true,
			element: {
				id: null,
				content: null,
				engContent: null,
			},
		};
	}

	updateElement(data) {
		this.setState(
			{
				element: {
					id: data.id,
					content: data.content,
					engContent: data.engContent,
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}

	getContainerListName() {
		return 'refuse-collection-container';
	}

	getUpdateSucces() {
		return 'Informacje zostały zaktualizowane';
	}

	setElement() {
		const { viewMode } = this.props;
		if (viewMode === 'EDIT' || viewMode === 'VIEW') {
			this.blockUi();
			this.service
				.getInformation()
				.then((data) => {
					this.setState({ loading: false }, () =>
						this.updateElement(data)
					);
				})
				.catch((err) => {
					this.handleGetDetailsError(err);
				});
		} else {
			this.setState({ loading: false }, this.initAfterSetElement());
		}
	}

	prepareFooterItems() {
		const { backUrl, viewMode } = this.props;
		let goBackUrl;
		goBackUrl = backUrl;
		return [
			{
				label: 'Anuluj',
				href: goBackUrl,
				rendered: viewMode === 'NEW' || viewMode === 'EDIT',
			},
			{
				label: 'Zapisz',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: viewMode === 'NEW' || viewMode === 'EDIT',
			},
		];
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label:
					viewMode === 'NEW'
						? 'Nowe informacje'
						: viewMode === 'EDIT'
						? 'Edycja informacji'
						: 'Informacje dotyczące wywozu śmieci',
				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(
				viewMode === 'VIEW' && this.state.element.id
			),
			{
				label: 'Dodaj informacje',
				href: this.props.newUrl,
				type: 'BUTTON',
				className: 'float-right',
				variant: 'yellow',
				iconName: 'mdi-plus',
				iconSide: 'left',
				iconColor: 'white',
                rendered: viewMode === 'VIEW' && !this.state.element.id
			},
		];
	}

	renderBackLink() {
		return null;
	}

	render() {
		return (
			<div className="container-fluid">
				<BreadcrumbsItem to="/refuse-collection-information" className='p-link'> 
					{'Informacje o wywozie śmieci'}
				</BreadcrumbsItem>
				{this.props.viewMode === 'EDIT' ? (
					<BreadcrumbsItem to="/refuse-collection-information/edit">
						{'Edycja'}
					</BreadcrumbsItem>
				) : null}
				{this.props.viewMode === 'NEW' ? (
					<BreadcrumbsItem to="/refuse-collection-information/create">
						{'Nowy'}
					</BreadcrumbsItem>
				) : null}
				{this.renderView()}
			</div>
		);
	}

	renderDetails() {
        return (
			<DivContainer colClass='container-fluid'>
				{this.state.loading ? null : (
            <React.Fragment>
                <div className='row'>
                    <StaticContentEditor
                        id={'content-editor-pl'} name={'content'}
                        onChange={this.handleChange} value={this.state.element.content}
                        viewMode={this.props.viewMode} label={'Treść w języku polskim'} showLabel/>
                    <StaticContentEditor
                        id={'content-editor-eng'} name={'engContent'}
                        onChange={this.handleChange} value={this.state.element.engContent}
                        viewMode={this.props.viewMode} label={'Treść w języku angielskim'} showLabel/>
                </div>
            </React.Fragment>
				)}
			</DivContainer>
        );
	}
}

RefuseCollectionInformationContainer.defaultProps = {
	backUrl: '/#/refuse-collection-information',
	cancelUrl: '/#/refuse-collection-information',
	editUrl: '/#/refuse-collection-information/edit',
	newUrl: '/#/refuse-collection-information/create',
};

RefuseCollectionInformationContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
	editUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default RefuseCollectionInformationContainer;
