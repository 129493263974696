import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import EmailService from '../services/EmailService';

class EmailListContainer extends BaseListContainer {
	constructor(props) {
		super(props, new EmailService());
		this.state = {
			list: [],
			loading: true,
			size: 0,
			first: 0,
			criteria: this.getCleanSearchCriteria(),
			emailTypeOptions: [],
		};
	}

	updateSearchCriteria(criteria) {
		return {
			emailType: criteria.emailType,
			description: criteria.description,
			firstResult: criteria.firstResult,
			maxResult: criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}

	getCleanSearchCriteria() {
		return {
			emailType: null,
			description: '',
			firstResult: 0,
			maxResult: 10,
			sortField: 'id',
			sortAsc: true,
		};
	}

	initializeFromBackend() {
		this.enumService
			.getEnumList('EmailType')
			.then((emailTypeOptions) =>
				this.setState({
					emailTypeOptions,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy typów wiadomości');
			});
	}

	getCriteriaName() {
		return 'email-list-sc';
	}

	getContainerListName() {
		return 'email-list-container';
	}

	renderCriteria() {
		return (
			<div className='row'>
				<CriteriaDropdownComponent
					id='emailType-sc'
					name='emailType'
					showClear
					label={'Typ wiadomości'}
					colClass='col-xl-6 col-lg-6 col-md-12 col-sm-12'
					value={this.state.criteria.emailType}
					options={this.state.emailTypeOptions}
					placeholder='Wszystkie'
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaTextComponent
					id='description-sc'
					name='description'
					label={'Opis'}
					colClass='col-xl-6 col-lg-6 col-md-12 col-sm-12'
					value={this.state.criteria.description}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
			</div>
		);
	}

	prepareColumns() {
		return [
			// { field: 'id', header: 'ID', sortable: true, width: '90px' },
			{ field: 'emailType', header: 'Typ wiadomości', sortable: true, body: this.enumTemplate.bind(this, 'emailType') },
			{ field: 'description', header: 'Opis', sortable: true },
			{
				key: 'actions',
				header: 'Szczegóły',
				body: this.actionTemplate,
				className: 'table-column-action',
			},
		];
	}

	prepareHeaderItems() {
		return [];
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/email-template-list'>{'Szablony wiadomości'}</BreadcrumbsItem>
				{this.renderView()}
			</div>
		);
	}
}

EmailListContainer.defaultProps = {
	detailUrl: '/#/email-template/details',
	newUrl: '/#/email-template/create',
};

EmailListContainer.propTypes = {
	detailUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default EmailListContainer;
