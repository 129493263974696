import React from 'react';
import PropTypes from 'prop-types';
import BaseContainer from './BaseContainer';
import ActionLink from '../components/ActionLink';
import DivContainer from '../components/DivContainer';
import BlockUi from '../components/waitPanel/BlockUi';
import StaticContentService from "../services/StaticContentService";
import CustomMessages from "../components/CustomMessages";
import StaticContentEditor from "../components/StaticContentEditor";

class BaseStaticContentContainer extends BaseContainer {
    constructor(props) {
        super(props);
        this.service = new StaticContentService();
        this.renderBackLink = this.renderBackLink.bind(this);
        this.updateElement = this.updateElement.bind(this);
        this.getBackLabel = this.getBackLabel.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.blockUi();
        this.initBeforeSetElement();
        this.setElement();
    }

    setElement() {
        const {contentType} = this.props;
        const {viewMode} = this.props;
        if (contentType && (viewMode === 'EDIT' || viewMode === 'VIEW')) {
            this.blockUi();
            this.service
                .getContent(contentType)
                .then((data) => {
                    this.setState({loading: false}, () => this.updateElement(data));
                })
                .catch((err) => {
                    this.handleGetDetailsError(err);
                });
        } else {
            this.setState({loading: false}, this.initAfterSetElement());
        }
    }

    updateElement(data) {
        if (this._isMounted) {
            this.setState(
                {
                    element: data,
                },
                () => this.initAfterSetElement()
            );
        }
    }

    handleGetDetailsError(err) {
        this.showErrorMessage('Błąd podczas pobrania treści');
        if (this.props.backUrl) {
            window.location.href = this.props.backUrl;
        } else {
            this.setState({loading: false}, () => this.unblockUi());
        }
    }


    initAfterSetElement() {
        this.unblockUi();
    }

    initBeforeSetElement() {
    }

    getBackLabel() {
        return 'Wróć';
    }

    handleGoBack(e) {
        e.preventDefault();
        this.blockUi();
        const {backUrl} = this.props;
        window.location.href = backUrl;
    }

    renderBackLink() {
        return <ActionLink rendered={this.backLinkRendered} label={this.getBackLabel()} className='p-link float-right'
                           handleClick={this.handleGoBack.bind(this)} variant='blue' iconName='mdi-arrow-left'
                           iconSide='left' iconSize='xs' iconColor='blue'/>;
    }

    getBreadcrumbsItem() {
        return null;
    }

    renderSeparator() {
        return <DivContainer colClass='col-12 separator-container'>
            <DivContainer colClass='row'>
                <DivContainer colClass='separator'></DivContainer>
            </DivContainer>
        </DivContainer>
    }

    render() {
        return (
            <DivContainer colClass='container-fluid'>
                <DivContainer>
                    <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                        <CustomMessages ref={(el) => (this.messages = el)}/>
                        <div className='row'>
                            <div className='col-12'>{this.renderBackLink()}</div>
                        </div>
                        {this.getBreadcrumbsItem()}
                        {this.renderSeparator()}
                        {this.state.loading ? null : (
                            <React.Fragment>
                                <StaticContentEditor
                                    id={'content-viewer'} name={'content'}
                                    onChange={this.handleChange} value={this.state.element.content}
                                    viewMode={'VIEW'}/>
                            </React.Fragment>
                        )}
                    </BlockUi>
                </DivContainer>
            </DivContainer>
        );
    }


}

BaseStaticContentContainer.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
};

BaseStaticContentContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
};

export default BaseStaticContentContainer;
