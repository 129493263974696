import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import InputTextComponent from "../components/inputs/InputTextComponent";
import PhoneNumberContentService from "../services/PhoneNumberContentService";
import YesNoDialog from "../components/YesNoDialog";
import InputNumberComponent from '../components/inputs/InputNumberComponent';
import InputMultiTextComponent from '../components/inputs/InputMultiTextComponent';

class PhoneNumberContentContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new PhoneNumberContentService());
        this.isHalfWidth = true;
        this.state = {
            loading: true,
            elementId: props.id,
            element: {
                id: null,
                name: '',
                engName: '',
                phoneNumbersList: [''],
                phoneNumberOrder: undefined,
            }
        };
    }

    updateElement(data) {
        this.setState(
            {
                element: {
                    id: data.id,
                    name: data.name,
                    engName: data.engName,
                    phoneNumbersList: data.phoneNumbersList,
                    phoneNumberOrder: data.phoneNumberOrder,
                    options: data.options,
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    updateElementBeforeCreateOrUpdate(element) {
        element.required = element.required?.boolValue;
        return element;
    }

    getContainerListName() {
        return 'phone-number-content-list-container';
    }

    getAddSucces() {
        return 'Numery telefonów zostały utworzone';
    }

    getUpdateSucces() {
        return 'Numery telefonów zostały zaktualizowane';
    }

    getRemoveSucces() {
        return 'Numery telefonów zostały usunięte';
    }

    initBeforeSetElement() {
    }

    prepareFooterItems() {
        const { backUrl, cancelUrl, viewMode } = this.props;
        const { elementId } = this.state;
        let goBackUrl;
        if (viewMode === 'NEW') {
            goBackUrl = backUrl;
        } else {
            goBackUrl = `${cancelUrl}/${elementId}`;
        }
        return [
            { label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
            {
                label: 'Usuń',
                className: 'float-right',
                onClick: this.showRemoveConfirmation,
                rendered:
                    viewMode === 'VIEW'
            },
            {
                label: 'Zapisz',
                className: 'float-right',
                onClick: this.handleFormSubmit,
                rendered: viewMode === 'NEW' || viewMode === 'EDIT',
            },
        ];
    }

    prepareHeaderItems() {
        const { viewMode } = this.props;
        return [
            {
                label: viewMode === 'NEW' ? 'Nowy numer telefonu' : viewMode === 'EDIT' ? 'Edycja numerów telefonów' : 'Szczegóły numerów telefonów',
                type: 'LABEL',
                className: '',
            },
            this.prepareEditButton(viewMode === 'VIEW'),
        ];
    }

    getBackLabel() {
        return 'Wróć do listy';
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/phone-number-content-list' className='p-link'>
                    {'Numery telefonów'}
                </BreadcrumbsItem>
                <YesNoDialog
                    onChange={this.onRemoveAction}
                    visible={this.state.showConfirmRemoveDialog}
                    header="Usuwanie numerów telefonów"
                    name="visible"
                    onHide={() => {
                        this.setState({ showConfirmRemoveDialog: false });
                    }}>
                    Czy na pewno chcesz usunąć wybrany rekord z numerami telefonów?
                </YesNoDialog>
                {this.props.viewMode === 'VIEW' ?
                    <BreadcrumbsItem to='/phone-number-content/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
                {this.props.viewMode === 'EDIT' ?
                    <BreadcrumbsItem to='/phone-number-content/edit'>{'Edycja'}</BreadcrumbsItem> : null}
                {this.props.viewMode === 'NEW' ?
                    <BreadcrumbsItem to='/phone-number-content/create'>{'Nowy'}</BreadcrumbsItem> : null}
                {this.renderView()}
            </div>
        );
    }

    renderDetails() {
        return (
            <React.Fragment>
                <div className='row'>
                    <InputTextComponent
                        id='name'
                        name='name'
                        label={'Nazwa w języku polskim'}
                        colClass='col-md-12'
                        value={this.state.element.name}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'required|max:100'}
                    />
                    <InputTextComponent
                        id='eng-name'
                        name='engName'
                        label={'Nazwa w języku angielskim'}
                        colClass='col-md-12'
                        value={this.state.element.engName}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'max:100'}
                    />
                    <InputMultiTextComponent
                        id='phoneNumbersList'
                        name='phoneNumbersList'
                        label={'Numery telefonów'}
                        colClass='col-md-12'
                        value={this.state.element.phoneNumbersList}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'required|phone'}
                        placeholder='Wpisz numer telefonu'
                        addLabel='Dodaj nowy numer'
                        minElements={1}
                        maxElements={5}
                        handleAdd={() => {
                            if (this._isMounted) {
                                const numbers = this.state.element.phoneNumbersList ? this.state.element.phoneNumbersList : [];
                                numbers.push("");
                                this.setState(
                                    (prevState) => ({
                                        element: {
                                            ...prevState.element,
                                            phoneNumbersList: numbers,
                                        },
                                    }),
                                    () => console.log(this.state.element.phoneNumbersList)
                                );
                            }
                        }
                        }
                        handleRemove={(i) => {
                            if (this._isMounted) {
                                const numbers = this.state.element.phoneNumbersList;
                                numbers.splice(i, 1);
                                this.setState((prevState) => ({
                                    element: {
                                        ...prevState.element,
                                        phoneNumbersList: numbers,
                                    },
                                }));
                            }
                        }}
                    />

                    <InputNumberComponent
                        id='phoneNumberOrder'
                        name='phoneNumberOrder'
                        label={'Kolejność'}
                        colClass='col-md-6'
                        min={0}
                        value={this.state.element.phoneNumberOrder}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'naturalNumber|required'}
                    />
                </div>
            </React.Fragment>
        );
    }
}

PhoneNumberContentContainer.defaultProps = {
    backUrl: '/#/phone-number-content-list',
    cancelUrl: '/#/phone-number-content/details',
    editUrl: '/#/phone-number-content/edit',
};

PhoneNumberContentContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default PhoneNumberContentContainer;
