import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InternalUserService from '../../services/InternalUserService';
import CategoryService from '../../services/CategoryService';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import InputCalendarComponent from '../../components/inputs/InputCalendarComponent';
import moment from 'moment';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import InputFileUploadComponent from '../../components/inputs/InputFileUploadComponent';
import PublicUnitService from '../../services/PublicUnitService';
import { MapLeaflet } from '../../components/business/MapLeaflet';
import IncidentService from '../../services/IncidentService';
import ActionLink from '../../components/ActionLink';
import MessageList from '../messages/MessageList';
import { Galleria } from 'primereact/galleria';
import ReminderContainer from './ReminderContainer';
import YesNoDialog from '../../components/YesNoDialog';
class IncidentContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new IncidentService(props.id));
		this.categoryId = props.id;
		this.subcategoryId = props.subId;
		this.categoryService = new CategoryService();
		this.internalUserService = new InternalUserService();

		this.state = {
			loading: true,
			elementId: this.props.id,
			showPublishDialog: false,
			showMap: false,
			showConwersation: false,
			images: [],
			reminderDialogVisibile: false,
			element: {
				category: undefined,
				subcategory: undefined,
				//publicUnit: undefined,
				description: '',
				address: '',
				longitude: undefined,
				latitude: undefined,
				followersCount: 0,
				assignedUser: undefined,
				hyperlink: '',
				immediatelySend: true,
				sendDate: undefined,
				activeTo: undefined,
				photos: [],
				areas: [],
				createLogin: '',
				anonymous: false
			},
			messages: [],
		};
		this.isAdmin = this.authService.getProfile()?.userRole === "Administrator"
		this.userId = this.authService.getProfile()?.userId;
		this.hideReminderDialog = this.hideReminderDialog.bind(this);
		this.responsiveOptions = [
			{
				breakpoint: '1024px',
				numVisible: 5,
			},
			{
				breakpoint: '768px',
				numVisible: 3,
			},
			{
				breakpoint: '560px',
				numVisible: 1,
			},
		];
	}


	getContainerListName() {
		return 'incident-list-container';
	}

	getAddSucces() {
		return 'Zgłoszenie zostało utworzone';
	}

	getUpdateSucces() {
		return 'Zgłoszenie zostało zaktualizowane';
	}


	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			this.setState({ elementId: this.props.id }, () => super.componentDidMount())
		}
	}

	updateElement(data) {

		this.setState(
			{
				element: {
					id: data.id,
					status: data.status,
					category: data.category,
					subcategory: data.subcategory,
					//publicUnit: data.publicUnit,
					description: data.description,
					address: data.address,
					longitude: data.longitude,
					latitude: data.latitude,
					followersCount: data.followersCount,
					uuid: data.uuid,
					hyperlink: data.hyperlink,
					immediatelySend: data.immediatelySend,
					sendDate: data.sendDate ? new Date(data.sendDate) : undefined,
					activeTo: data.activeTo ? new Date(data.activeTo) : undefined,
					photos: data.photos ? data.photos : [],
					areas: data.areas ? data.areas : [],
					assignedUser: data.assignedUser,
					createLogin: data.createLogin,
					anonymous: data.anonymous,
					incidentNo: data.incidentNo
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}
	setElement() {
		this.internalUserService.getUserReferenceList({ status: { enumValue: "ACTIVE", label: "Aktywna" }, notBlocked: true })
			.then(userOptions => {
				userOptions.forEach(u => u.label = `${u.firstName} ${u.lastName}`);
				this.setState({ userOptions }, () => this.realSetElement())
			})
			.catch(() => this.setState({ userOptions: [] }, () => this.realSetElement()))
	}
	renderSeparator() {
		return !this.props.listMode ? super.renderSeparator() : null
	}
	realSetElement() {

		const { viewMode } = this.props;
		const { elementId } = this.state;

		if (elementId && (viewMode === 'EDIT' || viewMode === 'VIEW')) {
			this.blockUi();
			this.service
				.get(elementId, viewMode)
				.then((data) => {
					let images = [];
					if (data.photos && data.photos.length !== 0) {
						data.photos.forEach((p) => {
							this.service
								.getImage(
									this.authService.getToken(),
									() => { this.showErrorMessage("Nie udało się pobrać pliku"); this.setState({ loading: false }, () => this.updateElement(data)) },
									data.id,
									p.id
								)
								.then((res) => {
									const image = new Object();
									image.photo =
										window.URL.createObjectURL(res);
									images.push(image);
									this.setState(
										{
											loading: false,
											images: images,
										},
										() => this.updateElement(data)
									);
								})
						});

					} else {
						this.setState({ loading: false, images: [] }, () => this.updateElement(data));
					}
				})
				.catch((err) => {
					this.handleGetDetailsError(err);
				});
		} else {
			this.setState({ loading: false }, this.initAfterSetElement());
		}
	}
	initBeforeSetElement() {
		this.categoryService.getCategories("NOTIFICATION")
		.then(categoryOpt => this.setState({ categoryOpt }))
		.catch(err => {
			this.showErrorMessage("Nie można pobrać kategorii");
		})
	}

	initAfterSetElement() {
		if (this.state.element?.category?.id) {
			this.fetchSubcategories();
		} else {
			this.unblockUi();
		}
	}

	prepareFooterItems() {
		return [
			{
				label: 'Ustaw przypomnienie',
				className: 'float-right',
				onClick: e => { if (e) { e.preventDefault() } this.setState({ reminderDialogVisibile: true }) },
				rendered: !this.props.listMode
			},

		];
	}

	prepareEditButton(rendered, label) {
		return null
	}


	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowe zgłoszenie' : viewMode === 'EDIT' ? 'Edycja zgłoszenia' : 'Szczegóły zgłoszenia',

				type: 'LABEL',
				className: '',
			},

		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}

	hideReminderDialog(e, callback) {
		if (e) {
			e.preventDefault();
		}
		this.setState({ reminderDialogVisibile: false }, () => {
			console.log('hideReminderDialog hidden')
			if (callback) callback()
		})
	}
	getReminderCookieKey(){
		return 'incident-reminder'
	}
	readReminderMessage() {
        const messageFromStorage = this.readCookie(this.getReminderCookieKey());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getReminderCookieKey());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
				this.unblockUi()
            }
        }
    }
	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/incident-list' className='p-link'>
					{'Zgłoszenia'}
				</BreadcrumbsItem>

				<ReminderContainer
					refId={this.state.elementId}
					onHide={e => this.hideReminderDialog(e)}
					visible={this.state.reminderDialogVisibile}
					viewMode={'NEW'}
					afterSave={e => this.hideReminderDialog(e, () => { this.readReminderMessage(); })}
					onError={e => this.hideReminderDialog(e, () => { this.readReminderMessage() })}
					header={'Przypomnienie o zgłoszeniu'}
					type='INCIDENT'
					className={"black"}
					reminderCookieKey={this.getReminderCookieKey()}
				/>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/incident/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/incident/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/incident/create'>{'Nowe'}</BreadcrumbsItem> : null}
				{this.renderView()}
			</div>
		);
	}

	fetchSubcategories() {
		this.service
			.getSubcategories(this.state.element?.category?.id)
			.then(subcategories => {
				this.setState({ subcategoriesOpt: subcategories }, () => super.initAfterSetElement())
			})
			.catch(err => {
				this.showErrorMessage(err.message);
				super.initAfterSetElement()
			});
	}


	renderDetails() {
		return (
			<React.Fragment>
				<div className='row'>
					<InputTextComponent
						id="incidentNo"
						name="incidentNo"
						label="Nr porządkowy"
						colClass='col-md-4 col-sm-12'
						value={this.state.element.incidentNo}
						validator={this.validator}
						viewMode={'VIEW'}
						rendered={this.props.viewMode!='NEW'}
					/>
					<InputDropdownComponent
						id='category'
						name='category'
						label={'Kategoria'}
						colClass='col-md-4 col-sm-12'
						value={this.state.element.category}
						validator={this.validator}
						options={this.state.categoryOpt}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						optionLabel='name'
						dataKey='id'
						validators='required'
						valueView={this.state.element.category ? this.state.element.category.name : ''}
						onAfterStateChange={e => { this.fetchSubcategories(e) }}
						validateViewMode
					/>
					<InputDropdownComponent
						id='subcategory'
						name='subcategory'
						label={'Podkategoria'}
						colClass='col-md-4 col-sm-12'
						value={this.state.element.subcategory}
						validator={this.validator}
						options={this.state.subcategoriesOpt}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						optionLabel='name'
						dataKey='id'
						validators='not_required'
						valueView={this.state.element.subcategory ? this.state.element.subcategory.name : ''}
						validateViewMode
						rendered={this.state.element.subcategory || (this.state.subcategoriesOpt?.length && this.state.subcategoriesOpt?.length > 0)}
					/>
				</div>
				<div className='row'>
					<InputTextareaComponent
						id='description'
						name='description'
						label={'Opis'}
						colClass='col-12'
						value={this.state.element.description}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'required|max:200'}
						validateViewMode
						autoResize
					/>
					<InputFileUploadComponent
						messages={this.messages}
						id='photos'
						name='photos'
						label={'Zdjęcia'}
						fileList={this.state.element.photos}
						showLabel
						value={this.state.element.photos}
						restApiUrl={`${this.service.getPath()}/${this.state.element.id}/attachments`}
						multiple
						showImagePreview
						maxFileSize={50000000}
						onChange={this.handleChange}
						itemLabel='fileName'
						itemName='uuid'
						colClass='col-12'
						viewMode={this.props.viewMode}
						validator={this.validator}
						validators="not_required"
						style={{ width: '100%' }}
						restrictExt={true}
						accept='.jpg'
						handleError={this.onUploladError}
						token={this.authService.getToken()}
						validateViewMode
					/>
					<Galleria
						ref={(el) => (this.galleria3 = el)}
						value={this.state.images}
						responsiveOptions={this.responsiveOptions}
						numVisible={3}
						style={{ maxWidth: 'fit-content', maxHeight: '100%', position: 'static' }}
						activeIndex={this.state.activeIndex}
						onItemChange={(e) =>
							this.setState({ activeIndex: e.index })
						}
						circular
						fullScreen
						showItemNavigators
						showThumbnails={false}
						item={this.itemTemplate}
						thumbnail={this.thumbnailTemplate}
						className="col-12"
					/>
					<div
						className="row row-images">
						{this.state.images &&
							this.state.images.map((image, index) => {
								let imgEl = (
									<img
										src={image.photo}
										height="200px"
										width="150px"
										style={{ cursor: 'pointer' }}
										onClick={() => {
											this.setState(
												{ activeIndex: index },
												() =>
													this.galleria3.show()
											);
										}}
									/>
								);

								return (
									<div
										className="p-col-4 ml-2 mb-2"
										key={index}>
										{imgEl}
									</div>
								);
							})}
					</div>
					<InputTextComponent
						id='address'
						name={"address"}
						label={"Lokalizacja"}
						colClass='col-12'
						value={this.state.element.address}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validateViewMode
						validators="not_required|max:255"
					/>
					{!this.props.listMode ?
						<ActionLink rendered={this.state.element.address} label={!!this.state.showMap ? 'Schowaj podgląd mapy' : 'Pokaż podgląd na mapie'} className='col-6' handleClick={() => this.setState({ showMap: !this.state.showMap })} variant='blue' iconName={!!this.state.showMap ? 'mdi-arrow-up' : 'mdi-arrow-down'} iconSide='left' iconSize='xs' iconColor='blue' />
						: null}
				</div>
				{!!this.state.showMap ?
					<div colClass="col-12">
						<div id="mapid">
							<MapLeaflet
								markers={this.state.element.latitude && this.state.element.latitude ? [[this.state.element.latitude, this.state.element.longitude]] : undefined}
								areas={this.state.element?.areas} handleClickArea={this.handleClickArea} viewMode={this.props.viewMode} />
						</div>
					</div> : null}

				<div className="row">

					<InputDropdownComponent
						//	colClass='col-md-4'
						label="Status"
						id="status"
						name="status"
						value={this.state.element.status}
						validator={this.validator}
						validators="not_required"
						optionLabel="label"
						viewMode={"VIEW"}
						rendered={this.props.viewMode !== "NEW"}
					/>

					<InputCalendarComponent
						id='sendDate'
						name='sendDate'
						label={'Data wysłania'}
						dateFormatMoment='YYYY-MM-DD HH:mm'
						//	colClass='col-md-4'
						value={this.state.element.sendDate}
						validator={this.validator}
						validators={`required|date|date_format~DD-MM-YYYY HH:mm,dd-mm-rrrr HH:mm|after_or_equal_time~${moment(new Date()).format('DD-MM-YYYY HH:mm').toString()}`}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						rendered={!this.state.element.immediatelySend || this.props.viewMode === 'VIEW'}
						validateViewMode
						showTime
						minDate={new Date()}
						required
					/>
					
					<InputNumberComponent
						id='createLogin'
						name='createLogin'
						label={'Autor'}
						colClass='col-md-6 col-sm-12'
						value={!!this.state.element.anonymous ? "Anonimowe" : this.state.element.createLogin}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={'VIEW'}
						validators={'naturalNumber|required'}
					/>
					<InputNumberComponent
						id='followersCount'
						name='followersCount'
						label={'Osoby popierające'}
						colClass='col-md-6 col-sm-12'
						value={this.state.element.followersCount}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'naturalNumber|required'}
					/>
				</div>
				{!this.props.listMode ?
					<ActionLink label={!!this.state.showConwersation ? 'Schowaj podgląd konwersacji' : 'Pokaż konwersację'} className='col-6' handleClick={() => this.setState({ showConwersation: !this.state.showConwersation })} variant='blue' iconName={!!this.state.showConwersation ? 'mdi-arrow-up' : 'mdi-arrow-down'} iconSide='left' iconSize='xs' iconColor='blue' />
					: null}
				{this.renderMessagesList()}

			</React.Fragment>
		);
	}
	itemTemplate(item) {
		return (
			<img
				src={item.photo}
				alt={item.text}
				style={{ width: '100%', display: 'block' }}
			/>
		);
	}

	thumbnailTemplate(item) {
		return (
			<img
				src={`${item.previewImageSrc}`}
				alt={item.alt}
				style={{ width: '100%', display: 'block' }}
			/>
		);
	}
	renderMessagesList() {
		const assignedUserID = this.state.element && this.state.element.assignedUser && this.state.element.assignedUser.id ? this.state.element.assignedUser.id : null;
		return this.state?.element?.uuid && !!this.state.showConwersation ?
			<MessageList id={this.state?.element?.uuid} showFileUpload={false} user={this.props.user} disableSendButton={!this.isAdmin && assignedUserID != null && assignedUserID != this.userId} /> : null;
	}
	renderBackLink() {
		return !this.props.listMode ? super.renderBackLink() :
			<ActionLink rendered={this.backLinkRendered} label={"Przejdź do szczegółów"} className='p-link float-right'
				handleClick={e => {
					e.preventDefault();
					this.blockUi();
					window.location.href = `${this.props.cancelUrl}/${this.state.elementId}`;

				}} variant='blue' />;
	}

}

IncidentContainer.defaultProps = {
	backUrl: '/#/incident-list',
	cancelUrl: '/#/incident/details',
	editUrl: '/#/incident/edit',
	listMode: false
};

IncidentContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
	listMode: PropTypes.bool
};

export default IncidentContainer;
