import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class SurveyResultService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'survey-manage';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			question_id: criteria.questionId,
			gender: criteria?.gender?.enumValue ? criteria?.gender?.enumValue : undefined,
			text: criteria.text,
			number: criteria.number,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	getList(criteria) {
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/list-answers${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
	
}
