import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';
export default class EventLogService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'eventLog';
	}

	getCriteria(criteria) {

		return this.objToQueryString({
			date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_TIME_FORMAT) : undefined,
			date_to: criteria.dateTo ?  moment(criteria.dateTo).format(Constants.DATE_TIME_FORMAT) : undefined,
			eventType: criteria.eventType?.enumValue,
			logType: criteria.logType?.enumValue,
			user_email: criteria.userEmail,			
			object_type: criteria.objectType?.enumValue,
			object_id: criteria.objectId,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
}
