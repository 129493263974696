import React from 'react';
import { DivContainer } from '../../components/DivContainer';
import { PropTypes } from 'prop-types';
import { ActionButton } from '../../components/ActionButton';
import StreetList from './StreetList';
import RefuseAreaService from '../../services/RefuseAreaService'
import YesNoDialog from '../../components/YesNoDialog';
import MessageUtils from '../../utils/MessageUtils';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import RefuseStreetService from '../../services/RefuseStreetService';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputAutoCompleteComponent from '../../components/inputs/InputAutocompleteComponent';
import EditableDataTableExt from '../../components/EditableDataTableExt';
import CustomLabel from '../../components/CustomLabel';
import uuid from 'uuid'
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';

class RefuseAreaContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props);
		this.service = new RefuseAreaService();
		this.streetService = new RefuseStreetService();
		this.showDeleteStreetDialog = this.showDeleteStreetDialog.bind(this);
		this.hideDeleteStreetDialog = this.hideDeleteStreetDialog.bind(this);
		this.filteredCities = this.filteredCities.bind(this);
		this.filteredStreets = this.filteredStreets.bind(this);
		this.handleAutoCompleteChangeStreet = this.handleAutoCompleteChangeStreet.bind(this);
		this.deleteArea = this.deleteArea.bind(this);
		this.counter = 0;
		this.state = {
			streetId: undefined,
			elementId: this.props.id,
			refreshFlag: false,
			element: {
				name: '',
				cities: [],
			},
			showConfirmBlockDialog: false,
			showConfirmUnblockDialog: false,
			statusOptions: [],
			roleTypeOptions: [],
			streetsRequired: true,
			addedList: [],
			modifiedList: new Map(),
			streetOptionsMap: new Map(),
		}
	}
	handleValidForm() {
		this.validateStreets().then(() => super.handleValidForm()).catch(err => this.unblockUi())
	}
	initBeforeSetElement(){
	this.filteredCities();
	}
	validateStreets() {
		return new Promise((resolve, reject) => {
			let valid = true;
			if (!this.state.element.cities || this.state.element.cities.length === 0) {
				this.showErrorMessage("Nie dodano żadnej miejscowości")
				this.unblockUi();
				return;
			}
			this.state.element.cities.forEach(c => {
				if (c.streets && c.streets.length) {
					const blankStreets = c.streets.filter(s => (!s.streetName || s.streetName === null || !s.streetName.name || s.streetName.name === '') && this.state.streetOptionsMap[c.city.code] && !!this.state.streetOptionsMap[c.city.code].streetsRequired);
					if (blankStreets && blankStreets.length && blankStreets.length > 0) {
						this.showErrorMessage(`Miejscowość ${c.city.name} zawiera ulice które nie mają ustawionych nazw`)
						return reject(valid);
					}
				}
			})
			resolve(valid);
		})
	}
	updateElement(data) {
		//check null

		this.setState(
			{
				element: {
					id: data.id,
					name: data.name,
					cities: data.cities,
				},
				streetOptionsMap: data.optionsMap,
			},
			() => {

				this.initAfterSetElement();
			}
		);
	}

	createNewElement(city) {
		return {
			cityName: city?.name,
			streetName: '',
			refuseCollectionArea: undefined,
			uuid: uuid.v4()
		};
	}


	prepareEditButton(rendered, label) {
		const { editUrl, viewMode } = this.props;
		const { element } = this.state;
		return {
			label: label !== undefined ? label : 'Edytuj',
			type: 'BUTTON',
			variant: '',
			className: 'float-right',
			href: `${editUrl}/${element.id}`,
			rendered: viewMode === 'VIEW' && (this.isUserInRole('ROLE_REFUSE_AREA_MANAGE') || this.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') || this.isUserInRole('ROLE_REFUSE_AREA_LIMITED_MANAGE')),
			iconName: 'mdi-pencil',
			iconSide: 'left',
			iconSize: 'm',
		};
	}

	showDeleteStreetDialog(streetId) {
		this.setState({ showDeleteStreetDialog: true, streetToDeleteId: streetId });
	}

	hideDeleteStreetDialog(callback) {
		this.setState({ showDeleteStreetDialog: false, streetToDeleteId: null }, () => {
			if (callback) {
				callback()
			}
		});
	}

	hideDeleteStreetDialogSuccess(callback) {
		this.setState({ showDeleteStreetDialog: false, streetToDeleteId: null, refreshFlag: !this.state.refreshFlag }, () => {
			if (callback) {
				callback()
			}
		});
	}

	handleExitAction() {
		window.location.href = '/#/refuse-area-list';
	}

	getContainerListName() {
		return 'refuse-area-list-container';
	}

	getAddSucces() {
		return 'Obszar został utworzony';
	}

	getUpdateSucces() {
		return 'Obszar został zaktualizowany';
	}

	saveCookie(cookieName, cookieValue) {
		sessionStorage.setItem(cookieName, cookieValue);
	}

	persistMessage(severity, summary, detail) {
		this.saveCookie(
			this.getContainerListName(),
			JSON.stringify({
				severity,
				summary,
				detail,
			})
		);
	}
	removeCity(e, i) {
		if (e) {
			e.preventDefault();
		}
		const modifiedList = this.state.element.cities;
		modifiedList.splice(i, 1)
	}
	selectAllStreets(i) {
		this.blockUi();
		if (this.state.element?.cities[i]?.city?.code) {
			const selectedStreets = this.state.streetOptionsMap[this.state.element.cities[i].city.code]?.options.map(o => {
				return {
					cityName: this.state.element?.cities[i].city.name,
					streetName: o,
					refuseCollectionArea: undefined,
					uuid: uuid.v4()
				};
			})
			const modifiedList = this.state.element.cities;
			modifiedList[i].streets = selectedStreets;
			this.setState((prevState) => ({
				element: {
					...prevState.element,
					cities: modifiedList,
				},

			}), () => this.unblockUi());
		}
	}
	deleteArea(e) {
		if (e) {
			e.preventDefault();
		}
		this.blockUi();
		this.service
			.remove(this.state.elementId)
			.then((result) => {
				this.persistMessage('success', '', 'Obszar został usunięty');
				window.location.href = this.props.backUrl;
			})
			.catch((err) => {
				this.hideBlockConfirmation(() => { MessageUtils.showErrorMessage(err.message, null, null, null, this.messages) });
				this.unblockUi();
			});
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowy obszar' : viewMode === 'EDIT' ? 'Edycja obszaru' : 'Szczegóły obszaru',
				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(viewMode === 'VIEW' && this.isUserInRole('ROLE_ADMIN_REFUSE_AREA_MANAGE' && this.isUserInRole('ROLE_REFUSE_AREA_LIMITED_MANAGE'))),
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}

	handleBlurSelectStreet(e) {
		console.log('handleBlurSelectStreet...');
		if (this.state.element.street === undefined) {
			this.setState({ streetName: undefined });
		}
	}


	handleAutoCompleteChangeStreet(inputType, parameters, event, onAfterStateChange, stateField) {

		const cityIndex = parameters && parameters[1] !== null && parameters[1] !== undefined ? parameters[1] : undefined;
		console.log('city index ', cityIndex);
		let varValue;
		if (event !== undefined) {
			varValue =
				event.target.value || event.target.value === ''
					? event.target.value
					: undefined;
			const isObject = varValue instanceof Object;

			console.log(`handleAutoCompleteChangeStreet: isObject=${isObject}`);
			this.setState({
				streetName: isObject
					? varValue.label : varValue
			},
			);
			if (!isObject) {
				event.target.value = undefined;
			}
			this.handleChange(
				'TEXT',
				undefined,
				event,
				() => {
				},
				stateField
			);
		}
	}

	filteredCities(query) {
		this.blockUi()
		this.streetService
			.getCitiesByName(query)
			.then((data) => {

				this.setState({ filteredCities: data }, () => this.unblockUi());
			})
			.catch(err => {
				this.setState({
					filteredCities: [],
				}, () => this.unblockUi());
				console.log(err)
				this.showErrorMessage('Wystąpił błąd systemu. Nie udało się pobrać listy miejscowości.');
			});
	}
	filteredStreets(code) {
		//todo
		console.log('****** filtered streets', code)
		if (code) {
			this.blockUi();
			this.streetService
				.getStreetsByCityCodeAndName(code)
				.then((data) => {
					data.forEach((c) => {
						(c.label = `${c.name}`)
					});

					//this.setState({ filteredStreets: data, streetsRequired: !(data.length === 0) }, () => console.log(this.state.filteredStreets, ' streets '));
					//console.log(' map ', this.streetOptionsMap.get('0092315'))
					const streetOptionsMapModified = this.state.streetOptionsMap;
					streetOptionsMapModified[code] = { options: data, streetsRequired: !(data.length === 0) };
					this.setState({ streetOptionsMap: streetOptionsMapModified }, () => this.unblockUi());
				})
				.catch(err => {
					this.unblockUi()
					console.log(err)
					this.showErrorMessage('Wystąpił błąd systemu. Nie udało się pobrać ulic miejscowości.');
				});
		}
	}

	handleAddCity(e) {
		if (e) {
			e.preventDefault();
		}
		const modifiedList = this.state.element.cities;
		modifiedList.push({
			city: '',
			cityCode: '',
			streets: [],
			uuid: uuid.v4(),
		});
		this.setState(
			(prevState) => ({
				element: {
					...prevState.element,
					cities: modifiedList
				},
			}),
		);
	}
	handleRemoveCity(e, uuid) {
		if (e) {
			e.preventDefault();
		}
		let modifiedList = this.state.element.cities;
		let index;
		modifiedList.forEach((element, i) => {
			if (element.uuid === uuid) {
				index = i;
			}
		});
		modifiedList.splice(index, 1);
		this.setState(
			(prevState) => ({
				element: {
					...prevState.element,
					cities: modifiedList
				},
			}),
		);
	}


	render() {
		return (
			// <React.Fragment>
			<div className='container-fluid'>
				<BreadcrumbsItem to='/refuse-area-list' className='p-link'>
					{'Lista obszarów'}
				</BreadcrumbsItem>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/refuse-area/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/refuse-area/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/refuse-area/create'>{'Nowy'}</BreadcrumbsItem> : null}
				{this.renderView()}
				<YesNoDialog visible={this.state.showConfirmBlockDialog} header='Usuwanie obszaru' name='visible'
					onChange={(type, x, target) => { if (target.value) { this.deleteArea(); } else { this.hideBlockConfirmation(); } }}
					onHide={() => { this.hideBlockConfirmation(); }}>
					Czy na pewno chcesz usunąć obszar?
				</YesNoDialog>
				<YesNoDialog visible={this.state.showDeleteStreetDialog} header='Usuwanie ulicy' name='visible'
					onChange={(type, x, target) => { if (target.value) { this.deleteStreetFromArea(); } else { this.hideDeleteStreetDialog(); } }}
					onHide={() => { this.hideDeleteStreetDialog(); }}>
					Czy na pewno chcesz usunąć ulicę z obszaru?
				</YesNoDialog>
			</div>
		);
	}

	handleGoBack() {
		window.location.href = this.props.backUrl;
	}



	renderDetails() {
		return (
			<div>
				<div className='row col-12'>
					<InputTextComponent
						id='name'
						name='name'
						label={'Nazwa'}
						colClass='col-12'
						value={this.state.element.name}
						validator={this.validator}
						validators='required|max:255'
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
					/>
				</div>
				{this.props.viewMode === 'VIEW' ?
					(<React.Fragment>
						<StreetList
							areaId={this.state.elementId}
							handleNew={this.handleNew}
							handleDetail={this.handleDetail}
							refreshFlag={this.state.refreshFlag}
						/>
						{/* {this.renderNavigationButtons()} */}

					</React.Fragment>)
					:
					(<DivContainer colClass="col-12">
						{this.isHalfWidth = true}
						<CustomLabel
							colClass="col-md-12"
							label="Miejscowości"
						/>
						<div>
							<div class="row">
								<div class="col-12 city-add">
									<ActionButton
										label="Dodaj miejscowość"
										className="float-right"
										key={'next-button'}
										handleClick={(e) => this.handleAddCity()}
										rendered={this.props.viewMode !== 'VIEW'}
									/>
								</div>
							</div>


							{this.state?.element?.cities && this.state?.element?.cities?.length ? this.state?.element?.cities.map((city, i) => {
								return (
									<div class="area-city-div" >
										<div className="row">
											<div className="col-12">
												<ActionButton
													label="Usuń miejscowość"
													className="float-right"
													key={'next-button'}
													handleClick={(e) => this.handleRemoveCity(e, city.uuid)}
													iconName='mdi-delete'
													iconSide='left'
													iconSize='m'
													rendered={this.props.viewMode !== 'VIEW'}
												/>
											</div>
										</div>
										<div className="row">
											<InputDropdownComponent
												colClass='col-12'
												label={'Miejscowość'}
												id={`${city.uuid}-city`}
												name="city"
												value={this.state.element.cities[i].city}
												validator={this.validator}
												validators={'required'}
												onChange={this.handleChange}
												optionLabel="name"
												viewMode={this.props.viewMode}
												filter
												filterBy="name"
												options={this.state.filteredCities}
												valueView={this.state.element?.cities[i]?.city?.name}
												disabled={!!(this.state.element?.cities[i]?.streets && this.state.element.cities[i].streets.length > 0 && !!this.state.streetOptionsMap[this.state.element.cities[i].city.code]?.streetsRequired)}
												stateField={`element.cities[${i}]`}
												onAfterStateChange={() => this.filteredStreets(this.state.element.cities[i].city.code )}
											/>
											<EditableDataTableExt
												// messages={this.messages}
												id={`${city.uuid}-streets`}
												name='streets'
												label='Ulice'
												showLabel={true}
												paginator
												columns={[
													{
														field: 'streetName',
														header: 'Ulica',
														editor: 'DROPDOWN',
														optionLabel: 'label',
														dataKey: 'label',
														options: this.state?.element?.cities[i]?.city?.code && this.state.streetOptionsMap ? this.state.streetOptionsMap[this.state.element.cities[i].city.code]?.options : [],
														editable: true,
														validators: 'required',
														filter: true
													}]}
												addedListName='addedList'
												addedList={this.state.addedList}
												modifiedListName='modifiedList'
												modifiedList={this.state.modifiedList}
												validator={this.validator}
												viewMode={this.props.viewMode}
												onChange={this.handleChange}
												createNewElement={this.createNewElement.bind(this, this.state?.element?.cities[i]?.city)}
												editable
												showAddButton={this.props.viewMode !== 'VIEW' && this.state.element.cities[i].city !== ''}
												showDeleteButton={this.props.viewMode !== 'VIEW'}
												addButtonLabel='Dodaj ulicę'
												stateField={`element.cities[${i}]`}
												dataKey='uuid'
												updateItemElementAfterEdit={this.updateItemElementAfterEdit}
												ref={this.editableListRef}
												showDeleteButtonFunction={this.isItemExpired}
												showDeleteButtonFunctionLabel='Nieaktywny'
												className='answer-area-table'
												tableClassName='answer-options-table'
												value={this.state.element.cities[i].streets}
												publicMode
												rendered={this.state?.element?.cities[i]?.city?.code && this.state.streetOptionsMap && !!this.state.streetOptionsMap[this.state.element.cities[i].city.code]?.streetsRequired}
												validators={this.state?.element?.cities[i]?.city?.code && this.state.streetOptionsMap ? this.state.streetOptionsMap[this.state.element.cities[i].city.code]?.streetsRequired ? "array_required" : 'not_required' : 'array_required'}
												permanentDelete
												customButton={this.state.element?.cities[i]?.city ? true : false}
												customButtonLabel="Wybierz wszystkie"
												handleCustomButton={() => this.selectAllStreets(i)}
												customButtonClassName="float-right"
											/>
										</div>
									</div>)
							}) : null}

						</div>
					</DivContainer>)
				}

			</div>
		)
	}
}

RefuseAreaContainer.defaultProps = {
	backUrl: '#/refuse-area-list',
	cancelUrl: '/#/refuse-area/details',
	editUrl: '/#/refuse-area/edit',
};

RefuseAreaContainer.propTypes = {
	streetId: PropTypes.object.isRequired,
	viewMode: PropTypes.string.isRequired,
};

export default RefuseAreaContainer;
