import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import RefuseAreaService from '../../services/RefuseAreaService';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CriteriaCalendarRangeComponent from '../../components/criteria/CriteriaCalendarRangeComponent';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import RefuseTimetableService from '../../services/RefuseTimetableService';
import RefuseTypeService from '../../services/RefuseTypeService';

class RefuseTimetableListContainer extends BaseListContainer {
	constructor(props) {
		super(props, new RefuseTimetableService());
		this.areaService = new RefuseAreaService();
		this.refuseTypeService = new RefuseTypeService();
		this.state = {
			list: [],
			loading: true,
			size: 0,
			first: 0,
			criteria: this.getCleanSearchCriteria(),
			statusOptions: [],
			areasOptions: [],
			refuseTypesOpt: [],
		};
	}
	initializeFromBackend() {
		this.enumService
			.getEnumList('RefuseTimetableStatus')
			.then((statusOptions) =>
				this.setState({
					statusOptions,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy statusów');
			});
		this.areaService
			.getActiveAreas()
			.then((areasOptions) => this.setState({ areasOptions }))
			.catch((err) => {
				this.showErrorMessage(err.message);
			});
		this.refuseTypeService
			.getActiveTypes()
			.then(refuseTypesOpt => this.setState({
				refuseTypesOpt,
			})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy typów odpadów');
			});
	}
	updateSearchCriteria(criteria) {
		return {
			areas: criteria.areas,
			refuseTypes: criteria.refuseTypes,
			dateFromSc: criteria.dateFromSc,
			dateToSc: criteria.dateToSc,
			status: criteria.status,
			firstResult: criteria.firstResult,
			maxResult: criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}

	getCleanSearchCriteria() {
		return {
			areas: [],
			refuseTypes: [],
			dateFromSc: undefined,
			dateToSc: undefined,
			status: undefined,
			firstResult: 0,
			maxResult: 10,
			sortField: 'lp',
			sortAsc: false,
		};
	}

	getCriteriaName() {
		return 'refuse-timetable-list-sc';
	}

	getContainerListName() {
		return 'refuse-timetable-list-container';
	}

	renderCriteria() {
		return (
			<div className='row'>
				<CriteriaMultiSelectComponent
					id="areas-sc"
					name="areas"
					showClear
					label="Obszary"
					colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
					value={this.state.criteria.areas}
					options={this.state.areasOptions}
					placeholder="Wszystkie"
					onChange={this.handleChangeSc}
					validator={this.validator}
					optionLabel="name"
					dataKey='id'
					filter
					filterBy="name"
				/>
				<CriteriaMultiSelectComponent
					id="refuseTypes-sc"
					name="refuseTypes"
					showClear
					label="Typy odpadów"
					colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
					value={this.state.criteria.refuseTypes}
					options={this.state.refuseTypesOpt}
					placeholder="Wszystkie"
					onChange={this.handleChangeSc}
					validator={this.validator}
					optionLabel="name"
					dataKey='id'
					filter
					filterBy="name"
				/>
			<CriteriaCalendarRangeComponent
					key={`dateSc`}
					idFrom={`dateFromSc`}
					idTo={`dateToSc`}
					nameTo={'dateToSc'}
					nameFrom={'dateFromSc'}
					label={'Data'}
					labelFrom="od"
					labelTo="do"
					//colClass={column.colClass ? column.colClass : 'col-md-6'}
					colClass={'col-12'}
					from={this.state.criteria.dateFromSc}
					to={this.state.criteria.dateToSc}
					validator={this.validator}
					onChange={this.handleChangeSc}
					viewMode={this.props.viewMode}
					showIcon
					placeholderFrom={'dd-mm-rrrr'}
					placeholderTo={'dd-mm-rrrr'}
					validateOnChange={false}
					stateField=""
					maxDateInfinite
				/>
				<CriteriaDropdownComponent
					id="status-sc"
					name="status"
					showClear
					label="Status"
                    colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                    value={this.state.criteria.status}
					options={this.state.statusOptions}
					placeholder="Wszystkie"
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
			</div>
		);
	}

	prepareColumns() {
		return [
			{ field: 'lp', header: 'Lp.', sortable: true },
			{ field: 'refuseArea.name', header: 'Obszar', 
			sortable: true 
		},
			{ field: 'refuseTypesDesc', header: 'Rodzaj odpadów' },
            { field: 'buildType', header: 'Typ zabudowy', body: this.enumTemplate.bind(this, 'buildType') },
            { field: 'status', header: 'Status', body: this.enumTemplate.bind(this, 'status') },
			{
				key: 'actions',
				header: 'Szczegóły',
				body: this.actionTemplate,
				className: 'table-column-action',
			},
		];
	}

	prepareHeaderItems() {
		return this.isUserInAnyRole('ROLE_REFUSE_TIMETABLE_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')
			? [
				{
					label: 'Nowy harmonogram',
					href: this.props.newUrl,
					type: 'BUTTON',
					className: 'float-right',
					variant: 'yellow',
					iconName: 'mdi-plus',
					iconSide: 'left',
					iconColor: 'white',
				},
			]
			: [];
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/refuse-timetable-list'>{'Harmonogramy'}</BreadcrumbsItem>
				{this.renderView()}
			</div>
		);
	}
}

RefuseTimetableListContainer.defaultProps = {
	detailUrl: '/#/refuse-timetable/details',
	newUrl: '/#/refuse-timetable/create',
};

RefuseTimetableListContainer.propTypes = {
	detailUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default RefuseTimetableListContainer;
