import React from 'react';
import BaseContainer from '../../baseContainers/BaseContainer';
import { DivContainer } from '../../components/DivContainer';
import BlockUi from '../../components/waitPanel/BlockUi';
import { FullCalendar } from 'primereact/fullcalendar';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import plLocale from '@fullcalendar/core/locales/pl';
import RefuseTimetableService from '../../services/RefuseTimetableService';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Tooltip } from 'bootstrap';

class InspectionScheduleCalendar extends BaseContainer {
	constructor(props) {
		super(props);
		this.service = new RefuseTimetableService();
		let param = props.params.param;
		if (param === 'new') {
			this.removeCookie('SCHEDULER_CALENDAR_CRITERIA');
		}
		let initialDate = null;
		let initialView = null;
		let criteriaFromCookie = this.readCookie('SCHEDULER_CALENDAR_CRITERIA');
		if (criteriaFromCookie !== undefined && criteriaFromCookie !== null) {
			let criteriaFromCookieObj = JSON.parse(criteriaFromCookie);
			initialDate = criteriaFromCookieObj.dateFrom;
			initialView = criteriaFromCookieObj.initialView;
		}

		this.state = {
			loading: false,
			blocking: true,
			element: {},
			wallSheathingOptions: [],
			events: props.days,
			currentEvents: [],
			dateFrom: null,
			dateTo: null,
			options: {
				plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
				initialView: initialView ? initialView : 'dayGridMonth',
				locale: plLocale,
				headerToolbar: {
					left: 'todayButton,prevButton',
					center: 'title',
					right: 'nextButton',
				},
				customButtons: {
					dayGridMonth: {
						text: 'Kalendarz miesiąc',
						click: () => {
							this.setState(
								{
									options: {
										dayMaxEvents: 3,
									},
								},
								() => {
									this.calendarRef.calendar.changeView('dayGridMonth');
									this.initializeData();
								}
							);
						},
					},
					prevButton: {
						icon: 'fc-icon fc-icon-chevron-left',
						text: '<',
						click: () => {
							this.calendarRef.calendar.prev();
							this.initializeData();
						},
					},
					nextButton: {
						icon: 'fc-icon fc-icon-chevron-right',
						text: '>',
						click: () => {
							this.calendarRef.calendar.next();
							this.initializeData();
						},
					},
					prevYearButton: {
						icon: 'fc-icon fc-icon-chevron-left scheduler-year-toolbar',
						text: '<',
						click: () => {
							this.calendarRef.calendar.prevYear();
							this.initializeData();
						},
					},
					nextYearButton: {
						icon: 'fc-icon fc-icon-chevron-right scheduler-year-toolbar',
						text: '>',
						click: () => {
							this.calendarRef.calendar.nextYear();
							this.initializeData();
						},
					},
					todayButton: {
						text: 'Dziś',
						click: () => {
							this.calendarRef.calendar.today();
							this.initializeData();
						},
					},
				},
				editable: false,
				selectable: true,
				navLinks: true,
				displayEventTime: false,
				dayMaxEvents: initialView === 'dayGridMonth' ? 2 : undefined,
				initialDate: initialDate ? initialDate : new Date(),
				hiddenDays: [],
				slotMaxTime: '00:00:00',
				slotMinTime: '00:00:00',
				fixedWeekCount: false,

				views: {
					timeGrid: {
						dayMaxEventRows: 6, // adjust to 6 only for timeGridWeek/timeGridDay
					},
				},
				dateClick: (event) => {
					return event.date && this.props.viewMode !== 'VIEW' ? this.props.handleEventClick(event.date) : () => { }

				},
				eventClick: (arg) => {
					return arg?.event?._instance?.range?.start && this.props.viewMode !== 'VIEW' ? this.props.handleEventClick(arg.event._instance.range.start) : () => { };
				},
				navLinkDayClick: this.handleNavLinkDayClick,
				eventContent: function (arg) {
					return { html: '<div> <span class="event-class" ></span></div>' }
				},
			},
		};
	}

	getInitialDate() {
		let criteriaFromCookie = this.readCookie('SCHEDULER_CALENDAR_CRITERIA');
		let initialDate = null;
		if (criteriaFromCookie !== undefined && criteriaFromCookie !== null) {
			let criteriaFromCookieObj = JSON.parse(criteriaFromCookie);
			initialDate = criteriaFromCookieObj.dateFrom;
		}
		return initialDate;
	}

	getInitialView() {
		let criteriaFromCookie = this.readCookie('SCHEDULER_CALENDAR_CRITERIA');
		let initialView = null;
		if (criteriaFromCookie !== undefined && criteriaFromCookie !== null) {
			let criteriaFromCookieObj = JSON.parse(criteriaFromCookie);
			initialView = criteriaFromCookieObj.initialView;
		}
		return initialView;
	}

	handleNavLinkDayClick = (date) => {
		if (this.props.viewMode !== 'VIEW') {
			this.props.handleSelectDate(date);
		}
	};

	componentDidMount() {
		super.componentDidMount();
		this.initializeData();
	}

	initializeData() {
		if (this.props.viewMode !== 'NEW') {
			this.blockUi();
			let dateFromCal =
				this.calendarRef &&
					this.calendarRef.calendar &&
					this.calendarRef.calendar.currentData &&
					this.calendarRef.calendar.currentData.dateProfile &&
					this.calendarRef.calendar.currentData.dateProfile.currentRange &&
					this.calendarRef.calendar.currentData.dateProfile.currentRange.start
					? this.calendarRef.calendar.currentData.dateProfile.currentRange.start
					: null;

			let dateToCal =
				this.calendarRef &&
					this.calendarRef.calendar &&
					this.calendarRef.calendar.currentData &&
					this.calendarRef.calendar.currentData.dateProfile &&
					this.calendarRef.calendar.currentData.dateProfile.currentRange &&
					this.calendarRef.calendar.currentData.dateProfile.currentRange.end
					? this.calendarRef.calendar.currentData.dateProfile.currentRange.end
					: null;

			let initialViewCal =
				this.calendarRef &&
					this.calendarRef.calendar &&
					this.calendarRef.calendar &&
					this.calendarRef.calendar.currentData &&
					this.calendarRef.calendar.currentData.currentViewType
					? this.calendarRef.calendar.currentData.currentViewType
					: null;

			let schedulerCalendarCriteria = { dateFrom: dateFromCal, dateTo: dateToCal, initialView: initialViewCal, timetableId: this.props.timetableId };
			this.saveCookie('SCHEDULER_CALENDAR_CRITERIA', JSON.stringify(schedulerCalendarCriteria));

			this.setState(
				{
					dateFrom: dateFromCal,
					dateTo: dateToCal,
				},
				() => {
					this.service
						.getDayList(schedulerCalendarCriteria)
						.then((item) => {

							this.props.currentInitMonth(item, () => this.unblockUi())
						})
						.catch(() => {
							this.props.handleError('Nie można pobrać dni kalendarza', () => this.unblockUi());
						});
				}
			);
		} else this.unblockUi();
	}

	handleValidForm() {
		this.create();
	}

	render() {
		return (
			<React.Fragment>
				<div class="col-md-12 calendar">
					<BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
						<React.Fragment>
							<DivContainer>
								<FullCalendar
									id='scheduler-calendar'
									ref={(el) => (this.calendarRef = el)}
									className='scheduler-calendar'
									events={this.props.days}
									options={this.state.options}></FullCalendar>
							</DivContainer>
						</React.Fragment>
					</BlockUi>
				</div>
			</React.Fragment>
		);
	}
}

InspectionScheduleCalendar.defaultProps = {
	viewMode: 'EDIT',
	days: [],
	timetableId: undefined
};
export default InspectionScheduleCalendar;
