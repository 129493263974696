import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import PropTypes from 'prop-types';

import BaseListContainer from '../baseContainers/BaseListContainer';
import NewsConfigurationService from '../services/NewsConfigurationService';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';

class NewsConfigurationListContainer extends BaseListContainer {
	constructor(props) {
		super(props, new NewsConfigurationService());
		this.state = {
			list: [],
			loading: true,
			size: 0,
			first: 0,
			criteria: this.getCleanSearchCriteria(),
		};
	}


	updateSearchCriteria(criteria) {
		return  {
			id: criteria.id,
			name: criteria.name,
			firstResult: criteria.firstResult,
			maxResult: criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}

	getCriteriaName() {
		return 'news-configuration-list-sc';
	}

	getContainerListName() {
		return 'news-configuration-list-container';
	}

	getCleanSearchCriteria() {
		return {
			id: null,
			name: '',
			firstResult: 0,
			maxResult: 10,
			sortField: 'orderNW',
			sortAsc: true,
		};
	}

	initializeFromBackend() {
    }

	renderCriteria() {
		return (
			<div className='row'>
				<CriteriaTextComponent
					id="name-sc"
					name="name"
					label="Nazwa kategorii"
					colClass="col-lg-4 col-sm-6"
					value={this.state.criteria.name}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
			</div>
		);
	}

	prepareColumns() {
		return [
			{ field: 'orderNW', header: 'Kolejność', sortable: true },
			{ field: 'name', header: 'Nazwa kategorii w języku polskim', sortable: true },
			{ field: 'nameEng', header: 'Nazwa kategorii w języku angielskim', sortable: true },
			{ field: 'rssAddress', header: 'Adres RSS', sortable: true},
			{
				key: 'actions',
				header: 'Szczegóły',
				body: this.actionTemplate,
				className: 'table-column-action',
			},
		];
	}

	prepareHeaderItems() {
		return this.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE')
			? [
				{
					label: 'Nowa konfiguracja aktualności',
					href: this.props.newUrl,
					type: 'BUTTON',
					className: 'float-right',
					variant: 'yellow',
					iconName: 'mdi-plus',
					iconSide: 'left',
					iconColor: 'white',
				},
			]
			: [];
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/news-configuration-list'>{'Konfiguracje aktualności'}</BreadcrumbsItem>
				{this.renderView()}
			</div>
		);
	}
}

NewsConfigurationListContainer.defaultProps = {
	detailUrl: '/#/news-configuration/details',
	newUrl: '/#/news-configuration/create',
};

NewsConfigurationListContainer.propTypes = {
	detailUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default NewsConfigurationListContainer;
