import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';
import { saveAs } from 'file-saver';

export default class IncidentService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'incident';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			text_sc: criteria.textSc,
			statuses: criteria.statuses ? criteria.statuses.map(s => s.enumValue) : undefined,
			sent_from: criteria.sentFrom ? moment(criteria.sentFrom).format(Constants.DATE_TIME_FORMAT) : undefined,
			sent_to: criteria.sentTo ? moment(criteria.sentTo).format(Constants.DATE_TIME_FORMAT) : undefined,
			category_id: criteria.category?.id,
			subcategory_id: criteria.subcategory?.id,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	
	getSubcategories(categoryId) {
		return this.fetch(`${this.domain}/${this.path}/sub/${categoryId}`, {
			method: 'GET',
		})
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((err) => {
				throw err;
			});
	}
	
	sendReply(request) {
		return this.fetch(`${this.domain}/${this.path}/reply`, {
			method: 'POST',
			body: JSON.stringify(request),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	changeStatus(request){
		return this.fetch(`${this.domain}/${this.path}/change-status`, {
			method: 'PUT',
			body: JSON.stringify(request),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	approve(request){
		return this.fetch(`${this.domain}/${this.path}/approve`, {
			method: 'PUT',
			body: JSON.stringify(request),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	assignUser(request){
		return this.fetch(`${this.domain}/${this.path}/assign-user`, {
			method: 'PUT',
			body: JSON.stringify(request),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	changeCategory(request){
		return this.fetch(`${this.domain}/${this.path}/category-change`, {
			method: 'PUT',
			body: JSON.stringify(request),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	
	getImage(token, handleError,incidentId, attachmentId) {
        const url = new URL(`${this.domain}/${this.path}/${incidentId}/attachments/${attachmentId}`);
		const headers = {
			'Cache-Control': 'no-cache, no-store, must-revalidate',
			Pragma: 'no-cahce',
		};
			headers['Authorization'] = token;
		return new Promise((resolve, reject) => {
			this.fetchGet(url, headers)
				.then((response) => {
					console.log('content-disposition', response.headers.get('content-disposition'));
					return resolve(response.blob());
				})
				.catch((err) => {
					console.log('downloadFile error', err);
					if (handleError) {
						handleError('Nie można pobrać pliku!');
					}
				});
		});
	}
}
