import QuestionModel from "./QuestionModel";
export default class SurveyModel {
    constructor() {
        this.id = undefined;
        this.workingName = '';
        this.officialName = '';
        this.officialNameEng = '';
        this.questions = [];
        this.category = undefined;
        this.subcategory = undefined;
        this.activeFrom = undefined;
        this.activeTo = undefined;
        this.status = undefined;
        this.thanksText = 'Dziękujemy za udział, Twój głos jest dla nas ważny';
        this.thanksTextEng = '';
        this.targetGroup = undefined;
        this.areas = []
    }


    static assign(from, to) {
        if (from) {
            to.id = from.id;
            to.workingName = from.workingName ? from.workingName : '';
            to.officialName = from.officialName ? from.officialName : '';
            to.officialNameEng = from.officialNameEng ? from.officialNameEng : '';
            to.questions = QuestionModel.copy(from.questions, to.questions);
            to.category = from.category ? from.category : undefined;
            to.subcategory = from.subcategory ? from.subcategory : undefined;
            to.activeFrom = from.activeFrom ? new Date(from.activeFrom) : undefined;
            to.activeTo = from.activeTo ? new Date(from.activeTo) : undefined;
            to.status = from.status;
            to.thanksText = from.thanksText;
            to.thanksTextEng = from.thanksTextEng;
            to.targetGroup = from.targetGroup;
            to.areas = from.areas;
            to.resultQuestionModels = from.resultQuestionModels && from.status && from.status?.enumValue !== 'DRAFT' ? this.assignResultQuestionModels(from.resultQuestionModels) : null;
            to.genderGroup = from.genderGroupResult && from.status && from.status?.enumValue !== 'DRAFT' ? this.assignGenderGroup(from.genderGroupResult) : null;

        } else {
            to = new SurveyModel();
        }
    }
    static assignGenderGroup(from) {
         const modifiedList = [['', '']];

        if (from && from.length) {
            from.forEach(d => {
                modifiedList.push([d.label, d.value])
            });
        }
        return modifiedList;
    }
    static assignResultQuestionModels(from) {

        const modifiedList = [];
        if (from && from.length) {
            from.forEach(d => {
                modifiedList.push(this.assignRQM(d))
            });
        }
        return modifiedList;
    }
    static assignRQM(from) {
        const to = {
            questionId: from.questionId,
            name: from.name,
            type: from.type,
            data: [['', '']],
            dataFemale: [['', '']],
            dataMale: [['', '']],
            dataOther: [['', '']]
        }

        if (from.data && from.data.length) {
            const modifiedList = [['', '']];
            from.data.forEach(d => {
                modifiedList.push([d.label, d.value])
            });
            to.data = modifiedList;
        }
        if (from.dataFemale && from.dataFemale.length) {
            const modifiedList = [['', '']];
            from.dataFemale.forEach(d => {
                modifiedList.push([d.label, d.value])
            });
            to.dataFemale = modifiedList;
        }

        if (from.dataMale && from.dataMale.length) {
            const modifiedList = [['', '']];
            from.dataMale.forEach(d => {
                modifiedList.push([d.label, d.value])
            });
            to.dataMale = modifiedList;
        }
        if (from.dataOther && from.dataOther.length) {
            const modifiedList = [['', '']];
            from.dataOther.forEach(d => {
                modifiedList.push([d.label, d.value])
            });
            to.dataOther = modifiedList;
        }
        return to;
    }
    static copy(from) {
        const to = new SurveyModel();
        SurveyModel.assign(from, to);
        return to;
    }
 
}
