import PropTypes from 'prop-types';
import React from 'react';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import SimpleReactValidator from '../../components/validator';
import EditableDataTableExt from '../../components/EditableDataTableExt';
import ActionButton from '../../components/ActionButton';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
class QuestionComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modifiedList: new Map(),
            addedList: [],
        }
        this.createNewElement = this.createNewElement.bind(this)
    }
    createNewElement() {
        const newObject = {
            index: this.props.element?.answerOptions?.length + 1,
            name: '',
            nameEng: '',
            editor: 'TEXT',
            editable: true,
            viewMode: this.props.viewMode,
        };
        return newObject;
    }

    render() {
        const { idPrefix } = this.props;
        return (
            <div className="question-div">
                <div className="row">
                    <div className="col-12">
                        <ActionButton
                            label="Usuń pytanie"
                            className="float-right"
                            key={'next-button'}
                            handleClick={(e) => this.props.handleRemoveQuestion(e, idPrefix)}
                            iconName='mdi-delete'
                            iconSide='left'
                            iconSize='m'
                            rendered={this.props.viewMode !== 'VIEW'}
                        />
                    </div>
                </div>
                <div className="row">
                    <InputTextComponent
                        id={`${idPrefix}questionName`}
                        name='questionName'
                        label={'Treść pytania'}
                        // colClass={this.props.colClass}
                        value={this.props.element?.questionName}
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        validator={this.props.validator}
                        validators='required|max:160'
                        disabled={this.props.disabled}
                        validateViewMode
                        placeholder="Wpisz treść pytania"
                    />

                    <InputTextComponent
                        id={`${idPrefix}questionNameEng`}
                        name='questionNameEng'
                        label={'Treść pytania w języku angielskim'}
                        //  colClass={this.props.colClass}
                        value={this.props.element?.questionNameEng}
                        stateField={this.props.stateField}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        validator={this.props.validator}
                        validators='not_required|max:300'
                        disabled={this.props.disabled}
                        validateViewMode
                        placeholder="Wpisz treść pytania w języku angielskim"
                    />

                    <InputDropdownComponent
                        // colClass={this.props.colClass}
                        label="Rodzaj odpowiedzi"
                        id={`${idPrefix}answerType`}
                        name="answerType"
                        value={this.props.element?.answerType}
                        validator={this.props.validator}
                        validators="required"
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        options={this.props.answerTypeOptions}
                        optionLabel="label"
                        dataKey={"enumValue"}
                        stateField={this.props.stateField}
                        validateViewMode
                    />
                    <EditableDataTableExt
                        // messages={this.messages}
                        id={`${idPrefix}answerOptions`}
                        name='answerOptions'
                        label='Opcje odpowiedzi'
                        showLabel={true}
                        columns={[{
                            field: 'name',
                            header: 'Nazwa',
                            editor: 'TEXT',
                            validators: 'required|max:50'
                        }, {
                            field: 'nameEng',
                            header: 'Nazwa w języku angielskim',
                            editor: 'TEXT',
                            validators: 'not_required|max:100'


                        }]}
                        addedListName='addedList'
                        addedList={this.state.addedList}
                        modifiedListName='modifiedList'
                        modifiedList={this.state.modifiedList}
                        validator={this.props.validator}
                        viewMode={this.props.viewMode}
                        onChange={this.props.handleChange}
                        createNewElement={this.createNewElement.bind(this)}
                        editable
                        showAddButton={this.props.viewMode !== 'VIEW'}
                        showDeleteButton={this.props.viewMode !== 'VIEW'}
                        addButtonLabel='Dodaj kolejną opcję'
                        stateField={this.props.stateField}
                        dataKey='uuid'
                        updateItemElementAfterEdit={this.updateItemElementAfterEdit}
                        ref={this.editableListRef}
                        showDeleteButtonFunction={this.isItemExpired}
                        showDeleteButtonFunctionLabel='Nieaktywny'
                        className='answer-area-table'
                        tableClassName='answer-options-table'
                        value={this.props.element.answerOptions}
                        rendered={!!(this.props.element?.answerType && (this.props.element?.answerType?.enumValue === 'SINGLE_CHOICE' || this.props.element?.answerType?.enumValue === 'MULTIPLE_CHOICE'))}
                        publicMode
                        addButtonDisabled={!!(this.props.element?.answerOptions?.length >= 10)}
                        validators="array_required"
                        permanentDelete
                    />
                    <InputNumberComponent
                        id='minAnswerCount'
                        name='minAnswerCount'
                        label={'Minimalna liczba odpowiedzi'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'                 
                        value={this.props.element.minAnswerCount}
                        validator={this.props.validator}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        validators={`naturalNumber|not_required_number_max:${this.props.element.maxAnswerCount ?this.props.element.maxAnswerCount : 10}|not_required_number_min:1|not_required`}
                        rendered={!!(this.props.element?.answerType && this.props.element?.answerType?.enumValue === 'MULTIPLE_CHOICE')}
                        stateField={this.props.stateField}
                        min={1}
                        validateViewMode

                    />
                    <InputNumberComponent
                        id='maxAnswerCount'
                        name='maxAnswerCount'
                        label={'Maksymalna liczba odpowiedzi'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                        value={this.props.element.maxAnswerCount}
                        validator={this.props.validator}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        validators={`naturalNumber|not_required_number_min:${this.props.element.minAnswerCount ?this.props.element.minAnswerCount : 1}|not_required_number_max:10|not_required`}
                        rendered={!!(this.props.element?.answerType && this.props.element?.answerType?.enumValue === 'MULTIPLE_CHOICE')}
                        stateField={this.props.stateField}
                        min={1}
                        validateViewMode

                   />

                    <InputNumberComponent
                        id='minScale'
                        name='minScale'
                        label={'Dolna granica skali'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                        value={this.props.element.minScale}
                        validator={this.props.validator}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        validators={`naturalNumber|not_required_number_max:${this.props.element.maxScale ? this.props.element.maxScale : 100}|not_required_number_min:1|not_required`}
                        rendered={!!(this.props.element?.answerType && this.props.element?.answerType?.enumValue === 'POINTS_ON_SCALE')}
                        stateField={this.props.stateField}
                        min={1}
                        validateViewMode

                    />
                    <InputNumberComponent
                        id='maxScale'
                        name='maxScale'
                        label={'Górna granica skali'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                        value={this.props.element.maxScale}
                        validator={this.props.validator}
                        onChange={this.props.handleChange}
                        viewMode={this.props.viewMode}
                        validators={`naturalNumber|not_required_number_min:${this.props.element.minScale ? this.props.element.minScale : 1}|not_required_number_max:100|not_required`}
                        rendered={!!(this.props.element?.answerType && this.props.element?.answerType?.enumValue === 'POINTS_ON_SCALE')}
                        stateField={this.props.stateField}
                        min={1}
                        max={100}
                        validateViewMode

                    />
                </div>
            </div>
        );
    }
}

QuestionComponent.defaultProps = {
    element: null,
    idPrefix: '',
    viewMode: 'VIEW',
    stateField: 'element',
    colClass: 'col-md-4',
    disabled: false
};

QuestionComponent.propTypes = {
    element: PropTypes.object,
    answerTypeOptions: PropTypes.array,
    idPrefix: PropTypes.string,
    stateField: PropTypes.string,
    viewMode: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    onErrorMessage: PropTypes.func.isRequired,
    validator: PropTypes.instanceOf(SimpleReactValidator).isRequired,
    handleRemoveQuestion: PropTypes.func.isRequired,
};

export default QuestionComponent;
