import React from 'react';
import PropTypes from 'prop-types';

export const DivContainer = props => {
	const { children, colClass, rendered, styling } = props;
	if (rendered) {
		return <div className={colClass} style={styling}>{children}</div>;
	} else {
		return null;
	}
};

DivContainer.defaultProps = {
	colClass: 'col-xl-12 col-lg-12 col-md-12 col-sm-12',
	rendered: true,
	styling: undefined,
};

DivContainer.propTypes = {
	colClass: PropTypes.string,
	rendered: PropTypes.bool,
	styling: PropTypes.any,
};

export default DivContainer;
