import React from 'react';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import PropTypes from 'prop-types';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import InputCalendarComponent from '../../components/inputs/InputCalendarComponent';
import moment from 'moment';
import ReminderService from '../../services/ReminderService';

class ReminderContainer extends BaseDialogContainer {
    constructor(props) {
        super(props, new ReminderService());
        this.state = {
            elementId: props.id,
            element: {
                note: '',
                refId: this.props.refId,
                type: this.props.type,
            },
        }
    }

    renderHeader() {

    }
    getContainerListName() {
        return this.props.reminderCookieKey ?  this.props.reminderCookieKey : ''
    }
    getAddSucces() {
        return 'Przypomnienie zostało zapisane';
    }
   
    handleError(err) {
        if (this.props.onError) {
            this.persistMessage('error', '', err.message);
            this.props.onError()
        } else {
            this.showErrorMessage(err.message)
        }
    }
    handleError(err) {
        if (this.props.onError) {
            this.persistMessage('error', '', err.message);
            this.props.onError()
        } else {
            this.showErrorMessage(err.message)
        }
    }
    createOrUpdate() {
		let { element } = this.state;
		this.scrollToTop();
		if (this._isMounted) {
			if (element.id === undefined || element.id === null) {
				element = this.updateElementBeforeCreateOrUpdate(element)
				this.service
					.add(element)
					.then((response) => {
                        this.persistMessage('success', '', "Powiadomienie zostało zapisane")
                        this.unblockUi()
                        this.props.afterSave()
					})
					.catch((err) => {
                        this.unblockUi()
						this.handleError(err)
					});
			}
		}
	}

    renderDetails() {
        return (
            <React.Fragment>
                {this.props?.type === 'INCIDENT' ?
                <div className="row">
                    <InputTextareaComponent
                        id='note'
                        name='note'
                        label={'Treść komunikatu'}
                        colClass='col-12'
                        value={this.state.element.note}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'required|max:200'}
                        validateViewMode
                        autoResize
                        rows={10}
                    /> 
                    <InputCalendarComponent
						id='sendDate'
						name='sendDate'
						label={'Data wysłania'}
						dateFormatMoment='YYYY-MM-DD HH:mm'
					//	colClass='col-md-4'
						value={this.state.element.sendDate}
						validator={this.validator}
						validators={`required|date|date_format~DD-MM-YYYY HH:mm,dd-mm-rrrr HH:mm|after_or_equal_time~${moment(new Date()).format('DD-MM-YYYY HH:mm:ss').toString()}`}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						rendered={!this.state.element.immediatelySend || this.props.viewMode === 'VIEW'}
						validateViewMode
						showTime
						minDate={new Date()}
						required
                        placeholder='dd-mm-rrrr GG:MM'
                        showIcon
					/>
                    </div>
                    : null}
            </React.Fragment>
        );
    }

}

ReminderContainer.defaultProps = {
    viewMode: 'NEW',
    visible: false,
};

ReminderContainer.propTypes = {
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    refId:PropTypes.number
};

export default ReminderContainer;