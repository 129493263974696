import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import $ from 'jquery';
export class DataScroller extends Component {
	static defaultProps = {
		id: null,
		value: null,
		rows: 0,
		inline: false,
		scrollHeight: null,
		loader: null,
		buffer: 0.9,
		style: null,
		className: null,
		onLazyLoad: null,
		itemTemplate: null,
		header: null,
		footer: null,
		lazy: false,
		lastId: null,
		oldestId: null,
		allowScroll: false,
		allowScrollDown:false,
		ready: false
	};

	static propTypes = {
		id: PropTypes.string,
		value: PropTypes.array,
		rows: PropTypes.number,
		inline: PropTypes.bool,
		scrollHeight: PropTypes.any,
		loader: PropTypes.any,
		buffer: PropTypes.number,
		style: PropTypes.object,
		className: PropTypes.string,
		onLazyLoad: PropTypes.func,
		itemTemplate: PropTypes.func,
		header: PropTypes.any,
		footer: PropTypes.any,
		lazy: PropTypes.bool,
		lastId: PropTypes.number,
		oldestId: PropTypes.number,
		allowScroll: PropTypes.bool,
		allowScrollDown: PropTypes.bool,
		ready: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {};
		this.dataToRender = [];
		this.value = this.props.value;
		this.first = 0;
		this.scrolledToLastMessage = false;
		this.firstScrolling = true;
	}

	handleDataChange() {
		if (this.props.lazy) {
			this.dataToRender = this.value;
			this.setState({ dataToRender: this.dataToRender });
		} else {
			this.load();
		}
	}

	load() {
		if (this.props.lazy) {
			if (this.props.onLazyLoad) {
				if (this.props.enableLoading) {
					this.props.onLazyLoad(this.createLazyLoadMetadata(), () => {
						//	this.first = this.first + this.props.rows;
						this.handleDataChange();
					});
				}
			}
		} else {
			if (this.value) {
				for (
					var i = this.first;
					i < this.first + this.props.rows;
					i++
				) {
					if (i >= this.value.length) {
						break;
					}

					this.dataToRender.push(this.value[i]);
				}

				this.first = this.first + this.props.rows;

				this.setState({
					dataToRender: this.dataToRender,
				});
			}
		}
	}
	reset() {
		this.first = 0;
		this.dataToRender = [];
		this.setState({ dataToRender: this.dataToRender });
		this.load();
	}

	isEmpty() {
		return !this.dataToRender || this.dataToRender.length === 0;
	}

	createLazyLoadMetadata() {
		return {
			first: this.first,
			rows: this.props.rows,
		};
	}

	bindScrollListener() {
		if (this.props.inline && this.contentElement ) {
			this.scrollFunction = () => {
				var scrollTop = this.contentElement ? this.contentElement.scrollTop : 0;
				// var scrollTop = this.contentElement.scrollTop;
				//scrollHeight = this.contentElement.scrollHeight,
				//	viewportHeight = this.contentElement.clientHeight;
				// if (
				// 	scrollTop <
				// 	scrollHeight * this.props.buffer - viewportHeight
				// ) {
				// 	console.log('scrolllllllllllll');
				// 	this.load(this.props.oldestId);
				// }
				if (scrollTop === 0) {
					this.load();
				}
			};

			this.contentElement.addEventListener('scroll', this.scrollFunction);
		} else if(this.contentElement) {
			this.scrollFunction = () => {
				var docBody = document.body,
					docElement = document.documentElement,
					scrollTop =
						window.pageYOffset ||
						document.documentElement.scrollTop,
					winHeight = docElement.clientHeight,
					docHeight = Math.max(
						docBody.scrollHeight,
						docBody.offsetHeight,
						winHeight,
						docElement.scrollHeight,
						docElement.offsetHeight
					);

				// if (scrollTop < docHeight * this.props.buffer - winHeight) {
				// 	this.load(this.props.oldestId);
				// }
				if (scrollTop === 0) {
					this.load();
				}
			};

			window.addEventListener('scroll', this.scrollFunction);
		}
	}

	unbindScrollListener() {
		if (this.scrollFunction) {
			if (this.props.inline) {
				this.contentElement.removeEventListener(
					'scroll',
					this.scrollFunction
				);
				this.contentElement = null;
			} else if (this.loader && this.isLoaded) {
				this.loader.removeEventListener('click', this.scrollFunction);
			} else {
				window.removeEventListener('scroll', this.scrollFunction);
			}
		}
	}

	componentDidMount() {
		this.scrolledToLastMessage = false;
		this.firstScrolling = true;
		// this.contentElement.scrollTo({
		//     left: 0,
		//     top:this.contentElement.scrollHeight - this.contentElement.clientHeight,
		//     behavior: 'smooth'
		//   });

		//     window.scrollTo(0,this.contentElement.scrollHeight);

		// this.contentElement.scrollTop = this.contentElement.scrollHeight;
		if(this.props.ready){
			this.load(true);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(!prevProps.ready && this.props.ready){
			this.load();
		}
		var newValue = this.props.value;
		if ((!this.scrolledToLastMessage && this.props.allowScroll) || this.props.allowScrollDown) {
			if (
				this !== undefined &&
				ReactDOM.findDOMNode(this) !== undefined &&
				ReactDOM.findDOMNode(this) !== null
			) {
				//const errors = ReactDOM.findDOMNode(this).getElementById(`${8}_datascrollitem`);
				let item;
				if (this.firstScrolling || this.props.allowScrollDown) {
					item = $(`#${this.props.lastId}_datascrollitem`);
				} else {
					console.log(this.props.oldestId, 'oldest id');
					item = $(`#${this.props.oldestId}_datascrollitem`);
				}
				if (item && item.length > 0) {
					item[0].parentNode.scrollIntoView();
					this.firstScrolling = false;
				}
				if (this.props.loader) {
					this.scrollFunction = () => {
						this.load(this.props.oldestId);
					};
					this.loader = ReactDOM.findDOMNode(this.props.loader);
					this.loader.addEventListener('click', this.scrollFunction);
					this.isLoaded = true;
				} else {
					this.bindScrollListener();
				}
			}
		}

		if (newValue && this.value !== newValue) {
			this.value = newValue;

			this.handleDataChange();
		}

		if (this.props.loader && !this.isLoaded) {
			this.unbindScrollListener();

			this.scrollFunction = () => {
				this.load(this.props.oldestId);
			};
			this.loader = ReactDOM.findDOMNode(this.props.loader);
			this.loader.addEventListener('click', this.scrollFunction);
			this.isLoaded = true;
		}
	}

	componentWillUnmount() {
		if (this.scrollFunction) {
			this.unbindScrollListener();
		}
	}
	render() {
		var className = classNames(
			'p-datascroller p-component',
			this.props.className,
			{
				'p-datascroller-inline': this.props.inline,
			}
		);

		var header = this.props.header && (
				<div className="p-datascroller-header">
					{' '}
					{this.props.header}
				</div>
			),
			footer = this.props.footer && (
				<div className="p-datascroller-footer">
					{' '}
					{this.props.footer}{' '}
				</div>
			),
			content = (
				<div
					ref={(el) =>
						(this.contentElement = ReactDOM.findDOMNode(el))
					}
					id="datascroller-content"
					className="p-datascroller-content"
					style={{
						maxHeight: this.props.scrollHeight,
						minHeight: this.props.scrollHeight,
					}}>
					<ul className="p-datascroller-list">
						{this.props.extendedRender && this.state.dataToRender
							? this.props.extendedRender(this.state.dataToRender)
							: this.state.dataToRender &&
							  this.state.dataToRender.map((val, i) => {
									var listItemContent = this.props
										.itemTemplate
										? this.props.itemTemplate(val)
										: val;
									return (
										<li
											key={i + '_datascrollitem'}
											id={i + '_datascrollitem'}>
											{listItemContent}
										</li>
									);
							  })}
					</ul>
				</div>
			);

		return (
			<div id={this.props.id} className={className}>
				{header}
				{content}
				{footer}
			</div>
		);
	}
}
