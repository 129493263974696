import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import InputTextEditorComponent from '../components/inputs/InputTextEditorComponent';
import InputFileUploadComponent from '../components/inputs/InputFileUploadComponent';
import CustomLabel from '../components/CustomLabel';
import EmailService from '../services/EmailService';
import DivContainer from '../components/DivContainer';
import { Button } from 'primereact/button';

class EmailContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new EmailService());
		this.state = {
			loading: true,
			elementId: props.id,
			element: {
				content: '',
				description: '',
				subject: '',
				emailType: undefined,
				attachments: [],
				contentEng: '',
				subjectEng: '',
			},
			emailTypeOptions: [],
			parameters: [],
		};
		this.editorContentRef = React.createRef();
		this.editorContentEngRef = React.createRef();
	}

	updateElement(data) {
		this.setState(
			{
				element: {
					id: data.id,
					active: data.active,
					content: data.content,
					description: data.description,
					subject: data.subject,
					emailType: data.emailType,
					attachments: data.attachments,
					contentEng: data.contentEng,
					subjectEng: data.subjectEng,
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}

	initBeforeSetElement() {
		this.enumService
			.getEnumList('EmailType')
			.then((emailTypeOptions) =>
				this.setState({
					emailTypeOptions,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy typów wiadomości');
			});
	}
	initAfterSetElement() {
		if (this.state.element.emailType) {
			this.service.getParameters(this.state.element.emailType.enumValue).then((params) => this.setState({ parameters: params }));
		}
		super.initAfterSetElement();
	}

	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode } = this.props;
		const { elementId } = this.state;
		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${elementId}`;
		}
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
				label: 'Zapisz',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: viewMode === 'NEW' || viewMode === 'EDIT',
			},
		];
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowy szablon wiadomości' : viewMode === 'EDIT' ? 'Edycja szablonu wiadomości' : 'Szczegóły szablonu wiadomości',
				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(viewMode === 'VIEW'),
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}

	tachmentRemove(e, i) {
		if (this._isMounted) {
			const modifiedList = this.state.element.attachments;
			modifiedList.splice(i, 1);
			this.setState((prevState) => ({
				element: {
					...prevState.element,
					attachments: modifiedList,
				},
			}));
		}
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/email-template-list' className='p-link'>
					{'Szablony wiadomości'}
				</BreadcrumbsItem>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/email-template/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/email-template/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/email-template/create'>{'Nowy'}</BreadcrumbsItem> : null}
				{this.renderView()}
			</div>
		);
	}
	getContainerListName() {
		return 'email-list-container';
	}
	
	getUpdateSucces(){
		return 'Szablon wiadomości został zaktualizowany';
	}
	renderDetails() {
		let parameterButtonDisabled = !(this.state.parameter != null && this.state.parameter != undefined);
		let parameterEngButtonDisabled = !(this.state.parameterEng != null && this.state.parameterEng != undefined);
		return (
			<React.Fragment>
				<div class='row'>
					<div class='col-md-6 col-sm-12'>
						<InputDropdownComponent
							id='emailType'
							name='emailType'
							label={'Typ wiadomości'}
							colClass='col-md-12'
							value={this.state.element.emailType}
							validator={this.validator}
							options={this.state.emailTypeOptions}
							onChange={this.handleChange}
							viewMode={this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode}
							optionLabel='label'
							dataKey='value'
							valueView={this.state.element.emailType ? this.state.element.emailType.label : ''}
						/>
						<InputTextComponent
							id='description'
							name='description'
							label={'Opis'}
							colClass='col-md-12'
							value={this.state.element.description}
							validator={this.validator}
							validators='required|max:255'
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
						/>
						<InputTextComponent
							id='subject'
							name='subject'
							label={'Temat'}
							colClass='col-md-12'
							value={this.state.element.subject}
							validator={this.validator}
							validators='required|max:255'
							onChange={this.handleChange}
							viewMode={this.props.viewMode}
						/>
						{this.props.viewMode !== 'VIEW' ? (
							<div className='col-xl-10 col-lg-10 col-md-10 col-sm-12'>
								<div className='row'>
									<InputDropdownComponent
										id='parameter'
										name='parameter'
										label='Parametr'
										optionLabel='label'
										dataKey='enumValue'
										validator={this.validator}
										validators='not_required'
										value={this.state.parameter}
										options={this.state.parameters}
										colClass='col-lg-8 col-md-8 col-sm-8'
										onChange={this.handleChange}
										viewMode={this.props.viewMode}
										rendered
										stateField=''
										showClear={false}
										valueView={this.state.parameter}
									/>
									<div>
										<Button
											className='p-button p-component p-button p-component p-button-text-only float-right p-button-text-only'
											style={{ marginTop: '30px' }}
											label='Wstaw'
											disabled={parameterButtonDisabled}
											onClick={(e) => {
												e.preventDefault();
												const quill = this.editorContentRef.current.quill;
												const range = quill.getSelection();
												const position = range ? range.index : 0;
												quill.insertText(position, this.state.parameter.enumValue);
											}}
										/>
									</div>
								</div>
							</div>
						) : null}
					</div>
				<InputTextEditorComponent
						colClass='col-xl-12'
						id='content'
						name='content'
						value={this.state.element.content}
						label='Treść wiadomości'
						placeholder='Treść wiadomości...'
						validator={this.validator}
						validators={`required|max:2048`}
						rows='10'
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						editorRef={this.editorContentRef}
					/>
					<InputTextComponent
						id='subjectEng'
						name='subjectEng'
						label={'Temat w języku angielskim'}
						colClass='col-md-4'
						value={this.state.element.subjectEng}
						validator={this.validator}
						validators='not_required|max:255'
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						rendered={this.state?.element?.emailType?.enumValue === 'LINK_USER_REGISTERED' ? false : true}
					/>
					{this.props.viewMode !== 'VIEW' && this.state?.element?.emailType?.enumValue !== 'LINK_USER_REGISTERED'? (
						<div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
							<div className='row'>
								<InputDropdownComponent
									id='parameter-eng'
									name='parameterEng'
									label='Parametr'
									optionLabel='label'
									dataKey='enumValue'
									validator={this.validator}
									validators='not_required'
									value={this.state.parameterEng}
									options={this.state.parameters}
									colClass='col-lg-4 col-md-12'
									onChange={this.handleChange}
									viewMode={this.props.viewMode}
									rendered
									stateField=''
									showClear={false}
									valueView={this.state.parameterEng}
									
								/>
								<div>
									<Button
										className='p-button p-component p-button p-component p-button-text-only float-right p-button-text-only'
										style={{ marginTop: '30px' }}
										label='Wstaw'
										disabled={parameterEngButtonDisabled}
										onClick={(e) => {
											e.preventDefault();
											const quill = this.editorContentEngRef.current.quill;
											const range = quill.getSelection();
											const position = range ? range.index : 0;
											quill.insertText(position, this.state.parameterEng.enumValue);
										}}
									/>
								</div>
							</div>
						</div>
					) : null}
					<InputTextEditorComponent
						colClass='col-xl-12'
						id='contentEng'
						name='contentEng'
						value={this.state.element.contentEng}
						label='Treść wiadomości w języku angielskim'
						placeholder='Treść wiadomości...'
						validator={this.validator}
						validators={`max:2048|not_required`}
						rows='10'
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						editorRef={this.editorContentEngRef}
						rendered={this.state?.element?.emailType?.enumValue === 'LINK_USER_REGISTERED' ? false : true}
					/>
					
					{/* <InputFileUploadComponent
						messages={this.messages}
						className='col-xl-6'
						id='attachments'
						name='attachments'
						label='Załączniki'
						fileList={this.state.element.attachments}
						showLabel
						insideTable={false}
						restApiUrl={`${process.env.REACT_APP_BACKEND_URL}/attachment/upload`}
						multiple
						maxFileSize={1000000}
						onChange={this.handleChange}
						// handleUpload={this.onUpload}
						// handleRemove={this.onAttachmentRemove}
						itemLabel='originalFileName'
						itemName='fileName'
						colClass='col-xl-2 col-lg-2 col-md-2 col-sm-2'
						rendered
						viewMode={this.props.viewMode}
						onAfterStateChange={() => {
							console.log('state', this.state.element);
						}}
						customHeaders={{
							Accept: 'application/json',
							'Cache-Control': 'no-cache, no-store, must-revalidate',
							Pragma: 'no-cahce',
							Authorization: this.authService.getToken(),
						}}
						token={this.authService.getToken()}
						handleError={this.onUploladError}></InputFileUploadComponent> */}
				</div>
			</React.Fragment>
		);
	}
}

EmailContainer.defaultProps = {
	backUrl: '/#/email-template-list',
	cancelUrl: '/#/email-template/details',
	editUrl: '/#/email-template/edit',
};

EmailContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
	editUrl: PropTypes.string,
};

export default EmailContainer;
