import moment from 'moment';
import 'moment/locale/pl';
import React from 'react';
import ActionButton from '../../../components/ActionButton';
import InputNumberComponent from '../../../components/inputs/InputNumberComponent';
import AppPrefixUtils from '../../../utils/AppPrefixUtils';
import './Message.css';

export default function Message(props) {
	const {
		data,
		isMine,
		startsSequence,
		endsSequence,
		showTimestamp,
		hideSender,
		onDownloadFile,
	} = props;
	const friendlyTimestamp = moment(data.createDate).format('LLLL');
	return (
		<div className={['message', `${isMine ? 'mine' : ''}`, `${startsSequence ? 'start' : ''}`, `${endsSequence ? 'end' : ''}`].join(' ')}>
			{showTimestamp && <div className='timestamp'>{friendlyTimestamp}</div>}

			<div className='bubble-container'>
				<div className={`bubble ${data.read === true ? '' : 'font-weight-bold'}`} title={friendlyTimestamp}>
					{data.message ? data.message : null}
					{data.attachment ? data.attachment.fileName : null}
					{!hideSender ? (
						<div className='bubble-sender'>
							{' '}
							{data.senderEmail}, {friendlyTimestamp}
						</div>
					) : (
						<React.Fragment />
					)}
					{data.attachment ? (
						<React.Fragment>
							<hr
								style={{
									marginTop: '2px',
									marginBottom: '2px',
								}}
							/>
							<ActionButton
								label='Pobierz plik'
								handleClick={() => {
									if (onDownloadFile) {
										onDownloadFile(data.attachment);
									}
								}}
								disabled={!data.attachment.id && data.attachment.downloading}
							/>
						</React.Fragment>
					) : (
						<React.Fragment />
					)}
				</div>
			</div>
		</div>
	);
}
