import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import BaseInputComponent from '../inputs/BaseInputComponent';

class CriteriaCalendarRangeComponent extends BaseInputComponent {
	componentDidMount() { }
	
render() {
	const {
		appendTo,
		colClass,
		dateFormat,
		dateFormatMoment,
		disabled,
		from,
		idFrom,
		idTo,
		label,
		labelFrom,
		labelTo,
		nameFrom,
		nameTo,
		onChange,
		placeholderFrom,
		placeholderTo,
		readOnlyInput,
		showIcon,
		to,
		validator,
		validators,
		validatorsFromExt,
		monthNavigator,
		yearNavigator,
		yearRange,
		timeOnly,
		showTime,
		validateOnChange,
		showSeconds,
		maxDateInfinite
	} = this.props;
	let validatorsTo = validators;
	let validatorsFrom = validatorsFromExt ? validatorsFromExt : validators;
	let fromDate = undefined;
	let toDate = undefined;
	addLocale('pl', {
		today: 'Dzisiaj',
		clear: 'Wyczyść',
		monthNames: [
			'Styczeń',
		'Luty',
		'Marzec',
		'Kwiecień',
		'Maj',
		'Czerwiec',
		'Lipiec',
		'Sierpień',
		'Wrzesień',
		'Październik',
		'Listopad',
		'Grudzień',
		],
	monthNamesShort: [
		'Sty',
		'Lut',
		'Mar',
		'Kwi',
		'Maj',
		'Cze',
		'Lip',
		'Sie',
		'Wrz',
		'Paź',
		'Lis',
		'Gru',
	],
	dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
	dayNamesShort: ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
	dayNamesMin: ['N', 'P', 'W', 'Ś', 'Cz', 'Pt', 'S'],
	firstDayOfWeek: 1,
});
const timeFormat = showSeconds ? 'HH:mm:ss' : 'HH:mm'

	if (from && moment.isDate(from) && moment(from).isValid()) {
		fromDate = new Date(from);
		if (!timeOnly) {
			validatorsTo = `${validatorsTo}|after_or_equal:${moment(fromDate).format(
				dateFormatMoment
			)},${dateFormatMoment}`;
		} else {
			validatorsTo = `${validatorsTo}|after_or_equal_time~${moment(fromDate).format(timeFormat).toString()}`;
		}
	}
	if (to && moment.isDate(to) && moment(to).isValid()) {
		toDate = new Date(to);
		if (!timeOnly) {
			validatorsFrom = `${validatorsFrom}|before_or_equal:${moment(toDate).format(
				dateFormatMoment
			)},${dateFormatMoment}`;
		} else {
			validatorsFrom = `${validatorsFrom}|before_or_equal_time~${moment(toDate).format(timeFormat).toString()}`;
		}
	}
	const requiredFrom =
		validatorsFrom !== undefined &&
		validatorsFrom.includes('required') &&
		!validatorsFrom.includes('not_required');
	const requiredTo =
		validatorsTo !== undefined &&
		validatorsTo.includes('required') &&
		!validatorsTo.includes('not_required');
	const info = '. Aby przejść do edycji kolejnego pola kliknij Esc następnie Tab';
	return (
		<div className={colClass}>
			<div className="row">
				<div className="col-md-12 form-group">
					<div className="row">
					<div className="col-md-6">
								<span id={`${idFrom}-label-id`} className="p-label" style={{ width: '50%' }}>
									{label} {labelFrom}
								</span>
							<Calendar
								className={"criteria-calendar-filter"}
								appendTo={appendTo}
								ariaDescribedBy={`${idFrom}-error`}
								ariaLabel={`${label} ${labelFrom} - data w formacie ${placeholderFrom}${info}`}
								key={idFrom}
								id={idFrom}
								inputId={`${idFrom}-input`}
								name={nameFrom}
								style={{ width: '100%' }}
								value={from}
								onChange={onChange}
								readOnlyInput={readOnlyInput}
								locale='pl'
								dateFormat={dateFormat}
								placeholder={placeholderFrom}
								showIcon={showIcon}
								disabled={disabled}
								monthNavigator={monthNavigator}
								yearNavigator={yearNavigator}
								yearRange={yearRange}
								maxDate={maxDateInfinite ? undefined : (!timeOnly ? new Date() : undefined)}
								required={requiredFrom}
								timeOnly={timeOnly}
								showTime={showTime}
								showSeconds={showSeconds}
								className="mt-2"
							/>
								{this.renderValidatorsMessages(validator, idFrom, labelFrom, from, validatorsFrom)}
						</div>
						<div className="col-md-6">
								<span id={`${idTo}-label-id`} className="p-label" style={{ width: '50%' }}>
									{label} {labelTo}
								</span>
							<Calendar
								className={"criteria-calendar-filter"}		
								appendTo={appendTo}
								ariaDescribedBy={`${idTo}-error`}
								ariaLabel={`${label} ${labelTo} - data w formacie ${placeholderTo}${info}`}
								key={idTo}
								id={idTo}
								inputId={`${idTo}-input`}
								name={nameTo}
								style={{ width: '100%' }}
								value={to}
								className="mt-2"
								onChange={onChange}
								readOnlyInput={readOnlyInput}
								minDate={validateOnChange ? fromDate : undefined}
								locale='pl'
								dateFormat={dateFormat}
								placeholder={placeholderTo}
								showIcon={showIcon}
								disabled={disabled}
								monthNavigator={monthNavigator}
								yearNavigator={yearNavigator}
								yearRange={yearRange}
								maxDate={maxDateInfinite ? undefined : (!timeOnly ? new Date() : undefined)}
								required={requiredTo}
								timeOnly={timeOnly}
								showTime={showTime}
								showSeconds={showSeconds}
							/>
								{this.renderValidatorsMessages(validator, idTo, labelTo, to, validatorsTo)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
}

CriteriaCalendarRangeComponent.defaultProps = {
	colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
	dateFormat: 'dd-mm-yy',
	dateFormatMoment: 'DD-MM-YYYY',
	disabled: false,
	labelFrom: 'od',
	labelTo: 'do',
	placeholderFrom: 'dd-mm-rrrr',
	placeholderTo: 'dd-mm-rrrr',
	readOnlyInput: false,
	showIcon: true,
	validators: 'not_required|date|date_format:DD-MM-YYYY,dd-mm-rrrr',
	monthNavigator: true,
	yearNavigator: true,
	yearRange: '1900:2030',
	timeOnly: false,
	showTime: false,
	validateOnChange: true,
	showSeconds: true,
	maxDateInfinite: false
};

CriteriaCalendarRangeComponent.propTypes = {
	appendTo: PropTypes.any,
	colClass: PropTypes.string,
	dateFormat: PropTypes.string,
	dateFormatMoment: PropTypes.string,
	disabled: PropTypes.bool,
	from: PropTypes.instanceOf(Date).isRequired,
	idFrom: PropTypes.string.isRequired,
	idTo: PropTypes.string.isRequired,
	label: PropTypes.string,
	labelFrom: PropTypes.string,
	labelTo: PropTypes.string,
	monthNavigator: PropTypes.bool,
	nameFrom: PropTypes.string.isRequired,
	nameTo: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholderFrom: PropTypes.string,
	placeholderTo: PropTypes.string,
	readOnlyInput: PropTypes.bool,
	showIcon: PropTypes.bool,
	to: PropTypes.instanceOf(Date).isRequired,
	validator: PropTypes.oneOfType(PropTypes.array, PropTypes.object).isRequired,
	validators: PropTypes.string,
	validatorsFromExt: PropTypes.string,
	yearNavigator: PropTypes.bool,
	yearRange: PropTypes.string,
	timeOnly: PropTypes.bool,
	showTime: PropTypes.bool,
	maxDateToday: PropTypes.bool,
	showSeconds: PropTypes.bool,
	maxDateInfinite:PropTypes.bool,
};

export default CriteriaCalendarRangeComponent;
