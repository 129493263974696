import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {ColorPicker} from 'primereact/colorpicker';
import BaseInputComponent from './BaseInputComponent';

class InputColorComponent extends BaseInputComponent {
	renderView() {
		const {
			colClass,
			formGroup,
			iconColor,
			iconName,
			iconSide,
			iconSize,
			id,
			insideTable,
			label,
			objectId,
			showLabel,
			titleElement,
			titleElementClass,
			titleElementShowId,
			value,
			validateViewMode,
			validator,
			validators,
		} = this.props;
		return (			
			<div className={insideTable ? '' : titleElement ? titleElementClass : colClass}>
				<div className={insideTable ? '' : 'row'}>
					<div className={insideTable ? '' : `col-md-12 ${formGroup ? 'form-group' : ''}`}>
						{label !== undefined && showLabel && !titleElement ? (
							<label
								id={`${id}-label-id`}
								className="p-label"
								htmlFor={id}
								style={{ width: '100%' }}
							>
								{label}
							</label>
						) : null}
						<span
							aria-label={label}
							aria-labelledby={`${id}-label-id`}
							className={`p-inputtext-view icon_text ${titleElement ? ' title-element' : ''} ${
								iconName !== undefined ? iconColor : ''
							}`}
						>
							{iconSide === 'left' && iconName !== undefined ? (
								<i className={`icon mdi ${iconName} ${iconSize}`} />
							) : null}
							
							{value ? (
							<div className="p-inputcolor-view" style={{backgroundColor: `#${value}`}} >&nbsp;</div>
							) : null}
							{iconSide === 'right' && iconName !== undefined ? (
								<i className={`icon mdi ${iconName} ${iconSize}`} />
							) : null}
						</span>
						{titleElement && titleElementShowId ? (
							<span className="p-inputtext-view">{` ID: ${objectId}`}</span>
						) : null}
						{validateViewMode ? this.renderValidatorsMessages(validator, id, label, value, validators) : null}
					</div>
				</div>
			</div>
		);
	}

	renderEdit() {
		return this.renderNew();
	}

	

	renderNew() {
		const {
			colClass,
			id,
			insideTable,
			label,
			name,
			onAfterStateChange,
			onChange,
			showLabel,
			stateField,
			validator,
			validators,
			value,
		} = this.props;

		return (
			<div className={insideTable ? '' : colClass}>
				<div className={insideTable ? '' : 'row'}>
					<div className={insideTable ? '' : 'col-md-12 form-group'}>
						{label !== undefined && showLabel ? (
							<label
								id={`${id}-label-id`}
								className="p-label"
								htmlFor={id}
								style={{ width: '100%' }}
							>
								{label}
							</label>
						) : null}
						{(value !== undefined && value !== null) ? (
						<ColorPicker
							id={id}
							name={name}
							value={value}
							format="hex"
							onChange={e =>
								onChange ? onChange('COLOR', undefined, e, onAfterStateChange, stateField) : null
							}
						/>
						) : null}						
							{this.renderValidatorsMessages(validator, id, label, value, validators)}
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { rendered, viewMode } = this.props;
		if (rendered) {
			switch (viewMode) {
				case 'NEW':
					return this.renderNew();
				case 'EDIT':
					return this.renderEdit();
				case 'VIEW':
				default:
					return this.renderView();
			}
		} else {
			return null;
		}
	}
}

InputColorComponent.defaultProps = {
	colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
	disabled: false,
	formGroup: true,
	iconSide: 'left',
	insideTable: false,
	max: 255,
	objectId: '',
	placeholder: '',
	publicMode: false,
	rendered: true,
	showLabel: true,
	stateField: 'element',
	titleElement: false,
	titleElementShowId: false,
	titleElementClass: 'col-md-12',
	validateViewMode: false,
	validators: 'required',
	viewMode: 'VIEW',
};

InputColorComponent.propTypes = {
	colClass: PropTypes.string,
	disabled: PropTypes.bool,
	formGroup: PropTypes.bool,
	iconColor: PropTypes.string,
	iconName: PropTypes.string,
	iconSide: PropTypes.string,
	iconSize: PropTypes.string,
	id: PropTypes.string.isRequired,
	insideTable: PropTypes.bool,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	objectId: PropTypes.string,
	onAfterStateChange: PropTypes.func,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	publicMode: PropTypes.bool,
	rendered: PropTypes.bool,
	showLabel: PropTypes.bool,
	stateField: PropTypes.string,
	titleElement: PropTypes.bool,
	titleElementClass: PropTypes.string,
	titleElementShowId: PropTypes.bool,
	validateViewMode: PropTypes.bool,
	validator: PropTypes.oneOfType(PropTypes.array, PropTypes.object).isRequired,
	validators: PropTypes.string,
	value: PropTypes.string,
	viewMode: PropTypes.string,
};

export default InputColorComponent;
