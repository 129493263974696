import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import NotificationService from '../../services/NotificationService';
import UserRoleService from '../../services/UserRoleService';
import CriteriaCalendarRangeComponent from '../../components/criteria/CriteriaCalendarRangeComponent';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import PublicUnitService from '../../services/PublicUnitService';
import CriteriaCheckboxComponent from '../../components/criteria/CriteriaCheckboxComponent';
import CategoryService from '../../services/CategoryService';
class NotificationListContainer extends BaseListContainer {
	constructor(props) {
		super(props, new NotificationService());
		this.roleService = new UserRoleService();
		this.categoryService = new CategoryService();
		this.state = {
			list: [],
			loading: true,
			size: 0,
			first: 0,
			criteria: this.getCleanSearchCriteria(),
			statusOptions: [],
			statusSC: undefined,
			subcategoriesOpt: [],
			categoryOpt: []
		};
	}

	updateSearchCriteria(criteria) {
		return {
			textSc: criteria.textSc,
			publicUnitIds: criteria.publicUnitIds,
			sentFrom: criteria.sentFrom,
			sentTo: criteria.sentTo,
			createFromSc: criteria.createFromSc,
			createToSc: criteria.createToSc,
			activeFromSc: criteria.activeFromSc,
			activeToSc: criteria.activeToSc,
			status: criteria.status,
			hasAttachments: criteria.hasAttachments,
			draftOnly: criteria.draftOnly,
			category: criteria.category,
			subcategory: criteria.subcategory,
			author: criteria.author,
			firstResult: criteria.firstResult,
			maxResult: criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}
	getCleanSearchCriteriaPrototype() {

		const criteria = this.getCleanSearchCriteria();
		const status = this.state.statusSC ? this.state.statusSC : criteria.status;

		criteria.limitObj = { value: criteria.maxResult };
		criteria.limit = 10;
		criteria.status = status;
		return criteria;
	}
	getCleanSearchCriteria() {
		return {
			textSc: '',
			publicUnitIds: undefined,
			sentFrom: undefined,
			sentTo: undefined,
			createFromSc: undefined,
			createToSc: undefined,
			activeFromSc: undefined,
			activeToSc: undefined,
			hasAttachments: false,
			draftOnly: this.state?.activeIndex !== 0,
			category: undefined,
			subcategory: undefined,
			author: '',
			id: null,
			firstResult: 0,
			maxResult: 10,
			sortField: 'createDate',
			sortAsc: false,
		};
	}

	initializeFromBackend() {
		this.enumService
			.getEnumList('UserActivityStatus')
			.then((statusOptions) =>
				this.setState({
					statusOptions,
				})
			)
			.catch(() => {
				this.showErrorMessage('Nie udało się pobrać listy statusów');
			});
		this.categoryService.getCategories("NOTIFICATION")
			.then(categoryOpt => this.setState({ categoryOpt }))
			.catch(err => {
				this.showErrorMessage("Nie można pobrać kategorii");
			})
		new PublicUnitService()
			.getAllActive()
			.then(pu => this.setState({ publicUnitOpt: pu }))
			.catch((err) => {
				this.messages.show({
					severity: 'error',
					summary: 'Błąd',
					detail: err.message,
				});
			});
	}
	fetchSubcategories() {
		if (this.state.criteria?.category?.id) {
			this.service
				.getSubcategories(this.state.criteria?.category?.id)
				.then(subcategories => {
					this.setState({ subcategoriesOpt: subcategories })
				})
				.catch(err => {
					this.setState({ subcategoriesOpt: [] })
					this.showErrorMessage(err.message);
				});
		} else {
			this.setState({ subcategoriesOpt: [] })
		}
	}
	getCriteriaName() {
		return 'notification-list-sc';
	}

	getContainerListName() {
		return 'notification-list-container';
	}

	renderCriteria() {
		return (
			<div className='row'>
				<CriteriaTextComponent
					id='textSc-sc'
					name='textSc'
					label={'Temat'}
					colClass='col-12'
					value={this.state.criteria.textSc}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaCalendarRangeComponent
					key={`sendDate`}
					idFrom={`sentFrom`}
					idTo={`sentTo`}
					nameTo={'sentTo'}
					nameFrom={'sentFrom'}
					label={'Data wysłania'}
					labelFrom="od"
					labelTo="do"
					//colClass={column.colClass ? column.colClass : 'col-md-6'}
					colClass={'col-12'}
					from={this.state.criteria.sentFrom}
					to={this.state.criteria.sentTo}
					validator={this.validator}
					onChange={this.handleChangeSc}
					viewMode={this.props.viewMode}
					showIcon
					placeholderFrom={'dd-mm-rrrr GG:MM'}
					placeholderTo={'dd-mm-rrrr GG:MM'}
					validateOnChange={false}
					stateField=""
					hourFormat="24"
					showTime={true}
					showSeconds={false}
				/>
				<CriteriaCalendarRangeComponent
					key={`createSc`}
					idFrom={`createFromSc`}
					idTo={`createToSc`}
					nameTo={'createToSc'}
					nameFrom={'createFromSc'}
					label={'Data dodania'}
					labelFrom="od"
					labelTo="do"
					//colClass={column.colClass ? column.colClass : 'col-md-6'}
					colClass={'col-12'}
					from={this.state.criteria.createFromSc}
					to={this.state.criteria.createToSc}
					validator={this.validator}
					onChange={this.handleChangeSc}
					viewMode={this.props.viewMode}
					showIcon
					onlyTime
					placeholderFrom={'dd-mm-rrrr GG:MM'}
					placeholderTo={'dd-mm-rrrr GG:MM'}
					validateOnChange={false}
					stateField=""
					hourFormat="24"
					showTime={true}
					showSeconds={false}
				/>
				<CriteriaCalendarRangeComponent
					key={`activeSc`}
					idFrom={`activeFromSc`}
					idTo={`activeToSc`}
					nameTo={'activeToSc'}
					nameFrom={'activeFromSc'}
					label={'Data ważności'}
					labelFrom="od"
					labelTo="do"
					//colClass={column.colClass ? column.colClass : 'col-md-6'}
					colClass={'col-12'}
					from={this.state.criteria.activeFromSc}
					to={this.state.criteria.activeToSc}
					validator={this.validator}
					onChange={this.handleChangeSc}
					viewMode={this.props.viewMode}
					showIcon
					placeholderFrom={'dd-mm-rrrr GG:MM'}
					placeholderTo={'dd-mm-rrrr GG:MM'}
					validateOnChange={false}
					stateField=""
					maxDateInfinite
					showTime={true}
					showSeconds={false}
				/>
				<CriteriaMultiSelectComponent
					id="publicUnit-sc"
					name="publicUnitIds"
					showClear
					label={'Działy/jednostki'}
					colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
					value={this.state.criteria.publicUnitIds}
					dataKey="id"
					optionLabel="name"
					options={this.state.publicUnitOpt}
					placeholder="Wszystkie"
					onChange={this.handleChangeSc}
					validator={this.validator}
					filter
					filterBy="name"
				/>
				<CriteriaTextComponent
					id='author-sc'
					name='author'
					label={'Autor'}
					colClass='col-xl-3 col-lg-3 col-md-6 col-sm-12'
					value={this.state.criteria.author}
					onChange={this.handleChangeSc}
					validator={this.validator}
				/>
				<CriteriaCheckboxComponent
					id="attachmentsSc"
					name="hasAttachments"
					label="Pokaż tylko z załącznikami"
					value={this.state.criteria.hasAttachments}
					onChange={this.handleChangeSc}
					validator={this.validator}
					colClass='col-xl-3 col-lg-3 col-md-6 col-sm-12'
				/>

				<CriteriaDropdownComponent
					id="category-sc"
					name="category"
					showClear
					label="Kategoria"
					colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
					value={this.state.criteria.category}
					onAfterStateChange={e => {
						this.setState(
							(prevState) => ({
								criteria: {
									...prevState.criteria,
									subcategory: undefined
								},
							}),
							() => this.fetchSubcategories(e)
						);
					}}
					options={this.state.categoryOpt}
					placeholder="Wszystkie"
					onChange={this.handleChangeSc}
					validator={this.validator}
					optionLabel="name"
				/>
				{this.state.criteria?.subcategory || (this.state.subcategoriesOpt?.length && this.state.subcategoriesOpt?.length > 0) ?
					<CriteriaDropdownComponent
						id="subcategory-sc"
						name="subcategory"
						showClear
						label="Podkategoria"
						colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
						value={this.state.criteria.subcategory}
						options={this.state.subcategoriesOpt}
						placeholder="Wszystkie"
						onChange={this.handleChangeSc}
						validator={this.validator}
						optionLabel="name"
					/> : null}

			</div>
		);
	}

	prepareColumns() {
		let modifiedList = [
			{ field: 'subject', header: 'Temat', sortable: true }
		];
		if (this.state.activeIndex === 0) {
			modifiedList.push({ field: 'sendDate', header: 'Data wysłania', sortable: true, body: this.dateTemplate.bind(this, 'sendDate', 'YYYY-MM-DD HH:mm') });
		}
		modifiedList = modifiedList.concat([
			{ field: 'createDate', header: 'Data dodania', sortable: true, body: this.dateTemplate.bind(this, 'createDate', 'YYYY-MM-DD HH:mm') },
			{ field: 'activeTo', header: 'Ważne do', sortable: true, body: this.dateTemplate.bind(this, 'activeTo', 'YYYY-MM-DD HH:mm') },
			{ field: 'author', header: 'Autor', sortable: true, body: this.authorTemplate.bind(this) },

			{ field: 'publicUnit.name', header: 'Działy/jednostki', sortable: true },
			{ field: 'category.name', header: 'Kategoria', sortable: true },
			{ field: 'attachmentsCount', header: 'Załączniki', body: this.attachmentsTemplate.bind(this) },
			{
				key: 'actions',
				header: 'Szczegóły',
				body: this.actionTemplate,
				className: 'table-column-action',
			},
		]);
		return modifiedList;
	}
	authorTemplate(rowData) {
		return rowData && rowData.author ? <span >{rowData.author?.firstName} {rowData.author?.lastName}{rowData.author?.removed ? ' (Usunięty)' : rowData.author?.blocked ? ' (Zablokowany)' : ''}</span> : ''
	}
	attachmentsTemplate(rowData) {
		return rowData && rowData.attachmentsCount ? <span className="icon_text"> <i alt className="icon-table mdi mdi-attachment"></i> {rowData.attachmentsCount}  </span> : "0"
	}
	prepareHeaderItems() {
		return this.isUserInAnyRole('ROLE_NOTIFY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')
			? [
				{
					label: 'Utwórz powiadomienie',
					href: this.props.newUrl,
					type: 'BUTTON',
					className: 'float-right',
					variant: 'yellow',
					iconName: 'mdi-plus',
					iconSide: 'left',
					iconColor: 'white',
				},
			]
			: [];
	}
	prepareTabPanels() {
		return [
			{ key: "sent", header: "Wysłane", headerClassName: "col-xl-6 col-lg-6 col-md-6 col-sm-12" },
			{ key: "drafts", header: "Robocze", headerClassName: "col-xl-6 col-lg-6 col-md-6 col-sm-12" },
		]
	}
	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/notification-list'>{'Powiadomienia'}</BreadcrumbsItem>
				{this.renderTabView()}
			</div>
		);
	}
	readCriteriaFromCookie() {

		let activeIndex = this.readCookie('notification-list-activeIndex');
		if (activeIndex === undefined || activeIndex === null || activeIndex === '') {
			activeIndex = 0;
		}
		let page =
			activeIndex === 0
				? this.readCookie('notification-sent-sc-page')
				: this.readCookie('notification-drafts-sc-page');
		if (page === undefined || page === null || page === '') {
			page = 0;
		}
		const prevCriteria = this.state.criteria;
		const criteria =
			this.readCookie(this.getCriteriaName()) !== undefined && this.readCookie(this.getCriteriaName()) != null
				? this.updateSearchCriteriaPrototype(JSON.parse(this.readCookie(this.getCriteriaName())))
				: this.getCleanSearchCriteriaPrototype();
		this.removeCookie(this.getCriteriaName());
		criteria.limit = prevCriteria.limit ? prevCriteria.limit : 10;
		criteria.draftOnly = parseInt(activeIndex) !== 0;
		criteria.page = parseInt(page)
		criteria.sentFrom = criteria.sentFrom ? new Date(criteria.sentFrom) : undefined;
		criteria.sentTo = criteria.sentTo ? new Date(criteria.sentTo) : undefined
		if (this._isMounted) {
			this.setState(
				prevState => ({
					activeIndex: parseInt(activeIndex),
					criteria,
					first: criteria.firstResult,
					loading: false,
				}),
				() => this.refreshTable()
			);
		}
	}

	handleTabChange(e) {
		// let page =
		// 	e.index === 0
		// 		? this.readCookie('notification-sent-sc-page')
		// 		: this.readCookie('notification-drafts-sc-page');
		// if (page === undefined || page === null || page === '') {
		// 	page = 0;
		// }
		let statuses = this.state.criteria.status;
		if (e.index !== this.state.activeIndex) {
			statuses = [];
		}
		const criteria =
			this.readCookie(this.getCriteriaName()) !== undefined && this.readCookie(this.getCriteriaName()) != null
				? this.updateSearchCriteriaPrototype(JSON.parse(this.readCookie(this.getCriteriaName())))
				: this.getCleanSearchCriteriaPrototype();
		this.removeCookie(this.getCriteriaName());
		this.setState(
			{
				activeIndex: e.index,
			},
			() => {

				this.saveCookie('notification-list-activeIndex', this.state.activeIndex);
				// if (this.state.activeIndex === 0) {
				// 	this.saveCookie('notification-sent-sc-page', this.state.criteria.page);
				// } else this.saveCookie('notification-drafts-sc-page', this.state.criteria.page);
				this.setState(
					prevState => ({
						criteria: {
							...prevState.criteria,
							draftOnly: this.state.activeIndex !== 0,
							status: statuses,
							page: 0,
							firstResult:0,
						},
						first: 0,
					}),
					() => super.refreshTable()
				);
			}
		);
	}
}

NotificationListContainer.defaultProps = {
	detailUrl: '/#/notification/details',
	newUrl: '/#/notification/create',
};

NotificationListContainer.propTypes = {
	detailUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default NotificationListContainer;
