import { saveAs } from 'file-saver';
import moment from 'moment';
import { Card } from 'primereact/card';
import PropTypes from 'prop-types';
import React from 'react';
import BaseDetailsComponent from '../../baseContainers/BaseDetailsContainer';
import ActionButton from '../../components/ActionButton';
import { Messages } from 'primereact/messages';
import DivContainer from '../../components/DivContainer';
import InputFileUploadComponent from '../../components/inputs/InputFileUploadComponent';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import SimpleReactValidator from '../../components/validator';
import BlockUi from '../../components/waitPanel/BlockUi';
import ConversationServive from '../../services/ConversationService';
import UserService from '../../services/UserService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import '../messages/Compose/Compose.css';
import Message from '../messages/Message/index';
import { DataScroller } from './MessageDataScroller';
import './MessageList.css';

class MessageList extends BaseDetailsComponent {
	constructor(props) {
		super(props);
		console.log('MessageList::constructor: props:', JSON.stringify(props));

		this.service = new ConversationServive();
		this.userService = new UserService();
		this.updatingInProgress = false;
		this.user = this.authService.getProfile();
		this.state = {
			newMessage: undefined,
			//conversationId: props.id,
			conversationId: props.id
				? props.id
				: props.match
					? props.match.params.id
					: undefined,
			messages: [],
			enableLoading: true,
			ready: false,
			filteredClients: [],
			filteredEngineerCompanies: [],
			assignManagerMode: false,
			changeManagerMode: false,
			managers: [],
			loading: true,
			attachment: undefined,
			onLazyLoadingInProgress: false,
			buildingCount: undefined,
			lastId: 0,
		};
		this.validator = new SimpleReactValidator();
		this.newConversationValidator = new SimpleReactValidator();
		this.assignManagerValidator = new SimpleReactValidator();
		this.buildingCountValidator = new SimpleReactValidator();
		this.handleSend = this.handleSend.bind(this);
		this.renderMessages = this.renderMessages.bind(this);
		this.onLazyLoad = this.onLazyLoad.bind(this);
		this.handleValidForm = this.handleValidForm.bind(this);
		this.createConversationHeader = this.createConversationHeader.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleNewMessageInMessageListCompose = this.handleNewMessageInMessageListCompose.bind(this);
		this.afterPrelogout = this.afterPrelogout.bind(this);
		this.handleDownloadFile = this.handleDownloadFile.bind(this);
		this.markLocalMessagesAsRead = this.markLocalMessagesAsRead.bind(this);
	}
	getContainerListName() {
		return 'messanger-container';
	}
	setElement() {
		this.initAfterSetElement();
	}
	initAfterSetElement() {
		console.log('init after set element', this.state.conversationId);
		if (this.state.conversationId) {
			this.intervalId = setInterval(this.updateConversation.bind(this, this.state.conversationId), 3000);
			this.setState({ loading: false });
		} else {
			this.setState({ loading: false });
		}
		super.initAfterSetElement();
	}
	handleGetDetailsError(err) {
		this.showErrorMessage(err.message);
	}

	initBeforeSetElement() {
		if (!this.props.handlePreLogoutUser) {
			this.afterPrelogout();
		}
	}
	afterPrelogout() {
		this.setState({ ready: true });
	}
	componentDidMount() {
		super.componentDidMount();
		if (this.props.handlePreLogoutUser) {
			this.props.handlePreLogoutUser(this.afterPrelogout);
		}
	}
	componentWillUnmount() {
		clearInterval(this.intervalId);
		super.componentWillUnmount();
	}
	handleErrorNoRedirect(err) {
		if (err.status === 401) {
			window.location.href = AppPrefixUtils.locationHrefUrl('/#/start');
		} else {
			this.unblockUi();
			this.showErrorMessage(err.message);
		}
	}
	handleError(err) {
		console.log(err)
		this.showErrorMessage(err.message);
	}
	persistMessage(severity, summary, detail) {
		this.saveCookie(
			this.getRedirectContainerName(),
			JSON.stringify({
				severity,
				summary,
				detail,
			})
		);
	}
	getRedirectContainerName() {
		if (this.isUserInAnyRole('ROLE_MESSAGES_MANAGER', 'ROLE_ACCOUNT_MANAGER', 'ROLE_ENGINEER_MANAGER')) {
			return 'messanger-container';
		} else {
			return 'start-container';
		}
	}
	markLocalMessagesAsRead(unreadCountSentFromOffice, unreadCountSentSenyByParticipant) {
		console.log('@@@@ markLocalMessagesAsRead', unreadCountSentFromOffice, unreadCountSentSenyByParticipant);
		const { messages } = this.state;
		const user = this.user;
		if (!messages) {
			return;
		}
		let changed = false;
		if (!unreadCountSentFromOffice || unreadCountSentFromOffice === 0) {
			messages.forEach(m => {
				if (m.senderEmail === user.sub && m.read === false) {
					changed = true;
					m.read = true;
				}
			})
		}
		if (!unreadCountSentSenyByParticipant || unreadCountSentSenyByParticipant === 0) {
			messages.forEach(m => {
				if (m.senderEmail === user.sub && m.read === false) {
					changed = true;
					m.read = true;
				}
			})
		}
		if (changed === true) {
			this.setState({ messages, allowScrollDown: true, enableLoading: true });
		}
	}
	updateConversation(conversationId) {
		if (this.state.lastId !== undefined && this.state.lastId != null && this.state.lastId != NaN) {
			if (this.updatingInProgress) {
				return;
			}
			if (document.hasFocus()) {
				this.service.markAsAllRead(this.state.conversationId);
			};
			this.updatingInProgress = true;
			this.service
				.updateConversation(conversationId, this.state.lastId)
				.then((data) => {
					const messages = this.props.public ? data : data.messages;
					const unreadCount = data.unreadCount;
					if (unreadCount) {
						console.log(`MessageList::updateConversation - persisting unreadCount ${unreadCount}`);
						sessionStorage.setItem('unreadMessageCount', JSON.stringify({ date: new Date(), unreadCount }));
					}
					this.updatingInProgress = false;
					if (messages && messages.length > 0) {
						let modifiedList = this.state.messages;
						modifiedList = modifiedList.concat(messages);
						modifiedList = modifiedList.filter((m) => m.id);
						this.setState({
							messages: modifiedList,
							lastId:
								modifiedList.length > 0
									? modifiedList[modifiedList.length - 1].id
									: 0,
							enableLoading: false,
							allowScrollDown: true,
						},
							() => {
								this.markLocalMessagesAsRead(data.unreadCountSentFromOffice, data.unreadCountSentSenyByParticipant)
							});
					} else {
						this.markLocalMessagesAsRead(data.unreadCountSentFromOffice, data.unreadCountSentSenyByParticipant)
					}
				})
				.catch((err) => {
					this.updatingInProgress = false;
					this.handleError(err);
				});
		}
	}
	onLazyLoad(request, callback) {
		console.log(`onLazyLoad: request=${JSON.stringify(request)}`);

		if (this.onLazyLoadingInProgress) {
			console.log('=> return...');
			return;
		}
		this.onLazyLoadingInProgress = true;
		return this.service
			.getMessages(
				this.state.conversationId,
				request.first,
				request.rows,
				this.state.oldestId
			)
			.then((messages) => {
				this.onLazyLoadingInProgress = false;
				let modifiedList = this.state.messages;

				const idsMap = new Map();

				modifiedList.forEach((message) =>
					idsMap.set(message.id, message)
				);
				messages.forEach((m) => {
					const exists = idsMap.has(m.id);

					if (!exists) {
						if (!this.state.oldestId) {
							modifiedList.push(m);
						} else {
							modifiedList.unshift(m);
						}
					}
				});
				console.log(modifiedList, ' mo di fied list')
				this.setState(
					{
						messages: modifiedList,
						oldestId: modifiedList.length > 0 ? modifiedList[0].id : 0,
						lastId:
							modifiedList.length > 0
								? modifiedList[modifiedList.length - 1].id
								: 0,
						enableLoading: messages.length > 0,
						allowScroll: true,
					},
					() => {
						console.log(
							'this state after on lazy load',
							this.state
						);
						if (callback && messages.length > 0) {
							callback();
						}
					}
				);
			})
			.catch((err) => {
				this.onLazyLoadingInProgress = false;
				this.handleError(err);
			});
	}


	getMessageSender() {
		const { messages } = this.state;
		const user = this.user;
		let sender;
		if (user) {
			sender = `${this.props.user.firstName} ${this.props.user.lastName}`;
		} else {
			if (messages) {
				const userMessages = messages.filter(m => m.sentFromOffice === false);
				console.log('bum')
				if (userMessages.length > 0) {
					sender = userMessages[0].sender;
				}
			}
		};
		return sender;
	}

	handleSend(e) {
		if (e) {
			e.preventDefault();
		}
		if (!this.state.newMessage || this.state.newMessage.trim().length === 0) {
			return;
		}
		if (this.validator.allValid()) {
			//this.blockUi();
			const request = {
				message: this.state.newMessage.trim(),
			};
			let modifiedList = this.state.messages;
			const sender = this.getMessageSender();
			modifiedList.push({
				message: request.message,
				sender,
				hideSender: sender === undefined,
				isMine: true,
				read: false,
			});
			console.log(modifiedList, 'modifiedlist')
			this.setState({ newMessage: '', messages: modifiedList, allowScrollDown: true },
				() => this.service
					.sendMessage(this.state.conversationId, request)
					.then(() => {
						//this.unblockUi();
						this.setState({
							allowScrollDown: true,
						}, () => console.log(this.state.messages, ' wiadomości'));
					})
					.catch((err) => {
						//this.unblockUi();
						let modifiedList = this.state.messages;
						modifiedList = modifiedList.filter((m) => m.id);
						this.setState(
							{
								messages: modifiedList,
								newMessage: request.message,
								allowScrollDown: true,
							},
							() => this.handleErrorNoRedirect(err)
						);
					})
			);
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.scrollToError = true;
			this.forceUpdate();
		}
	}
	renderSendButton() {
		const enabled = this.state.newMessage && this.state.newMessage.trim().length > 0 && !this.props.disableSendButton
		return (
			<ActionButton
				label="Wyślij"
				colClass="md-1"
				handleClick={this.handleSend}
				key={`send-button`}
				disabled={!enabled}
			/>
		);

	}
	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode } = this.props;
		let goBackUrl;
		if (viewMode === 'NEW' || !this.state.conversationId) {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${this.state.conversationId}`;
		}

		if (this.state.conversationId) {
			return [];
		} else {
			const result = [];
			if (this.isUserInAnyRole('ROLE_MESSAGES_MANAGER', 'ROLE_ACCOUNT_MANAGER', 'ROLE_ENGINEER_MANAGER')) {
				result.push({ label: 'Anuluj', href: AppPrefixUtils.locationHrefUrl(goBackUrl), rendered: !this.state.loading });
			}
			result.push({
				label: 'Wyślij',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: !this.state.loading

			});
			return result;
		}
	}
	renderView() {
		return (
			<React.Fragment>
				<Card footer={this.renderFooter()} header={this.renderHeader()}>
					<form onSubmit={this.handleFormSubmit} noValidate
						onKeyDown={(e) => {
							if (e.key === 'Enter' && !e.shiftKey) {
								e.preventDefault();
								this.handleFormSubmit();
							}
						}}>
						{this.renderDetails()}
					</form>
				</Card>
			</React.Fragment>

		);
	}
	render() {
		return (
			<React.Fragment>

				<BlockUi tag="div" blocking={this.state.blocking || this.state.loading} loader={this.loader}>
					<Messages id="custom-messages" ref={(el) => (this.messages = el)} ></Messages>
					{/* {this.state.conversationId ? this.renderViewWithoutCard() : this.renderView()} */}
					{this.renderView()}
				</BlockUi>
			</React.Fragment>
		);
	}

	renderDetails() {
		if (this.state.loading === true) {
			return;
		}
		if (this.state.conversationId) {
			return this.renderMessagesList();
		}
	}
	renderMessagesList() {
		return (
			<div className="datascroller-demo">
				<div className="card" style={{ padding: '0.5em' }}>
					<DataScroller
						value={this.state.messages}
						rows={9}
						inline
						scrollHeight="500px"
						header={''}
						extendedRender={this.renderMessages}
						onLazyLoad={this.onLazyLoad}
						lazy
						lastId={this.state.lastId}
						oldestId={this.state.oldestId}
						enableLoading={this.state.enableLoading}
						allowScroll={this.state.allowScroll}
						allowScrollDown={this.state.allowScrollDown}
						ready={this.state.ready}
					/>

					<div className="compose" style={{ width: '100%', paddingTop: '0.3em', paddingLeft: '0em', paddingRight: '0em' }}>
						<DivContainer colClass="row">

							<DivContainer colClass={!!this.props.showFileUpload ? "col-md-8" : "col-md-12"}>
								<InputTextareaComponent
									id="newMessage"
									name="newMessage"
									label={'Wiadomość'}
									showLabel={false}
									colClass="col-md-12"
									value={this.state.newMessage}
									validators="not_required"
									validator={this.validator}
									onChange={(a, b, e) =>
										this.handleNewMessageInMessageListCompose(e)
									}
									viewMode={'NEW'}
									placeholder={'Wiadomość...'}
									stateField=""
									insideTable
									onFocus={this.handleFocus}
									onKeyDown={(e) => {
										if (e.key === 'Enter' && !e.shiftKey) {
											e.preventDefault();
											this.handleFormSubmit.bind(this);
										}
									}}
									rows={5}
								/>
							</DivContainer>
							{!!this.props.showFileUpload ?
								<DivContainer colClass="col-md-4">
									<InputFileUploadComponent
										colClass="col-md-12"
										multiple={false}
										file={this.state.attachment}
										viewMode="NEW"
										name="attachment"
										label="Załącz plik"
										onChange={this.handleChange}
										validator={this.validator}
										stateField=""
										itemLabel="fileName"
										maxFileSize={20000000}
										maxFileCount={1}
										token={this.authService.getToken()}
										onAfterStateChange={(e) => {
											this.sendFileMessage();
										}}
										publicMode={!this.user}
									/>
								</DivContainer> : null}
						</DivContainer>
						{this.renderSendButton()}
					</div>
				</div>
			</div>
		);
	}

	sendFileMessage() {
		const { attachment, messages, conversationId } = this.state;
		console.log('sendFileMessage', attachment);
		let modifiedList = messages;
		const sender = this.getMessageSender();
		const msg = {
			attachment: attachment,
			sender,
			hideSender: sender === undefined,
			isMine: true,
		};
		modifiedList.push(msg);
		this.setState({ attachment: undefined, messages: modifiedList, allowScrollDown: true },
			() => {
				this.service.sendFile(conversationId, attachment)
					.then((data) => {
						msg.attachment.id = data.lastAttachmentId;
						this.setState({ messages: modifiedList });
					})
					.catch((err) => {
						let modifiedList = this.state.messages;
						modifiedList = modifiedList.filter((m) => m.id);
						this.setState({ messages: modifiedList, allowScrollDown: true },
							() => this.handleErrorNoRedirect(err)
						);
					})
			});
	}

	handleDownloadFile(attachment) {
		console.log('handleDownloadFile', attachment);
		const { conversationId } = this.state;
		const token = this.authService.getToken();
		this.setState({ allowScroll: false });
		this.blockUi();
		this.service.downloadAttachment(conversationId, attachment.id, token)
			.then(response => {
				console.log('content-disposition', response.headers.get('content-disposition'));
				return response.blob();
			})
			.then(blob => {
				this.unblockUi();
				saveAs(blob, attachment.fileName);
			})
			.catch(err => {
				this.unblockUi();
				console.log('handleDownloadFile error', err);
				this.handleErrorNoRedirect(err)

			});
	}

	handleNewMessageInMessageListCompose(e) {
		this.setState({
			[e.target.name]: e.target.value,
			allowScroll: false,
			allowScrollDown: false,
		});
	}

	handleClientNameChange(
		inputType,
		parameters,
		event,
		onAfterStateChange,
		stateField
	) {
		let varValue;
		if (event !== undefined) {
			varValue =
				event.target.value || event.target.value === ''
					? event.target.value
					: undefined;
			const isObject = varValue instanceof Object;

			console.log(`handleClientNameChange: isObject=${isObject}`);
			this.setState({ clientName: isObject ? varValue.label : varValue });
			if (!isObject) {
				event.target.value = undefined;
			}
			this.handleChange(
				'TEXT',
				undefined,
				event,
				() => {
					if (isObject) {
						if (varValue.hasChildrenClient && varValue.hasChildrenClient.value === true) {
							this.fetchClientChildren(varValue.id);
							this.fetchClientUsers();
						} else {
							this.fetchClientUsers();
						}
					} else {
						this.setState({
							clientOptions: [], clientUnit: undefined,
							usersOpt: [], user: undefined,
						});
					}

				},
				stateField
			);
		}
	}

	handleValidForm() {
		const { newMessage, engineerCompany } = this.state;
		const user = this.user;
		const { type } = this.props;
		let recipientId;
		if (type === 'client') {
			recipientId = user.id;
		} else if (type === 'engineer') {
			recipientId = engineerCompany.id;
		}
		const request = {
			recipient: {
				id: recipientId,
			},
			message: newMessage
		}
		this.service
			.startConversation(request)
			.then((res) => {
				if (res.uuid) {
					window.location.href = AppPrefixUtils.locationHrefUrl(`${this.props.detailsUrl}/${res.uuid}`);
				}
			})
			.catch((err) => this.handleErrorNoRedirect(err));
	}
	handleFormSubmit(event) {
		if (event !== undefined) {
			event.preventDefault();
		}
		if (!this.state.conversationId) {
			if (this.newConversationValidator.allValid()) {
				this.blockUi(this.handleValidForm);
			} else {
				this.newConversationValidator.showMessages();
				// rerender to show messages for the first time
				this.scrollToError = true;
				this.forceUpdate();
			}
		} else {
			this.handleSend();
		}
	}
	extendedRender(messages) {
		return this.renderMessages(messages);
	}
	handleFocus(e) {
		if (e) {
			e.preventDefault();
		}
		this.service.markAsAllRead(this.state.conversationId)
			.then(() => { })
			.catch((err) => {
				this.handleErrorNoRedirect(err);
			});
	}
	renderMessages(messages) {
		const user = this.user;
		let i = 0;
		let messageCount = messages.length;
		let tempMessages = [];

		while (i < messageCount) {
			let previous = messages[i - 1];
			let current = messages[i];
			let next = messages[i + 1];



			let myMessage = false;
			if (user && user.sub && user.sub === current.senderEmail) {
				myMessage = true;
			}
			if (!!current.fromOffice) {
				myMessage = true;
			}
			let isMine = !!messages[i].isMine ? messages[i].isMine : myMessage;

			let currentMoment = moment(current.date);
			let prevBySameAuthor = false;
			let nextBySameAuthor = false;
			let startsSequence = true;
			let endsSequence = true;
			let showTimestamp = true;
			let hideSender = messages[i].hideSender;
			if (previous) {
				let previousMoment = moment(previous.date);
				let previousDuration = moment.duration(
					currentMoment.diff(previousMoment)
				);
				prevBySameAuthor = previous.sender === current.sender;

				if (prevBySameAuthor && previousDuration.as('hours') < 1) {
					startsSequence = false;
				}

				if (previousDuration.as('hours') < 1) {
					showTimestamp = false;
				}
			}

			if (next) {
				let nextMoment = moment(next.date);
				let nextDuration = moment.duration(
					nextMoment.diff(currentMoment)
				);
				nextBySameAuthor = next.author === current.author;

				if (nextBySameAuthor && nextDuration.as('hours') < 1) {
					endsSequence = false;
				}
			}
			current.key = i;
			tempMessages.push(
				<div>
					<li
						key={current.id + '_datascrollitem'}
						id={current.id + '_datascrollitem'}>
						<Message
							key={i}
							isMine={isMine}
							startsSequence={startsSequence}
							endsSequence={endsSequence}
							showTimestamp={showTimestamp}
							data={current}
							hideSender={hideSender}
							onDownloadFile={this.handleDownloadFile}
							onAfterStateChange={() => this.setState({ allowScroll: false, allowScrollDown: false, })}
						/>
					</li>
				</div>
			);

			// Proceed to the next message.
			i += 1;
		}

		return tempMessages;
	}
	createConversationHeader() {
		return 'Wiadomości';
	}

}
MessageList.defaultProps = {
	backUrl: '/#/conversation-list',
	cancelUrl: '/#/conversation-list',
	detailsUrl: '/#/conversation/details',
	showFileUpload: true,
	disableSendButton: false
};

MessageList.propTypes = {
	id: PropTypes.string,
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
	detailsUrl: PropTypes.string,
	disableSendButton: PropTypes.bool
};

export default MessageList;
