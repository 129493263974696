import BaseServiceCrud from './BaseServiceCrud';

export default class EmailService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'email';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			email_type: criteria.emailType?.enumValue,
			description: criteria.description,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	getParameters(type) {
		return this.fetch(`${this.domain}/${this.path}/emailType/${type}`, {
			method: 'GET',
		}).then(res => {
			return Promise.resolve(res);
		});
	}
}
