import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import DivContainer from '../DivContainer';

class FooterComponent extends Component {
	constructor(props) {
		super(props);
		this.authService = new AuthService();
	}

	render() {
		if (this.authService.loggedIn()) {
			return (
				<React.Fragment>
					<DivContainer colClass='container footer'>
						<DivContainer colClass='col-12'>
							<DivContainer colClass='copyright'>
									<span>Copyright ©Primigenius</span>
							</DivContainer>
						</DivContainer>
					</DivContainer>
				</React.Fragment>
			);
		} else {
			return <React.Fragment></React.Fragment>;
		}
	}
}

export default withRouter(FooterComponent);
