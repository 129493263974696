import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
export class ColumnChart extends Component {

    constructor(props) {
        super(props);
        this.lightOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            }
        };
    }

    render() {
        return (
            <Chart
                height='50vh'
                chartType='ColumnChart'
                loader={<div>Pobieranie danych</div>}
                data={this.props.data}
                options={{
                    title: this.props.title,
                    chartArea: { width: '80%' , left: "10%"},
                    hAxis: {
                        title: this.props.title,
                        minValue: 0,
                    },
                    vAxis: {
                        title: this.props.vAxisTitle,
                    },
                    width: '100%',
                    width_units: '%',
                    legend: 'bottom'
                }}
                legendToggle
            />)
    }
}