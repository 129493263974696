
export default class AddressModel {
    constructor() {
        this.postCode = '';
        this.city = '';
        this.street = '';
        this.streetNumber = '';
        this.houseNumber = '';
        this.voivodeship = undefined;
        this.longitude = undefined;
        this.latitude = undefined;
    }

    static assign(from, to) {
        if (from) {
            to.postCode = from.postCode ? from.postCode : '';
            to.city = from.city ? from.city : '';
            to.street = from.street ? from.street : '';
            to.streetNumber = from.streetNumber ? from.streetNumber : '';
            to.houseNumber = from.houseNumber ? from.houseNumber : '';
            to.voivodeship = from.voivodeship ? from.voivodeship : undefined;
            to.longitude = from.longitude;
            to.latitude = from.latitude;
        } else {
            to = new AddressModel();
        }
    }

    static copy(from) {
        const to = new AddressModel();
        AddressModel.assign(from, to);
        return to;
    }
}