import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import InputTextComponent from '../components/inputs/InputTextComponent';
import SubcategoryService from '../services/SubcategoryService';
import InputMultiSelectComponent from '../components/inputs/InputMultiSelectComponent';
import InternalUserService from '../services/InternalUserService';
import CategoryService from '../services/CategoryService';
import YesNoDialog from '../components/YesNoDialog';
import InputNumberComponent from '../components/inputs/InputNumberComponent';
class SubcategoryContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new SubcategoryService(props.id));
		this.isHalfWidth = true;
		this.categoryId = props.id;
		this.subcategoryId = props.subId;
		this.categoryService = new CategoryService();
		this.internalUserService = new InternalUserService();

		let category = null;
		try {
			category = JSON.parse(sessionStorage.getItem('category'));
		} catch (err) {
			category = null;
		}
		this.state = {
			loading: true,
			elementId: this.subcategoryId,
			showDeleteDialog: false,
			element: {
				name: undefined,
				nameEng: undefined,
				users: [],
				subcategoryNumber: 1,
			},
			category,
		};
		this.showDeleteDialog = this.showDeleteDialog.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
	}


	getContainerListName() {
		return 'category-list-container';
	}

	getAddSucces() {
		return 'Podkategoria została utworzona';
	}

	getUpdateSucces() {
		return 'Podkategoria została zaktualizowana';
	}



	updateElement(data) {
		if (data.users) {
			data.users.forEach(u => u.label = `${u.firstName} ${u.lastName}${u.removed ? ' (Usunięty)' : u.blocked ? ' (Zablokowany)': ''}`);
		}
		this.setState(
			{
				element: {
					id: data.id,
					name: data.name,
					nameEng: data.nameEng,
					users: data.users,
					subcategoryNumber: data.subcategoryNumber
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}
	initAfterSetElement() {
		if (this.props.viewMode === 'EDIT' || this.props.viewMode === 'NEW') {
			const modifiedList = this.state.userOptions;
			if(this.state.element.users){
			this.state.element.users.forEach(u => {
				if (!this.state.userOptions.find(uo => uo.id === u.id)) {
					modifiedList.push(u);
				}
			});
			this.setState({userOptions: modifiedList}, ()=> super.initAfterSetElement())
		}
		} else { super.initAfterSetElement() }
	}
	initBeforeSetElement() {
		if (this.state.category === null) {
			this.categoryService.get(this.categoryId)
				.then(category => this.setState({ category }))
				.catch(err => {
					console.log(err);
					this.showErrorMessage("Nie można pobrać danych kategorii!");
				})
		}
	}
	setElement(){
		this.internalUserService.getUserReferenceList({ status: { enumValue: "ACTIVE", label: "Aktywna" }, notBlocked: true })
				.then(userOptions => {
					userOptions.forEach(u => u.label = `${u.firstName} ${u.lastName}`);
					this.setState({ userOptions }, () => super.setElement())
				})
				.catch(()=>this.setState({ userOptions: [] }, ()=> super.setElement()))
	}
	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode, id, subId } = this.props;
		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${id}/sub/${subId}`;
		}
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
				label: 'Zapisz',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: this.isUserInAnyRole('ROLE_CATEGORY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') && (viewMode === 'NEW' || viewMode === 'EDIT'),
			},
			this.prepareDeleteButton(viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_CATEGORY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')),
		];
	}

	prepareEditButton(rendered, label) {
		const { editUrl, id, subId } = this.props;

		return {
			label: label !== undefined ? label : 'Edytuj',
			type: 'BUTTON',
			variant: '',
			className: 'float-right',
			href: `${editUrl}/${id}/sub/${subId}`,
			rendered: rendered,
			iconName: 'mdi-pencil',
			iconSide: 'left',
			iconSize: 'm',
		};
	}

	prepareDeleteButton(rendered, label) {
		return {
			label: 'Usuń',
			type: 'BUTTON',
			variant: '',
			className: 'float-right',
			onClick: this.showDeleteDialog,
			rendered: rendered,
			iconName: 'mdi-delete-variant',
			iconSide: 'left',
			iconSize: 'm',
		};
	}


	showDeleteDialog() {
		this.setState({ showDeleteDialog: true });
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowa podkategoria' : viewMode === 'EDIT' ? 'Edycja podkategorii' : 'Szczegóły podkategorii',
				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_CATEGORY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')),
		];
	}

	getBackLabel() {
		return 'Wróć do listy';
	}

	changeStatus(type, costam, target) {
		if (target.value) {
			this.blockUi();
			this.service
				.remove(this.state.element)
				.then((data) => {
					this.persistMessage('success', '', 'Podkategoria została  usunięta');
					window.location.href = this.props.backUrl;
				})
				.catch((err) => {
					this.showErrorMessage(err.message);
					this.setState({ showDeleteDialog: false });
					this.unblockUi();
				});
		} else {
			this.setState({ showDeleteDialog: false });
		}
	}

	render() {
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/category-list' className='p-link'>
					{'Kategorie'}
				</BreadcrumbsItem>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/category/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/category/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/category/create'>{'Nowa'}</BreadcrumbsItem> : null}
				<YesNoDialog
					onChange={this.changeStatus}
					visible={this.state.showDeleteDialog}
					header="Usunięcie podkategorii"
					name="visible"
					onHide={() => {
						this.setState({ showDeleteDialog: false });
					}}>
					<span>Czy na pewno chcesz usunąć wybraną podkategorię?</span>
				</YesNoDialog>
				{this.renderView()}
			</div>
		);
	}




	renderDetails() {


		return (
			<React.Fragment>
				<div className='row'>
					<InputTextComponent
						id="category"
						name="category"
						label="Kategoria"
						colClass='col-md-12'
						value={this.state.category?.name}
						viewMode="VIEW"
					/>
					<InputTextComponent
						id="name"
						name="name"
						label="Nazwa podkategorii w języku polskim"
						colClass='col-md-12'
						value={this.state.element.name}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
					/>
					<InputTextComponent
						id="nameEng"
						name="nameEng"
						label="Nazwa podkategorii w języku angielskim"
						colClass='col-md-12'
						value={this.state.element.nameEng}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'max:50'}
					/>
					<InputNumberComponent
                        id='subcategoryNumber'
                        name='subcategoryNumber'
                        label={'Lp.'}
                        colClass='col-md-6'
                        min="1"
                        value={this.state.element.subcategoryNumber}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        validators={'naturalNumber|required'}
                    />
					<InputMultiSelectComponent id="users" name="users"
						colClass='col-md-12'
						label="Odpowiedzialni użytkownicy"
						value={this.state.element.users}
						validator={this.validator}
						validators="not_required"
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						options={this.props.viewMode === 'VIEW' ? [] : this.state.userOptions}
						dataKey="id"
						optionLabel="label"
						filter
					/>
				</div>
			</React.Fragment>
		);
	}
}

SubcategoryContainer.defaultProps = {
	backUrl: '/#/category-list',
	cancelUrl: '/#/category/details',
	editUrl: '/#/category/edit',
};

SubcategoryContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
};

export default SubcategoryContainer;
