import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import queryString from 'query-string';
import decode from 'jwt-decode';
import React from 'react';
import { Link } from 'react-router-dom';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputPasswordComponent from '../../components/inputs/InputPasswordComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import CustomMessages from '../../components/CustomMessages';
import BlockUi from '../../components/waitPanel/BlockUi';

import SimpleReactValidator from '../../components/validator';
import SettingService from '../../services/SettingService';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';

class AbstractConfirmPasswordContainer extends BaseDetailsContainer {

    // ABSTRACT METHODS
    getPasswordParams = () => {};
    getService = () => {};
    handleCorrectPasswordToken = (wsRes) => {};
    handleErrorPasswordToken = (wsErr) => {};
    handleCorrectPasswordChange = (wsRes) => {};
    renderDetail = () => {};

	constructor() {
		super();
		this.handleChange = this.handleChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.state = {
			newPassword: '',
			repeatPassword: '',
			maxLength: 15,
			minLength: 5,
			lowerCaseCount: 3,
			upperCaseCount: 4,
			numbersCount: 0,
			specialCharactersCount: 0,
			status: '',
		};
		this.validator = new SimpleReactValidator();
		this.afterPreLogoutUser = this.afterPreLogoutUser.bind(this);
		this.scrollToTopOnMount = false;
	}

	afterPreLogoutUser() {
		this.blockUi();
		console.log('after pre logout na formatce');
		this.getPasswordParams();
		const token = queryString.parse(this.props.location?.search)?.token;
		const tokenType =
			this.props.type === 'CREATE'
				? 'USER_REGISTER'
				: 'PASSWORD_RECOVERY';
		this.blockUi();
		this.getService()
			.checkResetPasswordToken(token, tokenType)
			.then(this.handleCorrectPasswordToken)
			.catch(this.handleCorrectPasswordToken);
	}

	initBeforeSetElement() {
	}

	componentDidMount() {
		super.componentDidMount();
		this.props.handlePreLogoutUser(this.afterPreLogoutUser);
	}

	handleChange(e) {
		console.log(e);
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	handleFormSubmit(e) {
		if (e) {
			e.preventDefault();
		}
		if (this.validator.allValid()) {
			const values = queryString.parse(this.props.location.search);

			const body = { newPassword: this.state.newPassword };
			const token = values.token;
			const tokenType =
				this.props.type === 'CREATE'
					? 'USER_REGISTER'
					: 'PASSWORD_RECOVERY';
			this.getService()
				.verifyTokenAndSendNewPassword(token, tokenType, body)
				.then(this.handleCorrectPasswordChange)
				.catch((err) => {
					this.messages.show({
						closable: false,
						life: 30000,
						severity: 'error',
						summary: 'Błąd',
						detail: err.message,
					});
				});
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.setState({
				valid: false,
			});
			this.forceUpdate();
		}
	}

	render() {
        return (
            <React.Fragment>
                <BlockUi
                    tag="div"
                    blocking={this.state.blocking || this.state.loading}
                    loader={this.loader}>

                    <div id="main-login">
                        <Messages id="custom-messages" ref={(el) => (this.messages = el)} />
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-9 col-md-7 col-lg-4 mx-auto">
                                    <div className="card card-signin my-5">
                                        {this.renderDetail()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </BlockUi>
            </React.Fragment>
        );
    }

    renderForm = () => {
        return (
            <div className="card-body">
                <div className="float-right">
                    <Link to="/">
                        <span className="p-button-text p-c">
                            {'Wróć'}
                        </span>
                    </Link>
                </div>
                <h5 className="card-title text-align-left">
                    {this.props.type &&
                        this.props.type === 'CREATE'
                        ? 'Nadanie hasła'
                        : 'Resetowanie hasła'}
                </h5>
                <form
                    className="form-signin"
                    onSubmit={(e) => {
                        this.handleFormSubmit(e);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            this.handleFormSubmit();
                        }
                    }}
                    //avoid setting red border just after the page has loaded (red border because of undefined required value in FF)
                    noValidate>
                    {/* <div className="form-group"></div> */}
                    {/* <div className="form-group"> */}
                    <div className="row">
                        <InputTextComponent
                            id="email"
                            name="email"
                            label="Email"
                            classLabel="black-label"
                            value={this.state.email}
                            onChange={(a, b, c) =>
                                this.handleChange(c)
                            }
                            className={
                                this.state.email
                                    ? 'form-control'
                                    : 'form-control invalid'
                            }
                            type="text"
                            validators="required|email"
                            validator={
                                this.validator
                            }
                            viewMode="VIEW"
                            colClass="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                        />
                        <div className='col-12'>
                        <label>{this.getPasswordLabel()}</label>
                        </div>
                        <InputPasswordComponent
                            id="newPassword"
                            // eslint-disable-next-line max-len
                            //label={this.getPasswordLabel()}
                            label="Nowe hasło"
                            classLabel="black-label"
                            labelForValidator="Nowe hasło"
                            name="newPassword"
                            value={
                                this.state
                                    .newPassword
                            }
                            validator={
                                this.validator
                            }
                            onChange={(a, b, c) =>
                                this.handleChange(c)
                            }
                            viewMode="EDIT"
                            validators={`required|extended_password:${this.state.minLength}:${this.state.maxLength}:${this.state.lowerCaseCount}:${this.state.upperCaseCount}:${this.state.numbersCount}:${this.state.specialCharactersCount}`}
                            colClass="col-12"
                            classInput={"black-label"}
                        />
                        <InputPasswordComponent
                            id="repeatPassword"
                            label="Powtórz nowe hasło"
                            name="repeatPassword"
                            classLabel="black-label"
                            value={
                                this.state
                                    .repeatPassword
                            }
                            feedback={false}
                            validator={
                                this.validator
                            }
                            onChange={(a, b, c) =>
                                this.handleChange(c)
                            }
                            viewMode="EDIT"
                            validators={`required|equals:${this.state.newPassword}:Nowe hasło`}
                            colClass="col-12"
                            classInput={"black-label"}
                        />
                    </div>
                    <Button
                        className="btn btn-lg btn-primary btn-block text-uppercase"
                        label={'Zapisz'}
                    />
                </form>
            </div>
        );
    }

	getPasswordLabel() {
		let message =
			'Pole nowe hasło musi się składać z od :min do :max znaków, zawierać co najmniej :lower i :upper, :number oraz :special spośród =!@#$%^&*(){}[]|:";\'<>?,./';
		message = message.replace(':min', this.state.minLength);
		message = message.replace(':max', this.state.maxLength);

		message = message.replace(
			':lower',
			this.validator.declination(this.state.lowerCaseCount, 2)
		);
		message = message.replace(
			':upper',
			this.validator.declination(this.state.upperCaseCount, 3)
		);
		message = message.replace(
			':number',
			this.validator.declination(this.state.numbersCount, 4)
		);
		message = message.replace(
			':special',
			this.validator.declination(this.state.specialCharactersCount, 5)
		);
		return message;
	}
}
export default AbstractConfirmPasswordContainer;