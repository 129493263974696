import BaseService from './BaseService';

export default class EnumService extends BaseService {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'enum';
		this.getEnumList = this.getEnumList.bind(this);
		this.getEnumLabel = this.getEnumLabel.bind(this);
		this.cache = {};
	}

	getEnumList(enumName) {
		const cachedValueStr = sessionStorage.getItem(enumName);

		if (cachedValueStr) {
			const cachedValue = JSON.parse(cachedValueStr);
			if (cachedValue) {
				console.log(`getEnumList for ${enumName} -> data fetched from cache`, cachedValue);
				return Promise.resolve(cachedValue);
			}
		}		
		return this.fetch(`${this.domain}/${this.path}/list/${enumName}`, {
			method: 'GET',
		}).then(res => {
			sessionStorage.setItem(enumName, JSON.stringify(res));
			console.log(`getEnumList for ${enumName} -> data fetched from server`, res);
			return Promise.resolve(res);
		});
	}

	getEnumLabel(enumName, enumValue) {
		return this.fetch(`${this.domain}/${this.path}/label/${enumName}/${enumValue}`, {
			method: 'GET',
		}).then(res => {
			return Promise.resolve(res);
		});
	}
}
