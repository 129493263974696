import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import PropTypes from 'prop-types';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaTextComponent from "../components/criteria/CriteriaTextComponent";
import PhoneNumberContentService from "../services/PhoneNumberContentService";

class PhoneNumberContentListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new PhoneNumberContentService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria()
        };
    }

    updateSearchCriteria(criteria) {
        return {
            id: criteria.id,
            name: criteria.name,
            phoneNumbers: criteria.phoneNumbers,
            firstResult: criteria.firstResult,
            maxResult: criteria.maxResult,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCriteriaName() {
        return 'phone-number-content-list-sc';
    }

    getContainerListName() {
        return 'phone-number-content-list-container';
    }

    getCleanSearchCriteria() {
        return {
            name: '',
            phoneNumbers: '',
            id: null,
            firstResult: 0,
            maxResult: 10,
            sortField: 'phoneNumberOrder',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='name'
                    label={'Nazwa'}
                    colClass='col-md-6'
                    value={this.state.criteria.name}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
                <CriteriaTextComponent
                    id='phone-number-sc'
                    name='phoneNumbers'
                    label={'Numery telefonów'}
                    colClass='col-md-6'
                    value={this.state.criteria.phoneNumbers}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }

    prepareColumns() {
        return [
            {field: 'phoneNumberOrder', header: 'Kolejność', sortable: true},
            {field: 'name', header: 'Nazwa', sortable: true},
            {field: 'phoneNumbersList', header: 'Telefony', sortable: true, sortField: 'PhoneNumbers', body: this.objectArrayTemplate.bind(this, 'phoneNumbersList', undefined)},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    prepareHeaderItems() {
        return [
                {
                    label: 'Dodaj numery telefonów',
                    href: this.props.newUrl,
                    type: 'BUTTON',
                    className: 'float-right',
                    variant: 'yellow',
                    iconName: 'mdi-plus',
                    iconSide: 'left',
                    iconColor: 'white',
                }
            ]
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/phone-number-content-list'>{'Numery telefonów'}</BreadcrumbsItem>
                {this.renderView()}
            </div>
        );
    }
}

PhoneNumberContentListContainer.defaultProps = {
    detailUrl: '/#/phone-number-content/details',
    newUrl: '/#/phone-number-content/create',
};

PhoneNumberContentListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default PhoneNumberContentListContainer;
