import BaseServiceCrud from './BaseServiceCrud';

export default class RefuseCollectionInformationService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'refuse-collection-information';
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            id: criteria.id,
        });
    }

    getInformation() {
        return this.fetch(`${this.domain}/${this.path}/`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
