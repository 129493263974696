/* eslint-disable react/jsx-handler-names */
import React from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Polygon, Marker, useMapEvents } from 'react-leaflet'
import { PropTypes } from 'prop-types';
import { useState, useRef, useCallback, useMemo } from 'react';


const center = [53.11308383065103, 17.60186688636979]

export const MapLeaflet = ({ areas, handleClickArea, viewMode, markers, handleClickMarker, markerMap, className, mapCenter, zoom, zoomChangeHandler, handleMarkerDrag, draggable }) => {

	return (
		<MapContainer key={mapCenter ? mapCenter : center} center={mapCenter ? mapCenter : center} zoom={zoom ? zoom : 13} scrollWheelZoom={false} className={className}>
			<TileLayer
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{areas && areas.length ? areas.map((area, index) => {
				return <Polygon
					key={`${index}-polygon`}
					pathOptions={!!area.selected ? { fillColor: 'blue', color: 'blue' } : { fillColor: 'gray', color: 'gray' }}
					positions={area.coordinates}
					eventHandlers={{
						click: () => {
							if (viewMode !== 'VIEW') {
								handleClickArea(area.id)
							}
						},
					}}
				/>
			}) : null}
			{markers && markers.length ? markers.map((position, index) => {
				return !draggable ?
					<Marker key={`${index}-marker`} position={position} /> :
					<DraggableMarker
						key={`${index}-marker`}
						currentPosition={{ lat: position[0], lng: position[1] }} handleMarkerDrag={handleMarkerDrag} />

			}) : null}
			{markerMap ? renderMarkerMap(markerMap, handleClickMarker, viewMode)

				: null}
			<UseMapEvents zoomChangeHandler={zoomChangeHandler} />
		</MapContainer>
	)
}
/**
 *   Obsługa zdarzeń związanych z mapą
 */
const UseMapEvents = ({ zoomChangeHandler }) => {
	useMapEvents({
		zoomend: (event) => {
			if (zoomChangeHandler) {
				var zoom = event.target.getZoom();
				zoomChangeHandler(zoom);
			}
		},
	});
	return null;
};

const renderMarkerMap = function (map, handleClickMarker, viewMode) {
	return map ? Array.from(map.entries()).map(([key, value]) => {
		return <Marker key={`${key}-markermap`} position={value} eventHandlers={{
			click: () => {
				if (viewMode !== 'VIEW' && handleClickMarker) {
					handleClickMarker(key.id, value)
				}
			}
		}}
			icon={!!key.selected ? selectedIcon : icon}
		/>
	}) : null;
}

const DraggableMarker = ({ handleMarkerDrag, currentPosition }) => {

	const [draggable, setDraggable] = useState(false)
	let [position, setPosition] = useState(currentPosition)
	const markerRef = useRef(null)
	const eventHandlers = useMemo(
		() => ({
			dragend() {

				const marker = markerRef.current
				if (marker != null) {
					setPosition(marker.getLatLng())
					if (handleMarkerDrag) {
						handleMarkerDrag(marker.getLatLng())
					}
				}
			},
		}),
		[],
	)
	const toggleDraggable = useCallback(() => {
		setDraggable((d) => !d)
	}, [])
	return (
		<Marker
			key='draggable-marker'
			draggable={true}
			eventHandlers={eventHandlers}
			position={position}
			ref={markerRef}>
		</Marker>
	)
}
const icon = L.icon({
	iconRetinaUrl: require('./marker-icon-blue.svg'),
	iconUrl: require('./marker-icon-blue.svg'),
	iconSize: [64, 64],
	iconAnchor: [32, 64],
	popupAnchor: null,
	shadowSize: null,
	shadowAnchor: null,
});

const selectedIcon = L.icon({
	iconRetinaUrl: require('./marker-icon-red.svg'),
	iconUrl: require('./marker-icon-red.svg'),
	iconSize: [64, 64],
	iconAnchor: [32, 64],
	popupAnchor: null,
	shadowSize: null,
	shadowAnchor: null,
});
MapLeaflet.propTypes = {
	handleClickArea: PropTypes.func,
	handleClickMarker: PropTypes.func
}