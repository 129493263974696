import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import InputTextComponent from '../components/inputs/InputTextComponent';
import InputColorComponent from '../components/inputs/InputColorComponent';
import CategoryService from '../services/CategoryService';
import InputMultiSelectComponent from '../components/inputs/InputMultiSelectComponent';
import InputImageComponent from '../components/inputs/InputImageComponent';
import InputCheckboxComponent from '../components/inputs/InputCheckboxComponent';
import InternalUserService from '../services/InternalUserService';
import YesNoDialog from '../components/YesNoDialog';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputNumberComponent from '../components/inputs/InputNumberComponent';
import Constants from '../utils/constants';
class CategoryContainer extends BaseDetailsContainer {
	constructor(props) {
		super(props, new CategoryService());
		this.isHalfWidth = true;
		this.internalUserService = new InternalUserService();
		this.state = {
			loading: true,
			elementId: props.id,
			showChangeStatusDialog: false,
			targetOptions: [],
			element: {
				name: undefined,
				nameEng: undefined,
				users: [],
				color: undefined,
				priority: undefined,
				status: undefined,
				subcategories: [],
				targets: [Constants.CATEGORY_TARGET_NOTIFICATION, Constants.CATEGORY_TARGET_ISSUE],
				categoryNumber: 1,
			},
		};
		this.showChangeStatusDialog = this.showChangeStatusDialog.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
	}

	getContainerListName() {
		return 'category-list-container';
	}

	getAddSucces() {
		return 'Kategoria została utworzona';
	}

	getUpdateSucces() {
		return 'Kategoria została zaktualizowana';
	}

	updateElement(data) {
		if (data.users) {
			data.users.forEach(u => u.label = `${u.firstName} ${u.lastName}${u.removed ? ' (Usunięty)' : u.blocked ? ' (Zablokowany)': ''}`);
		}
		this.setState(
			{
				element: {
					id: data.id,
					name: data.name,
					nameEng: data.nameEng,
					users: data.users,
					color: data.color ? data.color : 'ffffff',
					priority: data.priority,
					status: data.status,
					subcategories: data.subcategories,
					targets: data.targets,
					categoryNumber: data.categoryNumber,
				},
			},
			() => {
				this.initAfterSetElement();
			}
		);
	}
	initAfterSetElement() {
		if (this.props.viewMode === 'EDIT' || this.props.viewMode === 'NEW') {
			const modifiedList = this.state.userOptions;
			if(this.state.element.users){
			this.state.element.users.forEach(u => {
				if (!this.state.userOptions.find(uo => uo.id === u.id)) {
					modifiedList.push(u);
				}
			});
			this.setState({userOptions: modifiedList}, ()=> super.initAfterSetElement())
		}
		} else { super.initAfterSetElement() }
	}
	setElement(){
		this.internalUserService.getUserReferenceList({ status: { enumValue: "ACTIVE", label: "Aktywna" }, notBlocked: true })
				.then(userOptions => {
					userOptions.forEach(u => u.label = `${u.firstName} ${u.lastName}`);
					this.setState({ userOptions }, () => super.setElement())
				})
				.catch(()=>this.setState({ userOptions: [] }, ()=> super.setElement()))
	}
	initBeforeSetElement() {
		if (this.props.viewMode === 'EDIT' || this.props.viewMode === 'NEW') {

			this.enumService.getEnumList('CategoryTarget')
				.then(targetOptions => this.setState({ targetOptions }))
				.catch(err => {
					this.showErrorMessage('Nie można pobrać listy celów kategorii');
				})
			this.enumService
				.getEnumList('CategoryStatus')
				.then((statusOptions) =>
					this.setState({
						statusOptions,
					})
				).catch(() => {
					this.showErrorMessage('Nie udało się pobrać listy statusów');
				});
		}
	}
	handleFormSubmit(event) {
		if (event !== undefined) {
			event.preventDefault();
		}
		if(!this.state.element.color || this.state.element.color === 'ffffff'){
			this.showErrorMessage('Wymagane jest ustawienie koloru kategorii');
			return;
		}
		if (this.validator.allValid()) {
			this.blockUi(this.handleValidForm);
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.scrollToError = true;
			this.forceUpdate();
		}
	}
	prepareFooterItems() {
		const { backUrl, cancelUrl, viewMode } = this.props;
		const { elementId } = this.state;
		const status = this.state.element?.status?.enumValue;
		let goBackUrl;
		if (viewMode === 'NEW') {
			goBackUrl = backUrl;
		} else {
			goBackUrl = `${cancelUrl}/${elementId}`;
		}
		return [
			{ label: 'Anuluj', href: goBackUrl, rendered: viewMode === 'NEW' || viewMode === 'EDIT' },
			{
				label: 'Zapisz',
				className: 'float-right',
				onClick: this.handleFormSubmit,
				rendered: this.isUserInAnyRole('ROLE_CATEGORY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') && (viewMode === 'NEW' || viewMode === 'EDIT'),
			},
			this.prepareChangeStatusButton(viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_CATEGORY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')),
		];
	}

	prepareHeaderItems() {
		const { viewMode } = this.props;
		return [
			{
				label: viewMode === 'NEW' ? 'Nowa kategoria' : viewMode === 'EDIT' ? 'Edycja kategorii' : 'Szczegóły kategorii',
				type: 'LABEL',
				className: '',
			},
			this.prepareEditButton(viewMode === 'VIEW' && this.isUserInAnyRole('ROLE_CATEGORY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE')),
		];
	}

	prepareChangeStatusButton(rendered) {
		const status = this.state.element?.status?.enumValue;
		return {
			label: status === 'ACTIVE' ? 'Dezaktywuj' : 'Aktywuj',
			type: 'BUTTON',
			variant: '',
			className: 'float-right',
			onClick: this.showChangeStatusDialog,
			rendered: rendered,
			iconName: status === 'ACTIVE' ? 'mdi-lock' : 'mdi-lock-open',
			iconSide: 'left',
			iconSize: 'm',
		};
	}

	showChangeStatusDialog() {
		this.setState({ showChangeStatusDialog: true });
	}

	getBackLabel() {
		return 'Wróć do listy';
	}

	changeStatus(type, costam, target) {
		const status = this.state.element?.status?.enumValue;
		if (target.value) {
			this.blockUi();
			this.service
				.changeStatus(this.state.element)
				.then((data) => {
					this.showSuccessMessage(`Kategoria została  ${status === 'ACTIVE' ? 'dezaktywowana' : 'aktywowana'}`);
					this.setState({ showChangeStatusDialog: false }, () => {
						this.updateElement(data);
					});
				})
				.catch((err) => {
					this.showErrorMessage(err.message);
					this.setState({ showChangeStatusDialog: false });
					this.unblockUi();
				});
		} else {
			this.setState({ showChangeStatusDialog: false });
		}
	}

	render() {
		const status = this.state.element?.status?.enumValue;
		return (
			<div className='container-fluid'>
				<BreadcrumbsItem to='/category-list' className='p-link'>
					{'Kategorie'}
				</BreadcrumbsItem>
				{this.props.viewMode === 'VIEW' ? <BreadcrumbsItem to='/category/details'>{'Szczegóły'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'EDIT' ? <BreadcrumbsItem to='/category/edit'>{'Edycja'}</BreadcrumbsItem> : null}
				{this.props.viewMode === 'NEW' ? <BreadcrumbsItem to='/category/create'>{'Nowy'}</BreadcrumbsItem> : null}
				<YesNoDialog
					onChange={this.changeStatus}
					visible={this.state.showChangeStatusDialog}
					header={status === 'ACTIVE' ? 'Dezaktywacja kategorii' : 'Aktywacja kategorii'}
					name="visible"
					onHide={() => {
						this.setState({ showChangeStatusDialog: false });
					}}>
					{status === 'ACTIVE' ? (
						<span>Czy na pewno chcesz dezaktywować wybraną kategorię?</span>
					) : (
						<span>Czy na pewno chcesz aktywować wybraną kategorię?</span>
					)}

				</YesNoDialog>
				{this.renderView()}
			</div>
		);
	}

	renderDetails() {


		return (
			<React.Fragment>
				<div class='row'>
					<InputTextComponent
						id="name"
						name="name"
						label="Nazwa w języku polskim"
						colClass='col-md-12'
						value={this.state.element.name}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'max:50|required'}
					/>
					<InputTextComponent
						id="nameEng"
						name="nameEng"
						label="Nazwa w języku angielskim"
						colClass='col-md-12'
						value={this.state.element.nameEng}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'max:50'}
					/>
					{/* {this.state.element.users.map((user) => {				
						return (
							<div className='user-box'>{user.firstName} {user.lastName}</div>
						);
					})} */}
					<InputMultiSelectComponent id="users" name="users"
						colClass='col-md-12'
						label="Odpowiedzialni użytkownicy"
						value={this.state.element.users}
						validator={this.validator}
						validators="not_required"
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						options={this.props.viewMode === 'VIEW' ? [] : this.state.userOptions}
						dataKey="id"
						optionLabel="label"
						filter
					/>
					<InputDropdownComponent
						colClass='col-md-12'
						label="Status"
						id="status"
						name="status"
						value={this.state.element.status}
						validator={this.validator}
						validators="not_required"
						onChange={this.handleChange}
						viewMode={this.props.viewMode === "NEW" ? this.props.viewMode : []}
						rendered={this.props.viewMode !== "NEW"}
						options={this.state.statusOptions}
						optionLabel="label"
					/>
					<InputMultiSelectComponent id="targets" name="targets"
						colClass='col-md-12'
						label="Cel"
						value={this.state.element.targets}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						options={this.props.viewMode === 'VIEW' ? [] : this.state.targetOptions}
						dataKey="enumValue"
						optionLabel="label"
						filter
					/>
					<InputNumberComponent
						id='categoryNumber'
						name='categoryNumber'
						label={'Lp.'}
						colClass='col-md-6'
						min="1"
						value={this.state.element.categoryNumber}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						validators={'naturalNumber|required'}
					/>
					<div class='col-md-12'></div>
					<InputCheckboxComponent
						id="priority"
						name="priority"
						label="Priorytet"
						value={this.state.element.priority}
						validator={this.validator}
						validators='not_required'
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
						colClass="col-md-6"
					/>
					<div class='col-md-12'></div>
					<InputColorComponent
						id="color"
						name="color"
						label="Kolor"
						colClass='col-md-6'
						value={this.props.viewMode === 'NEW' ? 'ffffff' : this.state.element.color}
						validator={this.validator}
						onChange={this.handleChange}
						viewMode={this.props.viewMode}
					/>
				</div>
			</React.Fragment>
		);
	}
}

CategoryContainer.defaultProps = {
	backUrl: '/#/category-list',
	cancelUrl: '/#/category/details',
	editUrl: '/#/category/edit',
};

CategoryContainer.propTypes = {
	backUrl: PropTypes.string,
	cancelUrl: PropTypes.string,
};

export default CategoryContainer;
