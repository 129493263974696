import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import PropTypes from 'prop-types';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import StaticContentService from "../services/StaticContentService";
import CriteriaTextComponent from "../components/criteria/CriteriaTextComponent";

class StaticContentListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new StaticContentService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            staticContentTypeOptions: [],
        };
    }

    updateSearchCriteria(criteria) {
        return {
            id: criteria.id,
            staticContentType: criteria.staticContentType,
            title: criteria.title,
            firstResult: criteria.firstResult,
            maxResult: criteria.maxResult,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCriteriaName() {
        return 'static-content-list-sc';
    }

    getContainerListName() {
        return 'static-content-list-container';
    }

    getCleanSearchCriteria() {
        return {
            type: null,
            title: '',
            staticContentType: false,
            id: null,
            firstResult: 0,
            maxResult: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
        this.service
            .getStaticContentTypes()
            .then((staticContentTypeOptions) =>
                this.setState({
                    staticContentTypeOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów treści statycznych');
            });
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaDropdownComponent
                    id='static-content-type-sc'
                    name='staticContentType'
                    showClear
                    label={'Typ'}
                    colClass='col-md-6'
                    value={this.state.criteria.staticContentType}
                    options={this.state.staticContentTypeOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    filter
                    filterBy="label"
                />
                <CriteriaTextComponent
                    id='title-sc'
                    name='title'
                    label={'Tytuł'}
                    colClass='col-md-6'
                    value={this.state.criteria.title}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                />
            </div>
        );
    }

    prepareColumns() {
        return [
            {field: 'id', header: 'ID', sortable: true, width: '90px'},
            {
                field: 'staticContentType',
                header: 'Typ',
                sortable: true,
                body: this.enumTemplate.bind(this, 'staticContentType')
            },
            {field: 'title', header: 'Tytuł', sortable: true},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    //override
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        }
        return '';
    }

    prepareHeaderItems() {
        return [];
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/static-content-list'>{'Treści statyczne'}</BreadcrumbsItem>
                {this.renderView()}
            </div>
        );
    }
}

StaticContentListContainer.defaultProps = {
    detailUrl: '/#/static-content/details',
    newUrl: '/#/static-content/create',
};

StaticContentListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default StaticContentListContainer;
