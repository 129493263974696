import BaseServiceCrud from './BaseServiceCrud';

export default class RefuseAreaService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'refuse-area';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			name: criteria.name,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	remove(id) {
		// Get a token from api server using the fetch api

		return this.fetch(`${this.domain}/${this.path}/${id}`, {
			method: 'DELETE',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	getActiveAreas() {
		return this.fetch(`${this.domain}/${this.path}/all-active`, {
			method: 'GET',
		})
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((err) => {
				throw err;
			});
	}
}
