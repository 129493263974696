import BaseServiceCrud from './BaseServiceCrud';

export default class PublicUnitService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'public-unit';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			name: criteria.name,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}

	deleteAccount(id) {
		// Get a token from api server using the fetch api
		return this.fetch(`${this.domain}/${this.path}/delete/${id}`, {
			method: 'PUT',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
	getAllActive() {
		return this.fetch(`${this.domain}/${this.path}/allActive`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
}
