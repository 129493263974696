import React from 'react';
import BaseContainer from '../baseContainers/BaseContainer';
import CustomMessages from '../components/CustomMessages';
import DivContainer from '../components/DivContainer';
import NavComponent from '../components/layouts/NavComponent';
import BlockUi from '../components/waitPanel/BlockUi';
import { Messages } from 'primereact/messages';
import Statistic from './Statistics';

class StartContainer extends BaseContainer {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			list: [],
			coordinate: 0
		};
	}
	componentDidMount(){
		super.componentDidMount();
		this.readMessage();
	}
	getContainerListName() {
        return 'start-container';
    }

    readMessage() {
        const messageFromStorage = this.readCookie(this.getContainerListName());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getContainerListName());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
            }
        }
    }
	render() {
		return (
			<DivContainer colClass='container-fluid' styling={{padding: 0}}>
				<DivContainer colClass='dashboard'>
					<BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
					<Messages id="custom-messages" ref={(el) => (this.messages = el)} ></Messages>
						{this.state.loading ? null : (
							<form onSubmit={this.handleFormSubmit} noValidate>
								{this.renderDetails()}
							</form>
						)}

					</BlockUi>
				</DivContainer>
			</DivContainer >
		);
	}

	renderDetails() {
		return (
			<Statistic limitedView={true}></Statistic>
		);
	}
}

export default StartContainer;
