import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';
export default class StatisticService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'statistic';
	}

	getCriteria(criteria) {
		return this.objToQueryString({
			date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_TIME_FORMAT) : undefined,
			date_to: criteria.dateTo ?  moment(criteria.dateTo).format(Constants.DATE_TIME_FORMAT) : undefined,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
}
