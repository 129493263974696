import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class NotificationService extends BaseServiceCrud {
	// Initializing important variables
	constructor() {
		super();
		this.path = 'notification';
	}

	getCriteria(criteria) {
		let statuses = [];
		if (criteria.status !== null && criteria.status && criteria.status.length) {
			criteria.status.forEach(element => {
				statuses.push(element.enumValue);
			});
		}
		return this.objToQueryString({
			id: criteria.id,
			text_sc: criteria.textSc,
			status: statuses ? statuses : undefined,
			public_unit_ids: criteria.publicUnitIds?.map(i =>i.id),
			sent_from: criteria.sentFrom ? moment(criteria.sentFrom).format(Constants.DATE_TIME_FORMAT) : undefined,
			sent_to: criteria.sentTo ? moment(criteria.sentTo).format(Constants.DATE_TIME_FORMAT) : undefined,
			create_from:criteria.createFromSc ? moment(criteria.createFromSc).format(Constants.DATE_TIME_FORMAT) : undefined,
			create_to: criteria.createToSc ? moment(criteria.createToSc).format(Constants.DATE_TIME_FORMAT) : undefined,
			active_from: criteria.activeFromSc ?  moment(criteria.activeFromSc).format(Constants.DATE_TIME_FORMAT) : undefined,
			active_to: criteria.activeToSc ?  moment(criteria.activeToSc).format(Constants.DATE_TIME_FORMAT) : undefined,
			has_attachments: criteria.hasAttachments,
			draft_only: criteria.draftOnly,
			author: criteria.author,
			category_id: criteria.category?.id,
			subcategory_id: criteria.subcategory?.id,
			first_result: criteria.firstResult,
			max_result: criteria.maxResult,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
		});
	}
	
	getSubcategories(categoryId) {
		return this.fetch(`${this.domain}/${this.path}/sub/${categoryId}`, {
			method: 'GET',
		})
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((err) => {
				throw err;
			});
	}
	publish(element) {
		return this.fetch(`${this.domain}/${this.path}/publish`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
	}
	publishDraft(element){
		return this.fetch(`${this.domain}/${this.path}/publishDraft/${element.id}`, {
			method: 'PUT',
			body: JSON.stringify(element),
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
}
