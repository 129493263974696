import $ from 'jquery';
import React from 'react';
import {
	FaBars, FaBook, FaCog, FaBell, FaEnvelopeSquare,
	FaChartLine,
	FaHome, FaSignOutAlt,
	FaUser,
	FaUserTie,
	FaExclamationTriangle,
	FaCalculator, FaRegEdit, FaBookOpen, FaPhone, FaTasks, FaRegNewspaper, FaTrash, FaMapMarkedAlt, FaInfo, FaTrashAlt, FaCalendarAlt
} from 'react-icons/fa';
import { IoMdCalendar } from 'react-icons/io';
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import header_logo from '../../assets/img/app_header_logo.png';


const Sidebar = ({ collapsed, toggled, loggedUser, handleToggleSidebar, handleCollapsedChange, handleLogoutUser, authService }) => {
	const userName = authService.getProfile().sub;
	const role = authService.getProfile().role;
	$(document).on('click', '.pro-item-content', function (e) {
		$('.pro-item-content').each(function (index) {
			$(this).removeClass('active');
		});
		$(this).addClass('active').siblings().removeClass('active');
	});
	const userRole = authService?.getProfile()?.userRole;
	return !authService.loggedIn() ? null : (
		<React.Fragment>
			<div className='btn-toggle' onClick={() => handleToggleSidebar(true)}>
				<FaBars />
			</div>
			<ProSidebar collapsed={collapsed} toggled={toggled} breakPoint='lg' onToggle={handleToggleSidebar} className='pro-sidebar-layout'>
				<SidebarHeader>
					<div class="sidebar-header-appname">
						<img className='header-logo naklo-sidebar' src={header_logo} alt='MojUrzad' />
					</div>
					<Menu iconShape='circle'>
						<MenuItem icon={<FaUser />}>
							{userName}
							<Link to='/manage-account-details' />
						</MenuItem>
					</Menu>
					{userRole === 'Jednostka' || userRole === 'Miasto' ?
						<Menu iconShape='circle'>
							<MenuItem icon={<FaEnvelopeSquare />}>
								Kontakt z administratorem
								<Link to='/contact-admin' />
							</MenuItem>
						</Menu> : null}
				</SidebarHeader>
				<SidebarContent>
					<Menu iconShape='circle'>
						<MenuItem icon={<FaHome />}>Strona główna<Link to='/start' /> </MenuItem>
					</Menu>
					{authService.isUserInAnyRole('ROLE_CATEGORY_MANAGE', 'ROLE_CATEGORY_VIEW', 'ROLE_ADMIN_SUPER_MANAGE') ?
						<Menu iconShape='circle' popperArrow='false'>
							<SubMenu title='Administracja' icon={<FaCog />}>
								{authService.isUserInAnyRole('ROLE_CATEGORY_MANAGE', 'ROLE_CATEGORY_VIEW', 'ROLE_ADMIN_SUPER_MANAGE') ? <MenuItem>Kategorie<Link to='/category-list' /></MenuItem> : null}
								{authService.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') ? <MenuItem>Szablony e-mail<Link to='/email-template-list' /></MenuItem> : null}
								{authService.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') ? <MenuItem>Dziennik zdarzeń<Link to='/event-log-list' /></MenuItem> : null}
								{authService.isUserInRole('ROLE_ADMIN_SUPER_MANAGE') ? <MenuItem>Konfiguracja aktualności<Link to='/news-configuration-list' /></MenuItem> : null}
							</SubMenu>
						</Menu> : null}
					{authService.isUserInAnyRole('ROLE_NOTIFY_VIEW', 'ROLE_NOTIFY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') ?
						<Menu iconShape='circle'>
							<MenuItem icon={<FaBell />}>Powiadomienia<Link to='/notification-list' /> </MenuItem>
						</Menu> : null}

					{authService.isUserInAnyRole('ROLE_EVENT_VIEW', 'ROLE_EVENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') ?
						<Menu iconShape='circle'>
							<MenuItem icon={<IoMdCalendar />}>Wydarzenia<Link to='/event-list' /> </MenuItem>
						</Menu>
						: null}

					{authService.isUserInAnyRole('ROLE_SURVEY_VIEW', 'ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') ?
						<Menu iconShape='circle'>
							<MenuItem icon={<FaChartLine />}>Konsultacje społeczne<Link to='/survey-list' /> </MenuItem>
						</Menu> : null}
					{authService.isUserInAnyRole('ROLE_INCIDENT_VIEW', 'ROLE_INCIDENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') ?
						<Menu iconShape='circle'>
							<MenuItem icon={<FaExclamationTriangle />}>Zgłoszenia<Link to='/incident-list' onClick={() => {
								return (
									sessionStorage.removeItem('incident-checked-list-container'),
									sessionStorage.removeItem('incident-checked-categories-list-container'),
									sessionStorage.removeItem('incident-checked-sub-categories-list-container'),
									sessionStorage.removeItem('incident-checked-assigned-user-list-container')
								);
							}}/>
							</MenuItem>
						</Menu> : null}
					<Menu iconShape='circle' popperArrow='false'>
						{authService.isUserInAnyRole('ROLE_INTERNAL_USER_VIEW', 'ROLE_INTERNAL_USER_MANAGE', 'ROLE_PUBLIC_UNIT_USER_VIEW','ROLE_PUBLIC_UNIT_USER_MANAGE' , 'ROLE_ADMIN_SUPER_MANAGE') || authService.isUserInAnyRole('ROLE_PUBLIC_UNIT_ACC_MANAGE','ROLE_PUBLIC_UNIT_ACC_VIEW', 'ROLE_ADMIN_SUPER_MANAGE','ROLE_PUBLIC_UNIT_ACC_LIMITED_MANAGE','ROLE_PUBLIC_UNIT_ACC_LIMITED_VIEW') ?
						<SubMenu title='Zarządzanie użytkownikami' icon={<FaUserTie />}>
							{authService.isUserInAnyRole('ROLE_INTERNAL_USER_VIEW', 'ROLE_INTERNAL_USER_MANAGE', 'ROLE_PUBLIC_UNIT_USER_VIEW','ROLE_PUBLIC_UNIT_USER_MANAGE' , 'ROLE_ADMIN_SUPER_MANAGE') ?<MenuItem>Użytkownicy<Link to='/user-list' /></MenuItem> : null}
							{authService.isUserInAnyRole('ROLE_PUBLIC_UNIT_ACC_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE', 'ROLE_PUBLIC_UNIT_ACC_VIEW','ROLE_PUBLIC_UNIT_ACC_LIMITED_MANAGE','ROLE_PUBLIC_UNIT_ACC_LIMITED_VIEW') ?<MenuItem>Działy/jednostki<Link to='/public-unit-list' /></MenuItem> :null}
						</SubMenu> : null}
					</Menu>
					{authService.isUserInAnyRole('ROLE_ADMIN_SUPER_MANAGE') ?
						<Menu iconShape='circle' popperArrow='false'>
							<SubMenu title='Zarządzanie treściami' icon={<FaRegNewspaper />}>
								<MenuItem icon={<FaRegEdit />}>Treści statyczne<Link to='/static-content-list' />
								</MenuItem>
								<MenuItem icon={<FaPhone />}>Numery telefonów<Link to='/phone-number-content-list' />
								</MenuItem>
								<MenuItem icon={<FaTasks />}>Zgody<Link to='/approval-content-list' /> </MenuItem>
							</SubMenu>
						</Menu>
						: null}
					<Menu iconShape='circle'>
						<MenuItem icon={<FaBookOpen />}>Regulamin<Link to='/regulations' /> </MenuItem>
					</Menu>
					<Menu iconShape='circle'>
						<MenuItem icon={<FaCalculator />}>Statystyki<Link to='/statistics-list' /> </MenuItem>
					</Menu>
					{authService.isUserInAnyRole('ROLE_REFUSE_AREA_VIEW', 'ROLE_REFUSE_AREA_MANAGE','ROLE_REFUSE_COLLECTION_INFORMATION_VIEW', 'ROLE_REFUSE_COLLECTION_INFORMATION_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') ?
						<Menu iconShape='circle' popperArrow='false'>
							<SubMenu title='Wywóz odpadów' icon={<FaTrash />}>
								{authService.isUserInAnyRole('ROLE_REFUSE_AREA_VIEW', 'ROLE_REFUSE_AREA_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') ? <MenuItem icon={<FaMapMarkedAlt />}>Lista obszarów<Link to='/refuse-area-list' /> </MenuItem> : null}
								{authService.isUserInAnyRole('ROLE_REFUSE_COLLECTION_INFORMATION_VIEW', 'ROLE_REFUSE_COLLECTION_INFORMATION_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') ? <MenuItem icon={<FaInfo />}>Informacje<Link to='/refuse-collection-information' /> </MenuItem> : null}
								{authService.isUserInAnyRole('ROLE_REFUSE_AREA_VIEW', 'ROLE_REFUSE_AREA_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') ? <MenuItem icon={<FaTrashAlt />}>Rodzaje odpadów<Link to='/refuse-type-list' /> </MenuItem> : null}
								{authService.isUserInAnyRole('ROLE_REFUSE_TIMETABLE_VIEW', 'ROLE_REFUSE_TIMETABLE_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE') ? <MenuItem icon={<FaCalendarAlt />}>Harmonogram<Link to='/refuse-timetable-list' /> </MenuItem> : null}</SubMenu>
						</Menu>
						: null}


				</SidebarContent>

				<SidebarFooter style={{ textAlign: 'center' }}>
					<div
						className='sidebar-btn-wrapper'
						style={{
							padding: '20px 24px',
						}}>
						<div onClick={handleLogoutUser} className='sidebar-btn' rel='noopener noreferrer'>
							<FaSignOutAlt />
							<span>Wyloguj</span>
						</div>
					</div>
					<div className='sidebar-btn-wrapper' style={{ padding: '5px', }}>
						<Switch
							height={16}
							width={30}
							checkedIcon={false}
							uncheckedIcon={false}
							onChange={handleCollapsedChange}
							checked={collapsed}
							onColor='#cd0a0a'
							offColor='#cd0a0a'
						/>
					</div>
				</SidebarFooter>
			</ProSidebar>
		</React.Fragment>
	);
};

export default Sidebar;
