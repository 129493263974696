import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaCalendarRangeComponent from '../components/criteria/CriteriaCalendarRangeComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import InternalUserService from '../services/InternalUserService';
import UserRoleService from '../services/UserRoleService';
import TileComponent from '../components/business/TileComponent';
import DivContainer from '../components/DivContainer';
import StatisticService from '../services/StatisticService';
import { PieChartComponent } from '../components/inputs/PieChartComponent';
import { ColumnChart } from '../components/inputs/ColumnChart';
import moment from 'moment';
import Constants from '../utils/constants';
class StatisticsContainer extends BaseListContainer {
	constructor(props) {
		super(props, new StatisticService());
		this.roleService = new UserRoleService();
		this.state = {
			element: {},
			loading: true,
			criteria: this.getCleanSearchCriteria(),
		};
	}

	updateSearchCriteria(criteria) {
		return {
			dateFrom: criteria.dateFrom,
			dateTo: criteria.dateTo,
			firstResult: criteria.firstResult,
			maxResult: criteria.maxResult,
			sortField: criteria.sortField,
			sortAsc: criteria.sortAsc,
		};
	}

	getCleanSearchCriteria() {
		return {
			dateFrom: undefined,
			dateTo: undefined,
			firstResult: 0,
			maxResult: 10,
			sortField: 'id',
			sortAsc: true,
		};
	}

	getFirstEnterSearchCriteria() {
		return {
			dateFrom: moment(this.dateFrom).startOf('day').subtract(30, 'days').format(Constants.DATE_TIME_FORMAT),
			dateTo: moment().format(Constants.DATE_TIME_FORMAT),
			firstResult: 0,
			maxResult: 10,
			sortField: 'id',
			sortAsc: true,
		};
	}
	
	assignData(data) {
		if (data) {
			return {
				messagesCount: data.messagesCount,
				incidentCount: data.incidentCount,
				surveyCount: data.surveyCount,
				voteCount: data.voteCount,
				followersCount: data.followersCount,
				notificationCount: data.notificationCount,
				genderGroup: data.genderGroupResult ? this.assignGenderGroup(data.genderGroupResult) : null,
				genderGroupActive: data.genderGroupActiveResult ? this.assignGenderGroup(data.genderGroupActiveResult) : null,
				publicUnits: data.publicUnits ? this.assignPublicUnits(data.publicUnits) : null
			}
		} else { return {} }
	}
	assignPublicUnits(from) {
		const modifiedList = [];
		if (from && from.length) {
			from.forEach(d => {
				const chunkList = [['Nazwa', 'Liczba powiadomień', 'Liczba użytkowników']]
				if (d && d.length) {
					d.forEach(dd => {
						chunkList.push([dd.label, parseInt(dd.notificationCount), parseInt(dd.usersCount)])
					})
					modifiedList.push(chunkList);
				}
			});
		}
		return modifiedList;
	}
	assignGenderGroup(from) {
		const modifiedList = [['', '']];

		if (from && from.length) {
			from.forEach(d => {
				if (d.label) {
					modifiedList.push([d.label, d.value])
				}
			});
		}
		return modifiedList;
	}
	getList() {
		const { criteria } = this.state;
		this.service
			.getList(criteria)
			.then((data) => {
				if (this._isMounted) {
					this.setState(
						{
							element: this.assignData(data),
							loading: false,
						},
						() => this.onAfterGetList()
					);
				}
			})
			.catch((err) => {
				if (this._isMounted) {
					this.setState(
						{
							element: {},
							loading: false,
						},
						() => {
							this.showErrorMessage('Wystąpił błąd systemu. Nie udało się pobrać zawartości.', 10000);
						}
					);
				}
			});
	}
	getCriteriaName() {
		return 'statistics-list-sc';
	}

	getContainerListName() {
		return 'statistics-list-container';
	}

	renderCriteria() {
		return (
			<div className='row'>
				<CriteriaCalendarRangeComponent
					key={`time`}
					idFrom={`dateFrom`}
					idTo={`dateTo`}
					nameTo={'dateTo'}
					nameFrom={'dateFrom'}
					label={'Data'}
					labelFrom="od"
					labelTo="do"
					//colClass={column.colClass ? column.colClass : 'col-md-6'}
					colClass={'col-12'}
					from={this.state.criteria.dateFrom}
					to={this.state.criteria.dateTo}
					validator={this.validator}
					onChange={this.handleChangeSc}
					viewMode={this.props.viewMode}
					showIcon
					onlyTime
					placeholderFrom={'dd-mm-rrrr GG:MM'}
					placeholderTo={'dd-mm-rrrr GG:MM'}
					validateOnChange={false}
					stateField=""
					showTime
					hourFormat="24"
					maxDateInfinite
					showSeconds={false}
				/>
			</div>
		);
	}
	mapCriteria(criteria) {
		criteria.dateFrom = criteria.dateFrom ? new Date(criteria.dateFrom) : undefined;
		criteria.dateTo = criteria.dateTo ? new Date(criteria.dateTo) : undefined;
		return criteria;
	}
	renderDataTable() {
		return <React.Fragment><DivContainer colClass='row tile-component-row'>
			<TileComponent fullBg={true} variant="black"
				colClass='col-xl-4 col-md-6 mb-4'
				styling={{display: 'flex'}}
				title="Liczba powiadomień"
				content={this.state.element.notificationCount}
				onLinkClick={() => {
					let sc = {
						sentFrom: moment(this.dateFrom).startOf('day').subtract(30, 'days').format(Constants.DATE_TIME_FORMAT),
						sentTo: moment().format(Constants.DATE_TIME_FORMAT),
						firstResult: 0,
						maxResult: 10,
						sortField: "id",
						sortAsc: false,
						limitObj: {
							value: 10
						}
					}

					this.saveCookie('notification-list-sc', JSON.stringify(sc));
					this.saveCookie('notification-list-activeIndex', 0);
					window.location.href = '/#/notification-list';
				}} />
			<TileComponent fullBg={true} variant="red"
				colClass='col-xl-4 col-md-6 mb-4'
				styling={{display: 'flex'}}
				title="Liczba zgłoszeń dokonanych przez użytkowników"
				content={this.state.element.incidentCount}
				//renderedLink={false}
				onLinkClick={() => {
					let sc = {
						sentFrom: moment(this.dateFrom).startOf('day').subtract(30, 'days').format(Constants.DATE_TIME_FORMAT),
						sentTo: moment().format(Constants.DATE_TIME_FORMAT),
						firstResult: 0,
						maxResult: 10,
						sortField: "id",
						sortAsc: false,
						limitObj: {
							value: 10
						}
					}

					this.saveCookie('incident-list-sc', JSON.stringify(sc));
					this.saveCookie('incident-list-activeIndex', 0);
					window.location.href = '/#/incident-list';
				}} />
			<TileComponent fullBg={true} variant="gray"
				title="Liczba przeprowadzonych ankiet/&#8203;sondaży/&#8203;głosowań"
				colClass='col-xl-4 col-md-6 mb-4'
				styling={{display: 'flex'}}
				content={this.state.element.surveyCount}
				onLinkClick={() => {
					let sc = {
						activeFromSc: moment(this.dateFrom).startOf('day').subtract(30, 'days').format(Constants.DATE_TIME_FORMAT),
						activeToSc: moment().format(Constants.DATE_TIME_FORMAT),
						firstResult: 0,
						maxResult: 10,
						sortField: "id",
						sortAsc: false,
						limitObj: {
							value: 10
						}
					}

					this.saveCookie('survey-list-sc', JSON.stringify(sc));
					this.saveCookie('survey-list-activeIndex', 0);
					window.location.href = '/#/survey-list';
				}} />
		</DivContainer>
			<DivContainer colClass='row tile-component-row'>
				<TileComponent fullBg={true} variant="green"
					colClass='col-xl-4 col-md-6 mb-4'
					styling={{display: 'flex'}}
					title="Liczba udzielonych odpowiedzi w ankietach/&#8203;sondażach/&#8203;głosowaniach"
					content={this.state.element.voteCount}
					renderedLink={false}
				/>
				<TileComponent fullBg={true} variant="blue"
					title="Liczba “Popieram” przy zgłoszonych inicjatywach na mapie"
					colClass='col-xl-4 col-md-6 mb-4'
					styling={{display: 'flex'}}
					content={this.state.element.followersCount}
					renderedLink={false}
				/>
				<TileComponent fullBg={true} variant="yellow"
					title="Liczba odczytanych wiadomości"
					colClass='col-xl-4 col-md-6 mb-4'
					styling={{display: 'flex'}}
					content={this.state.element.messagesCount}
					renderedLink={false}
				/>
			</DivContainer>
			{!this.props.limitedView ?
				<DivContainer styling={{ padding: 0 }}>
					<DivContainer colClass='row tile-component-row'>
						<PieChartComponent data={this.state.element.genderGroup} questionName={"Podział użytkowników aplikacji mobilnej ze względu na wiek"} />
					</DivContainer>
					<DivContainer colClass='row tile-component-row'>
						<PieChartComponent data={this.state.element.genderGroupActive} questionName={"Podział aktywnych użytkowników aplikacji mobilnej ze względu na wiek"} />
					</DivContainer>
					<DivContainer colClass='row tile-component-row'>
						{this.state.element.publicUnits && this.state.element.publicUnits.length ? this.state.element.publicUnits.map(pu => {
							return (
								<ColumnChart data={pu} title={"Działy/jednostki"} vAxisTitle={""} />
							)
						}) : null}

					</DivContainer>
				</DivContainer>
				: null}
		</React.Fragment>
	}
	renderPagination() {

	}

	prepareHeaderItems() {
		const { criteria } = this.state;
		console.log('prepareHeaderItems')
		if (!this.props.limitedView) {
			return [{
				label: 'Wydrukuj',
				onClick: (e) => {
					e.preventDefault();
					this.blockUi();
					this.service.download('Statystyki.pdf', {
						date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_TIME_FORMAT) : undefined,
						date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_TIME_FORMAT) : undefined
					}, this.authService.getToken(), () => {
						this.messages.show({
							severity: 'error',
							summary: 'Błąd',
							detail: 'Nie udało się pobrać statystyk',
						});
						this.unblockUi();
					}, () => this.unblockUi());
				}, type: 'BUTTON',
				className: 'float-right',
				variant: 'yellow',
				iconName: 'mdi-printer',
				iconSide: 'left',
				iconColor: 'white',
			},];
		}
	}

	render() {
		return (
			<div className='container-fluid'>
				{!this.props.limitedView ? <BreadcrumbsItem to='/statistics-list'>{'Statystyki'}</BreadcrumbsItem> : null}
				{this.renderView()}
			</div>
		);
	}
}

StatisticsContainer.defaultProps = {
	detailUrl: '/#/user/details',
	newUrl: '/#/user/create',
};

StatisticsContainer.propTypes = {
	detailUrl: PropTypes.string,
	newUrl: PropTypes.string,
};

export default StatisticsContainer;
