import $ from 'jquery';
import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import CookieConsent from './components/CookieConsent';
import CookiesContainer from './containers/CookiesContainer';
import EmailContainer from './containers/EmailContainer';
import EmailListContainer from './containers/EmailListContainer';
import EventLogContainer from './containers/EventLogContainer';
import EventLogListContainer from './containers/EventLogListContainer';
import HelpContainer from './containers/HelpContainer';
import Sidebar from './components/layouts/Sidebar';
import Login from './containers/LoginContainer';
import ManageAccountContainer from './containers/ManageAccountContainer';
import SettingContainer from './containers/SettingContainer';
import SettingListContainer from './containers/SettingListContainer';
import CategoryListContainer from './containers/CategoryListContainer';
import CategoryContainer from './containers/CategoryContainer';
import StartContainer from './containers/StartContainer';
import UserListContainer from './containers/UserListContainer';
import UserContainer from './containers/UserContainer';
import PublicUnitListContainer from './containers/PublicUnitListContainer';
import PublicUnitContainer from './containers/PublicUnitContainer';

import ResetPasswordContainer from './containers/users/ResetPasswordContainer';
import ConfirmPasswordContainer from './containers/users/ConfirmPasswordContainer';
import MobileConfirmPasswordContainer from './containers/users/MobileConfirmPasswordContainer';

import AuthService from './services/AuthService';
import withAuth from './utils/withAuth';
import UserService from "./services/UserService";
import StaticContentListContainer from "./containers/StaticContentListContainer";
import StaticContentContainer from "./containers/StaticContentContainer";
import RegulationsContainer from "./containers/RegulationsContainer";
import ApprovalContentListContainer from "./containers/ApprovalContentListContainer";
import ApprovalContentContainer from "./containers/ApprovalContentContainer";
import PhoneNumberContentContainer from "./containers/PhoneNumberContentContainer";
import PhoneNumberContentListContainer from "./containers/PhoneNumberContentListContainer";
import SubcategoryContainer from './containers/SubcategoryContainer';
import ChangePasswordContainer from './containers/users/ChangePasswordContainer';
import NotificationListContainer from './containers/notification/NotificationListContainer';
import NotificationContainer from './containers/notification/NotificationContainer';
import EventListContainer from './containers/event/EventListContainer'
import ContactContainer from './containers/ContactContainer';
import SurveyContainer from './containers/survey/SurveyContainer';
import SurveyContainerList from './containers/survey/SurveyContainerList';
import SurveyResultGenerator from './containers/survey/SurveyResultGenerator';
import IncidentListContainer from './containers/incident/IncidentListContainer';
import IncidentContainer from './containers/incident/IncidentContainer';
import StatisticsContainer from './containers/Statistics';
import NewsConfigurationContainer from './containers/NewsConfigurationContainer';
import NewsConfigurationListContainer from './containers/NewsConfigurationListContainer';
import EventContainer from './containers/event/EventContainer';
import RefuseAreaListContainer from './containers/refuse-area/RefuseAreaListContainer';
import RefuseAreaContainer from './containers/refuse-area/RefuseAreaContainer';
import RefuseCollectionInformationContainer from './containers/RefuseCollectionInformationContainer';import RefuseTypeContainer from './containers/RefuseTypeContainer';
import RefuseTypeListContainer from './containers/RefuseTypeListContainer';
import RefuseTimetableContainer from './containers/refuse-timetable/RefuseTimetableContainer';
import RefuseTimetableListContainer from './containers/refuse-timetable/RefuseTimetableListContainer';
import VersionContainer from "./containers/VersionContainer";
class App extends Component {
    constructor() {
        super();
        this.authService = new AuthService();
        this.userService = new UserService();
        this.state = {
            user: this.authService.getProfile(),
            collapsed: false,
            toggled: false,
        };
        this.handleLogoutUser = this.handleLogoutUser.bind(this);
    }

    handleToggleSidebar() {
        this.setState((prevState) => ({ toggled: !prevState.toggled }));
    }

    handleCollapsedChange() {
        this.setState((prevState) => ({ collapsed: !prevState.collapsed }), () => {
            if (this.state.collapsed) {
                $(".pro-sidebar-inner").css('position', 'relative');
                $(".naklo-sidebar").css('display', 'none');
            } else {
                $(".pro-sidebar-inner").css('position', 'fixed');
                $(".naklo-sidebar").css('display', 'block');

            }
        });
    }

    handleLogoutUser(url) {
        const lastUseToken = this.authService.getToken();
        this.authService.logout();
        if (url) {
            window.location.href = '/#login?location=' + url;
        } else {
            window.location.href = '/#/login?force=true';
        }
        if (this.state.user) {
            this.setState({ user: null });
        }
        if (lastUseToken !== undefined && lastUseToken !== null) {
            this.userService.logout(lastUseToken);
        }
    }

    afterPreLogoutUser() {
    }

    handlePreLogoutUser(callBack) {
        this.authService.logout();
        if (this.state.user !== null) {
            this.setState({ user: null }, () => this.afterPreLogoutUser());
            this.setState({ user: null }, () => {
                if (callBack) callBack();
            });
        } else {
            if (callBack) callBack();
        }
    }


    render() {
        const authService = this.authService;
        return (
            <React.Fragment>
                <CookieConsent
                    location='bottom'
                    buttonText='Akceptuję'
                    cookieName='cookiePolicy'
                    style={{ background: 'rgba(0, 0, 0, 0.8)' }}
                    buttonStyle={{
                        background: '#1c3275',
                        color: 'white',
                        fontSize: '16px',
                        fontFamily: 'Ubuntu',
                        fontWeight: 500,
                        borderRadius: '3px'
                    }}
                    expires={150}>
                    Nasz serwis wykorzystuje pliki cookies. Możesz zrezygnować ze zbierania informacji poprzez pliki
                    cookies, zmieniając ustawienia Twojej
                    przeglądarki - w takim przypadku nie gwarantujemy poprawnego działania serwisu.
                </CookieConsent>
                <Router>
                    <div className={`${authService.loggedIn() ? 'app' : ''}`}>
                        <Sidebar
                            collapsed={this.state.collapsed}
                            toggled={this.state.toggled}
                            loggedUser={this.state.user}
                            handleToggleSidebar={this.handleToggleSidebar.bind(this)} O
                            handleCollapsedChange={this.handleCollapsedChange.bind(this)}
                            handleLogoutUser={this.handleLogoutUser.bind(this)}
                            authService={this.authService}
                        />
                        <main>
                            <div className={`${authService.loggedIn() ? 'container-fluid' : ''}`}>
                                <Switch>
                                    <Route exact path='/' render={(props) => (<Login {...props} onAfterLogin={() => {
                                        this.setState({ user: this.authService.getProfile().sub });
                                    }} />)} />
                                    <Route path='/login' render={(props) => (<Login {...props} onAfterLogin={() => {
                                        this.setState({ user: this.authService.getProfile().sub });
                                    }} />)} />
                                    <Route exact path='/start'
                                        component={withAuth(StartContainer, 'VIEW', undefined, this.handleLogoutUser)} />
                                    <Route exact path='/help-page'
                                        component={withAuth(HelpContainer, 'VIEW', ['ROLE_ADMIN', 'ROLE_DISPATCHER'], this.handleLogoutUser)} />
                                    <Route exact path='/cookies-page' component={CookiesContainer} />
                                    {/* <Route exact path='/user-list' component={withAuth(UserListContainer, 'VIEW', ['ROLE_ADMIN_INTERNAL_USER_VIEW','ROLE_ADMIN_INTERNAL_USER_MANAGE' ], this.handleLogoutUser)} /> */}
                                    <Route exact path='/user-list' component={withAuth(UserListContainer, 'VIEW', ['ROLE_INTERNAL_USER_VIEW', 'ROLE_INTERNAL_USER_MANAGE', 'ROLE_PUBLIC_UNIT_USER_VIEW', 'ROLE_PUBLIC_UNIT_USER_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/user/create' component={withAuth(UserContainer, 'NEW', ['ROLE_INTERNAL_USER_MANAGE', 'ROLE_PUBLIC_UNIT_USER_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/user/details/:id' component={withAuth(UserContainer, 'VIEW', ['ROLE_INTERNAL_USER_VIEW', 'ROLE_INTERNAL_USER_MANAGE', 'ROLE_PUBLIC_UNIT_USER_VIEW', 'ROLE_PUBLIC_UNIT_USER_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/user/edit/:id' component={withAuth(UserContainer, 'EDIT', ['ROLE_INTERNAL_USER_MANAGE', 'ROLE_PUBLIC_UNIT_USER_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, {
                                        logoutFunc: this.handleLogoutUser.bind(this),
                                    })} /><Route exact path='/public-unit/create' component={withAuth(PublicUnitContainer, 'NEW', ['ROLE_PUBLIC_UNIT_ACC_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/public-unit/details/:id' component={withAuth(PublicUnitContainer, 'VIEW', ['ROLE_PUBLIC_UNIT_ACC_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE', 'ROLE_PUBLIC_UNIT_ACC_VIEW','ROLE_PUBLIC_UNIT_ACC_LIMITED_MANAGE','ROLE_PUBLIC_UNIT_ACC_LIMITED_VIEW'], this.handleLogoutUser)} />
                                    <Route exact path='/public-unit/edit/:id' component={withAuth(PublicUnitContainer, 'EDIT', ['ROLE_PUBLIC_UNIT_ACC_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE','ROLE_PUBLIC_UNIT_ACC_LIMITED_MANAGE'], this.handleLogoutUser, undefined, {
                                        logoutFunc: this.handleLogoutUser.bind(this),
                                    })} />
                                    <Route exact path='/public-unit-list' component={withAuth(PublicUnitListContainer, 'VIEW', ['ROLE_PUBLIC_UNIT_ACC_MANAGE', 'ROLE_PUBLIC_UNIT_ACC_VIEW', 'ROLE_ADMIN_SUPER_MANAGE','ROLE_PUBLIC_UNIT_ACC_LIMITED_MANAGE','ROLE_PUBLIC_UNIT_ACC_LIMITED_VIEW'], this.handleLogoutUser)} />
                                    <Route exact path='/category-list'
                                        component={withAuth(CategoryListContainer, 'VIEW', ['ROLE_CATEGORY_MANAGE', 'ROLE_CATEGORY_VIEW', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/category/details/:id'
                                        component={withAuth(CategoryContainer, 'VIEW', ['ROLE_CATEGORY_MANAGE', 'ROLE_CATEGORY_VIEW', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/category/create'
                                        component={withAuth(CategoryContainer, 'NEW', ['ROLE_CATEGORY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/category/edit/:id'
                                        component={withAuth(CategoryContainer, 'EDIT', ['ROLE_CATEGORY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/category/details/:id/sub/:subId'
                                        component={withAuth(SubcategoryContainer, 'VIEW', ['ROLE_CATEGORY_MANAGE', 'ROLE_CATEGORY_VIEW', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/category/edit/:id/sub/:subId'
                                        component={withAuth(SubcategoryContainer, 'EDIT', ['ROLE_CATEGORY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/category/create/:id/sub'
                                        component={withAuth(SubcategoryContainer, 'NEW', ['ROLE_CATEGORY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/setting-list'
                                        component={withAuth(SettingListContainer, 'VIEW', ['ROLE_ADMIN', 'ROLE_DISPATCHER'], this.handleLogoutUser)} />
                                    <Route exact path='/setting/details/:id'
                                        component={withAuth(SettingContainer, 'VIEW', ['ROLE_ADMIN', 'ROLE_DISPATCHER'], this.handleLogoutUser)} />
                                    <Route exact path='/setting/edit/:id'
                                        component={withAuth(SettingContainer, 'EDIT', ['ROLE_ADMIN', 'ROLE_DISPATCHER'], this.handleLogoutUser)} />
                                    <Route exact path='/email-template-list'
                                        component={withAuth(EmailListContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/email-template/details/:id'
                                        component={withAuth(EmailContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/email-template/edit/:id'
                                        component={withAuth(EmailContainer, 'EDIT', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/event-log-list'
                                        component={withAuth(EventLogListContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/event-log/details/:id'
                                        component={withAuth(EventLogContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route path='/manage-account-details'
                                        component={withAuth(UserContainer, 'VIEW', undefined, this.handleLogoutUser, undefined, { 'myId': true })} />
                                    <Route path='/manage-account-edit'
                                        component={withAuth(UserContainer, 'EDIT', undefined, this.handleLogoutUser, undefined, { 'myId': true })} />
                                    <Route exact path='/email-template-list'
                                        component={withAuth(EmailListContainer, 'VIEW', ['ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/email-template/details/:id'
                                        component={withAuth(EmailContainer, 'VIEW', ['ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/email-template/edit/:id'
                                        component={withAuth(EmailContainer, 'EDIT', ['ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route path='/reset-password' component={ResetPasswordContainer} />

                                    <Route
                                        path='/confirm-reset-password'
                                        component={(props) => (
                                            <ConfirmPasswordContainer {...props} type='RESET'
                                                handlePreLogoutUser={this.handlePreLogoutUser.bind(this)} />
                                        )}
                                    />
                                    <Route
                                        path='/mobile/confirm-reset-password'
                                        component={(props) => (
                                            <MobileConfirmPasswordContainer {...props} type='RESET'
                                                handlePreLogoutUser={this.handlePreLogoutUser.bind(this)} />
                                        )}
                                    />
                                    <Route
                                        path='/user-register'
                                        component={(props) => (
                                            <ConfirmPasswordContainer {...props} type='CREATE'
                                                handlePreLogoutUser={this.handlePreLogoutUser.bind(this)} />
                                        )}
                                    />
                                    <Route exact path='/static-content-list'
                                        component={withAuth(StaticContentListContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/static-content/details/:id'
                                        component={withAuth(StaticContentContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/static-content/edit/:id'
                                        component={withAuth(StaticContentContainer, 'EDIT', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/approval-content-list'
                                        component={withAuth(ApprovalContentListContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/approval-content/create'
                                        component={withAuth(ApprovalContentContainer, 'NEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/approval-content/details/:id'
                                        component={withAuth(ApprovalContentContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/approval-content/edit/:id'
                                        component={withAuth(ApprovalContentContainer, 'EDIT', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/phone-number-content-list'
                                        component={withAuth(PhoneNumberContentListContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/phone-number-content/create'
                                        component={withAuth(PhoneNumberContentContainer, 'NEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/phone-number-content/details/:id'
                                        component={withAuth(PhoneNumberContentContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/phone-number-content/edit/:id'
                                        component={withAuth(PhoneNumberContentContainer, 'EDIT', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/regulations'
                                        component={withAuth(RegulationsContainer, 'EDIT', undefined, this.handleLogoutUser)} />
                                    <Route path='/change-password' component={withAuth(ChangePasswordContainer, 'EDIT', undefined, this.handleLogoutUser)} />
                                    <Route exact path='/notification-list' component={withAuth(NotificationListContainer, 'VIEW', ['ROLE_NOTIFY_VIEW', 'ROLE_NOTIFY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/notification/create' component={withAuth(NotificationContainer, 'NEW', ['ROLE_NOTIFY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/notification/details/:id' component={withAuth(NotificationContainer, 'VIEW', ['ROLE_NOTIFY_VIEW', 'ROLE_NOTIFY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/notification/edit/:id' component={withAuth(NotificationContainer, 'EDIT', ['ROLE_NOTIFY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, {
                                        logoutFunc: this.handleLogoutUser.bind(this),
                                    })} />
                                    <Route exact path='/event-list' component={withAuth(EventListContainer, 'VIEW', ['ROLE_EVENT_VIEW', 'ROLE_EVENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/contact-admin' component={withAuth(ContactContainer, 'NEW', undefined, this.handleLogoutUser, undefined, {
                                        logoutFunc: this.handleLogoutUser.bind(this),
                                    })} />
                                    <Route exact path='/survey-list' component={withAuth(SurveyContainerList, 'NEW', ['ROLE_SURVEY_VIEW', 'ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, {
                                        logoutFunc: this.handleLogoutUser.bind(this),
                                    })} />
                                    <Route exact path='/survey/create' component={withAuth(SurveyContainer, 'NEW', ['ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, { logoutFunc: this.handleLogoutUser.bind(this) })} />
                                    <Route exact path='/survey/details/:id' component={withAuth(SurveyContainer, 'VIEW', ['ROLE_SURVEY_VIEW', 'ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, { logoutFunc: this.handleLogoutUser.bind(this) })} />
                                    <Route exact path='/survey/edit/:id' component={withAuth(SurveyContainer, 'EDIT', ['ROLE_SURVEY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, { logoutFunc: this.handleLogoutUser.bind(this) })} />
                                    <Route exact path='/generate-survey-result' component={withAuth(SurveyResultGenerator, 'EDIT', ['ROLE_TESTER_MANAGE'], this.handleLogoutUser, undefined, { logoutFunc: this.handleLogoutUser.bind(this) })} />
                                    <Route exact path='/incident-list' component={withAuth(IncidentListContainer, 'NEW', ['ROLE_INCIDENT_VIEW', 'ROLE_INCIDENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, {
                                        logoutFunc: this.handleLogoutUser.bind(this),
                                    })} />
                                    <Route exact path='/incident/details/:id' component={withAuth(IncidentContainer, 'VIEW', ['ROLE_INCIDENT_VIEW', 'ROLE_INCIDENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, { logoutFunc: this.handleLogoutUser.bind(this) })} />
                                    <Route exact path='/statistics-list' component={withAuth(StatisticsContainer, 'VIEW', ['ROLE_NOTIFY_VIEW', 'ROLE_NOTIFY_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
  									<Route exact path='/news-configuration-list'
                                        component={withAuth(NewsConfigurationListContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/news-configuration/details/:id'
                                        component={withAuth(NewsConfigurationContainer, 'VIEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/news-configuration/create'
                                        component={withAuth(NewsConfigurationContainer, 'NEW', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/news-configuration/edit/:id'
                                        component={withAuth(NewsConfigurationContainer, 'EDIT', ['ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/event/create' component={withAuth(EventContainer, 'NEW', ['ROLE_EVENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/event/details/:id' component={withAuth(EventContainer, 'VIEW', ['ROLE_EVENT_VIEW', 'ROLE_EVENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/event/edit/:id' component={withAuth(EventContainer, 'EDIT', ['ROLE_EVENT_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, {
                                        logoutFunc: this.handleLogoutUser.bind(this),
                                    })} />
                                    <Route exact path='/refuse-area/create' component={withAuth(RefuseAreaContainer, 'NEW', ['ROLE_REFUSE_AREA_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/refuse-area/details/:id' component={withAuth(RefuseAreaContainer, 'VIEW', ['ROLE_REFUSE_AREA_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE', 'ROLE_REFUSE_AREA_VIEW'], this.handleLogoutUser)} />
                                    <Route exact path='/refuse-area/edit/:id' component={withAuth(RefuseAreaContainer, 'EDIT', ['ROLE_REFUSE_AREA_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, {
                                        logoutFunc: this.handleLogoutUser.bind(this),
                                    })} />
                                    <Route exact path='/refuse-area-list' component={withAuth(RefuseAreaListContainer, 'VIEW', ['ROLE_REFUSE_AREA_MANAGE', 'ROLE_REFUSE_AREA_VIEW', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/refuse-collection-information/create' component={withAuth(RefuseCollectionInformationContainer, 'NEW', ['ROLE_REFUSE_COLLECTION_INFORMATION_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/refuse-collection-information/edit/:id' component={withAuth(RefuseCollectionInformationContainer, 'EDIT', ['ROLE_REFUSE_COLLECTION_INFORMATION_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, {
                                        logoutFunc: this.handleLogoutUser.bind(this),
                                    })} />
                                    <Route exact path='/refuse-collection-information' component={withAuth(RefuseCollectionInformationContainer, 'VIEW', ['ROLE_REFUSE_COLLECTION_INFORMATION_MANAGE', 'ROLE_REFUSE_COLLECTION_INFORMATION_VIEW', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    
                                    <Route exact path='/refuse-type/create' component={withAuth(RefuseTypeContainer, 'NEW', ['ROLE_REFUSE_AREA_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/refuse-type/details/:id' component={withAuth(RefuseTypeContainer, 'VIEW', ['ROLE_REFUSE_AREA_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE', 'ROLE_REFUSE_AREA_VIEW'], this.handleLogoutUser)} />
                                    <Route exact path='/refuse-type/edit/:id' component={withAuth(RefuseTypeContainer, 'EDIT', ['ROLE_REFUSE_AREA_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, {
                                        logoutFunc: this.handleLogoutUser.bind(this),
                                    })} />
                                    <Route exact path='/refuse-type-list' component={withAuth(RefuseTypeListContainer, 'VIEW', ['ROLE_REFUSE_AREA_MANAGE', 'ROLE_REFUSE_AREA_VIEW', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/refuse-timetable/create' component={withAuth(RefuseTimetableContainer, 'NEW', ['ROLE_REFUSE_TIMETABLE_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route exact path='/refuse-timetable/details/:id' component={withAuth(RefuseTimetableContainer, 'VIEW', ['ROLE_REFUSE_TIMETABLE_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE', 'ROLE_REFUSE_TIMETABLE_VIEW'], this.handleLogoutUser)} />
                                    <Route exact path='/refuse-timetable/edit/:id' component={withAuth(RefuseTimetableContainer, 'EDIT', ['ROLE_REFUSE_TIMETABLE_MANAGE', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser, undefined, {
                                        logoutFunc: this.handleLogoutUser.bind(this),
                                    })} />
                                    <Route exact path='/refuse-timetable-list' component={withAuth(RefuseTimetableListContainer, 'VIEW', ['ROLE_REFUSE_TIMETABLE_MANAGE', 'ROLE_REFUSE_TIMETABLE_VIEW', 'ROLE_ADMIN_SUPER_MANAGE'], this.handleLogoutUser)} />
                                    <Route path='/version' render={(props) => <VersionContainer />} />
                                </Switch>
                            </div>
                        </main>
                    </div>
                </Router>
            </React.Fragment>
        );
    }
}

export default App;
